<template>
  <div class="jigsaw">
    <ul
      class="image-container"
      @dragstart="dragStart"
      @dragover.prevent
      @drop="dragDrop"
    >
      <li
        :style="styles"
        v-for="tile in tiles"
        :key="tile.id"
        :class="tile.className"
        :data-index="tile.dataIndex"
        draggable="true"
      ></li>
      <v-btn @click="startGame" dark color="teal" class="mt-5">Start</v-btn>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["link"],
  data() {
    return {
      images: [
        { id: 0, dataIndex: 0, className: "list0" },
        { id: 1, dataIndex: 1, className: "list1" },
        { id: 2, dataIndex: 2, className: "list2" },
        { id: 3, dataIndex: 3, className: "list3" },
        { id: 4, dataIndex: 4, className: "list4" },
        { id: 5, dataIndex: 5, className: "list5" },
        { id: 6, dataIndex: 6, className: "list6" },
        { id: 7, dataIndex: 7, className: "list7" },
        { id: 8, dataIndex: 8, className: "list8" },
        { id: 9, dataIndex: 9, className: "list9" },
        { id: 10, dataIndex: 10, className: "list10" },
        { id: 11, dataIndex: 11, className: "list11" },
        { id: 12, dataIndex: 12, className: "list12" },
        { id: 13, dataIndex: 13, className: "list13" },
        { id: 14, dataIndex: 14, className: "list14" },
        { id: 15, dataIndex: 15, className: "list15" },
      ],
      tiles: [],
      draggedTile: {},
    };
  },
  computed: {
    styles() {
      return {
        "background-image": `url("${this.link.puzzleImg}")`,
        width: "100px",
        height: "100px",
      };
    },
  },
  methods: {
    createTiles() {
      this.tiles = [...this.images];
    },
    shuffleTiles() {
      return this.tiles.sort(() => Math.random() - 0.5);
    },
    startGame() {
      this.createTiles();
      setTimeout(() => {
        this.shuffleTiles();
      }, 3000);
    },
    dragStart(e) {
      this.draggedTile = {
        el: e.target,
        class: e.target.classList[0],
        index: [...e.target.parentNode.children].indexOf(e.target),
      };
    },
    dragDrop(e) {
      let originPlace;
      let isLast = false;

      if (this.draggedTile.el.nexSibling) {
        originPlace = this.draggedTile.el.nexSibling;
      } else {
        originPlace = this.draggedTile.el.previousSibling;
        isLast = true;
      }

      if (e.target.className !== this.draggedTile.class) {
        const droppedIndex = [...e.target.parentNode.children].indexOf(
          e.target
        );
        this.draggedTile.index > droppedIndex
          ? e.target.before(this.draggedTile.el)
          : e.target.after(this.draggedTile.el);
        isLast ? originPlace.after(e.target) : originPlace.before(e.target);
      }
    },
  },
  created() {
    this.createTiles();
    setTimeout(() => {
      this.shuffleTiles();
    }, 2000);
  },
};
</script>

<style scoped>
.jigsaw {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
ul.image-container {
  padding: 100px 100px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-width: 400px;
  min-height: 400px;
  background: #fff;
}
.list0 {
  background-position-x: 0px;
  background-position-y: 0px;
}
.list1 {
  background-position-x: -100px;
  background-position-y: 0px;
}
.list2 {
  background-position-x: -200px;
  background-position-y: 0px;
}
.list3 {
  background-position-x: -300px;
  background-position-y: 0px;
}
.list4 {
  background-position-x: 0px;
  background-position-y: -100px;
}
.list5 {
  background-position-x: -100px;
  background-position-y: -100px;
}
.list6 {
  background-position-x: -200px;
  background-position-y: -100px;
}
.list7 {
  background-position-x: -300px;
  background-position-y: -100px;
}
.list8 {
  background-position-x: -0px;
  background-position-y: -200px;
}
.list9 {
  background-position-x: -100px;
  background-position-y: -200px;
}
.list10 {
  background-position-x: -200px;
  background-position-y: -200px;
}
.list11 {
  background-position-x: -300px;
  background-position-y: -200px;
}
.list12 {
  background-position-x: 0px;
  background-position-y: -300px;
}
.list13 {
  background-position-x: -100px;
  background-position-y: -300px;
}
.list14 {
  background-position-x: -200px;
  background-position-y: -300px;
}
.list15 {
  background-position-x: -300px;
  background-position-y: -300px;
}
</style>
