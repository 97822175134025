<template>
  <v-dialog v-model="dialog" max-width="1200" overlay-opacity="0.8" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card v-if="week" v-bind="attrs" v-on="on">
          <v-img :src="week.src" height="300px" class="week-image">
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal text-h2 white--text"
                style="height: 100%;"
              >
                {{ week.name }} <span class="ml-2 text-h4">주차</span>
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-hover>
    </template>

    <v-card v-if="week">
      <v-tabs color="white" right>
        <v-tab class="orange white--text">월-화</v-tab>
        <v-tab class="teal white--text">수-목</v-tab>
        <v-tab class="purple white--text">금</v-tab>

        <v-tab-item v-for="(round, n) in week.rounds" :key="n" class="mt-3">
          <v-container>
            <v-row>
              <v-col v-if="selectedStep" cols="12" class="align-center">
                <v-card class="mx-auto" max-width="350">
                  <v-card-subtitle class="text-center blue--text text-h5">
                    {{ selectedStep.name.toUpperCase() }}
                  </v-card-subtitle>
                  <video
                    v-if="selectedStep.videoURL"
                    width="100%"
                    :poster="selectedStep.imageURL"
                    controls
                    :src="selectedStep.videoURL"
                    controlsList="nodownload"
                  ></video>
                  <v-img
                    v-else
                    max-width="100%"
                    class="align-center mx-auto"
                    :src="selectedStep.imageURL"
                  >
                    <v-btn
                      icon
                      dark
                      large
                      :href="selectedStep.htmlURL"
                      target="_blank"
                    >
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                  </v-img>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <TeachingGuideModal :guides="selectedStep.teachingGuides" />
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="my-2">
              <v-col cols="12" sm="6" lg="2">
                <v-card>
                  <v-img
                    class="white--text align-end"
                    src="/mono.jpg"
                    aspect-ratio="1"
                  >
                    <v-card-title>{{ level_volume }}</v-card-title>
                    <v-card-subtitle class="text-right">
                      {{ week.name }} 주차
                    </v-card-subtitle>
                  </v-img>
                  <v-card-subtitle class="text-center grey--text">
                    ROUND {{ n + 1 }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col
                v-for="(step, i) in round.steps"
                :key="i"
                cols="12"
                sm="6"
                lg="2"
              >
                <v-card>
                  <v-img
                    @click="handleClick(step)"
                    class="align-center"
                    :src="step.imageURL"
                    :lazy-src="
                      `https://picsum.photos/10/6?image=${i * n * 5 + 10}`
                    "
                    aspect-ratio="1"
                  >
                  </v-img>
                  <v-card-subtitle class="text-center grey--text">
                    {{ step.name.toUpperCase() }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
import TeachingGuideModal from "../components/TeachingGuideModal.vue";

export default {
  components: { TeachingGuideModal },
  props: ["level_volume", "week"],
  data() {
    return {
      dialog: false,
      selectedStep: "",
    };
  },
  methods: {
    handleClick(step) {
      this.selectedStep = step;
    },
  },
};
</script>

<style scoped>
/* .close {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
} */
.week-image {
  filter: grayscale(0%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
