<template>
  <div class="play-and-think">
    <v-container v-if="member == '부모님'">
      선생님만 보실 수 있습니다.
    </v-container>
    <v-container v-else>
      <!-- level 1, 2, 3 -->
      <!-- <v-row class="d-flex flex-nowrap" v-for="(level, i) in levels" :key="i"> -->
      <v-row v-for="(level, i) in levels" :key="i">
        <!-- level 1, 2, 3 -> book 1, 2, 3.... 9, 10 (3 * 10) -->
        <template v-for="(book, j) in level.books">
          <!-- <v-col
            v-if="levelOfEnglish.includes(`K-${i + 1}`)"
            :key="j"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          > -->
          <v-col
            v-if="levelOfEnglish.includes(`K-${i + 1}`)"
            :key="j"
            cols="4"
            md="3"
            class="pa-1"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
              >
                <router-link
                  :to="{
                    name: 'PlayAndThinkDetails',
                    params: {
                      level_volume: `${level.level}-${book.volume}`,
                    },
                  }"
                >
                  <v-img
                    :src="`/img/stories/${level.level}-${book.volume}.jpg`"
                  >
                    <v-card-title :class="`white--text ${level.color}`">
                      {{ level.level }}-
                      {{ book.volume != "10" ? book.volume[1] : book.volume }}
                    </v-card-title>
                  </v-img>
                </router-link>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
      <v-row> </v-row>
    </v-container>
  </div>
</template>

<script>
import { projectAuth } from "../firebase/config";
import { projectFirestore } from "../firebase/config";

export default {
  data() {
    return {
      levels: this.$store.state.items,
      member: "",
      levelOfEnglish: [],
      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  methods: {
    getUser() {
      const user = projectAuth.currentUser;
      projectFirestore
        .collection("users")
        .where("user_id", "==", user.uid)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            projectFirestore
              .collection("users")
              .doc(doc.id)
              .get()
              .then((user) => {
                this.levelOfEnglish = user.data().levelOfEnglish;
                this.member = user.data().member;
              });
          });
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
/* .flex-nowrap {
  overflow-x: scroll;
} */
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}

.v-card__title {
  opacity: 0.4;
}

.show-btns {
  color: rgb(10, 0, 0) !important;
}
.v-responsive__content {
  text-align: center;
}
</style>
