<template>
  <div class="wrap-all">
    hi
    {{ img }}
    <!-- <ul v-if="pieces" class="image-container">
      <li class="image-piece" :for="(piece, i) in pieces" :key="i"></li>
    </ul> -->
  </div>
</template>

<script>
export default {
  props: ["img"],
  data() {
    return {};
  },
  created() {
    // let pieces = [
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    // ];
    // pieces = pieces.map((piece, index) => {
    //   return {
    //     id: index,
    //     image: this.img,
    //   };
    // });
    console.log(this.img);
  },
};
</script>

<style scoped>
.wrap-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

ul.image-container {
  list-style: none;
  border: 2px solid #3b5998;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 400px;
  height: 100px;
}

li.image-piece {
  width: 100px;
  height: 100px;
  color: #fff;
  background-image: url("https://placeimg.com/400/400/any");
}
</style>
