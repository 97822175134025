<template>
  <div class="classroom-english">
    <!-- select cards -->
    <!-- <v-container class="mt-5">
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-select
            dense
            :items="units"
            label="Unit"
            outlined
            v-model="unit"
            @change="initInput('unit')"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-select
            dense
            :items="chapters"
            label="Chapter"
            outlined
            v-model="chapter"
            @change="initInput('chapter')"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-select
            dense
            :items="titles"
            label="Title"
            outlined
            v-model="title"
            @change="initInput('title')"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-select
            dense
            :items="cases"
            label="Case"
            outlined
            v-model="Case"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-col cols="6">
          <h3 class="indigo--text">{{ selectedCards.length }} cards</h3>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Cards"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col
          v-for="(card, index) in selectedCards"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card>
            <v-img
              :src="`https://picsum.photos/500/300?image=${getImage()}`"
              height="200px"
            >
            </v-img>
            <v-card-text>
              <p>{{ card.title }} > {{ card.case }}</p>
            </v-card-text>
            <v-card-title class="blue-grey--text">{{
              card.english
            }}</v-card-title>
            <v-card-subtitle class="pink--text">{{
              card.korean
            }}</v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text class="grey--text">
                <v-icon>mdi-volume-high</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- unit 1 -->
    <v-container fluid>
      <h2 class="blue-grey--text my-3 text-center">Unit 1 Everyday Routines</h2>
      <v-row justify="center">
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/500/300?image=${getImage()}`"
              height="300px"
            >
            </v-img>
            <v-card-title>Chapter 1</v-card-title>
            <v-card-subtitle>Beginning/Ending the Day</v-card-subtitle>
            <v-card-actions>
              <v-btn
                text
                color="orange"
                :to="{
                  name: 'ClassroomEnglishDetails',
                  params: { chapter: 'Chapter 01 Beginning/Ending the Day' },
                }"
              >
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 2</v-card-title>
            <v-card-subtitle>Getting Started</v-card-subtitle>
            <v-card-actions>
              <v-btn
                text
                color="orange"
                :to="{
                  name: 'ClassroomEnglishDetails',
                  params: { chapter: 'Chapter 02 Getting Started' },
                }"
              >
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 3</v-card-title>
            <v-card-subtitle>Basic Routines</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="orange">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 4</v-card-title>
            <v-card-subtitle>Lunch &amp; Snack</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="orange">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 5</v-card-title>
            <v-card-subtitle>Bathroom Activities</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="orange">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- unit 2 -->
    <v-container fluid>
      <h2 class="blue-grey--text my-3 text-center" text-center>
        Unit 2 Managing Instruction
      </h2>
      <v-row justify="center">
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 6</v-card-title>
            <v-card-subtitle>During the Lesson</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="teal">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 7</v-card-title>
            <v-card-subtitle>Teaching Language Skills</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="teal">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 8</v-card-title>
            <v-card-subtitle>Subjects 1</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="teal">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 9</v-card-title>
            <v-card-subtitle>Subjects 2</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="teal">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- unit 3 -->
    <v-container fluid>
      <h2 class="blue-grey--text my-3 text-center">Unit 3 Managing Affect</h2>
      <v-row justify="center">
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 10</v-card-title>
            <v-card-subtitle>Classroom Management</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="pink">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 11</v-card-title>
            <v-card-subtitle>Compliments &amp; Encouragement</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="pink">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 12</v-card-title>
            <v-card-subtitle>Feelings</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="pink">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- unit 4 -->
    <v-container fluid>
      <h2 class="blue-grey--text my-3 text-center">Unit 4 Special Days</h2>
      <v-row justify="center">
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 13</v-card-title>
            <v-card-subtitle>Special Days</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="indigo">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-card>
            <v-img
              :src="`https://picsum.photos/200/300?image=${getImage()}`"
              height="300px"
            ></v-img>
            <v-card-title>Chapter 14</v-card-title>
            <v-card-subtitle>Special Days 2</v-card-subtitle>
            <v-card-actions>
              <v-btn text color="indigo">
                Explore
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { classroomEnglish } from "../data/db";

export default {
  data() {
    return {
      search: "",
      cards: classroomEnglish,
      unit: "",
      chapter: "",
      title: "",
      Case: "",
      book: [
        {
          unit: "Unit 01 Everyday Routines",
          chapters: [
            {
              chapter: "Chapter 01 Beginning/Ending the Day",
              titles: [
                {
                  title: "Beginning the DAY",
                  cases: [
                    "만났을 때",
                    "날씨와 관련된 인사",
                    "개별 아동의 모습과 관련된 인사",
                    "아이가 늦거나 아파 보이는 경우",
                    "주말을 지내고 온 경우",
                    "출석체크하기",
                  ],
                },
                {
                  title: "Getting  Ready",
                  cases: [
                    "신발 정리 하기",
                    "옷과 개인 소지품 정리하기",
                    "메모 등 전달하기",
                  ],
                },
                {
                  title: "Ending the Day",
                  cases: [
                    "전달 사항 전하기",
                    "개인 소지품 챙기기",
                    "하원지도",
                    "귀가 인사하기",
                    "차량 타고 내리기",
                    "차량 안전지도",
                  ],
                },
              ],
            },
            {
              chapter: "Chapter 02 Getting Started",
              titles: [
                {
                  title: "Getting Ready for the Lesson",
                  cases: [
                    "자리에 앉히기",
                    "자리 옮기기",
                    "주의집중하기",
                    "조용히 시킬 때",
                    "",
                  ],
                },
                {
                  title: "Beginning/Ending the Lesson",
                  cases: ["시작할 때", "수업을 마칠 때", "정리하기"],
                },
                {
                  title: "Break Time and Transition",
                  cases: ["휴식시간 휴식", "이동하기", "줄서기"],
                },
              ],
            },
            {
              chapter: "Chapter 03 Basic Routines",
              titles: [
                {
                  title: "Calendar and Weather Routines",
                  cases: [
                    "오늘 날짜, 요일, 날씨",
                    "어제 날짜, 요일, 날씨",
                    "내일 날짜오 요일, 날씨 예측하기",
                    "기타",
                  ],
                },
                {
                  title: "Alphabet Routine",
                  cases: ["알파벳 노래", "알파벳 관련 활동"],
                },
                {
                  title: "Number Routine",
                  cases: ["수 세기"],
                },
                {
                  title: "Others",
                  cases: ["느낌", "Content-based Routine"],
                },
              ],
            },
            {
              chapter: "Chapter 04 Lunch & Snack",
              titles: [
                {
                  title: "Preparation & Clean Up",
                  cases: ["준비하기", "정리하기"],
                },
                {
                  title: "Serving Food",
                  cases: [""],
                },
                {
                  title: "Prayer & Song",
                  cases: [""],
                },
                {
                  title: "Table Conversation",
                  cases: [" 일반적인 식사 중 대화", "간식시간"],
                },
                {
                  title: "Table Manners",
                  cases: [""],
                },
              ],
            },
            {
              chapter: "Chapter 05 Bathroom Activities",
              titles: [
                {
                  title: "Brushing Teeth",
                  cases: [""],
                },
                {
                  title: "Using the Bathroon",
                  cases: [""],
                },
                {
                  title: "Washing hands",
                  cases: [""],
                },
                {
                  title: "Others",
                  cases: [""],
                },
              ],
            },
          ],
        },
        {
          unit: "Unit 02 Managing Instruction",
          chapters: [
            {
              chapter: "Chapter 06 During the Lesson",
              titles: [
                {
                  title: "Whole Group Interaction",
                  cases: [
                    "자발적인 참여 유도하기",
                    "지명하기, 순서 지키기",
                    "자기 자리로 돌아가기",
                  ],
                },
                {
                  title: "Board Activity",
                  cases: ["보드활동", "융판활동", ""],
                },
                {
                  title: "Textbook Activity",
                  cases: [
                    "텍스트 북 준비하기",
                    "페이지 찾기",
                    "자료의 위치 찾기",
                  ],
                },
                {
                  title: "Handout Distribution",
                  cases: ["자료 배부하기", "자료보관, 추가 배부", ""],
                },
              ],
            },
            {
              chapter: "Chapter 07 Teaching Language Skills",
              titles: [
                {
                  title: "Listening",
                  cases: [
                    "듣기활동 일반 지시문",
                    "듣고 지시 따라하기",
                    "자료 듣기",
                    "음운인식",
                  ],
                },
                {
                  title: "Speaking",
                  cases: [
                    "말하기활동 읽기 지시문",
                    "볼륨, 속도 조절하기",
                    "짝 활동하기",
                    "역할놀이",
                  ],
                },
                {
                  title: "Reading",
                  cases: ["읽기활동 일반 지시문", "Phonics 1", "Phonics 2", ""],
                },
                {
                  title: "Writing",
                  cases: [
                    "쓰기활동 일반 지시문",
                    "쓰기 준비하기",
                    "쓰기",
                    "쓰기 확장활동",
                  ],
                },
              ],
            },
            {
              chapter: "Chapter 08 Subjects 1",
              titles: [
                {
                  title: "Language Arts",
                  cases: ["동화책 소개하기", "읽고 이해하기"],
                },
                {
                  title: "Social Studies",
                  cases: [
                    "나와 가족과 관련된 질문",
                    "주거와 관련된 질문",
                    "직업, 특별한 날과 관련된 질문",
                  ],
                },
                {
                  title: "Cooking",
                  cases: ["요리 소개하기", "요리 활동", "도구및 재료 다루기"],
                },
                {
                  title: "Creative Arts",
                  cases: ["조형활동하기", "도구및 재료 다루기"],
                },
                {
                  title: "Music",
                  cases: ["노래부르기", "율동, 동작하기"],
                },
                {
                  title: "Group Activity",
                  cases: ["짝활동", "그룹활동"],
                },
              ],
            },
            {
              chapter: "Chapter 09 Subjects 2",
              titles: [
                {
                  title: "Science",
                  cases: ["과학 활동"],
                },
                {
                  title: "Math",
                  cases: [
                    "수, 패턴활동",
                    "모양, 색 활동",
                    "분류하기",
                    "크기, 무게, 길이",
                    "서열, 비교",
                  ],
                },
                {
                  title: "Games",
                  cases: [
                    "Simin Says (사이먼 가라사대)",
                    "Concentration (집중게임)",
                    "Bingo (빙고)",
                    "I Spy",
                    "White Duck, Yellow Duck",
                  ],
                },
                {
                  title: "Physical Education",
                  cases: [
                    "Relay Race( 릴레이경주)",
                    "신체 활동",
                    "Hide And Seek (숨바꼭질)",
                    "Streching",
                  ],
                },
                {
                  title: "Outdoor Play",
                  cases: ["놀이터", "모래놀이"],
                },
              ],
            },
          ],
        },
        {
          unit: "Unit 03 Managing Affect",
          chapters: [
            {
              chapter: "Chapter 10 Classroom Management",
              titles: [
                {
                  title: "Classroom  Rules",
                  cases: ["규칙 정하기", "교실 규칙 1", "교실 규칙 2"],
                },
                {
                  title: "Other Rules",
                  cases: ["수업시간의 규칙", "지시하기", ""],
                },
                {
                  title: "Problem  Solving",
                  cases: ["또래와의 갈등", "거짓말하는 상황"],
                },
                {
                  title: "Posiltive Attitude",
                  cases: ["적절한 상황에서 의사표현하기", "타인과의 관계"],
                },
              ],
            },
            {
              chapter: "Chapter 11 Compliments & Encouragement",
              titles: [
                {
                  title: "Compliments 1",
                  cases: [""],
                },
                {
                  title: "Compliments 2 :  Answers",
                  cases: [""],
                },
                {
                  title: "Compliments 3 :  Worksheet & Homework",
                  cases: [""],
                },
                {
                  title: "Encouragement 1 :  Improvement",
                  cases: [""],
                },
                {
                  title: "Encouragement 2 :  Challenge",
                  cases: [""],
                },
                {
                  title: "Encouragement 3 :  Courge & Lack Confidence",
                  cases: [""],
                },
              ],
            },
            {
              chapter: "Chapter 12 Feelings",
              titles: [
                {
                  title: "General Feelings",
                  cases: ["고마울 때", "미안할 때", "놀랐을 때", "기분 나쁠때"],
                },
                {
                  title: "Controlling Feelings",
                  cases: ["갑자기 울 때", "흥분 가라앉히기"],
                },
                {
                  title: "Controlling Peer Conflict",
                  cases: [
                    "다른 친구에게 방해가 될 때",
                    "서로 양보하지 않을 때",
                  ],
                },
                {
                  title: "Teacher-Student Conflict",
                  cases: [
                    "관계없는 이야기가 계속 될 때",
                    "핑계를 댈 때",
                    "지시를 거부 할 때",
                    "계속 진행하도록 할 때",
                  ],
                },
                {
                  title: "Illness",
                  cases: [
                    "아이의 상태 점검하기",
                    "증상 다루기",
                    "후속 조치하기",
                  ],
                },
              ],
            },
          ],
        },
        {
          unit: "Unit 04 Special Days",
          chapters: [
            {
              chapter: "Chapter 13 Special Days",
              titles: [
                {
                  title: "Orientaion & Graduation",
                  cases: ["오리 엔테이션", "수료식, 졸업식"],
                },
                {
                  title: "Birthady Parties",
                  cases: [
                    "생일 전",
                    "생일 파티- 생일 축하노래부르기",
                    "생일 파티- 선물 주고받기",
                  ],
                },
                {
                  title: "Field Trips",
                  cases: ["사전 학습", "준비물 챙기기", "현장 학습"],
                },
                {
                  title: "Open House",
                  cases: [""],
                },
              ],
            },
            {
              chapter: "Chapter 14 Special Days 2",
              titles: [
                {
                  title: "Common Expressions",
                  cases: [""],
                },
                {
                  title: "Chusok",
                  cases: ["의미와 유래", "관련 활동"],
                },
                {
                  title: "Christmas",
                  cases: ["의미와 유례", "관련 활동"],
                },
                {
                  title: "New Year's Day",
                  cases: ["의미와 유례", "관련 활동"],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    getImage() {
      const min = 530;
      const max = 560;

      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    initInput(item) {
      if (item == "unit") {
        this.chapter = "";
        this.title = "";
        this.Case = "";
      } else if (item == "chapter") {
        this.title = "";
        this.Case = "";
      } else if (item == "title") {
        this.Case = "";
      } else if (item == "all") {
        this.unit = "";
        this.chapter = "";
        this.title = "";
        this.Case = "";
      }
    },
  },
  computed: {
    selectedCards() {
      return this.cards.filter((card) => {
        return (
          card.unit.match(this.unit) &&
          card.chapter.match(this.chapter) &&
          card.title.match(this.title) &&
          card.case.match(this.Case) &&
          (card.english.match(this.search) ||
            card.korean.match(this.search) ||
            card.case.match(this.search) ||
            card.title.match(this.search) ||
            card.chapter.match(this.search) ||
            card.unit.match(this.search))
        );
      });
    },
    units() {
      let units = [""];
      this.book.forEach((unit) => {
        units.push(unit.unit);
      });
      return units;
    },
    chapters() {
      let chapters = [];
      this.book.forEach((unit) => {
        if (unit.unit == this.unit) {
          unit.chapters.forEach((chapter) => {
            chapters.push(chapter.chapter);
          });
        }
      });
      return chapters;
    },
    titles() {
      let titles = [];
      this.book.forEach((unit) => {
        if (unit.unit == this.unit) {
          unit.chapters.forEach((chapter) => {
            if (chapter.chapter == this.chapter) {
              chapter.titles.forEach((title) => {
                titles.push(title.title);
              });
            }
          });
        }
      });
      return titles;
    },
    cases() {
      let cases = [];
      this.book.forEach((unit) => {
        if (unit.unit == this.unit) {
          unit.chapters.forEach((chapter) => {
            if (chapter.chapter == this.chapter) {
              chapter.titles.forEach((title) => {
                if (title.title == this.title) {
                  cases = title.cases;
                }
              });
            }
          });
        }
      });
      return cases;
    },
  },
};
</script>

<style></style>
