<template>
  <div class="videos-for-parents">
    <v-container>
      <v-row v-if="book.parents">
        <v-col cols="12" md="6">
          <v-card class="mx-auto" flat>
            <v-img
              class="white--text"
              src="/steam.jpeg"
              height="272"
              @click="book.steam.show = !book.steam.show"
            >
              <v-card-title>STEAM</v-card-title>
            </v-img>

            <v-expand-transition>
              <div v-show="book.steam.show">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(link, i) in filteredLinks"
                    :key="i"
                    class="mt-3"
                  >
                    <SteamModal :link="link" />
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-for="(item, i) in book.parents"
          :key="i"
          class="text-center"
        >
          <video
            :poster="item.imageURL"
            width="100%"
            controls
            controlsList="nodownload"
            :src="item.videoURL"
          ></video>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { projectStorage } from "../firebase/config";
import SteamModal from "../components/SteamModal.vue";

export default {
  components: { SteamModal },
  name: "VideosForParents",
  // props: ["parents", "steam"],
  data() {
    return {
      levels: this.$store.state.items,
      level_volume: this.$route.params.level_volume,
    };
  },
  methods: {
    fillItem() {
      this.book.parents.forEach((item) => {
        // fill thumbnails
        let imagePath = "/thumbnails/" + item.videoTitle + ".jpg";
        projectStorage
          .ref(imagePath)
          .getDownloadURL()
          .then((imageURL) => {
            if (imageURL) {
              item.imageURL = imageURL;
            }
          })
          .catch((err) => {
            console.log(err.message);
            item.imageURL =
              "https://images.unsplash.com/photo-1571210862729-78a52d3779a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";
          });

        // fill videos
        if (item.videoTitle) {
          let path = "/videos/" + item.videoTitle + ".mp4";
          projectStorage
            .ref(path)
            .getDownloadURL()
            .then((videoURL) => {
              if (videoURL) {
                item.videoURL = videoURL;
              }
            })
            .catch((err) => {
              console.log(err.message);
              item.videoURL =
                "https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/rZJIMvhmliwmde8a6/videoblocks-little-boys-and-girls-playing-with-block-toys-in-playroom-kids-playing-with-developing-toys-indoor-creative-kids-learning-center_rvzekwq0e__634b285e66ab9f705dd1b613bb400463__P360.mp4";
            });
        }
      });
    },
  },
  computed: {
    levelNumber() {
      return parseInt(this.level_volume.slice(1, 2)) - 1;
    },
    bookNumber() {
      return parseInt(this.level_volume.slice(3, 5)) - 1;
    },
    book() {
      return this.levels[this.levelNumber].books[this.bookNumber];
    },
    filteredLinks() {
      return this.book.steam.links.filter((link) => {
        return link.title == "Story Puzzle" || link.title == "Memory Game";
      });
    },
  },
  mounted() {
    // this.getSupplement();
    this.fillItem();
  },
};
</script>

<style scoped>
.link-image {
  filter: grayscale(0%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
