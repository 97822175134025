const classroomEnglish = [
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "만났을 때", korean : "안녕하세요!", english : "Hi!"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "만났을 때", korean : "좋은 아침이에요.", english : "Good morning."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "만났을 때", korean : "여러분 만나서 반가워요.", english : "I'm so happy to see you."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "만났을 때", korean : "나도 여러분 만나서 반가워요.", english : "I'm glad to see, too."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "만났을 때", korean : "오늘 기분 어때요?", english : "How are you today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "날씨와 관련된 인사", korean : "바깥 날씨가 정말 춥네요.", english : "It's very cold outside."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "날씨와 관련된 인사", korean : "춥지/덥지 않나요?", english : "Aren't you cold/hot?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "날씨와 관련된 인사", korean : "손을 봐요. 손이 얼었네요.", english : "Look at your hands. They are frozen."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "날씨와 관련된 인사", korean : "땀을 흘리고 있네요.", english : "You are sweating."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "개별 아동의 모습과 관련된 인사", korean : "오늘 네가 멋져 보이는구나.", english : "You look nice today."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "개별 아동의 모습과 관련된 인사", korean : "원피스가 정말 예쁘구나.", english : "I like your dress."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "개별 아동의 모습과 관련된 인사", korean : "셔츠가 너무 멋져 보이네!", english : "What a nce shirt!"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "개별 아동의 모습과 관련된 인사", korean : "멋있다!", english : "How nice/ wonderful!"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "아이가 늦거나 아파 보이는 경우", korean : "오늘 늦었네요. 괜찮아요?", english : "You are late. Are you OK?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "아이가 늦거나 아파 보이는 경우", korean : "얼굴이 왜 그래요?", english : "What happened to your face?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "아이가 늦거나 아파 보이는 경우", korean : "왜 울고 있어요?", english : "Why are you crying?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "아이가 늦거나 아파 보이는 경우", korean : "무슨 문제가 있어요?", english : "What's wrong?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "아이가 늦거나 아파 보이는 경우", korean : "기분이 좀 나아졌어요?", english : "Do you feel better today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "즐거운 주말을 보냈어요?", english : "Did you have a good weekend?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "주말 어땠어요?", english : "How was your weekend?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "어디에 갔어요?", english : "Where did you go?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "그 곳에서 무었을 했어요?", english : "What did you do?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "주말에 무었을 했어요?", english : "What did you do over the weekend?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "재미있었어요?", english : "DId you have fun?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "주말을 지내고 온 경우", korean : "얘기해 주고 싶은 것이 있나요?", english : "Do you have something to share?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "이름을 부르겠어요.", english : "I'm going to call your names."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "출석을 부르겠어요.", english : "I'm going to take roll."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "모두 왔어요?", english : "Is everyone here?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "누가 결석 했어요?", english : "Who is missing today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "민지 어디 있나요?", english : "Where is Minji?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "민지에게 무슨 일이 생겼어요?", english : "What happened to Minji?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "민지에게 무슨 일 있어요?", english : "What's the matter with Minji?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Beginning the DAY", case : "출석체크하기", korean : "그/그녀 가 온거예요?", english : "Is he/she going to come?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "신발 정리 하기", korean : "신발을 벗어요", english : "Take off you shoes "},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "신발 정리 하기", korean : "운동화로 바꿔 신어요", english : "Put your sneakers on"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "신발 정리 하기", korean : "신발을 선반위에 놓아요", english : "Put your shoes on the shelf"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "신발 정리 하기", korean : "신발을 신발장에 넣어요", english : "Put your shoes in the shoe cabinet"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "신발 정리 하기", korean : "신발을 바르게 놓아요", english : "Put them in neatly"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "옷과 개인 소지품 정리하기", korean : "코트를 벗어요", english : "Take off your coat"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "옷과 개인 소지품 정리하기", korean : "가방을 걸어 놓아요", english : "Hang up your backpack"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "옷과 개인 소지품 정리하기", korean : "컵을 테이블에 놓아요", english : "Put your cup one the table."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "옷과 개인 소지품 정리하기", korean : "코트가 아직 바닥에 있네요", english : "Youre coat is still on the floor"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "옷과 개인 소지품 정리하기", korean : "집어서 옷걸이에 걸어 놓아요", english : "Pick it and hang it on the hanger"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "메모 등 전달하기 ", korean : "엄마/아빠가 주신 메모가 있으면 ", english : "If you have any  notes from your mom or dad,"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "메모 등 전달하기 ", korean : "선생님 한테 주세요.", english : "give them to me"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "메모 등 전달하기 ", korean : "도서관 책을 반납해요", english : "Turn in your library books"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "메모 등 전달하기 ", korean : "가져온 약 있으면 주세요", english : "Give me any medicine you may have brought"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Getting  Ready", case : "메모 등 전달하기 ", korean : "숙제를 여기에 놓아요", english : "Put your homework here"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "누가 집에 갈 준비가 되었는지 봅시다.", english : "Let's see who is ready to go home"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "민지가 집에 갈 준비가 되었네요", english : "Minji is ready to go home"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "선생님이 가정 통신문을 가방에 넣어 놓았어요", english : "I put a parent letter in your backpack"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "엄마한테 가정 통신문을 보여주세요", english : "Show it to your mom"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "내일 현장학습을 갈 거예요", english : "Tomorrow, we are going to go on a field trip"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "음식과 간식 가져오는  걸 잊지마세요", english : "Don't forget your lunch and snack"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "날씨가 점점 추워지네요", english : "It's getting cold, "},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "전달 사항 전하기", korean : "장갑과 목도리 가져오세요", english : "Please bring your mittens and muffler"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "개인 소지품 챙기기", korean : "누구의 코트인가요?", english : "Whose coat is this?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "개인 소지품 챙기기", korean : "코트를 입어요", english : "Put your coat on"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "개인 소지품 챙기기", korean : "혼자 코트를 입을 수 있어요?", english : "Can you put it on youself?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "개인 소지품 챙기기", korean : "혼자 버튼을 채울 수 있어요?", english : "Can you button it up youself?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "개인 소지품 챙기기", korean : "가방을 챙겨요.", english : "Get your backpack."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "하원지도", korean : "가야 할 시간이에요", english : "It's time to go"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "하원지도", korean : "오늘 엄마가 데리러 오나요?", english : "Is your mom picking you up today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "하원지도", korean : "1호차 버스를 탈 거예요", english : "You are going to take bus number one"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "하원지도", korean : "선생님 따라가요", english : "Follow your teacher"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "하원지도", korean : "줄을 서요", english : "Make a line"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "하원지도", korean : "줄 안에 있으세요", english : "Syay in a/ the line"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "귀가 인사하기", korean : "좋은 주말 보내세요", english : "Have a good weekend"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "귀가 인사하기", korean : "안녕!", english : "Good-bye"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "귀가 인사하기", korean : "내일 봐요", english : "See you tomorrow"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 타고 내리기", korean : "버스에 타자", english : "let's get on the bus"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 타고 내리기", korean : "승합차에 타자", english : "Let's get in the van"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 타고 내리기", korean : "버스에서 내리자", english : "Let's get off the bus"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 타고 내리기", korean : "발밑을 조심해요", english : "Watch your step"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "민지 여기 앉을 래요?", english : "Minji, can you sit over here?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "민지 안전벨트를 매요", english : "Minji, put your seatbelt."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "안전 벨트를 매요", english : "Fasten your seatbelt."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "발을 내려 놓아요", english : "Put your feet down."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "버스/ 승합차 안에서 놀지 않아요", english : "We do not play on the bus/ in the van"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "손을 창 밖으로 내밀지 않아요", english : "Don’t stick your hands out the window."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "버스에서 조용히 애기해요", english : "Talk quieltly on the bus"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 01 Beginning/Ending the Day", title : "Ending the Day", case : "차량 안전지도", korean : "떠들지 마요", english : "Don’t' make too much noise"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "모두 자리에 앉아요.", english : "Everyone, come and sit down."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "바르게 앉아요.", english : "Sit nicely."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "똑바로 앉아요.", english : "Sit up straight."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "카펫 위에 앉아요.", english : "Sit on the carpet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "자기 자리에 앉아 있어요.", english : "Stay on your spot."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "원을 만드세요.", english : "Let's make a circle."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "손을 아래에 놓아요.", english : "Put your hands down."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "손을 깍지 껴서 무릎에 놓아요.", english : "Fold your hands and prt them in your lap."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리에 앉히기", korean : "만지지 말아요.", english : "Keep your hands to yourself."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리 옮기기", korean : "여기로 오세요.", english : "Move over here."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리 옮기기", korean : "서로 자리를 바꿔요.", english : "Trade places."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리 옮기기", korean : "의자를 이쪽 방향으로 옮길래요?", english : "Could you move your chair this way?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리 옮기기", korean : "조금 뒤로 갈래요?", english : "Could you move back a little?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리 옮기기", korean : "옆에 앉아요.", english : "Sit next to Emma."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "자리 옮기기", korean : "의자를 뒤로 밀어요.", english : "Push your chair to the back."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "바르게 앉아 있는 사람이 누구죠?", english : "Who is sitting nicely?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "조용히 할 때까지 기다릴 거예요.", english : "I'm waiting for you to be quiet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "이야기하는 사람이 누구죠?", english : "Who is talking?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "부드럽게 얘기해 봐요.", english : "Soft voice, please."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "지금은 들어야 할 시간이에요.", english : "It's time to listen."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "지금은 민지가 말할 차례예요.", english : "It's Minji's turn to talk."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "누가 노래를 들을 준비가 되었나요.", english : "Let's see who is ready to listen to a song."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "여기를 봐요.", english : "Look over here"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "나를 봐요.", english : "Look at me"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "집중해서 보고 들으세요.", english : "Keep your eyes and ears open."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "주의집중하기", korean : "주의해서 보고 들으세요.", english : "Look and listen carefully."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "조용히 해요.", english : "Quiet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "소리내지 말아요.", english : "Keep silent."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "그만 말해요.", english : "Stop talking."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "더 이상 말하지 말아요.", english : "No more talking."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "말하지 말아요.", english : "Don't talk"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "아직 끝나지 않았어요.", english : "I'm not finished yet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "선생님이 이야기하겠어요.", english : "Let me talk. Please."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "선생님이 얘기할 때는 조용히 해요.", english : "Please be quiet when I'm talking."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Getting Ready for the Lesson", case : "조용히 시킬 때", korean : "모든 사람이 조용히 할 때 시작할 거예요.", english : "I won't start until everyone is quiet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "시작할 때", korean : "준비 되었어요?", english : "Are you ready now?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "시작할 때", korean : "모두들 시작할 준비가 되었어요?", english : "Is everybody ready to start?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "시작할 때", korean : "이제 시작할 시간이예요.", english : "It's time to start now."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "시작할 때", korean : "수업을 시작합시다.", english : "Let's start our lesson."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "시작할 때", korean : "시작합시다.", english : "let's get started."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "시작할 때", korean : "이제 우리는 책을 읽을 거예요.", english : "Now, we're going to read a book."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "(거의) 끝났어요?", english : "Are you (almost) finished?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "모두들 끝났어요?", english : "Is everybody finished?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "시간이 다 되었어요.", english : "Time's up."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "지금 끝날 시간이에요.", english : "It's time to stop now."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "서두르세요!", english : "Hurry up!"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "시간이 더 필요해요?", english : "do you need more time?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "내일 끝낼 수 있어요.", english : "You can finish it tomorrow."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "서두를 필요 없어요.", english : "You don't need to rush."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "활동지를 주세요.", english : "Give me your worksheet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "조금 일찍 끝났어요.", english : "We finished a little early."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "놀 수 있는 시간이 5분 있어요.", english : "You have five minutes to play."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "5분 동안 놀 수 있어요.", english : "You can play for five minutes."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "수업을 마칠 때", korean : "영역활동에 갈 수 있어요.", english : "You can go to a center."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "테이블을 정리할 수 있어요?", english : "Can yo clean up the table?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "누가 정히하는 거 도와줄 수 있어요?", english : "Who can help me to clean up?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "블록을 제자리에 놓아요.", english : "Put the blocks away."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "쓰레기를 버려요.", english : "Throw the trash away."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "쓰레기를 어디에 버리나요?", english : "Where does the garbage go?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "쓰레기통에 쓰레기를 버리세요.", english : "Put the garbage into the bin."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Beginning/Ending the Lesson", case : "정리하기", korean : "종이를 주워요.", english : "Pick up the paper."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "휴식시간 휴식", korean : "잠깐 쉬자.", english : "Let's take a break."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "우리는 어디 가고 있어요.", english : "We are going somewhere."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "조용히 가야 해요.", english : "You neet to go quietly."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "뛰지 말고 천천히 걸으세요.", english : "Don't run, walk slowly."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "똑바로 내려가세요.", english : "Go straight down."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "계단을 내려갈 때는 조심하세요.", english : "Be careful when you go down the stairs."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "계단 난간을 잡으세요.", english : "Hold on to the handrail."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "저쪽이 아니라 이쪽이에요.", english : "It's this way. Not that way."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "이동하기", korean : "지금 교실로 다시 돌아갈 거예요.", english : "Let's go back to the classroom now. "},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "줄 서세요.", english : "Line up."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "줄 안에 서세요.", english : "Get in a/the line."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "두줄로 서세요.", english : "Stand in two lines."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "두줄을 만드세요.", english : "Make two lines."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "그룹으로 줄을 서세요.", english : "Line up in groups"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "똑바로 걸으세요.", english : "Walk in a straight line."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "끼어들지 마세요.", english : "Don't cut in line."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "줄 끝으로 가세요.", english : "Go to the end of the line."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "민지, 앞으로 나오세요.", english : "Minji, come to the front."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 02 Getting Started", title : "Break Time and Transition", case : "줄서기", korean : "나를 따라오세요.", english : "Follow me."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "달력을 봐요.", english : "Let's look at the calendar."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "몇월인가요?", english : "What month is it?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "3월이에요.", english : "It is March."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "몇일인가요?", english : "What is the date?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "숫자를 세요.", english : "Let's count."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "하나, 둘, 셋, 넷.", english : "One. Two, three, four."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "다음에 무엇이 오나요?", english : "What comes next?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "다섯이요.", english : "Five."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "이것이 다섯인가요?", english : "Is this five?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "오늘은 무슨 요일이에요?", english : "What day is it today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "월요일이에요.", english : "It is Monday."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "오늘 무슨 날이에요?", english : "What is today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "오늘은 3월 5일 토요일이에요.", english : "Today is Saturday, March 5."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "오늘은 날씨가 어때요?", english : "What's the weather like today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "날씨가 맑아요?", english : "is it sunny?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "날씨가 맑아요.", english : "It is sunny."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "오늘 기온이 어때요?", english : "What.s the temperature like today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "오늘 날짜, 요일, 날씨", korean : "더워요.", english : "It is hot."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 몇월이었어요?", english : "What month was it yesterday?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "3월이었어요.", english : "It was March."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 몇일이었어요?", english : "What was the date yesterday?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 4일이었어요.", english : "It was four."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 4일이었어요.", english : "It was the fourth."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 무슨 요일이었어요?", english : "What day was it yesterday?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 3월 4일 일요일이었어요.", english : "It was Sunday."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 날씨가 어땠어요?", english : "What was yesterday?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 맑았어요.", english : "Yesterday was Sunday, March 4."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 기온이 어땠어요?", english : "What was the weather like yesterday?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 맑았어요.", english : "It was sunny."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 기온이 어땟어요?", english : "What was the weather/temperature like yesterday?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "어제 날짜, 요일, 날씨", korean : "어제는 더웠어요.", english : "It was hot."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일은 몇 월이에요?", english : "What month will it be tomorrow?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "3월이에요.", english : "It will be March."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일은 몇 일이에요?", english : "What will the date be tomorrow?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "4일이에요.", english : "It will be four."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일은 무슨 요일이에요?", english : "What day will it be tomorrow?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "일요일이에요.", english : "It will be Sunday."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일은 무슨 날이에요?", english : "What will tomorrow be?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일은 3월 6일 화요일이에요.", english : "Tomorrow will be Tuesday. March 6."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일 날씨는 어떨까요?", english : "What will the weather be like tomorrow?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "화창할 거예요.", english : "It will be sunny."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "내일 기온은 어떨까요?", english : "What will the temperature be like tomorrow?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "내일 날짜오 요일, 날씨 예측하기", korean : "더울 거예요.", english : "It will be hot."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "기타", korean : "지난주에 비가 며칠간 왔었어요?", english : "How many days did it rain last week?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "기타", korean : "지난주에 2일간 비가 왔었어요.", english : "It rained 2 days last week."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "기타", korean : "3월 15일이요.", english : "It is the 15th of March."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "기타", korean : "3월 15일이요.", english : "It is March 15th."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Calendar and Weather Routines", case : "기타", korean : "봄이에요.", english : "It is spring."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 노래", korean : "", english : "Do yo know the ABC song?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 노래", korean : "", english : "Let's sing the ABC song."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 노래", korean : "", english : "ABCDEFG HIJKLMN OPQ RST UVW XYZ."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 노래", korean : "", english : "Now, I know my ABC's."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 노래", korean : "", english : "Next time won't you sing with me."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "알파벳 A를 찾아요.", english : "Find the letter A."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "사과' 사진을 찾아요.", english : "Find the picture :  apple."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "알파벳 A에 삼각형을 그려요.", english : "Draw a triangle around the letter A,"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "알파벳 A에 초록 삼각형을 그려요.", english : "Draw a green triangle around the letter A,"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "사과'의 첫 글자에 빨간 원을 그려요.", english : "Draw a red circle around the first letter of the word apple."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "손가락을 보여 주세요.", english : "Show me your finger."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Alphabet Routine", case : "알파벳 관련 활동", korean : "알파벳 A를 써요.", english : "Let's write the letter A."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "학교에 등교한 지 며칠 되었나요?", english : "How many days have we been in school?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "같이 세어 보자.", english : "Let's count."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "하나, 둘, 셋…", english : "One, two, three…"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "그 다음에는 무엇이 오지요?", english : "What comes next?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "52", english : "Fifty-two."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "학교에 다닌 지 52일 되었어요.", english : "We have been in school fifty-two days."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "학교에 다닌 지 100일찌 되는 날, 파티를 할 거예요.", english : "When we have been in school 100 days, we will have a party."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "막대 하나를 더하자.", english : "Let's add one more stick."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "10개 묶음이 몇 개 있어요?", english : "How many tens are there?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "1개는 몇 개 있어요?", english : "How many ones are there?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Number Routine", case : "수 세기", korean : "숫자를 써 보자.", english : "Let's write the number."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "느낌", korean : "오늘 기분이 어때요?", english : "How do yo feel today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "느낌", korean : "기뻐요?", english : "Are you happy?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "느낌", korean : "기뻐요.", english : "I am happy"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "느낌", korean : "오늘 민지 기분이 어떼요?", english : "How does Minji feel today?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "느낌", korean : "민지는 신나요.", english : "She is excited."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "느낌", korean : "짝에게 민지 기분이 어떤지 물어봐요.", english : "Ask your partner how she feels today."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "Content-based Routine", korean : "복습해 보자.", english : "Let's review."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "Content-based Routine", korean : "이 이야기를 기억해요?", english : "Do you remember this story?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "Content-based Routine", korean : "다시 한 번 해보자.", english : "Let's go over it one more time."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 03 Basic Routines", title : "Others", case : "Content-based Routine", korean : "지난 번에 우리가 무엇을 했나요?", english : "What did we do last time?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "점심시간이에요.", english : "It's time for lunch."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "점심을 먹자.", english : "Let's have lunch."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "점심 먹기 전에 손을 씻어요.", english : "Wash your hands before eating lunch."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "손, 씻었어요?", english : "Did you wash your hands?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "도시락/식판을 꺼내요.", english : "Take out your lunch tray."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "도시락/식판이 어디 있어요?", english : "Where is your lunch tray?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "도시락/식판을 가져와요.", english : "Go get your lunch tray."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "준비하기", korean : "음식 먹을 준비를 해요.", english : "Get ready to eat."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "음식을 다 끝냈어요?", english : "Did you finish your food?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "음식을 다 먹었어요?", english : "Are you done with your food?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "음식을 맛있게 먹었어요?", english : "Did you enjoy your lunch?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "음식이 남았다면, 버리세요.", english : "If you have leftovers, you need to throw them away."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "남은 음식은 이 용기에 넣자.", english : "Put the leftovers in this container."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "도시락/식판과 테이블을 닦자.", english : "Clean your lunch tray and table."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "음식을 집어서 버리자.", english : "Pick up the food and throw it away."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Preparation & Clean Up", case : "정리하기", korean : "도시락 통을 가방에 넣어요.", english : "Put your lunch box in your backpack."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "도시락/식판을 갖고 줄을 서요.", english : "Get your tray and line up."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "한줄로 서서 기다리자.", english : "Please Wait in one line."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "똑바로 한 줄을 만들자.", english : "One straight line, please."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "줄 맨 끝으로 가야 해요.", english : "You need to go to the end of the line."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "도시락/식판을 똑바로 들어요.", english : "Hold your tray straight."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "생선 더 먹을래요?", english : "Would you like some more fish?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "무엇을 먹고 싶어요?", english : "What would you like?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "무엇을 마시고 싶어요?", english : "What would you like to drink?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "잘 들고 자기 자리로 조심히 돌아가요.", english : "Hold it tight and go back to your table carefully."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "잘 들고, 자리로 천천히 걸어서 돌아가요.", english : "Hold it still and walk slowly to your seat."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "무엇을 원하는지 말해줘요.", english : "Tell me what you want."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "밥을 얼마나 먹을래요?", english : "How much rice would you like?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "당근을 몇 개 원해요?", english : "How many carrots would you like?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "과식하지 말아요.", english : "Don't overeat."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "먹을 만큼 충분히 먹어요.", english : "Eat just enough."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Serving Food", case : "", korean : "너무 많이 먹지 말아요.", english : "Don't eat too much."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "점심 먹을 준비를 합시다.", english : "Get ready for lunch."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "도시락/식판을 테이블 위에 놓아요.", english : "Put your lunch tray on the table."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "바르고 조용하게 앉아요.", english : "Sit nicely and quietly."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "모두 점심을 받을 때까지 기다려요.", english : "Wait until everyone has been served."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "조심해요. 국이 뜨거워요.", english : "Be careful. The soup is hot."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "아직 음식 만지지 말아요.", english : "Don't touch your food yet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "거기에 놓아요.", english : "Leave it there."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "아직 먹지 말아요.", english : "Don't eat yet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "내가 먹자고 말하기 전에 먹지 말아요.", english : "Don't start to eat until I say so."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "점심 노래를 부르자.", english : "Let's sing the lunch song."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Prayer & Song", case : "", korean : "기도하자.", english : "Let's pray."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "점심이 뭐죠?", english : "What's for lunch?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "좋아하는 음식이 뭐예요?", english : "What is your favorite food?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "맛있게 먹어요.", english : "Enjoy your meal."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "맛이 어떤가요?", english : "How is the taste?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "맛있어요?", english : "Is it tasty?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "매워요?", english : "Is it spicy?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "어떤 맛이에요?", english : "What does it taste like?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "좋아해요?", english : "Do you like it?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "다 먹어요.", english : "Please eat up everything."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "과일과 야채를 많이 먹어야 해요.", english : "You need to eat lots of fruits and vegetables."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "과일과 야채는 몸에 좋아요.", english : "Fruits and vegetables are good for you."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "더 먹을래요?", english : "Do you want some more?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "가서 더 달라고 해요.", english : "Go and ask for some more."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "깍두기 조금 먹어 보자.", english : "Let's try a little bit of radish kimchi."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "이거 한 번 먹어 보자.", english : "Try this one."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "일반적인 식사 중 대화", korean : "잘 씹어요.", english : "Chew well."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "간식 시간이에요.", english : "It's time for a snack."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "간식을 먹자.", english : "Let's have a snack."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "간식으로 요구르트를 먹을 거예요.", english : "We are going to eat yogurt for our snack."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "사과를 좀 먹어 봐요.", english : "Have some apples."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "컵에 주스를 잘 따라요.", english : "Pour the juice into the cup carefully. "},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "공평하게 바나나를 나누어요.", english : "You need to share the bananas equally."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "한 사람당 3개씩 가질 거예요.", english : "You will each get 3."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "천천히 먹어요.", english : "Eat slowly."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "천천히 먹어요.", english : "Take your time eating."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Conversation", case : "간식시간", korean : "두 번 더 먹어요.", english : "Two more bites to go."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "도시락/식판에 음식을 남기지 말아요.", english : "Don't leave food on your tray."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "먹는 동안에는 입을 다물어요.", english : "Close your mouth while you are eating/chewing."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "입에 음식이 있을 때 얘기하지 말아요.", english : "Don't talk with food in your mouth."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "숟가락과 젓가락을 사용해요.", english : "Use your spoon and chopstick."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "음식을 흘리지 말아요.", english : "Don't spill your food."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "젓가락으로 장난하지 말아요.", english : "Don't play with your chopsticks."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "음식으로 장난하지 말아요.", english : "Don't play with your food."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "바닥에 흘린 음식을 먹지마세요.", english : "Don't eat food off the floor."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "테이블 가까이 앉아서 먹어요.", english : "Sit close to the table to eat."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 04 Lunch & Snack", title : "Table Manners", case : "", korean : "의자를 테이블 쪽으로 끌어와요.", english : "Move your chair closer to the table."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "양치질해요.", english : "Brush your teeth."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "치약을 짜서 칫솔에 묻혀요.", english : "Squeeze sone toothpaste on your toothbrush."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "치약이 너무 많아요.", english : "That's too much toothpaste."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "이를 위 아래로 닦아요.", english : "Brush your teeth up and down."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "입을 물로 헹궈요.", english : "Rinse your mouth with water."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "타월로 입을 닦아요.", english : "Wipe your mouth with a towel."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "칫솔을 갖다 놓아요.", english : "Put your toothbrush away."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "치약 뚜껑을 닫아요.", english : "Put the cap back on the toothpaste."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "거울로 이를 보자.", english : "Look at your teeth in the mirror."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "깨끗해요?", english : "Are they clean?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Brushing Teeth", case : "", korean : "물을 틀어요.", english : "Turn on the water."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "방 안에서 조용한 목소리로 말해요.", english : "use your indoor voices."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "화장실에서 조~용~히 말해요.", english : "Speak quietly in the bathroom."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "화장실에서 놀지 마세요.", english : "Don't play in the bathroom."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "들어가기 전에 노크하세요.", english : "Please knock on the door before you enter."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "줄을 서요.", english : "Line up"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "차례를 기다려요.", english : "Wait for your turn."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "변기에 물 내리는 거 잊지 말아요.", english : "Don't forget to flush the toilet."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "사용 후 변기에 물을 내려요.", english : "Flush the toilet after using it."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "화장실에서 물장난하지 말아요.", english : "Do not splash water in the bathroom."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "물장난하지 말아요.", english : "Don't play with water."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "종이타월은 휴지통에 버리세요.", english : "Put the paper towel in the trash can."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "종이타월을 사용한 뒤, 버리세요.", english : "Use a paper towel and throw it away."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Using the Bathroon", case : "", korean : "수건으로 손을 말려요.", english : "Dry you hands with a towel."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "소매를 걷어 올려요.", english : "Roll up your sleeves."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "손에 물을 적셔요.", english : "Wet your hands with water."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "흐르는 물에 손을 대세요.", english : "Put your hands under the water."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "비누를 잡으세요.", english : "Get the bar of soap."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "손에 비누칠을 해요.", english : "Put some soap on your hands."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "거품을 내요.", english : "Make bubbles."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "손을 문질러요.", english : "Scrub your hands."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "손바닥을 씻어요.", english : "Wash your palms"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "물로 손을 씻어요.", english : "Rinse your hands with water."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Washing hands", case : "", korean : "종이타월로 손은 닦아요.", english : "Dry your hands with a paper towel."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "화장실 가고 싶은 사람 있어요?", english : "Does anyone want to go to the bathroom?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "누구 화장실 가고 싶어요?", english : "Who wants to go to the bathroom?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "화장실에 가고 싶으면, May I go tothe bathroom? 이라고 말해야 돼요.", english : "If you want to go to the bathroom, you should ask 'May I go to the bathroom?'"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "문을 잠궈요.", english : "Lock the door."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "문을 잠그지 말아요.", english : "Don't lock the door."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "바지 단추를 풀러요.", english : "Unbutton your pants."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "벨트를 풀어요.", english : "Unbuckle your belt."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "바지를 내려요.", english : "Pull down your pants."},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "옷 입는 거 도와줄까요?", english : "Do you neet help getting dressed?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "바지 지퍼를 올리는 거 도와줄까요?", english : "Do you need help zipping up your pants?"},
    { unit : "Unit 01 Everyday Routines", chapter : "Chapter 05 Bathroom Activities", title : "Others", case : "", korean : "변기의 이쪽 부분은 만지지 말아요.", english : "Don't touch this part of the toilet."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자발적인 참여 유도하기", korean : "누가 나와서 해볼래요?", english : "Who wants to come up here?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자발적인 참여 유도하기", korean : "누가 이것을 해볼래요?", english : "Who would like to do it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자발적인 참여 유도하기", korean : "누가 이것을 할 수 있어요?", english : "Who can do it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자발적인 참여 유도하기", korean : "손을 드세요.", english : "Raise your hands!"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자발적인 참여 유도하기", korean : "자진해서 할 사람?", english : "Any volunteers?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자발적인 참여 유도하기", korean : "원하는 사람 있어요?", english : "Does anybody want to?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "지명하기, 순서 지키기", korean : "민지, 앞으로 나오세요!", english : "Minji, come up here!"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "지명하기, 순서 지키기", korean : "민지, 해볼래요?", english : "Minji, will you try?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "지명하기, 순서 지키기", korean : "누가 바르게 앉아 있어요?", english : "Who is sitting nicely?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "지명하기, 순서 지키기", korean : "누가 다음이이요?", english : "Who is next?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "지명하기, 순서 지키기", korean : "1분만 기다려요.", english : "Wait a minute."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "지명하기, 순서 지키기", korean : "지금 누구 차례예요?", english : "Whose turn is it now?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자기 자리로 돌아가기", korean : "자리로 돌아가요.", english : "Go back to your seat."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자기 자리로 돌아가기", korean : "앉으세요.", english : "Sit down."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자기 자리로 돌아가기", korean : "자기 자리에 앉아요.", english : "Sit in your place."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자기 자리로 돌아가기", korean : "가서 앉으세요.", english : "Go and sit down."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Whole Group Interaction", case : "자기 자리로 돌아가기", korean : "자기 자리로 가요.", english : "Go to your place."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "보드를 봐요.", english : "Look at the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "보드에 가요.", english : "Go to the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "마커를 가져요.", english : "Get a marker."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "마커를 집어요.", english : "Pick up the marker."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "보드에 책을 그려요.", english : "Draw a book on the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "보드에 글씨를 써요.", english : "Write the word on the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "보드에 있는 글씨를 읽어요.", english : "Read the word on the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "보드를 닦아 줄래요?", english : "Would you please clean the board?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "보드활동", korean : "그림을 지워줄 수 있어요?", english : "Can you erase this picture?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "융판에 그림을 붙여요", english : "Put the picture on the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "저기에 붙여요.", english : "Put it there."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "여기 안에 놓아요.", english : "Put it in here."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "이것을 떼세요.", english : "Take it off."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "여기 위에 놓아요.", english : "Put it up here."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "여기 아래에 놓아요.", english : "Put it down here."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "거꾸로 있어요./뒤집혔어요.", english : "It's upside down."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Board Activity", case : "융판활동", korean : "오른족이 위로 올라오도록 해요.", english : "Turn it right side up."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "이제, ~을 그릴 거예요.", english : "Now, you are going to draw something."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "책을 꺼내요.", english : "Take your books out."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "장/사물함에서 저널을 꺼내요.", english : "Take out your journals from your cubby."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "책을 준비해요.", english : "Get your books ready."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "책을 테이블 위에 놓아요.", english : "Put your books on the table."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "책이 어디 있어요?", english : "Where is your book?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "텍스트 북 준비하기", korean : "짝과 함계 볼래요?", english : "Could you share it with your partner?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "페이지 찾기", korean : "15페이지 봐요.", english : "Look at page 15."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "페이지 찾기", korean : "15페이지 펼쳐요.", english : "Open your books to page 15."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "페이지 찾기", korean : "15페이지를 찾았어요?", english : "Did you find page 15?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "페이지 찾기", korean : "다음 페이지로 넘겨요.", english : "Turn to the next page."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "페이지 찾기", korean : "2페이지를 건너뛰어요.", english : "Skip two pages."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "페이지 찾기", korean : "15페이지로 돌아가요.", english : "Turn back to page 15."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "자료의 위치 찾기", korean : "책 앞에 있어요.", english : "It's in the front of the book."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "자료의 위치 찾기", korean : "페이지 도입에 있어요.", english : "It's at the top of the page."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "자료의 위치 찾기", korean : "위쪽에 있어요.", english : "It's at the top."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "자료의 위치 찾기", korean : "왼쪽에 있어요.", english : "It's on the left."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "자료의 위치 찾기", korean : "그림 옆에 있어요.", english : "It's next to the picture."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Textbook Activity", case : "자료의 위치 찾기", korean : "그림 밑에 있어요.", english : "It's underneath the picture."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료 배부하기", korean : "인쇄물을 나눠줄게요.", english : "I'll give you the handouts."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료 배부하기", korean : "여기에 있어요.", english : "Here you are."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료 배부하기", korean : "하나를 가져요.", english : "Take one."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료 배부하기", korean : "친구들한테 줘요.", english : "Give these to your friends."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료 배부하기", korean : "인쇄물을 나눠 줄래요?", english : "Could you pass out these handouts?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "파일에 넣어요.", english : "Put it in your file."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "활동지를 받으면 이름을 써요.", english : "Write your name when you get it."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : " 먼저 이름 쓰는 거 잊지 말아요.", english : "Don't forget to write your name first."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "모두 받았어요?", english : "Did everyond get one?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "만약 못 받았으면 손을 들어오.", english : "Raise your hands if you didn't get one."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "얼마나 더 필요해요?", english : "How many more do you need?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "와서 하나 가져가요.", english : "Come and get one."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "민지한테 줘요.", english : "Pass it to Minji."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 06 During the Lesson", title : "Handout Distribution", case : "자료보관, 추가 배부", korean : "필요하면 가서 하나 가져가요.", english : "Go get one if you need more."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "들을 준비 됐어요?", english : "Are you ready to listen?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "내가 말하는 것을 들어요.", english : "Listen to what I say."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "내 목소리가 들려요?", english : "Can you hear me?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "다시 들어봐요.", english : "Listen again."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "뭐라고 말했어요?", english : "What does it say?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "소리를 들을 수 있어요?", english : "Can you hear the sound?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "무엇이 들려요?", english : "What do you hear?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "그녀의 말을 들어봐요.", english : "Let's listen to her."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "주의 깊게 들었어요?", english : "Did you listen carefully?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣기활동 일반 지시문", korean : "이야기가 재밌었어요?", english : "Did you enjoy the story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣고 지시 따라하기", korean : "오렌지가 어디 있어요?", english : "Where is an orange?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣고 지시 따라하기", korean : "알파벳 B를 가리켜 봐요.", english : "Point to the letter B."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣고 지시 따라하기", korean : "오렌지에 파란색으로 삼각형을 그리세요.", english : "draw a blue triangle around the orange."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣고 지시 따라하기", korean : "책을 보여줘요.", english : "Show me the book."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "듣고 지시 따라하기", korean : "little' 이란 단어를 들으면 손뼉을 치세요.", english : "When you hear the word 'little', clap your hands."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "자료 듣기", korean : "테이프를 들어 봐요.", english : "Let's listen to the tape."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "자료 듣기", korean : "CD를 들어 봐요.", english : "Let's listen to the CD."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "자료 듣기", korean : "인터넷으로 들어 봐요.", english : "Let's listen on the Internet."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "자료 듣기", korean : "영화를 봐요.", english : "Let's watch a movie."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "자료 듣기", korean : "비디어 장면을 봐요.", english : "Let's watch a video clip."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "글자의 소리를 들어 봐요.", english : "Listen to the sounds."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "단어를 들어 봐요.", english : "Listen to the words."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "같아요? 달라요?", english : "Are they the same of different?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "몇 개의 소리가 들렸나요?", english : "How many sounds did you hear?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "첫 소리가 무엇이에요?", english : "What is the beginning sound?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "그들이 음운이에요?", english : "Do they rhyme?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "hop와 같은 음운인 단어는 무엇인가요?", english : "What word rhymes with hop?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Listening", case : "음운인식", korean : "hop와 Mop은 같은 음운이에요.", english : "Mop rhymes with hop."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "말하기활동 읽기 지시문", korean : "무슨 그림인지 말해 보세요.", english : "Name the picture."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "말하기활동 읽기 지시문", korean : "이것을 무엇이라 부르세요?", english : "What is this called?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "말하기활동 읽기 지시문", korean : "이것의 이름을 알아요?", english : "Do you know its name?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "말하기활동 읽기 지시문", korean : "이것을 무엇이라고 부르나요?", english : "What do you call it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "말하기활동 읽기 지시문", korean : "그림의 이름을 말해 주세요.", english : "Tell me the name of the picture."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "잘 안 들려요.", english : "I can't hear you."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "천천히 말하세요.", english : "Speak slowly."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "분명하게 말하세요.", english : "Speak clearly."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "더 크게 말해요.", english : "Speak up."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "소리치지 말아요.", english : "Don’t shout."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "다시 말해줄 수 있어요?", english : "Can you say it again?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "되풀이해서 말해 줄래요?", english : "Can you repeat that?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "볼륨, 속도 조절하기", korean : "뭐라고 했나요?", english : "What did you say?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "짝 활동하기", korean : "짝끼리 말해요.", english : "Talk in pairs."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "짝 활동하기", korean : "영주, 민지에게 기분이 어떤지 물어봐요.", english : "Youngju, ask Minji how she feels."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "짝 활동하기", korean : "영주, 그녀 기분이 어떤가요?", english : "Youngju, how does she feel?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "짝 활동하기", korean : "짝과 함께 연습해 봐요.", english : "Practice with your partner."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "역할극을 할 시간이에요.", english : "It's time for role-playing."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "역할극을 할 거에요.", english : "We are going to role-play."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "Gingerbread man인 것처럼 말해봐요.", english : "Speak as if you were a gingerbread man."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "잠을 자고 있는 척 해봐요.", english : "Pretend that you are sleeping."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "곰인 척 해봐요.", english : "Pretend that you are a bear."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "피곤한 척 해봐요.", english : "Pretend that you are tired."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "원숭이처럼 걸어 봐요.", english : "Walk like a monkey."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "누가 농부가 될래요?", english : "Who wants to be the farmer?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "민지가 농부가 될 거에요.", english : "Minji will be the farmer."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "어떤 역할을 하고 싶어요?", english : "Which role are you going to take?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "물을 주세요.' 라고 말해요.", english : "Say, 'Give me same water.'"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Speaking", case : "역할놀이", korean : "여기에 서요.", english : "Stand here."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "내가 먼저 읽을게요.", english : "Let me read it first."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "다같이 읽어 봐요.", english : "Let's read it together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "누가 읽을래요?", english : "Who wants to read?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "잘 읽었어요.", english : "Good job reading."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "이 단어를 어떻게 읽을까요?", english : "How would you read this word?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "이것을 어떻게 말할까요?", english : "How would you say it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "소리내 보세요.", english : "Sound it out."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "내가 이야기를 읽을 거에요.", english : "I'm going to read the story."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "이야기를 들어요.", english : "Listen to the story."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "읽기활동 일반 지시문", korean : "이야기가 좋았어요?", english : "Did you like the story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "이것은 A에요.", english : "This is A"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "대문자 L에 동그라미 하세요.", english : "Circle the big letter L."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "글자를 맞추어 봐요.", english : "Match the letters."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "이 글자의 이름이 무엇인가요?", english : "What is the name of this letter?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "무슨 글자로 시작하나요?", english : "What letter does it start with?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "/m/소리가 나는 글자는 무엇인가요?", english : "What letter maker/m/sound?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "/m/소리가 나는 글자를 써봐요.", english : "Write the letter that makes /m/ sound."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 1", korean : "글자 B가 무슨 소리를 내요?", english : "What sound does the letter B make? "},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "S로 시작하는 단어들을 써봐요.", english : "Write the words that begin with the letter S."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "이 단어의 첫소리가 무엇이에요?", english : "What is the beginning sound of this word?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "뭐라고 말했어요?", english : "What does it say?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "누가 이 단어를 읽어 볼 수 있어요?", english : "Who can read this word?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "들리는 첫소리가 무엇이에요?", english : "What beginning sound do you Hear?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "그들이 라임(음운)인가요?", english : "Do they rhyme?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "이 문장에 몇 단어가 있어요?", english : "How many words are in this sentence?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "/a/ 소리가 나는 단어들을 찾아봐요.", english : "Find the words that have /a/sound."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "pan에서 몇 개의 모음 소리가 들려요?", english : "What vowel sound do you hear in the word pan?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Reading", case : "Phonics 2", korean : "몇 개의 음절이 있어요?", english : "How many syllables does this word have?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "이제 쓰기를 해보자.", english : "Now, let's do some writing."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "함께 글씨를 써보자.", english : "Let's write."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "글자를 베껴요.", english : "Trace the letter."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "단어를 옮겨 적어요.", english : "Copy the word."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "단어를 쓰세요.", english : "Write the word."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "내가 단어를 어떻게 쓰는지 봐요.", english : "Watch how I write the word."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "내가 글자 A 쓰는 걸 봐요.", english : "Watch me write the letter A."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "보드에 써있는 문장들을 옮겨적을 수 있어요?", english : "Can you copy the sentences from the board?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "보드에 있는 것을 쓰세요.", english : "Write down what's on the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "보드에 있는 것을 베껴요.", english : "Copy what's on the board."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "베껴 쓰는 동안 읽어봐요.", english : "Read while you are copying."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "이 단어를 지워줄 수 있어요?", english : "Can you erase this word?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "빠진 글자를 써서 단어를 완성하세요.", english : "Write the missing letter to complete the word."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "반듯하게 글씨를 써봐요.", english : "Please write neatly."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "글씨체가 보기 좋아요.", english : "Your handwriting is good."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기활동 일반 지시문", korean : "이 글자는 O인가요, C인가요?", english : "Is this letter O or C? "},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "무엇이 필요해요?", english : "What do you need?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "연필과 종이를 가져요.", english : "Get a pencil and a piece of paper."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "연필이 있어요?", english : "Do you have a pencil?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "종이가 필요해요?", english : "Do you need a piece of paper?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "공책이 어디에 있어요?", english : "Where is your notebook?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "연필을 이렇게 잡아요.", english : "Hold the pencil like this"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "천천히 쓰세요.", english : "Writed slowly"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "서두르지 말아요.", english : "Don't rush."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "천천히 해요.", english : "Take your time."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 준비하기", korean : "천천히 해요.", english : "Do it slowly."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "줄을 보세요.", english : "Look at the lines."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "맨 윗줄에서 글자를 시작해요.", english : "Start the letter at the top of the line."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "선을 넘지 말아요.", english : "Don't cross the line."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "줄 안에 써요.", english : "Stay between the lines."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "줄에 맞춰서 써요.", english : "Write it on the line."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "글자를 좀 더 크게 써요.", english : "Write the letter bigger."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "단어 사이에 띄어쓰기가 필요해요.", english : "You need more space betweeb the words."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기", korean : "여기에 쉼표가 있어야해요.", english : "You need a comma here."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 확장활동", korean : "단어를 찾아 문장을 완성하세요.", english : "Find a word to complete the sentence."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 확장활동", korean : "빈칸을 채워요.", english : "Fill in the blank./Fill the blanks."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 확장활동", korean : "그림을 보고, 동물의 이름을 쓰세요.", english : "Look at the picture and write the name of the animal."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 확장활동", korean : "그림을 보고 보이는 것을 쓰세요.", english : "Look at the picture and write what you see."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 확장활동", korean : "더 쓰고 싶은 것이 있어요?", english : "Can you think of anything else to write?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 07 Teaching Language Skills", title : "Writing", case : "쓰기 확장활동", korean : "무엇에 관해 쓰고 싶어요?", english : "What would you like to write about?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "책의 겉표지를 봐요.", english : "Look at the cover of the book."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "저자가 누구에요?", english : "Who is the author?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "저자는 Eric Carle이에요.", english : "The author is Eric Carle."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "무엇이 보여요?", english : "What do yo see?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "이 책은 무엇에 대한 것이라고 생각해요?", english : "What do you think this book is about?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "이 이야기/시는   에 관한 것이에요.", english : "This story/poem is about_______."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "이 책이 야생 동물에 관련된 책이라고 생각하나요?", english : "Do you think the book is about wild animals?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "이야기를 들어봐요", english : "Listen to the story."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "보면서 잘 들어봐요", english : "Look and listen carefully."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "이야기가 재밌었어요?", english : "Did you like the story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "그림을 보자", english : "Let's look at the pictures."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "무엇이 보여요?", english : "What do you see?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "무엇이 보이는지 말 할 수 있어요?", english : "Can you tell me what you see?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "그림들을 찾아보자", english : "Let's find the pictures."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "잘 들어봐요", english : "Listen carefully."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "동화책 소개하기", korean : "사자를 찾아보아요", english : "Find the lion."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "이야기에 몇 명의 등장인물이 있어요?", english : "How many characters are in the story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "등장인물의 이름을 말할 수있어요?", english : "Can you name the characters?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "이야기에 누가 나와요?", english : "Who is in this story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "누가 주인공이에요?", english : "Who is the main character in the story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "좋아하는 등장인물이 누구에요?", english : "Who is your favorite character?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "무엇에 관한 이야기예요?", english : "What is the story about?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "이야기가 어디서 일어났어요?", english : "Where does the story take place?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "이야기가 언제 일어났어요?", english : "When does the story take place?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "문제가 무엇인에요?", english : "What is the problem?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "처음에 무슨일이 일어났어요?", english : "What happened first?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "그 다음에 무슨 일이 일어날까요?", english : "What will happen next?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "누가 추측할 수있어요?", english : "Does anyone want to guess?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "이야기의 어떤 부분을 좋아해요?", english : "What was your favorite part of the story?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Language Arts", case : "읽고 이해하기", korean : "이야기의 어던 부분이 제일 좋아요?", english : "Which part of the story did you like the best?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "생일이 언제예요?", english : "When is your birthday?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "언제 태어났어요?", english : "When were you born?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "멸 살이에요?", english : "How old are you?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "생일에 무엇을 해요?", english : "What do you do on your birthday?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "생일에 무엇을 하고 싶어요?", english : "What do you like to do on your birthday?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "가족이 몇 명이에요?", english : "How many people are in your family?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "나와 가족과 관련된 질문", korean : "조부모님들과 같이 살아요?", english : "do you live with your grandparents?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "어디에 살아요?", english : "Where do you live?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "아파트에 살아요?", english : "do you live in an apartment?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "집에 방이 몇 개 있어요?", english : "How many rooms does your house have?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "자신의 방이 있어요?", english : "Do you have your own room?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "침대에서 잠을 자나요?", english : "Do you sleep in a bed?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "형/ 동생과 방을 같이 써요?", english : "Do you share a room with your brother?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "벽에 있는 지도를 가리키세요", english : "Point to the map on the wall."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "주거와 관련된 질문", korean : "어디서 사는지 지도에서 찾아볼 수있어요?", english : "Can you find where you live on the map?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "그의 직업이 무엇이에요?", english : "What dose he do?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "그의 직업이 무엇이에요?", english : "What is his job?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "그녀는 어디서 일하나요?", english : "Where does she work?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "그들에게 어떤 특별한 도구가 필요하나요?", english : "Do they need any special tools?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "어떤 도구가 필요해요?", english : "What kind of tools do they need?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "누가 가위를 써요?", english : "Who uses scissors?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "크리스마스가 언제예요?", english : "When is Christmas?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "설날에 사람들이 무엇을해요?", english : "What do people do on New Year's Day?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Social Studies", case : "직업, 특별한 날과 관련된 질문", korean : "추석에는 사람들이 무엇을 먹어요?", english : "What do people eat on Chusok?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "과일 샐러드를 만들 거예요.", english : "We are going to make fruit salad."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "얼린 바나나를 만들어 봅시다.", english : "Let's make a frozen banana."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "과일 샐러드를 만들려면 무엇이  필요해요?", english : "What do we need to make fruit salad?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "과일 샐러드를 만들려면 어떤 재료가 필요해요?", english : "What ingredients do we need for fruit salad?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "요리법 읽는 동안 잘 들어보세요.", english : "Listen carefully while I read the recipe."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "요리법을 같이 읽어 봅시다.", english : "Let's read the recipe together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 소개하기", korean : "처음에 무엇을 해요?", english : "What do we do first?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "내가 무엇하는지 잘 봐요.", english : "Look at what I'm doing."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "쉬어요.", english : "Mix it (up)."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "휘 저어요.", english : "Stir it (up)."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "잘라요.", english : "Cut it."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "요구르트를 넣어요.", english : "Add some yogurt."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "과일을 그릇에 담아요.", english : "Put the fruit in the bowl."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "5분 동안 기다려요.", english : "Wait five minutes."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "만일 열을 가하면 어떤일이 생길까요?", english : "What will happen if I heat it up?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "맛이 어때요?", english : "How does it taste?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "요리 활동", korean : "무슨 소리가 들리나요?", english : "What sound do you hear?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "손을 씻어요.", english : "Wash your hands."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "모자를 써요.", english : "Put on your hat."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "정리할 시간이에요.", english : "It's time to clean up."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "뒤로 물러 나요.", english : "Step back."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "뒤로 조금 움직여요.", english : "Move back a little."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "너무 가까이 있지 말아요.", english : "Don't stand too close."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "칼을 조심해요.", english : "Be careful with the knife."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "불에서 멀리 있어야 돼요.", english : "Stay away from the fire."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Cooking", case : "도구및 재료 다루기", korean : "이것은 날카로워요.", english : "It is sharp."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "고양이를 그려요.", english : "Draw a picture of a cat."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "종이에 고양이를 그려요.", english : "Draw a cat on the paper."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "사과를 빨간색으로 칠해요.", english : "Color the apple red."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "연필을 사용해요.", english : "Use pencils."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "종이에 있는 점선 부분을 잘라요.", english : "Cut the paper on the dotted lines."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "가위로 잘라내요.", english : "Cut it out with scissors."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "종이에 이렇게 접어요.", english : "Fold the paper like this."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "종이에 반을 접어요.", english : "Fold the paper in half."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "누가 풀을 가져다줄래요?", english : "Who can bring me the flue?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "이것을 같이 붙여요.", english : "Glue these together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "조각들을 붙여요.", english : "Glue the pieces together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "이것들을 함께 붙이세요.", english : "Put them together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "음악을 들어봐요.", english : "Listen to the music."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "무슨 노래를 부르고싶어요.", english : "What song do you want to sing?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "가사를 부르지 말고 음만 따라불러요.", english : "Don't sing the words."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "조형활동하기", korean : "우리가 할 수 있는 한 노래를 빨리 불러요.", english : "Let's sing as fase as we can."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "도구및 재료 다루기", korean : "정리 할 시간이에요", english : "It's time to clean up"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "도구및 재료 다루기", korean : "연필 치우세요", english : "Put the penclis away"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "도구및 재료 다루기", korean : "풀 뚜껑을 덮어요", english : "Put the lid on the glue."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "도구및 재료 다루기", korean : "쓰레기를 버려요", english : "Throw the trash away."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "도구및 재료 다루기", korean : "찰흙을 통에 다시 넣어요", english : "Let's put the clay back in the container"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Creative Arts", case : "도구및 재료 다루기", korean : "찰흙을 싸요", english : "Squeeze the clay"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "노래부르기", korean : "함께 노래를 불러보자", english : "Let's sing  together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "노래부르기", korean : "ABC 노래를 불러보자", english : "Let's sing the ABC song."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "노래부르기", korean : "음악을 들어봐요.", english : "Listen to the music."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "노래부르기", korean : "무슨 노래를 부르고 싶어요?", english : "What song do you want to sing?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "노래부르기", korean : "가사를 부르지 않고, 음만 따라 불러요.", english : "Don't sing the words."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "노래부르기", korean : "우리가 할 수있는 한 노래를 빨리 불러봐요", english : "Let's sing as fase as we can."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "노래와 함께 율동을 해보자.", english : "Let's sing with motions."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "박수치자.", english : "Let's clap."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "리듬에 맞춰 박수를 쳐요.", english : "Clap to the rhythm."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "나를 봐요.", english : "Watch me."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "내가 하는대로 해요.", english : "Do what I do."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "나를 따라해요.", english : "Follow me."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "팔을 이렇게 움직여 봐요.", english : "Move your arms like this."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Music", case : "율동, 동작하기", korean : "조금 더 빨리 움직이세요.", english : "Move a little faster."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "짝활동", korean : "둘씩 짝을지어요.", english : "Get into pairs."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "짝활동", korean : "둘씩 작업해요.", english : "Work in pairs."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "짝활동", korean : "짝을 지어요.", english : "Get a partner."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "짝활동", korean : "나는 여러분이 둘씩 작업하기 원해요.", english : "I want you to work in pairs."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "짝활동", korean : "짝과 함께 작업을 해요.", english : "Work together with your partner."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "짝활동", korean : "짝을 바꿔요.", english : "Change your partner."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "우리 5그룹으로 만들어봐요.", english : "Let's make five groups."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "우리 4명씩 그룹을 만들어봐요.", english : "Let's make groups of four."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "4명씩 그룹을 지어요.", english : "Get into groups four."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "각 팀에 4명씩 있어야 해요.", english : "Each team should have four members."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "우리 반을 5팀으로 나눌 거예요.", english : "I'll divide the class into five teams."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "이쪽 반은 A 팀이에요.", english : "This half is team A."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "이반의 나머지는 B팀이에요.", english : "The rest of the class is team B."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "이 5섯명은 C팀이에요.", english : "These five students are team C."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "이 두줄은 D팀이에요.", english : "These two rows are team D."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "나머지들은 E팀이에요.", english : "The others are team E."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "남자 어린이들만 노래할 거예요.", english : "Only the boys will sing."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "여러분은 F팀이에요.", english : "Yor are on the F team."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 08 Subjects 1", title : "Group Activity", case : "그룹활동", korean : "팀을 바꿔요.", english : "Change teams."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "어떻게 보여요?", english : "What does it look like?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "무엇이 보여요?", english : "What do you see?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "제일 처음에 무엇이 올까요?", english : "What comes first?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "다음에 무엇을 해요?", english : "What do we do next?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "무슨일이 일어났죠?", english : "What happened?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "왜요?", english : "Why?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "왜 녹았어요?", english : "Why did it melt?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "뜨겁기 때문에 녹았어요.", english : "It melted because it was hot."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "본 것을 그림에 그려봐요.", english : "Draw a picture of what you see."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "그림을 순서대로 놓아요.", english : "Put the pictures in order."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Science", case : "과학 활동", korean : "그림을 찾아봐요.", english : "Find the picture."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "수, 패턴활동", korean : "1번 문제를 해보자.", english : "Let's do question number one."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "수, 패턴활동", korean : "의자가 몇 개 있어요.", english : "How many chairs are there?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "수, 패턴활동", korean : "의자 하나가 있어요.", english : "There is one chair."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "수, 패턴활동", korean : "의자 다섯 개가 있어요.", english : "There are five chairs."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "수, 패턴활동", korean : "이것은 패턴이에요.", english : "This is a pattern."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "수, 패턴활동", korean : "다음에 무엇이 올까요?", english : "What comes next?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "모양, 색 활동", korean : "어떤 모양이에요?", english : "What shape is this?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "모양, 색 활동", korean : "삼각형이에요.", english : "This is a triangle."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "모양, 색 활동", korean : "무슨 색이에요?", english : "What color is this?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "모양, 색 활동", korean : "빨간 색이에요.", english : "It is red."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "분류하기", korean : "사진을 분류해 보자.", english : "Let's sort the pictures."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "분류하기", korean : "사진을 분류해 보자.", english : "Let's categorize the pictures."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "분류하기", korean : "이것이 여기에 속하나요?", english : "Does it belong here?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "분류하기", korean : "어떤 것이 속하지 않나요?", english : "Which one doesn't belong?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "분류하기", korean : "이것은 어디에 속해요?", english : "Where does it belong?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "분류하기", korean : "이것은 어떤 그룹에 속해요?", english : "What group does this belong to?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "크기, 무게, 길이", korean : "얼마나 높아요?", english : "How tall is it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "크기, 무게, 길이", korean : "이것은 10센티미터에요.", english : "It is 10 centimeters tall."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "크기, 무게, 길이", korean : "높이가 얼마나 돼요?", english : "What is the height of this?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "크기, 무게, 길이", korean : "무게가 얼마나 나가요?", english : "How much does it weight?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "크기, 무게, 길이", korean : "500 그램이에요.", english : "It is 500 grams."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "서열, 비교", korean : "이것들의 사이즈를 비교해 봅시다.", english : "Let's compare the size of these things."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "서열, 비교", korean : "어떤 것이 더 큰가요?", english : "Which one is larger?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "서열, 비교", korean : "이것이  더 커요.", english : "This one is larger."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "서열, 비교", korean : "이것이 저것보다 더 커요.", english : "This one is larger than that."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "서열, 비교", korean : "어떤 것이 제일 높아요?", english : "Which one is the tallest?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Math", case : "서열, 비교", korean : "이것이 제일 높아요.", english : "This one is the tallest."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : "게임을 하자.", english : "Let's play a game."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : "Simon Says' 게임을 하자.", english : "Let's play Simon Says."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : "내가 말하는 것을 들어요.", english : "Listen to what I say."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : "", english : "When I say, 'Simon says, stand up,' you need to stand up"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : "", english : "When I say, ' stand up,' without Simon says,  you shouls not stand up"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : "", english : "If you do, you are out."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Simin Says (사이먼 가라사대)", korean : " ", english : "Simon says, ' Put your hands on your head.'"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "두 카드를 뒤집어요.", english : "Turn two cards over."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "만약 두 카드가 일치하면, 카드를 가져가요.", english : "If the cards match, you get to keep them."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "한번 더 할 수있어요.", english : "You get another turn."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "만약 카드가 불일치하면, 두 카드를 다시 뒤집어 놓아요.", english : "If the cards don't match, turn both of them over again."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "한번 쉬세요/ 기회를 잃었네요.", english : "You lose your turn."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "누구 차례예요?", english : "Whose turn is it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "그 다음은 누구예요?", english : "Who is next?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Concentration (집중게임)", korean : "카드를 가장 많이 가지고 있는 사람이 승자예요.", english : "The one who gets the most cards wins."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "게임을 해봐요.", english : "Let's play a game."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "빙고 게임을 해요.", english : "Let's play BINGO."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "내말을 들어봐요.", english : "Listen to what I say."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "만약 카드에 있으면, *표를 해요.", english : "If it is on your card, cross it out."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "만약 카드에 있으면 마커로 표시해요.", english : "If it is on your card, place a marker on it."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "만약 한 줄에 5개가 모든 있는 경우,손을 들고 '빙고'라고 말하세요.", english : "If you get five in a row, raise your hand and say, 'Bingo.'"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "Bingo (빙고)", korean : "만약 한 줄에 5개가 되면 게임에 이깁니다.", english : "If you get five in a row, you win."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "인형을 감출거예요.", english : "I'm going to hide the doll."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "눈을 감아요.", english : "Close your eyes."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "훔쳐보지 말아요.", english : "Don't peek."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "눈을 떠요.", english : "Open your eyes."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "인형이 어딨나요?", english : "Where is the doll?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "이것이 보여요?", english : "Can you see it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "일어나지 말아요.", english : "Don't get up."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "거기에 가만히 있어요.", english : "Stay where you are."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "교실을 둘러봐요.", english : "Look around the room."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "I Spy", korean : "먼저 찾은 사람이 승자예요.", english : "The one who finds it first is the winner."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "손을 잡고, 원을 만들어요.", english : "Hold hands and make a circle."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "바닥에 앉아요.", english : "Sit down on the ground."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "술래가 원 주위를 돌아 걸어요.", english : "The one who is it walks around the circle."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "네가 술래네.", english : "You are it."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "(원을 돌면서 각 아이들의 머리를 가볍게 치면서 'White Duck'이라고 말해요. 한명을 선택해서, 'Yellow Duck' 이라고 말해요.", english : "Tap each child's head and say, 'White Duck.'"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "(선택 당한) 친구는 일어나요.", english : "Your friend (the yellow duck) will stand up."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "술래는 원 주위를 뛰면서 돌아요.", english : "You have to run around the circle."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "(선택된) 친구가 굴래를 쫓아가요.", english : "Your friend (the yellow duckc) will run after you."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "(선택된 친구는) 술래를 잡으료고 할 거예요.", english : "Your friend (the yellow duck) will try to catch you."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "선택된 친구가 술래를 잡기전에 술래는 친구의 자리에 앉아요.", english : "Try to sit down in your friend's place before your friend catches you."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "만약 친구가 술래를 잡으면, 술래는 다시 술래가 되요.", english : "If your friend catches you, you are it again."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Games", case : "White Duck, Yellow Duck", korean : "만약 친구가 술래를 놓치면 그 친구가 술래가 돼요.", english : "If your friend doesn't catch you, your friend is it."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "바닥에 앉아요.", english : "Sit on the ground."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "두 팀을 만들어요.", english : "Let's make two teams."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "너희들은 토끼에요.", english : "You are a rabbit."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "누가 먼저 시작 할래요?", english : "Who is first?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "릴레이 게임을 해요.", english : "Let's have a relay race."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "여기에 서 있으세요.", english : "Stand here."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "나무까지 뛰었다가 돌아와요.", english : "Run to the tree and back."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "자, 준비, 시작!", english : "Ready, set, go."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Relay Race( 릴레이경주)", korean : "누가 승자예요.", english : "Who is the winner?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "짝에 손을 잡아요.", english : "Hold your partner's hands."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "돌아서요.", english : "Turn around."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "앞을 봐요.", english : "Face forward."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "몸을 이렇게 움직여봐요.", english : "Move your body like this."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "몸을 좀 더 빨리 움직여요.", english : "Move your body a little faster."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "뒤로 걸어요.", english : "Walk backward."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "토끼처럼 뛰어요.", english : "Hop like a rabbit."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "원 주위를 뛰어요.", english : "Hop around the circle."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "손을 흔들어봐요.", english : "Wave your hands."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "신체 활동", korean : "공을 던져요.", english : "Throw the ball."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Hide And Seek (숨바꼭질)", korean : "숨바꼭질 놀이를 해요", english : "We are going to play hide and seek."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Hide And Seek (숨바꼭질)", korean : "누가 술래가 될래요?", english : "Who wants to be it?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Hide And Seek (숨바꼭질)", korean : "술래는 눈을 감고, 10을 세요.", english : "If you are it, close your eyes and count to ten."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Hide And Seek (숨바꼭질)", korean : "훔쳐보지 말아요.", english : "Do not peek."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Hide And Seek (숨바꼭질)", korean : "네 차례에요.", english : "It's yout turn."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "스트레칭을 해보자.", english : "Let's stretch."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "팔을 쭉 펴요.", english : "Stretch your arms out."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "팔을 흔드세요.", english : "Shake your arms."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "팔을 이렇게 올려요.", english : "Bring your arms up like this."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "팔을 양쪽으로 내려요.", english : "Put your arms down at your side,"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "머리를 끄덕여요.", english : "Nod your head."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "머리를 한쪽으로 돌려요.", english : "Turn your head."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "오른 쪽 다리를 올려요.", english : "Raise your right leg up."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "한 다리로 균형을 잡아요.", english : "Balance on the leg."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "왼쪽 다리로 서요.", english : "Stand on your left leg."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "두발을 모아요.", english : "Bring your feet together."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "한 다리를 뒤로 놓아요.", english : "Put one leg behind you."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "왼쪽 다리를 뒤로 놓아요.", english : "Put your left leg behind you."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "다리를 꼬아요.", english : "Cross your legs."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "돌아요.", english : "Turn around."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "왼족으로 돌아요.", english : "Turn to your left."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "오른쪽으로 돌아서 똑바로 가세요.", english : "Turn right and go straight."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "발을 쾅쾅 굴러요.", english : "Stomp your left."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "팔굽혀 펴기를 다섯번 해요.", english : "Do five push-ups."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "깊이 숨을 쉬어요.", english : "Take a deep breath,."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "숨을 들이마시고 내 쉬어요.", english : "Breath in and out."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "코로 숨을 쉬어요.", english : "Breath through your nose."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Physical Education", case : "Streching", korean : "입으로 숨을 쉬어요.", english : "Breath through your mouth."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "줄을 서요.", english : "Make a line."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "친구들을 기다려 줄 수있나요?", english : "Can you wait for your friends?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "친구들 앞으로 끼어들지 마세요.", english : "Don't cut in front of your friends."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "그네에 너무 가까이 가지 말아요.", english : "Don't get too close to the swings."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "다칠 수 있어요.", english : "You might get hurt."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "그네를 타요.", english : "Swing on the swings."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "미끄럼틀을 타요.", english : "Slide down the side."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "한번에 한명씩 내려와요.", english : "Slide down one at a time."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "밀지 말아요.", english : "No pushing."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "발부터 먼저 내려와요.", english : "Slide down feat first."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "시소 놀이를 해요.", english : "Play on the seesaw."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "구름사다리를 타요.", english : "Play on the monkey bars."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "구름사다리에 올라가요.", english : "Climb the monkey bars."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "줄사다리를 올라가요.", english : "Climb up the rope ladder."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "꽉 잡아요.", english : "Hold on tight."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "놀이터", korean : "거기 위에서 뛰어내리지 말아요.", english : "No jumping from up there."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "모래를 던지지 말아요.", english : "Do not throw sand."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "친구에게 모래를 던지지 말아요.", english : "Don't throw sand at your friends."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "모래상자에 모래를 놔두세요.", english : "Keep the sand in the sandbox."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "모래상자에 물을 넣지 말아요.", english : "Don't put water in the sandbox."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "양동이/삽을 함께 사용하세요.", english : "Share the bucket/shovel."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "무엇을 만들어요?", english : "What are you making?"},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "정말 깊은 구멍이네요.", english : "It's a very deep hole."},
    { unit : "Unit 02 Managing Instruction", chapter : "Chapter 09  Subjects 2", title : "Outdoor Play", case : "모래놀이 ", korean : "잘 팠어요.", english : "Good job digging."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "다 같이 교실 규칙을 정해요", english : "Let’s make classrom rules together."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "우리 교실에서는 규칙이 필요해요", english : "We need some rules for our classroom."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "우리에게 어떤 규칙이 있어야 할 까요?", english : "What rules should we have?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "좋은 생각이 있어요?", english : "Any ideas?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "우리는 어떤 것을 하지 말아야 할까요?", english : "What things shouldn't we do?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "우리는 어떤 것들을 해야 할 까요?", english : "What things shouldn we do?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "여기 기억해야 할 것이 있어요", english : "Here are some things to remember."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "여기 또 다른 규칙이 있어요", english : "Here is anther rule."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "나눔에 대해서 어떻게 생각해요?", english : "What do you think about sharing?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "규칙 정하기", korean : "경청에 대해 어떻게 생각해요?", english : "What do you think about listening carefully?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "잘 들어봐요", english : "Listen careglly."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "규칙을 잘 따라요", english : "Follow diretions"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "일할 때 다른 사람을 방해하지 말아요", english : "Do not disturb other who are working."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "타인을 존중하세요. 친절하세요", english : "Respect others, Be kind"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "좋은 말을 사용해요", english : "Use nice words."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "때리지 말아요", english : "Do not hit."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "물건을 소중히 해요", english : "Take care of things."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 1", korean : "안전하게 일하고 놀이해요", english : "Work and play safely."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "손을 들어요", english : "Raise your hands"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "조용히 일해요", english : "Work quietly"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "손과 발을 제자리에 놓아요", english : "Keep your hands and feet to yourself"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "자신의 일을 해요", english : "Do your work"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "방해하지 말아요", english : "Do not interrupt"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "선생님의 말을 들어요", english : "Listen to the teacher"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Classroom  Rules", case : "교실 규칙 2", korean : "자기 자리에 있어요", english : "Stay in your seat"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "수업시간의 규칙", korean : "쉬는 시간에 화장실 가요", english : "Go to the bathroom during the break."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "수업시간의 규칙", korean : "눈은 선생님으 보세요", english : "Keep your eyes on the teacher."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "수업시간의 규칙", korean : "앞을 봐요", english : "Face the front"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "책을 여기에 놓아요", english : "Put the books here"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "문을 닫아요", english : "Close the door"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "불을 꺼요", english : "Turn of the lights"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "그림을 만지지 말아요", english : "Don’t's touch the picture."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "유리 조심하세요", english : "Be careful with the glass"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "바닥에 블록을 던지지 말아요", english : "Don’t throw blocks on floor."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "교실에서 물건을 던지지 말아요", english : "Don’t throw things in the classroom."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "테이블 위에 쓰지 말아요", english : "Don't write on the table."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "기침 할 때 입을 가리세요", english : "Cover your mouth when you cough"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "교실에서 뛰지 말아요", english : "Don't run in the classroom"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "친구들을 밀지 말아요", english : "Don't push your friends."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "학교에 장남감을 가져 오지 말아요", english : "Don't bring toys to school."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "시간이 많지 않아요", english : "You don't have much time"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Other Rules", case : "지시하기", korean : "일을 끝내요", english : "Finish you work"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "무슨 일이에요?", english : "What is going on?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "무엇에 대해 싸우고 있어요?", english : "What are you arguing about?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "왜 싸우나요?", english : "Why are you  fighting?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "무슨 일인지 말해 줄래요?", english : "Can you tell me what happened?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "나는 이것이 사고였다고 생각해요.", english : "I think it was an accident."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "어떻게 생각해요?", english : "What do you think?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "그/그녀가 일부러 했다고 생각해요?", english : "Do you think he/she did it on  purpose?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "그건 사고였어요.", english : "It was an accident"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "민지, 다음번에 좀 더 조심 할 래요?", english : "Minji, can you be more careful next time?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "ㅇㅇㅇ에게 미안하다고 말해요.", english : "Say sorry to ooo"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "또래와의 갈등", korean : "서로 악수 해요", english : "Shake hands."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "거짓말은 좋지않아요", english : "Lying is not good."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "정직하세요", english : "Be honest."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "민지를 고의로 때렸나요?", english : "Did you hit Minji on purpose?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "나는 네가 민지를 고의로 때리지 않았다는 걸 알아요.", english : "I know you did not hit Minji on purpose."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "그러나 민지는 다쳤어요", english : "But Minji is hurt now."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "민지한테 미안하다고 말할 수있어요?", english : "Can you say sorry to Minji?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Problem  Solving", case : "거짓말하는 상황", korean : "제발 조심 하세요", english : "Please be careful"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "손을 들어요", english : " Raise your hand(s)"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "다른 사람이 말하는 걸 들어요", english : "Listen to what others say."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "차례를 기다려서 말해요", english : "Wait for your turn to speak"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "다른 사람이 말할 때,", english : "Listen to others when they are speaking."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "우리는 조용한 목소리를 사용할 께요", english : "We are going to use our indoor voices."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "조용한 목소리를 사용해요", english : "Use your indoor voice."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "부드럽게 말해요", english : "Speak softly"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "다정하게 말해요", english : "Speak gently"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "적절한 상황에서 의사표현하기", korean : "소리치지 말아요. 밖이 아니에요", english : "Don't shout .You are not outside"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "예의 바르게 해요", english : "Be polite "},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "좋은 말을 해요", english : "Say something nice"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "찬구들한테는 좋은 말을 해요", english : "Say nice things to your friends."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "친절한 말을 사용해요", english : "Use kind words"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "교실에서는 모두에게 좋은 친구가 될 수 있도록 해요", english : "Be a good/nice friend to everyone in the classroom"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "다른 사람에게 좋은 말을 사용해요", english : "You need to use nice words with others."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "공평해야 해요", english : "Be fair."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "블록을 친구들과 함께 써요", english : "Share the blocks with your friends"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "크레용을 함께써요", english : "Let's share the crayons."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "싸우지 말고 나눠 쓸래요?", english : "Can you share with your fighting?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "함께 쓸 준비가 됐나요?", english : "Are you ready to share?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "함께 나눠쓰는 방법을 배워야 해요.", english : "You need to learn how to share things."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "사람들 한테 힘이 되어줘야 해요", english : "Be helpful to others."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "사람들과 협동해요.", english : "Cooperate with others."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 10 Classroom Management", title : "Posiltive Attitude", case : "타인과의 관계", korean : "나는 여러분이 서로 도와주길 바래요", english : "I want you to help each other"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Good!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Noce!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Well done!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Great job!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Ver good!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Excellent!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Perfect!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Brilliant!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Terrific!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Fantastic!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Right!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Correct!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Coo!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Awesome!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Fabulous!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Marvlous!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Outstanding!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "That's it!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Impressive!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Unbelievale!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 1", case : "", korean : "", english : "Amazing!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "좋은 답이에요", english : "That's a good answer."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "똑똑한 답이에요", english : "That's a smart answer."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "완벽한 답이에요", english : "It's a perfect answer."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "좋은 생각이에요", english : "Good job thinking"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "정말 멋진 생각이에요!", english : "What a brilliant idea!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "훌륭한 생각이에요", english : "It's an excellent idea."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "영리한 생각이에요", english : "That's clever"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "흥미로운 생각이에요", english : "Very interesting."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 2 :  Answers", case : "", korean : "아이디어가 좋아요", english : "I like your idea"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "멋진 작품이네! 네가 자랑스러워.", english : "This is great work! I'm proud of you"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "이건 멋진 작품이네!", english : "It's a beautiful piece of work!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "와, 이건 정말 대단해!", english : "Wow, this really something!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "나는 이부분이 마음에 들어요", english : "I like this part."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "나는 네가 얼마나 열심히 일 했는지 알 수있어.", english : "I can see you worked very hard."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "나는 네가 그린 그림이 마음에 들어", english : "I like the way you've drawn"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "나는 네가 선택한 색이 맘에 들어.", english : "I like your choice of colors."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Compliments 3 :  Worksheet & Homework", case : "", korean : "이전 정말 흥미로워요", english : "This is really interesting."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "발전 하고 있어요", english : "You are imprvoving"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "많이 발전했어요", english : "You have improved a lot."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "많이 향상 되었어요", english : "You have made a lot of progress."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "점점 나아지고 있어요", english : "You are getting better."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "훨씬 좋네요", english : "This much better "},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "잘 따라잡고 있어요", english : "You are catching up"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "계속하세요", english : "Keep it up"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "계속 열심히 하세요", english : "Keep up the good work "},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "나는 네가 잘 할 줄 알았어", english : "I knew you could do it"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "나는 네가 잘 해 낼 줄 알았어.", english : "I knew you could make it"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "너는 이제 올바른 방향으로 가고 있어요", english : "You are on the right track now"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 1 :  Improvement", case : "", korean : "그에게 큰 박수를 쳐 주세요", english : "Let's give him a big hand"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "좋은 시도에요", english : "Nice try."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "답에 매우 가까워요", english : "You've very close."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "답에 매우 가까워 졌어요", english : "That was so close."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "좋은 추측이에요", english : "Good guess."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "거의 맞아요", english : "That's almost righit."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "거의 맞아요", english : "You are almost there."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "여기 무언가 빠져  있어요", english : "Something is missing here."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "다시 시도해 볼래요?", english : "Will you try it again?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 2 :  Challenge", case : "", korean : "다시 해봐요.", english : "Try again."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "할 수있어요", english : "You can do it."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "다시 해봐요", english : "Try again."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "답에 가까워요", english : "You are close."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "이번에는 맟출 수 있을 거에요", english : "You will get it this time."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "네가 알고 있는 거야", english : "You know it."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "거의 맞았어요", english : "You almost got it."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "한번 시도해 봐요", english : "Give it a try."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "잘하고 있어요", english : "You're doing just fine."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "긴장하지 말아요. 초조해 하지 말아요", english : "Relax. Don't get nervous."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "내가 돠와줄께요", english : "I'll help you."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "힌트를 줄께요", english : "I'll give you a hint."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 11 Compliments & Encouragement", title : "Encouragement 3 :  Courge & Lack Confidence", case : "", korean : "누가 그/ 그녀를 도와 줄래요?", english : "Who would like to help him/her?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "도와줘서 고마워요", english : "Thank you for your help."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "너무 친절하네요", english : "That was so kind."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "정말 진절하시네요", english : "That's nice of you."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "생각이 깊네요", english : "That's thoughtful of you."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "정말 다정하네요", english : "You are so sweet."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "마음이 따뜻하군요", english : "You are so warn-hearted."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "아주 큰 도움이 됐어요", english : "You've been a great help."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "나를 도와줘서 고마워요", english : "Thank you for helping me."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "그것은 나한테 큰 의미가 있어요", english : "That means a lot to me."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "고마울 때", korean : "나는 감동했어요", english : "I'm touched."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "그것에 대해 미안해요", english : "Sorry about that."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "미안해요 난 몰랐어요", english : "Sorry, I didn't know."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "그건 사고(우연)였어요", english : "It was an accident."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "내가 고의로 한 게 아니에요", english : "I didn't do it on purpose."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "정말 미안해요", english : "I'm really sorry."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "내 잘못이에요", english : "It's my fault."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "실수에요", english : "That's my mistake."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "의도하지 않았어요", english : "I didn't mean to do that."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "미안해요, 내가 혼동을 했어요", english : "Sorry, I got confused."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "이름을 잘못 알고 있어서 미안해요", english : "Sorry, I got your name wrong."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "미안할 때", korean : "나는 네가(당신이) 한 줄 알았어요. 미안해요", english : "Sorry, I thought it was you."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "놀랐어요", english : "I'm surprised."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "깜짝 놀랐어요", english : "I'm shocked."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "놀랍네요!", english : "What a surprise!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "나를 놀라게 했어요", english : "You surprised me."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "믿을 수 없어요", english : "This is unbelievable!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "이런!/ 어머나!", english : "Oh, dear!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "어!/ 이런!/ 아이고!", english : "Oh, my!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "다쳤어요?", english : "Are you hurt?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "괜찮아요?", english : "Are you ok?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "놀랐을 때", korean : "와, 어떻게 했어요?", english : "Wow, how did you do that?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "기분 나쁠때", korean : "난 지금 기분이 안좋아요", english : "I don't feel well."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "기분 나쁠때", korean : "기분이 나빠요", english : "I am upset."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "General Feelings", case : "기분 나쁠때", korean : "기분이 나쁘네요", english : "I am unhappy."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "갑자기 울 때", korean : "무슨일 인가요?", english : "What's wrong?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "갑자기 울 때", korean : "울지마요", english : "Don't cry."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "갑자기 울 때", korean : "괜찮아요", english : "That's okay."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "갑자기 울 때", korean : "큰일 아니에요", english : "It's not a big deal."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "흥분 가라앉히기", korean : "침착해요", english : "Calm down."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "흥분 가라앉히기", korean : "너무 흥분하지 말아요", english : "Don't get so excited."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "흥분 가라앉히기", korean : "천천히 해요", english : "Slow down."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "흥분 가라앉히기", korean : "한번에 하나씩 해요", english : "One at a time."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "흥분 가라앉히기", korean : "시간을 가져요", english : "Take your time."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Feelings", case : "흥분 가라앉히기", korean : "서두르지 말아요!", english : "Do not hurry!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "다른 친구에게 방해가 될 때", korean : "그를 가만히 내버려둬요", english : "Leave him alone."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "다른 친구에게 방해가 될 때", korean : "그를 방해하지 말아요", english : "Don't bother him."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "다른 친구에게 방해가 될 때", korean : "그를 놀리지 말아요", english : "Stop making fun of him."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "다른 친구에게 방해가 될 때", korean : "그만하세요", english : "Stop doing that."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "서로 양보하지 않을 때", korean : "친구와 함께 써요", english : "Share with your friend."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "서로 양보하지 않을 때", korean : "네겐 두가지 선택권이 있어요", english : "You have two choices."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "서로 양보하지 않을 때", korean : "친구랑 함께 쓰거나, 아니면 이걸 네가 가져다가는 거예요", english : "Share it with your friends of I get to have it."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "서로 양보하지 않을 때", korean : "나 한테주세요", english : "Hand it to me."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Controlling Peer Conflict", case : "서로 양보하지 않을 때", korean : "타인과 어떻게 나누는지 배울때까지 내가 가지고 있을 꺼에요", english : "I will hold it till you learn how to share with others."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "그거면 충분해요", english : "That's enough."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "공유해 줘서 고맙지만, 이젠 그만해요", english : "Thank you for sharing but stop now."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "나중에 얘기해요", english : "Talk to me later."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "나중에 더 얘기해 줘요", english : "Tell me more later."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "이야기에 관한 그림을 그려줄래요?", english : "Why don't you draw a picture to describe the story?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "난 정말 이거 읽고 싶네요", english : "I would love to read about it."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "좋은 얘기 해줘서 고마워요", english : "Thank you for your great story."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "관계없는 이야기가 계속 될 때", korean : "나중에 말해줘요.", english : "Tell me later."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "핑계를 댈 때", korean : "그녀를 탓하지말아요", english : "Don't blame her."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "핑계를 댈 때", korean : "변명하지 말아요", english : "Don't make excuses."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "지시를 거부 할 때", korean : "듣고 있어요?", english : "Are you listening?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "지시를 거부 할 때", korean : "하세요!", english : "Do it!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "지시를 거부 할 때", korean : "끝내세요!", english : "Finish it!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "지시를 거부 할 때", korean : "기다리고 있어요", english : "I am waiting."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "지시를 거부 할 때", korean : "지시를 반드시 따라야 해요", english : "You must follow directions."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "지시를 거부 할 때", korean : "일어서요!", english : "Stand up!"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "계속 진행하도록 할 때", korean : "계속 해요", english : "Keep on going."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "계속 진행하도록 할 때", korean : "바쁘게 지내요", english : "Keep busy."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "계속 진행하도록 할 때", korean : "계속 잘해요", english : "Keep up the good work."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "계속 진행하도록 할 때", korean : "열심히 해", english : "Get busy."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Teacher-Student Conflict", case : "계속 진행하도록 할 때", korean : "다시 일 하세요.", english : "Get back to work."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "피곤해요?", english : "Are you tired?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "아파요?", english : "Do you feel ill?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "아픈가요?", english : "Are you feeling sick?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "(몸이) 안좋아보여요", english : "You didn't look well."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "괜찮아요?", english : "Are you OK?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "뭐 갖다 줄까요?", english : "Can I get you something?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "필요한 게 있나요?", english : "Do you need anything?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "열이 있는 것 같아요", english : "I Think you have a fever."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "(몸이) 안 좋으면(아프면), 선생님께 알려주세요", english : "If you don't feel well, let your teacher know."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "아이의 상태 점검하기", korean : "아프면, 나한테 알려주세요.", english : "When you feel sick, you need to let me know."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "감기 걸렸어요?", english : "Did you catch a cold?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "배가 아픈가요?", english : "Do you have a stomachache?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "두통이 있어요?", english : "Do you have a headache?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "어지러운가요?", english : "Do you feel dizzy?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "체온을 재 봅시다.", english : "Let's take your temperature."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "열이 있어요", english : "You have a fever."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "증상 다루기", korean : "종이에 베었어요?", english : "Did you ger a paper cut?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "피가 나네요", english : "You are bleeding."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "밴드를 붙일께요", english : "I will put a bandage on it."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "열이있어요", english : "You've got a fever."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "엄마한테 전화하는 게 좋겠어요", english : "I better call your mom."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "기침을 많이 하네요", english : "You are coughing a lot."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "물을 마셔요", english : "Drink some water."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "코를 풀어요", english : "Blow yout nose."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "누워서 잠을 자도록 노력해요", english : "Lie down and try to get some sleep."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "잠시 누워 있어요", english : "Lie down for a little while."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "잠을 잘래요?", english : "Would you like to sleep?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "누워 있을 래요?", english : "Would you like to lie down?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "물을 마실래요?", english : "Would you like a glass of water?"},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "몸이 나아지면, 교실로 돌아와요.", english : "When you feel batter, come back to the classroom."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "엄마한테 전화 할께요", english : "I will call your mom."},
    { unit : "Unit 03 Managing Affect", chapter : "Chapter 12 Feelings", title : "Illness", case : "후속 조치하기", korean : "엄마가 여기에 데리러 올 거예요", english : "Your mom will be here to pick you up."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "--반, 만나서 반가워요.", english : "-- class, I am so happy to see you here."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "난 Mrs. Lee 에요.", english : "I am Mrs. Lee."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "난 여러분의 선생이에요.", english : "I will be your teacher."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "이름이 뭐에요?", english : "What is yout name?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "이 교실에 남자/ 여자가 몇 명인가요?", english : "How many boys/girls are in this class?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "지금부터  여러분은 oo 학교 올거에요.", english : "Now, you are going to come to oo school."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "이곳이 어러분의 교실이에요.", english : "This will be our classroom."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "교실을 둘러봅시다.", english : "Let's look around our classroom."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "무엇이 보여요?", english : "What do you see?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "손은 여기서 닦을 수 있어요.", english : "You can wash your hands over here."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "책을 읽고 싶을때, 이곳을 사용하면 돼요.", english : "When you want to read a book, you can use this area."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "여기서 블록 놀이를 할 수있어요.", english : "You can play with blocks."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "여기서 재밌게 놀 수있어요.", english : "You can have fun over here."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "교실에 오면 본관함/ 사물함에 신발/ 가방을 두세요.", english : "When you come to class, put your shoes/bags in the cubby."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "고리에 코트를 걸어요.", english : "Hang your coat on the hook."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "필요하면,나한테 알려줘요.", english : "If you need something, let me know."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "카펫에 앉아요.", english : "Sit down on the carpet."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "오늘, 뭔가를 만들어 봅시다.", english : "Today, let's make something."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "화장실 가고 싶은 사람 있어요?", english : "Does anyone want to go to the bathroom?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "오리 엔테이션", korean : "화장실 가고 싶으면, 나한테 알려줘요.", english : "If you want to go to the bathroom, let me know."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "마지막 수업이라는 게 믿기질 않네요.", english : "I can't believe it is the end of our class."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "여러분이 그리울 거예요.", english : "I am going to miss you."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "나는 어려분이 매우 자랑스러워요.", english : "I am very proud of you."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "여러분이 많이 컸군요.", english : "You are growing up."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "수업중 가장 좋았던 것이 무엇인가요?", english : "What was your favorite thing in class?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "몇 명은  00반이 될거예요.", english : "Some of you will be in 00  class."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "몇 명은 초등 학교에 갈 거예요.", english : "Some of you will go to elementary school."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "여러분의 선생님이어서 행복했어요.", english : "I was so happy to be your teacher."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Orientaion & Graduation", case : "수료식, 졸업식 ", korean : "다른반에 가더라도, 만나면, hi! 라고 얘기해요", english : "Even if you go to another class, let's say, 'hi,' to each other."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "내일은 민지의 생일 이에요.", english : "Tomorrow is Minji's birthday."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "민지 생일 파티에 무엇이 필요한가요?", english : "What do we need for Minji's birthday party?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "케익, 카드 그리고 선물이 필요해요.", english : "We need a cake, a card and presents."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "민지 생일 카드를 만들어 줍시다.", english : "Let's make a birthday card for Minji."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "선물로 무엇을 줄까요?", english : "What can we give Minji for a present?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "노래 부르기도 좋은 생각이에요.", english : "Singing a song would be a good idea."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 전", korean : "어떤 노래를 불러줄까요?", english : "What song should we sing for Minji?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "오늘은 매우 특별한 날이에요.", english : "Today is very special."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "오늘이 왜 특별한 날인지 누가 말해 줄 수 있나요?", english : "Who can tell me why today is so special?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "누군가의 생일 이에요.", english : "It is someone's birthday."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "누구 생일이죠?", english : "Whose birthday is it?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "민지 생일이에요.", english : "It is Minji's birthday."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "민지 생일 축하해요.", english : "Happy birthday Minji."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "민지 생일 케익이에요.", english : "This is Minji's birthday cake."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "케익에 초를 꽂아요.", english : "Let's put some candles on the cake."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "민지는 몇 살이죠?", english : "How old is Minji?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "민지는 다섯 살이에요.", english : "Minji is five years old."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "다섯 개 초가 필요해요.", english : "We need five candles."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "초에 불을 붙여요.", english : "Let's light the candles."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "Happy Birthady', 노래를 불러요.", english : "Let's sing 'Happy Birthday.'"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "소원을 빌었나요?", english : "Did you make a wish?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 생일 축하노래부르기", korean : "촛불을 끄세요. 생일 축하해요!", english : "Blow out the candles. Happy Birthday!"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "무엇을 받았는지 봅시다.", english : "Look at what we've got."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "민지 생일카드가 있어요.", english : "We have a birthday card of Minji."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "영주, 민지한테 뭐라고 썼어요?", english : "Youngju, what did you write for Minji?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "여기 나와서 쓴 내용을 말해 줄래요?", english : "Can you come up here and tell us what you wrote?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "우리가 민지를 위해 준비한 게 뭔지 기억해요?", english : "Do you rememver what we prepared fo Minji?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "민지 선물이 있어요.", english : "We have a present for Minji."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "민지, 여기로 나오세요.", english : "Minji, come up here."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "선물이에요.", english : "This is your present."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "열어 볼래요?", english : "Do you want to open it?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "여는 거 도와줄께요.", english : "Let me help you open it."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "안에 뭐가 있나요?", english : "What's inside?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "와, 종이비행기를 받았네요.", english : "Wow, you got a paper airplane."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "누가 선물을 줬어요?", english : "Who gave you this present?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "민지를 위한 것이 하나 더 있어요.", english : "We have one more thing for Minji."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Birthady Parties", case : "생일 파티- 선물 주고받기", korean : "무엇이죠?", english : "What is it?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "여러분은 내일 무엇을 할지 알아요?", english : "Do you know what we are going to do tomorrow?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "견학/현장학습을 갈 거예요.", english : "We are going to go on a field trip."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "내일 어디로 가요?", english : "Where are we going tomorrow?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "내일은 많은 것들을 볼 거예요.", english : "We will see many different things tomorrow."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "우리는 거기서 무엇을 볼까요?", english : "What will we see there?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "우리는 무엇을 할까요?", english : "What will we do tomorrow?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "견학/현장학습 갈 때 여러분은 짝이 있을 거에요.", english : "You are going to have a partner for the field trip."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "짝의 손을 잡고 갈 거예요.", english : "You are going to hold your partner's hand."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "밖에 나가면, 나를 따라와요.", english : "When we go out, please follow me."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "사전 학습", korean : "만약, 따라오지 않으면, 길을 잃을 수 있어요.", english : "If you don't follow me, you might get lost."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "견학/ 현장 학습 갈때 무엇이 필요해요?", english : "What do we need for our field trip?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "물, 과자 그리고 가방이 필요해요.", english : "We need water, a Snack, ang a bag."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "점심은 밖에서 먹을 거예요.", english : "We are going to eat our lunch outside."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "점심을 가져와요.", english : "Bring your lunch."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "간식도 가져와도 돼요.", english : "You can bring a snack too."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "3가지 종류의 간삭을 가져와야 할까요?", english : "Should you bring three different kinds of snacks?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "너무 많은 간식은 가져오지 말아요.", english : "Do not bring too many snacks."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "음료수가 필요해요.", english : "We need something to drink."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "물 또는 주스를 가져와도 돼요.", english : "You can bring a bottle of water or juice."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "유리병은 가져오지 말아요.", english : "Don't bring any glass bottles."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "병은 깨질 수있어서, 다칠 수도있어요.", english : "They can break, and you might get hurt."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "뚜껑이 있는 플라스틱 병을 가져와요.", english : "I want you to brinh a plastic bottle with a lid."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "준비물 챙기기", korean : "돗자리를 가져와요.", english : "Bring a mat."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "주위를 둘러봐요.", english : "Look around."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "무엇이 보여요?", english : "What do you see?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "줄을 서서 걸을 수 있어요?", english : "Can you walk in line?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "짝의 손을 너무 꽉 잡지 말아요.", english : "Don't hold your partner's hand too tight."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "위층으로 올라갑시다. 짝의 손을 놓으세요", english : "Let's go up the stairs. Let go of partner's hand too right."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "거의 다 왔어요.", english : "We are almost there."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "점심은 여기서 먹을 거예요.", english : "We are going to eat lunch here."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "너무 멀리 가지 말아요.", english : "Don't go too far."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "너무 빨리 뛰지 말아요. 다칠 수도 있어요.", english : "Don't run too fast, you might get hurt."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "다 함께 있어요.", english : "Stay together."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "엄마가 만들었어요?", english : "Did your mom make this?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "맛있어 보이네요.", english : "It looks yummy."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "화장실에 가고 싶으면, 나한테 말해요..", english : "If you want to go to the bathroom, you need to tell me."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "화장실에 혼자가지 말아요.", english : "Do not go to the bathroom alone."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "자기 자리를 정리하고 쓰레기를 주워요.", english : "Clean up your spot and pick up the trash."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "쓰레기를 쓰레기통에 버리세요.", english : "Put the garbage in the bin."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "줄을 지키세요.", english : "Stay in line."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "사진을 찍읍시다 .", english : "Let's take a picture."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Field Trips", case : "현장 학습", korean : "스마일 ~  '치즈' 라고 말해요.", english : "Smile~Say 'cheeses.'"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "내일 엄마와 아빠가 오실 거예요.", english : "Your moms and dads are coming tomorrow."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "엄마와 아빠가 여러분을 보러 오실 거예요.", english : "Your moms and dads are coming to see you."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "엄마와 아빠가 학교에 오시면 여러분은 어떻게 행동 해야 할 까요?", english : "When your moms and dads come to school, how should you behave?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "바르게 행동해야 해요.", english : "Should/Can you behave nicely?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "엄마가 여기 계실때, 엄마 보지 말아요.", english : "When your mom is here, don't look at your mom."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "선생님을 봐요.", english : "Look at your teacher."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "뒤를 돌아보지 말아요.", english : "Don't turn around."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "누가 어떻게 않는지 보여 줄래요?", english : "Who can show me how to sit?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "누구의 엄마인가요? 민지의 엄마에요.", english : "Whose mom is she? She is Minji's mom."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "민지 엄마 손 잡을래요?", english : "Minji, can you hold your mom's hand?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "엄마 옆에 서요.", english : "Stand by your mom."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "학부모님, 제가 하는대로 해주세요.", english : "Parents, do what I do."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "부모님, 여러분의 아이들을 도와 주세요.", english : "Parents, help your child."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 13 Special Days", title : "Open House ", case : "", korean : "여러분, 지금 부모님께 우리의 춤을 보여줄 수있어요?", english : "Children, now can we show our dance to the parents?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "오늘은 무슨 날이에요?", english : "Do you know what day it is today?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "오늘은 추석이에요", english : "Today is Chusok."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석이 어떤 날인지 아나요?", english : "Do you know what Chusok is?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석이 무엇인지 아는 사람 있어요?", english : "Does anyone know what Chusok is?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석이 언제인가요?", english : "When is Chusok?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석 때 어디 가요? ", english : "Do you go somewhere on Chusok?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "여러분은 어디로 가요?", english : "Where are yo going?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "여러분은 어떻게 가요?", english : "How will you get there?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석때 무엇을 해요", english : "What do you do on Chusok?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석때 무엇을 입어요?", english : "What do you wear on Chusok?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "추석 때 무엇을 먹어요?", english : "What do you eat on Chusok?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Common Expressions", case : "", korean : "산타에 대해서 들어봤어요?", english : "Have you heard about Santa?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "의미와 유래", korean : "옛날에 사람들은 수확하는 것에 대해 매우 감사해 했어요", english : "A long time ago, people were very thankful for the harvest."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "의미와 유래", korean : "봄과 여름에 벼가 자라면, 가을에는 수확 할 때가 돼요", english : "Rice grows during spring and summer, and when it is fall it is ready to harvest."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "의미와 유래", korean : "우리가 벼를 자르면, 이것을 수확/ 추수라고 해요", english : "When we cut the rice, we call it 'harvest'"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "추석에 우리는 특별한 음식을 먹어요", english : "For Chusok, we eat special foods."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "추석때 송편을 먹어요", english : "We eat songpyun on Chusok."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "송편을 만듭시다.", english : "Let's make songpyun."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "송편을 만들기 위해  무엇이 필요해요?", english : "What do we need to make songpyun?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "쌀 반죽, 콩 그리고 솔잎이 필요해요.", english : "We need rice dough, beans, and some pine needles."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "송편을 찔때 솔잎이 필요해요.", english : "We need pine needles to cook the songpyun."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "경단을 만듭시다.", english : "Make a small rice ball."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "콩을 경단 가운데에 넣어요", english : "Put some geans in the middle of the rice ball."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "송편을 눌러서 빚어요", english : "Spueeze it together."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "접시에 송편과 솔잎을 함께 놓아요", english : "Put them on the plate with pine needles."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "송편을 찔 거예요", english : "I am going to steam the songpyun."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "맛이 어떤가요?", english : "How does it taste?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "좋은가요?", english : "Is it good?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "나는 민지 한복이 좋아요", english : "I like minji's hanbok."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Chusok", case : "관련 활동", korean : "예뻐 보여요", english : "You look very pretty."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "의미와 유례", korean : "크리스마스는 무엇인가요?", english : "What is Christmas?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "의미와 유례", korean : "크리스 마스는 예수 그리스도의 탄생을 축하는 날이에요", english : "Christmas celebrates the birth of Jesus Christ."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "의미와 유례", korean : "산타가 무엇을 하는지 알아요?", english : "Do you know what Santa does?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "의미와 유례", korean : "좋은 일을 하면, 산타가 선물을 줄거에요", english : "It you do good things, Santa will give you a present."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "의미와 유례", korean : "여러분이 잠잘 때  산타가 선물을 놓고 갈꺼예요", english : "Santa will leave presents when you are sleeping."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "크리스마스가 오면 우리는 크리스마스 나무를 장식해요", english : "When Christmas comes, we decorate Christmas trees."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "크리스마스 나무 꾸밀때, 우리는 장식품이 필요해요", english : "When we decorate the Christmas tree, we need ornaments."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "나무를 장식품, 리본, 고드름으로 꾸며요", english : "Decorate the tree with ornaments, ribbons, and icicles."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "나무를 예쁘게 꾸밉시다.", english : "Let's make the tree pretty."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "크리스마스 나무를 꾸몄어요", english : "We decorated a Christmas tree."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "매우 멋있어 보여요", english : "It looks very nice."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "크리스마스 카드를 만듭시다.", english : "Let's make a Christmas card."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "누구한테 카드를 줄래요?", english : "Who do you want to give the card to?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "뭐라고 말하고 싶어요?", english : "What do you want to say?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "산타에게는 편지를 쓸 수있을거예요", english : "Maybe you can write a letter to Santa."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "I love you'  라고 쓸 수 있게 도움이 필요한가요? ", english : "Do you need help to write 'I love you.'?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "내가 써 줄게요. 베껴 쓸 수 있어요?", english : "I will write it for you. Can you trace th words?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "Christmas", case : "관련 활동", korean : "카드를 꾸미고 싶어요?", english : "Would you like to decorate your card?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "설날이 무엇인지 알아요?", english : "Do you know what Seolnal is?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "언제가 설날인가요", english : "When is Seolnal?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "이날은 구정이에요", english : "It is the Lunar New Year."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "우리에겐 음력과 양력이 있어요", english : "We have a lunar calendar and a solar calendar."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "음력의 첫날이 설날이에요", english : "The first day of the lunar calendar, is Seolnal."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "구정은 매년 달라요", english : "The Lunar New Year is different every year."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "작년은 1월26일 이 구정이었어요", english : "Last year, the Lunar New Year was January 26th."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "올해는 2월14일이 구정 이에요", english : "This year, the Lunar New Year is February 14th."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "이 날이 설날이에요", english : "It is New Year's Day."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "의미와 유례", korean : "설날에 우리는 한복을 입어요", english : "On Seolnal, we wear hanboks."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "여자들은 저고리와 치마를 입어요", english : "Girls wear 저고리 and 치마."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "남자들은 저고리와 바지를 입어요", english : "Boys wear 저고리 and 바지."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "설날은 무엇을 먹어요?", english : "What do you eat on Seolnal?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "어제는 2022년 오늘은 2023년이 에요 새해예요", english : "Yesterday was 2022, but today is 2023. It is a new year."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "여러분 몇 살이에요?", english : "How old are you?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "모두 한살 더 먹었어요", english : "You are all a year older."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "올해를 상징하는 동물은 무엇인가요?", english : "What animal represents this year?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "호랑이가 올해를 상징해요", english : "The animal that represents this year is the tiger."},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "여러분은 무슨 띠인가요?", english : "What animal are you?"},
    { unit : "Unit 04 Special Days", chapter : "Chapter 14 Special Days 2", title : "New Year's Day", case : "관련 활동 ", korean : "올해는 무엇을 하고 싶어요?", english : "What do you want to do this year?"}
]

const teachingGuides = [
    {
        title: 'Play & Think',
        guides: [
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Feelings Song Teaching Guide ",
                  "Feelings Song 영상을 본다.",
                  "How do you feel today? ",
                  "I am happy !",
                  "Happy / sad"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Hello, how are you? ",
                  "How do you feel? ",
                  "Happy, happy, I am happy. ",
                  "This is how I feel.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine ‘The Weather’ Teaching Guide ",
                  "The Weather Song 영상, 노래",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.) ",
                  "Look out the window.",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. ",
                  "The Weather Song 영상을 본다.",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Feelings Song Teaching Guide ",
                  "Feelings Song 영상을 본다.",
                  "How do you feel today? ",
                  "I am happy !",
                  "Happy / sad"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Hello, how are you? ",
                  "How do you feel? ",
                  "Happy, happy, I am happy. ",
                  "This is how I feel.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "Put the Anna and cake stickers on the",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 케잌, 촛불, 모자,… 라고 말한다. ",
                  "Let’s Look at the Cover ",
                  "영상을 보며, 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s look at the picture. / Today is Anna’s birthday. ",
                  "Do you see a (cake/candle/hat/cup)?",
                  "그림을 가리키며, 읽는다.",
                  "Yes. I see a (cake/candle/hat/cup). ",
                  "Circle the pictures. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"04",
               page:"5",
               sentences:[
                  "How do you feel? Teaching Guide ",
                  "How do you feel? ",
                  "Happy / sad ",
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"05",
               page:"",
               sentences:[
                  "How do you feel?",
                  "Draw your face. ",
                  "(얼굴을 그리게 한다)",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Feelings Song Teaching Guide ",
                  "Feelings Song 영상을 본다.",
                  "How do you feel today? ",
                  "I am happy !",
                  "Happy / sad"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Hello, how are you? ",
                  "How do you feel? ",
                  "Happy, happy, I am happy. ",
                  "This is how I feel.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Birthday Gift Teaching Guide ",
                  "Birthday Gift 제목을 말한다.",
                  "It’s my birthday. I want a gift.",
                  "Teddy bear. I want a teddy bear!",
                  "Birthday Gift 영상, 활동"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"9",
               sentences:[
                  "What do you want?",
                  "Do you want ",
                  "(a book, a tea set, a camera, a teddy bear, ",
                  "stickers, a ball, a toy car)?",
                  "I want (a teddy bear/ stickers /a toy car/...)",
                  "Circle the gifts that you want. ",
                  "Good Job! "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 짚어가며, 노래한다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the Alphabet song?",
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big A Little a Teaching Guide ",
                  "Big A Little a 제목을 말한다.",
                  "Let’s sing: Big A Little a. 영상을 본다.",
                  "Letter A ('애' 소리가 난다)",
                  "What letter is this? / Big A and little a."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big A and little a. apple",
                  "Put the matching sticker on the circle.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Big A Little a Teaching Guide ",
                  "Big A Little a 제목을 말한다.",
                  "Let’s sing: Big A Little a. 영상을 본다.",
                  "Letter A ('애' 소리가 난다)",
                  "What letter is this? / Big A and little a.",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big A and little a. Teaching Guide ",
                  "Find the letters big A and little a. 말한다.",
                  "Point to the big A and little a. ",
                  "Find the letters big A and little a. 영상을 본다.",
                  "Say the sound of the letter A. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big A and little a.",
                  "Circle the letters big A and little a.",
                  "Good Job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine How do you feel today? ",
                  "Feelings 영상, 노래, 율동",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "happy / sad / ",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.) ",
                  "Circle the picture. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "My Face Teaching Guide ",
                  "My Face 제목을 말한다.",
                  "This is Anna. / This is a face. ",
                  "My Face 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "These are eyes. ",
                  "How many eyes are there? / Let’s count. ",
                  "One, two. Two eyes. / There are two eyes.",
                  "This is a nose. / How many noses are there?",
                  "One. One nose. There is one nose."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "This is a mouth. / How many mouths are there?",
                  "One. One mouth. / There is one mouth. ",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine ‘The Weather’ Teaching Guide ",
                  "The Weather Song 영상을 보며, 노래",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.) ",
                  "Look out the window. ",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny ",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy ",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy ",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "The Weather Song 영상을 본다.",
                  "Good job! Singing. ",
                  "Circle the picture.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상을 보며, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s Have Tea Together. Teaching Guide ",
                  "Let’s Have Tea Together. 제목을 말한다.",
                  "How many cups do we need?",
                  "Let’s Have Tea Together. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "How many cups do we need? ",
                  "We need two cups.",
                  "Circle the cups we need. ",
                  "Trace the number in the box.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Big A Little a Teaching Guide ",
                  "Big A Little a 제목을 말한다.",
                  "Let’s sing: Big A Little a. 영상을 본다.",
                  "Letter A ('애' 소리가 난다)",
                  "What letter is this? / Big A and little a.",
                  "apple ",
                  "Good job! Singing",
                  ""
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big B and little b. Teaching Guide ",
                  "Big B and little b. 제목을 말한다.",
                  "Let’s sing: Big B Little b.영상을 본다.",
                  "Letter B (‘브' 소리가 난다)",
                  "What letter is this? / Big B and little b."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big B and little b.",
                  "banana. ",
                  "Put the matching sticker on the circle.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Find the letters big B and little b. Teaching Guide ",
                  "Find the letters big B and little b. 말한다.",
                  "Find the letters big B and little b. 영상을 본다.",
                  "Point to the big B and little b. ",
                  "Say the sound of the letter B. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big B and little b. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Treasure Hunt Teaching Guide ",
                  "Treasure Hunt 제목을 말한다.",
                  "Let’s play a game. ",
                  "Find the birthday hat. ",
                  "Treasure Hunt 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"20",
               sentences:[
                  "What Do You See? Teaching Guide ",
                  "What Do You See? 제목을 말한다.",
                  "What is hot and what is cold? ",
                  "What Do You See? 영상을 본다.",
                  "What is hot ?"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "If it is hot, color it red. ",
                  "It's hot. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "Patterns Teaching Guide ",
                  "Patterns 제목을 말한다.",
                  "Do you see patterns? ",
                  "Circle, square, circle, square. ",
                  "Patterns 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What comes next?",
                  "(Triangle) comes next. ",
                  "Put the matching stickers on the circles.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Happy Birthday의 의미",
                  "생일 축하합니다.",
                  "생일 축하합니다.",
                  "사랑하는 애나의 생일 축하합니다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Simon Says… Teaching Guide ",
                  "Simon Says… 제목을 말한다.",
                  "Listen and touch your body parts.",
                  "Listen carefully and move. ",
                  "Simon Says… 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Simon says, touch your _______ ! ",
                  "\"사이몬 says\" 라고 말이 들리면 터치하도록 해요.",
                  "ears! / nose! / eyes! / mouth! ",
                  "Put the sticker on the circle when you are done.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Happy Birthday Teaching Guide ",
                  "Song: Happy Birthday 제목을 말한다.",
                  "Happy Birthday Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Happy Birthday Story 영상, 노래가사, 율동",
                  "Look at me. "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "Happy birthday to you. ",
                  "Happy birthday to you. ",
                  "Happy birthday dear Anna. ",
                  "Happy birthday to you. ",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Let’s Make Bubbles Teaching Guide ",
                  "Let’s Make Bubbles! 제목을 말한다.",
                  "Let’s Make Bubbles! 영상을 본다.",
                  "Can you make bubbles?"
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "What do we need? ",
                  "a soap / an apron / a washbowl ",
                  "Wash your hands with soap. ",
                  "Do you see bubbles? ",
                  "Put the bubbles stickers on the circles.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "The Weather’ Teaching Guide ",
                  "The Weather Song 영상, 노래.",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가면서 (시선은 창밖을 본다.) ",
                  "Look out the window. ",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "The Weather Song 영상.",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 제목을 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine How do you feel today? ",
                  "",
                  "Feelings 영상을 본다. ",
                  "How do you feel today?",
                  "I am happy !",
                  "Happy / sad / angry ",
                  "Circle the picture. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Hello, how are you? ",
                  "How do you feel? ",
                  "Happy, happy, I am happy. ",
                  "This is how I feel",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 남자아이, 여자아이, 개, 벌레… 라고 말한다.",
                  "Let’s Look at the Cover 영상, 상호작용"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "The boy and girl are looking for animals. ",
                  "There is a (dog / beehive) in the picture. ",
                  "Do you see a (dog / butterfly / beehive / bee)? ",
                  "Yes/no. I see a dog and a beehive. ",
                  "Circle the pictures. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "Here is the beehive. Teaching Guide ",
                  "Here is the beehive. 영상을 본다.",
                  "Do you see bees in the pictures?",
                  "Can you draw bees? ",
                  "Draw bees.",
                  "Excellent!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 제목을 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "beehive, bees, sees, hive, five ",
                  "Put the beehive and bee stickers on the circles. ",
                  "Here Is the Beehive Song 영상",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big B and little b. Teaching Guide ",
                  "Big B and little b. 제목을 말한다.",
                  "Let’s sing: Big B Little b.영상.",
                  "Letter B (‘브' 소리가 난다)",
                  "What letter is this? / Big B and little b.",
                  "banana. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Let’s Act It Out Teaching Guide ",
                  "Let’s Act It Out 제목을 말한다.",
                  "Can you make (bee / bird) sounds? ",
                  "Buzz! / Tweet! ",
                  "Let’s Act It Out 영상으로 활동을 한다. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Can you make animal sounds? ",
                  "Bee say, Buzz!... / bird say, Tweet! …",
                  "Put the matching stickers on the circles. ",
                  "Good Job ! "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"9",
               sentences:[
                  "Can you move like animals? ",
                  "Let’s pretend. ",
                  "Can you fly like a butterfly? ",
                  "fly like a butterfly",
                  "Can you bite like a squirrel? ",
                  "bite like a squirrel"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Good Job ! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 제목을 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Alphabet Song ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 짚어가며 노래"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the Alphabet Song?",
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions. ",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big C Little c Teaching Guide ",
                  "Big C Little c 제목을 말한다.",
                  "Let’s sing: Big C Little c. 영상.",
                  "Letter C (＇크' 소리가 난다)",
                  "What letter is this? / Big C and little c."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big C and little c.",
                  "Put the matching sticker on the circle.",
                  "Trace the little c in the word cookie.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big C and little c. Teaching Guide ",
                  "Find the letters big C and little c. 말한다.",
                  "Find the letters big C and little c. 영상.",
                  "Point to the big C and little c. ",
                  "Say the sound of the letter C. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big C and little c. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine What’s the weather like today? ",
                  "The Weather Song 영상, 노래",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가면서 (시선은 창밖을 본다.) ",
                  "Look out the window.",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. ",
                  "Circle the picture. ",
                  "Good job! Singing. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Count the Animals Teaching Guide ",
                  "Count the Animals 말한다.",
                  "I see (birds / butterflies / bees). ",
                  "How many animals are there? ",
                  "Count the Animals 영상."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "How many (birds) are there? ",
                  "Let’s count. ",
                  "One, two, three. three birds.",
                  "This is a butterflies/ How many butterflies?",
                  "Four, four butterflies. / There is four butterflies. ",
                  "This is a bees/ How many bees?",
                  "Five, five bees. / There is five bees. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "One, two, three, four, five",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine How do you feel today?",
                  "Feelings 영상, 노래, 율동",
                  "How do you feel today? ",
                  "How do you feel?",
                  "Happy / sad / angry ",
                  "Are you happy?",
                  "I am happy (선생님도 기뻐요.) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Circle the picture. ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Patterns Teaching Guide ",
                  "Patterns 말한다.",
                  "Let’s look at the bee. ",
                  "The bee has a pattern. ",
                  "Yellow, black, yellow, black. ",
                  "Patterns 영상."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "The bee has a pattern. ",
                  "Yellow, black, yellow, black. ",
                  "What comes next? ",
                  "Color in the box. ",
                  "Yellow comes next. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"14-15",
               sentences:[
                  "What Am I? Teaching Guide ",
                  "What Am I? 말한다.",
                  "Listen to the animal sounds. ",
                  "What Am I? 영상."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What is it? ",
                  "Can you make ( cat / dog / pig ) sounds? ",
                  "Meow! Woof! Oink! ",
                  "I am a ( cat /dog / pig ). ",
                  "I say ( meow / woof / oink ). ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let‘s find the same animals. ",
                  "Bee / dog/ cat / bird / squirrel",
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics ",
                  "Big C Little c Teaching Guide ",
                  "Big C Little c 제목을 말한다.",
                  "Let’s sing: Big C Little c. 영상.",
                  "Letter C (＇크' 소리가 난다)",
                  "What letter is this? / Big C and little c.",
                  "cookie.",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics ",
                  "Big D Little d Teaching Guide ",
                  "Big D Little d 말한다.",
                  "Let’s sing: Big D Little d. 영상.",
                  "Letter D (‘드' 소리가 난다)",
                  "What letter is this? / Big D and little d."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big D and little d.",
                  "Put the matching sticker on the circle.",
                  "Trace the little d in the word doughnut.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions. ",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "Find the letters big D and little d. Teaching Guide ",
                  "Find the letters big D and little d. 말한다.",
                  "Find the letters big D and little d. 영상.",
                  "Point to the big D and little d. ",
                  "Say the sound of the letter D. "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big D and little d. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ ."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"20",
               sentences:[
                  "What Do You See? Teaching Guide ",
                  "What Do You See? 말한다.",
                  "Do you see animals or plants? ",
                  "What Do You See? 영상.",
                  "Rabbit / Cat"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Do you see animals or plants? ",
                  "Find and color only the animals. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"21",
               sentences:[
                  "Circle Teaching Guide ",
                  "Circle 말한다.",
                  "Draw a bee with the circles. ",
                  "head / body / wing / ",
                  "Circle 영상"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Draw a bee with the circles. ",
                  "head / body / wing / ",
                  "Trace the circles and draw wings on it. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Simon Says… Teaching Guide ",
                  "Simon Says… 말한다.",
                  "Let’s be Robby. ",
                  "Listen and do the actions. ",
                  "Simon Says… 영상."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Simon says, Jump like a dog!",
                  "\"사이몬 says\" 라고 말이 들리면",
                  "Jump like a dog! / Run like a cat! / Sting like a bee!",
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions. ",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Here Is the Beehive의 의미",
                  "여기 벌집이 있네요.",
                  "그런데 벌은 어디 있나요?",
                  "아무도 볼 수 없는 곳에 숨어버렸나 봐요.",
                  "저것 보세요. 벌집에서 나오네요.",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "윙-!"
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Bumblebees Teaching Guide ",
                  "Bumblebees 제목을 말한다.",
                  "Let’s chant and do a finger play. ",
                  "Can you guess which finger stung your neck? ",
                  "Bumblebees 영상을 본다.",
                  "all around, bumblebee, finger, guess, sting "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? (누가 나와서 해볼래요?)",
                  "Raise your hands. (누가 손들어 볼까요?)",
                  "민지, Come to the front. (민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat (들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s chant and do a finger play. ",
                  "Bumblebees / Bumblebees ",
                  "Buzz all around. ",
                  "Who‘s going to sting? ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Here Is the Beehive Teaching Guide ",
                  "Song: Here Is the Beehive 말한다.",
                  "Here Is the Beehive Song 영상.",
                  "Let’s listen to the Story.",
                  "Here Is the Beehive Story",
                  "영상, 노래가사, 율동",
                  "Let’s sing with motions.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Hello Song Teaching Guide ",
                  "Hello Song 말한다.",
                  "Say hello to your friends! ",
                  "Hello, teacher! ",
                  "Hello Song 영상."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "Hello, hello. Say it high. (Hi! Hi! Hi!) ",
                  "Hello, hello. Say it low. (Hello. Hello. Hello.)",
                  "Hello, hello. Say it fast. (Hello, hello, hello, hello, hello.) ",
                  "Hello, hello. Say it slow. (Hello-. Hello-.) ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상, 노래.",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가면서 (시선은 창밖을 본다.) ",
                  "Look out the window. ",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 금붕어, 지렁이… 라고 말한다. ",
                  "Let’s Look at the Cover 영상, 상호작용."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "There is a fish and a worm. ",
                  "Do you see a (fish/worm/frog/bear)? ",
                  "Yes/no. I see a fish and a worm. ",
                  "Circle the pictures. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "Fishy Fishy in a Brook",
                  "영상을 보고, 물고기 그리기",
                  "Is the fish going to eat the worm? ",
                  "(옆에 그림을 보면서…. )",
                  "Draw the fish.",
                  "Do you see fish in the picture? ",
                  "Excellent!"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "baby, daddy, mommy ",
                  "Put Daddy and Mommy stickers on the circles. ",
                  "Fishy Fishy in a Brook Song 영상을 보며 마무리.",
                  "Good job! Singing.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big D Little d. Teaching Guide ",
                  "Big D Little d. 말한다.",
                  "Let’s sing: Big D Little d. 영상을 본다.",
                  "Letter D (‘드' 소리가 난다)",
                  "What letter is this? / Big D and little d.",
                  "doughnut",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Let’s Act It Out Teaching Guide ",
                  "Let’s Act It Out 말한다.",
                  "Mommy is cooking. ",
                  "Can you cook like Mommy? ",
                  "Let’s Act It Out 영상. "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요)"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Can you act like Mommy? ",
                  "Does she need a(n) (pan / oven mitt / apron / fishing pole)? ",
                  "Circle the things Mommy needs. ",
                  "She needs a pan, an oven mitt, and an apron. ",
                  "Good Job !"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"9",
               sentences:[
                  "Can you fish like Daddy? ",
                  "Does he need a(n) (pan/oven mitt/apron/fishing pole)? ",
                  "He needs a fishing pole and a worm. ",
                  "Circle the things Daddy needs. ",
                  "Good Job ! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동) ",
                  "Good job! Singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 짚어가며 노래"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the Alphabet Song ?",
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big E Little e. Teaching Guide ",
                  "Big E Little e. 말한다.",
                  "Let’s sing Big E little e. 영상.",
                  "Letter E (‘에' 소리가 난다)",
                  "What letter is this? / Big E and little e."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big E and little e.",
                  "Put the matching sticker on the circle.",
                  "Trace the little e in the word egg.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big E and little e. Teaching Guide ",
                  "Find the letters big E and little e. 말한다.",
                  "Find the letters big E and little e. 영상",
                  "Point to the big E and little e. ",
                  "Say the sound of the letter E. "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요)"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big E and little e. ",
                  "Circle the letters big E and little e.",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine ",
                  "How do you feel today? Teaching Guide ",
                  "Feelings 영상, 노래, 율동 ",
                  "How do you feel today? ",
                  "How do you feel?",
                  "happy / sad / angry ",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.) "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Circle the picture.",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Bear Family. Teaching Guide ",
                  "Bear Family. 말한다.",
                  "This is a bear family. ",
                  "This is (Mommy/Daddy/Baby).",
                  "Point to (Mommy/Daddy/Baby). ",
                  "Bear Family. 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요)"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many bears are there?",
                  "Let’s count. ",
                  "One, two, three. ",
                  "There are three bears.",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine",
                  "What’s the weather like today? Teaching Guide ",
                  "The Weather Song 영상",
                  "What’s the weather like today?",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny.",
                  "It is cloudy.",
                  "It is rainy.",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s make fish. Teaching Guide ",
                  "Let’s make fish. 말한다.",
                  "Can you make fish with shapes?",
                  "This is a ( circle / square / triangle )",
                  "Let’s make fish. 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Can you make fish with shapes?",
                  "This is a ( circle / square / triangle )",
                  "Let’s make a fish. ",
                  "Let’s make a (head/tail) with a (circle/triangle).",
                  "Make your own fish with stickers. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big E Little e. Teaching Guide ",
                  "Big E Little e. 말한다.",
                  "Let’s sing Big E little e. 영상.",
                  "Letter E (‘에' 소리가 난다)",
                  "What letter is this? / Big E and little e.",
                  "egg",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big F Little f. Teaching Guide ",
                  "Big F Little f. 말한다.",
                  "Let’s sing Big F little f. 영상.",
                  "Letter F (‘푸' 소리가 난다)",
                  "What letter is this? / Big F and little f."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big F and little f.",
                  "Put the matching sticker on the circle.",
                  "Trace the little f in the word fish.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Find the letters big F and little f. Teaching Guide ",
                  "Find the letters big F and little f. 말한다.",
                  "Find the letters big F and little f. 영상을 보며 상호작용.",
                  "Point to the big F and little f. ",
                  "Say the sound of the letter F. "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Find the letters big F and little f. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Walk Around the Garden Teaching Guide ",
                  "Walk Around the Garden 말한다.",
                  "Let’s chant and do a finger play. ",
                  "Walk Around the Garden 영상."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Walk around the garden like a teddy bear. ",
                  "One step. Two step.",
                  "Tickling / Tickling",
                  "Tickle you everywhere. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"20",
               sentences:[
                  "What Do You See? Teaching Guide ",
                  "What Do You See? 말한다.",
                  "Do you see daddy’s or mommy’s things? ",
                  "What Do You See? 영상."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Do you see daddy’s or mommy’s things? ",
                  "Find and color mommy’s things",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"21",
               sentences:[
                  "Cookie Cutters 영상.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "What shape do you see? ",
                  "Fish / triangle / circle / square ",
                  "Put the matching stickers on the circles.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Simon Says… Teaching Guide ",
                  "Simon Says… 말한다.",
                  "Let’s be Teddy Bear. ",
                  "Listen and do the actions. ",
                  "Simon Says… 영상."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"22",
               sentences:[
                  "Simon says, ",
                  "stand UP!/ Touch the ground! / Turn around!",
                  "\"사이몬 says\" 라고 말이 들리면",
                  "Stand up/ Touch the ground / Turn around",
                  "Listen and do the actions. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Fishy Fishy in a Brook의 의미",
                  "시냇가의 물고기를",
                  "아빠는 낚시해서 잡고",
                  "엄마는 팬에 튀기고",
                  "아기는 남자답게 씩씩하게 먹었어요."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14-15",
               sentences:[
                  "Bubbles! Bubbles! Bubbles! Teaching Guide ",
                  "Bubbles! Bubbles! Bubbles! 말한다.",
                  "Can you make bubbles with your hands? ",
                  "What do we need? ",
                  "Bubbles! Bubbles! Bubbles! 영상."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Do we need (bubble soap, an apron, a bowl? ",
                  "We need bubble soap, an apron, and a bowl. ",
                  "Rub your hands together. "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Touch the bubbles with your hands! ",
                  "Circle the things we need. ",
                  "bubble soap, an apron, and a bowl. ",
                  "Good job! 배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: ",
                  "Fishy Fishy in a Brook Teaching Guide ",
                  "Fishy Fishy in a Brook 말한다.",
                  "Fishy Fishy in a Brook Song 영상.",
                  "Let’s listen to the Story.",
                  "Fishy Fishy in a Brook Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Shapes Song Teaching Guide ",
                  "Shapes Song 말한다.",
                  "What shape is this? ",
                  "Square / Circle / Triangle",
                  "Shapes Song 영상."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "Square, square, square! ",
                  "Circle, circle, circle!",
                  "Triangle, triangle, triangle! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상, 노래",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Picture Teaching Guide ",
                  "Let’s Look at the Picture 말한다.",
                  "Let’s Look at the Picture",
                  "What do you see? ",
                  "아이) 지니, 시장…라고 말한다.",
                  "Let’s Look at the Picture 영상, 상호작용"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Here’s a market.",
                  "I see many people. / Jenny. / fruits, / vegetables, ",
                  "/ cheese and milk / a big milk can.",
                  "Do you see (Jenny/milk/a market/a milk can)? ",
                  "Yes/no. I see Jenny, milk, a market, and a milk can. ",
                  "Circle the pictures.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "What do you see at a market? Teaching Guide ",
                  "What do you see at a market? ",
                  "영상을 보고 그리기",
                  "What do you see at a market?",
                  "(옆에 그림을 보면서…)",
                  "fruits / vegetables / cheese and milk / a big milk can"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "What do you see at a market? ",
                  "Draw a picture. / I see... ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions. "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "hop, market, milk ",
                  "Put the market and hop stickers on the circles. ",
                  "Jenny Song 영상, 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big F Little f. Teaching Guide ",
                  "Big F Little f. 말한다.",
                  "Let’s sing: Big F Little f. 영상.",
                  "Letter F (‘푸' 소리가 난다)",
                  "What letter is this? / Big F and little f.",
                  "fish",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Let’s Act It Out Teaching Guide ",
                  "Let’s Act It Out 제목을 말한다.",
                  "This is a (market / school / hospital / home).",
                  "This is Jenny. / Can you act like Jenny? ",
                  "Let’s Act It Out 영상을 보며 상호작용한다. "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Where is she going? / Let’s act like Jenny.",
                  "hurry to the market / walk to school ",
                  "run to the hospital / hop home ",
                  "Good Job !",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions. ",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 짚어가며 노래",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions. ",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big G Little g. Teaching Guide ",
                  "Big G Little g. 제목을 말한다.",
                  "Let’s sing Big G little g. 영상을 본다.",
                  "Letter G (‘그' 소리가 난다)",
                  "What letter is this? / Big G and little g."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big G and little g.",
                  "Put the matching sticker on the circle.",
                  "Trace the little g in the word grapes.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big G and little g. Teaching Guide ",
                  "Find the letters big G and little g. 말한다.",
                  "Let’s listen and find the letters. G g 영상을 본다.",
                  "Point to the big G and little g. ",
                  "Say the sound of the letter G. "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big G and little g. ",
                  "Circle the letters big G and little g. ",
                  "Good Job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions. ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine ",
                  "Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상, 노래율동. ",
                  "How do you feel today? ",
                  "How do you feel?",
                  "happy / sad / angry ",
                  "Are you happy?",
                  "I am happy ",
                  "Circle the picture.",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Hop, Hop, Hop Teaching Guide ",
                  "Hop, Hop, Hop 말한다.",
                  "This is Jenny. ",
                  "Jenny is hopping to the market. ",
                  "How many hops do you see? / I see 4 hops.",
                  "Hop, Hop, Hop 영상."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many hops? ",
                  "Let’s count. ",
                  "One, two, three. Four / I see 4 hops.",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine What’s the weather like today? Teaching Guide ",
                  "The Weather Song 영상, 노래",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Whose Hat Is It? Teaching Guide ",
                  "Whose Hat Is It? 말한다.",
                  "Hats are black. ",
                  "Can you guess whose hat it is? ",
                  "Whose Hat Is It? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "There are shadow hats. ",
                  "They are black. / Whose hat is it? ",
                  "Can you find it? ",
                  "firefighter / baker / police officer ",
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big G Little g. Teaching Guide ",
                  "Big G Little g. 말한다.",
                  "Let’s sing Big G little g. 영상.",
                  "Letter G (‘그' 소리가 난다)",
                  "What letter is this? / Big G and little g.",
                  "grapes",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big H Little h. Teaching Guide ",
                  "Big H Little h. 말한다.",
                  "Let’s sing Big H little h. 영상.",
                  "Letter H (‘흐' 소리가 난다)",
                  "What letter is this? / Big H and little h."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big H and little h.",
                  "Put the matching sticker on the circle.",
                  "Trace the little h in the word ham.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Find the letters big H and little h. Teaching Guide ",
                  "Find the letters big H and little h. 말한다.",
                  "Find the letters big H and little h. 영상.",
                  "Point to the big H and little h. ",
                  "Say the sound of the letter H. "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big H and little h. ",
                  "Circle the letters big H and little h.",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Who Has the Milk? Teaching Guide ",
                  "Who Has the Milk? 말한다.",
                  "Let’s play a game. ",
                  "Who Has the Milk? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Jenny has to guess who has the milk. ",
                  "Play rock, paper, and scissors",
                  "Who has the milk? "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "How to play: / 활동 책 참조",
                  "Play rock, paper, and scissors",
                  "Who has the milk? ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"20-21",
               sentences:[
                  "To the Market Teaching Guide ",
                  "To the Market 말한다.",
                  "Let’s buy some milk. ",
                  "What Do You See? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Right / Left / Up / Down",
                  "Home / Fire Station / Bakery / School / Market",
                  "Draw a line to the market. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "How To Make Milkshake Teaching Guide ",
                  "How To Make Milkshake 말한다.",
                  "How To Make Milkshake ",
                  "Milk / Ice cream / Orange/ Grape / Strawberry / Banana",
                  "How To Make Milkshake 영상."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How To Make Milkshake.",
                  "Milk / Ice cream / Orange/ Grape / Strawberry / Banana",
                  "Put the stickers in the blender. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Jenny의 의미",
                  "시장에 서둘러 가요,",
                  "깡충, 깡충, 깡충.",
                  "우유를 흘렸어요.",
                  "한 방울, 한 방울, 한 방울,",
                  "모든 방울, 모든 방울,",
                  "제니는 빈 통으로 집에 왔네요."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14-15",
               sentences:[
                  "Mirror. Mirror Teaching Guide ",
                  "Mirror. Mirror 제목을 말한다.",
                  "This is a mirror",
                  "What do you see? ",
                  "Mirror. Mirror 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Draw a dot on your face.",
                  "Where is the dot in the mirror? ",
                  "Point to the dot in the mirror. ",
                  "Do you see a (happy/sad) face? "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s look at the mirror. ",
                  "Can you see the dot? ",
                  "Draw your face with the dot. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Jenny Teaching Guide ",
                  "Song: Jenny 말한다.",
                  "Jenny Song 영상.",
                  "Let’s listen to the Story.",
                  "Jenny Story 영상. ",
                  "Let’s sing with motions.",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Colors Song Teaching Guide ",
                  "Colors Song 말한다.",
                  "What colors are they? ",
                  "Red / Blue / Yellow",
                  "Colors Song 영상"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "The color red! Red! Red! ",
                  "The color blue! Blue! Blue! ",
                  "The color yellow! Yellow! Yellow! ",
                  "This is the iWonder rainbow! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상을 본다. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Song. Teaching Guide ",
                  "The Weather Song. 영상, 노래",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "What do you see? ",
                  "아이) 감자, 집….라고 말한다. ",
                  "Let’s Look at the Cover 영상, 상호작용."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "These are potatoes. ",
                  "They are holding food.",
                  "Do you see",
                  "(potatoes/a measuring cup/salt/mashed potatoes)? ",
                  "Yes, I see (the potatoes/mashed potatoes). ",
                  "Circle the pictures. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "What do you want? Teaching Guide ",
                  "What kind of potatoes do you want? ",
                  "Do you want",
                  "(mashed potatoes / french fries / a baked potato)?",
                  "Draw a picture.",
                  "I want (mashed potatoes). ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상을 본다. ",
                  "Let’s sing with motions. (노래가사, 율동)"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "one, two, three, four, five, six, seven, potato ",
                  "Put the stickers on the circles. ",
                  "Good job! Singing. ",
                  "배운 영상을 부모님과 같이 해 보세요."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics",
                  "Big H Little h. Teaching Guide ",
                  "Big H Little h. 말한다.",
                  "Let’s sing: Big H Little h. 영상",
                  "Letter H (‘흐' 소리가 난다)",
                  "What letter is this? / Big H and little h.",
                  "ham",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Good Foods Teaching Guide ",
                  "Good Foods 말한다.",
                  "What foods are good for us? ",
                  "Good Foods 영상을 보며 상호작용. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What foods are good for us? ",
                  "Is (a lollipop / rice / a cookie / fish / an apple /",
                  "a doughnut / a potato / chocolate / a carrot) "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Good for us? ",
                  "Rice, fish, apples, potatoes, and ",
                  "carrots are good for us.",
                  "It’s good for me! ",
                  "Circle the good foods. ",
                  "Good Job !",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상을 본다. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"9",
               sentences:[
                  "Daily Routine",
                  "Shapes Song Teaching Guide ",
                  "Shapes Song 말한다.",
                  "What shape is this? ",
                  "Square / Circle / Triangle",
                  "Shapes Song 영상."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "Square, square, square! ",
                  "Circle, circle, circle!",
                  "Triangle, triangle, triangle! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상을 본다. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"9",
               sentences:[
                  "Who Is Doing a Good job? Teaching Guide ",
                  "Who Is Doing a Good job? 말한다.",
                  "Find the friends doing a good job. ",
                  "Who Is Doing a Good job? 영상을 보며 상호작용."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the friends doing a good job. ",
                  "Sandy is eating a lollipop. ",
                  "John is brushing his teeth. ",
                  "John is washing his hands. ",
                  "Jane is doing jumping ropes. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Nick is drinking milk. ",
                  "John has dirty hands. ",
                  "Is he/she doing a good job? ",
                  "Put the good job stickers on her/him. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song.",
                  "영상의 Alphabet을 짚어가며 노래",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Big I Little I. Teaching Guide ",
                  "Big I Little I. 말한다.",
                  "Let’s sing Big I little i. 영상.",
                  "Letter I (‘이' 소리가 난다)",
                  "What letter is this? / Big I and little i."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big I and little i.",
                  "Put the matching sticker on the circle.",
                  "Trace the little i in the word igloo.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big I and little i. Teaching Guide ",
                  "Find the letters big I and little i. 말한다.",
                  "Find the letters big I and little i. 영상",
                  "Point to the big I and little i. ",
                  "Say the sound of the letter I. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big I and little I. ",
                  "Circle the letters big I and little I. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine",
                  "Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상. ",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "happy / sad / angry ",
                  "Are you happy? ",
                  "I am happy ",
                  "Circle the picture. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Count the Vegetables Teaching Guide ",
                  "Count the Vegetables 말한다.",
                  "Count the Vegetables 영상.",
                  "There are",
                  "Potatoes /tomatoes / peppers / carrots",
                  "How many (potatoes) are there? / Let’s count. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "How many (potatoes) are there?",
                  "Let’s count. / One, two, three. Four, Five",
                  "There are four potatoes. ",
                  "There are three tomatoes. ",
                  "There are four peppers.",
                  "There are five carrots. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Trace the numbers. ",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine",
                  "What’s the weather like today? Teaching Guide ",
                  "The Weather Song 영상, 노래",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "It is windy. windy 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해 보세요."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Big Vegetables Teaching Guide ",
                  "Big Vegetables 말한다.",
                  "Which one is big? ",
                  "Circle the big vegetables. ",
                  "Big Vegetables 영상."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Which one is big? ",
                  "Circle the big vegetables. ",
                  "big, carrot, potato, tomato",
                  "Draw a big potato. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big I Little I. Teaching Guide ",
                  "Big I Little I. 말한다.",
                  "Let’s sing Big I little i. 영상.",
                  "Letter I (‘이' 소리가 난다)",
                  "What letter is this? / Big I and little i.",
                  "igloo",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big J Little j. Teaching Guide ",
                  "Big J Little j. 말한다.",
                  "Let’s sing Big J little j. 영상.",
                  "Letter J (‘즈' 소리가 난다)",
                  "What letter is this? / Big J and little j."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the Big J and little j.",
                  "Put the matching stickers on the circles.",
                  "Trace the little j in the word Jam.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Find the letters big J and little j Teaching Guide ",
                  "Find the letters big J and little j 말한다.",
                  "Find the letters big J and little j 영상.",
                  "Point to the big J and little j. ",
                  "Say the sound of the letter J. "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big J and little j. ",
                  "Circle the letters big J and little j. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Speed Number Game Teaching Guide ",
                  "Speed Number Game 말한다.",
                  "Let’s look at the cards. / What do you see? ",
                  "Speed Number Game 영상."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s play the Speed Number Game. ",
                  "One, two, three. Four, Five",
                  "Listen and get it! ",
                  "How to play: / 활동 책 참조",
                  "Find one apple! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "What Do You See? Teaching Guide ",
                  "What Do You See? 말한다.",
                  "Do you see soft or hard food? ",
                  "What Do You See? 영상."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Potato, tomato, banana, cake, ice cream, carrot ",
                  "Find and color the hard food. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "Hot Potato Game Teaching Guide ",
                  "Hot Potato Game 말한다.",
                  "Let’s play a game. ",
                  "Song: One Potato Two Potato 영상."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s play a game. ",
                  "Talk about how to play the game.",
                  "How to play 활동 참조",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "One Potato, Two Potato의 의미",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "감자 다섯, 감자 여섯,",
                  "감자 일곱, 그리고 더요.",
                  "감자 하나, 감자 둘,",
                  "감자 셋, 감자 넷,",
                  "나는 으깬 감자가 좋아요!",
                  "더 주세요, 제발!"
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Patterns Teaching Guide",
                  "Patterns 제목을 말한다.",
                  "What comes next? ",
                  "Patterns 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What comes next? ",
                  "potato, doughnut/ apple, tomato / banana, cone / ",
                  "cucumber, pepper",
                  "Put the correct stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One Potato Two Potato Teaching Guide ",
                  "Song: One Potato Two Potato 말한다.",
                  "One Potato Two Potato Song 영상.",
                  "Let’s listen to the Story.",
                  "One Potato Two Potato Story 영상을 본다. ",
                  "Let’s sing with motions. (노래가사, 율동) ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요."
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Thank You Song Teaching Guide ",
                  "Thank You Song 제목을 말한다.",
                  "Say thank you to friends! ",
                  "Thank You Song 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. 노래와 율동을 해볼까요?",
                  "Let’s all say, thank you. ",
                  "Let’s all say, thank you, we thank you very much. ",
                  "Thank you, to teacher! ",
                  "Thank you, to friends! ",
                  "Let’s all say, thank you, we thank you very much. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Song: Funny Turkey 말한다.",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상을 보며 노래, 율동",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 칠면조, 모자…라고 말한다. ",
                  "Let’s Look at the Cover 영상을 보며, 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "This is a farm. ",
                  "There are animals.",
                  "Do you see a",
                  "(cat/turkey/rabbit/dog)? ",
                  "Yes/no. I see a turkey. ",
                  "Find the animal. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "I have feathers! Teaching Guide ",
                  "Can you see feathers on the turkey? ",
                  "Turkeys have feathers. ",
                  "Draw feathers on the turkey. ",
                  "Excellent! (그리는 것으로 수업 마무리) ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Song: Funny Turkey 제목을 말한다.",
                  "Funny Turkey Song 영상을 본다.",
                  "Let’s listen to the Story.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Put the matching sticks on the circles.",
                  "스티커 붙이는 활동으로 마무리",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big J Little j Teaching Guide ",
                  "Big J Little j 제목을 말한다.",
                  "Let’s sing: Big J Little j 영상을 본다.",
                  "Letter J 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
                  "What letter is this? / Big J and little j.",
                  "jam"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Move Your Body Teaching Guide ",
                  "Move Your Body 제목을 말한다.",
                  "Let’s act like Funny Turkey. ",
                  "Move Your Body 영상으로 활동을 한다. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Funny Turkey wobbles his head. ",
                  "He says gobble. ",
                  "Can you wobble your head? ",
                  "Can you say gobble? ",
                  "Wobble your head! ",
                  "Good Job ! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"9",
               sentences:[
                  "Colors Song Teaching Guide ",
                  "Colors Song 제목을 말한다.",
                  "Colors Song 영상을 본다.",
                  "What colors are they? ",
                  "Red / Blue / Yellow"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. 노래와 율동을 해볼까요?",
                  "The color red! Red! Red! ",
                  "The color blue! Blue! Blue! ",
                  "The color yellow! Yellow! Yellow! ",
                  "This is the iWonder rainbow! ",
                  "영상을 보면서 활동 마무리"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"9",
               sentences:[
                  "Say “Hello! Teaching Guide ",
                  "Say “Hello! 제목을 말한다.",
                  "Say “Hello” to each other. ",
                  "Say “Hello! 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Say “Hello” to each other. ",
                  "Say “Hello” in",
                  "(Chinese, Japanese, Korean, English, French). ",
                  "05PA106-209-02-4",
                  "Ni hao! / Konnichiwa!",
                  "안녕! / Hello! / Bonjour!",
                  "Put the sticker on the circle. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big K Little k Teaching Guide ",
                  "Big K Little k 제목을 말한다.",
                  "Let’s sing Big K little k. 영상을 본다.",
                  "Letter K가 무슨 소리가 날까요? (“크' 소리가 난다)",
                  "What letter is this? / Big K and little k.",
                  "Trace the little k in the word kiwi.",
                  "Good job! (활동 마무리)"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Find the letters big K and little k. Teaching Guide ",
                  "Find the letters big K and little k. 말한다.",
                  "Find the letters big K and little k. 영상을 본다.",
                  "Point to the big K and little k. ",
                  "Say the sound of the letter K. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big K and little k. ",
                  "Good Job! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상을 보며 노래, 율동",
                  "How do you feel today? ",
                  "How do you feel?",
                  "happy / sad / angry ",
                  "Are you happy?",
                  "I am happy"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Circle the picture.",
                  "Good job! Singing ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"12",
               sentences:[
                  "Fall Foods Teaching Guide ",
                  "Fall Foods 제목을 말한다.",
                  "How many do you see? ",
                  "pears /apples / peanuts / chestnuts",
                  "These are foods you can see in the fall. ",
                  "Fall Foods 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "How many (pears) do you see? ",
                  "Let’s count. / One, two, three. Four, Five, six",
                  "There are (four) (pears). ",
                  "There are three apples",
                  "There are five peanuts",
                  "There are six chestnuts"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"05",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine What’s the weather like today? Teaching Guide ",
                  "The Weather Song 영상을 보며, 노래",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is windy. windy를 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture.",
                  "활동을 마무리한다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Patterns: Hanbok Teaching Guide ",
                  "Patterns: Hanbok 제목을 말한다.",
                  "These are hanboks. ",
                  "Say the colors in the pattern. What comes next? ",
                  "Patterns: Hanbok 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Say the colors in the pattern. What comes next? ",
                  "Can you see patterns on the hanboks? ",
                  "Yes, I see patterns. ",
                  "Blue / Yellow / Green / Red ",
                  "Color in the boxes. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Big K Little k Teaching Guide ",
                  "Big K Little k 제목을 말한다.",
                  "Let’s sing Big K little k. 영상을 본다.",
                  "Letter K 는 무슨 소리가 날까요? (“크' 소리가 난다)",
                  "What letter is this? / Big K and little k.",
                  "kiwi",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Big L Little l. Teaching Guide ",
                  "Big L Little l. 제목을 말한다.",
                  "Let’s sing Big L little l. 영상을 본다.",
                  "Letter L 은 무슨 소리가 날까요? (‘르' 소리가 난다)",
                  "What letter is this? / Big L and little l.",
                  "Trace the little l in the word lemon.",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big L and little l. Teaching Guide ",
                  "Find the letters big L and little l. 말한다.",
                  "Find the letters big L and little l. 영상을 본다.",
                  "Point to the big L and little l. ",
                  "Say the sound of the letter L. ",
                  ""
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  ""
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big L and little l. ",
                  "Good Job! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Look Inside! Teaching Guide ",
                  "Look Inside! 제목을 말한다.",
                  "Let's Make Chicken Sandwich 영상을 본다.",
                  "What is in the sandwich? ",
                  "Cut the sandwich in half. ",
                  "Look inside! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "What is in the sandwich? ",
                  "Find the foods that you see inside.",
                  "Tomato, cheese, 김치, salad, banana, Chicken ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big L Little l Teaching Guide ",
                  "Big L Little l 제목을 말한다.",
                  "Let’s sing Big L little l. 영상을 본다.",
                  "Letter L 은 무슨 소리가 날까요? (‘르' 소리가 난다)",
                  "What letter is this? / Big L and little l.",
                  "lemon",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big M Little m Teaching Guide ",
                  "Big M Little m 제목을 말한다.",
                  "Let’s sing Big M little m. 영상을 본다.",
                  "Letter M 은 무슨 소리가 날까요? (‘ 므' 소리가 난다)",
                  "What letter is this? / Big M and little m.",
                  "Trace the little m in the word milk.",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Find the letters big M and little m. Teaching Guide ",
                  "Find the letters big M and little m. 말한다.",
                  "Find the letters big M and little m. 영상을 본다.",
                  "Point to the big M and little m. ",
                  "Say the sound of the letter M. ",
                  "Find the letters big M and little m. "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"19",
               sentences:[
                  "Make the Words .",
                  "Let’s find the first letter of each word. ",
                  "Kiwi/Lemon/Milk) starts with (k/l/m). ",
                  "Put the matching stickers on the circles. ",
                  "Good job! 하이파이브!!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide ",
                  "Hidden Pictures 제목을 말한다.",
                  "Today is Funny Turkey’s wedding day. ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "ice cream / milk / yogurt / cheese/ camera / wedding cake ",
                  "Find the hidden things. ",
                  "Good job! (활동 마무리)",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Funny Turkey의 의미",
                  "칠면조는 재미있는 새랍니다.",
                  "머리를 이리저리 앞뒤로 흔들죠.",
                  "그가 할 줄 아는 말은 단 하나,",
                  "고블, 고블, 고블!"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Same or Different ? Teaching Guide ",
                  "Same or Different? 제목을 말한다.",
                  "What is different ? ",
                  "Same or Different? 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What is different? ",
                  "Circle what is different. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Funny Turkey Teaching Guide ",
                  "Funny Turkey Song 영상.",
                  "Let’s listen to the Story.",
                  "Funny Turkey Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bird, funny, gobble, turkey, wobble ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"16-17",
               sentences:[
                  "Memory Game Teaching Guide ",
                  "Memory Game 제목을 말한다.",
                  "Look, they are the same! ",
                  "Memory Game 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "Let’s look at the cards. ",
                  "Can you find the same cards? ",
                  "Look, they are the same! ",
                  "cat, chicken, dog, rabbit, turkey "
               ]
            },
            {
               level: "01",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"04",
               page:"",
               sentences:[
                  "Let’s play a memory game. ",
                  "Guess which cards are the same. ",
                  "How to play 교재 참조",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Numbers Song. Teaching Guide ",
                  "Numbers Song. 제목을 말한다.",
                  "Can you count the numbers? ",
                  "Numbers Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "1, 2, 3, 4, 5. 6, 7, 8, 9, 10. ",
                  "One, Two, Three, Four, Five, ",
                  "Six, Seven, Eight, Nine, Ten",
                  "Let’s sing the Numbers Song! ",
                  "영상을 보며 활동 마무리",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Song: Dance for Your Daddy 말한다.",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "Good job! Singing. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상을 보며, 노래",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "It is windy. windy 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Picture Teaching Guide ",
                  "Let’s Look at the Picture 제목을 말한다.",
                  "Let’s Look at the Picture그림을 가리키며, 말한다.",
                  "What do you see in the sea? ",
                  "아이) 춤추는 엄마, 오빠, 언니, 배, 바다... 라고 말한다.",
                  "Let’s Look at the Picture 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "This is the sea. ",
                  "A mom, a girl and a boy are dancing. ",
                  "Do you see a ",
                  "(bike/truck/boat/car)? ",
                  "Yes/no. I see a boat. ",
                  "Find the Picture. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "Do you see a(boat/fish/seashell/starfish) in the sea? ",
                  "I see a boat. ",
                  "Draw some boats. ",
                  "Excellent! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles.",
                  "스티커 붙이는 활동으로 마무리",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Big M Little m Teaching Guide ",
                  "Big M Little m 제목을 말한다.",
                  "Let’s sing Big M little m. 영상을 본다.",
                  "Letter M 가 무슨 소리가 날까요? (‘ 므' 소리가 난다)",
                  "What letter is this? / Big M and little m.",
                  "milk",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Daddy Is Coming! Teaching Guide ",
                  "How is Daddy coming home? ",
                  "Daddy Is Coming! 제목을 말한다.",
                  "Daddy Is Coming! 영상으로 활동을 한다. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Is Daddy coming by (car/motorcycle/tractor/boat)? ",
                  "Yes/No. Daddy is coming home by boat. ",
                  "Find the picture. ",
                  "Good Job ! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"9",
               sentences:[
                  "Daily Routine Thank You Song. Teaching Guide ",
                  "Thank You Song. 제목을 말한다.",
                  "Let’s all say, thank you. ",
                  "Teacher / friends",
                  "Thank You Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing: Thank You Song.",
                  "Let’s all say, thank you. ",
                  "we thank you very much. ",
                  "Thank you, to teacher! ",
                  "Thank you, to friends! ",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"9",
               sentences:[
                  "Go and Stop Teaching Guide ",
                  "Go and Stop 제목을 말한다.",
                  "Let’s play a game! ",
                  "Say “Hello! 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Green for Go! ",
                  "Red for Stop! ",
                  "Put the matching stickers on the circles. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the ABC song?",
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Big N Little n Teaching Guide ",
                  "Let’s sing Big N little n. 영상을 본다.",
                  "Big N Little n 제목을 말한다.",
                  "Letter N 이 무슨 소리가 날까요? (“느' 소리가 난다)",
                  "What letter is this? / Big N and little n.",
                  "Trace the little n in the word nuts.",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Find the letters big N and little n. Teaching Guide ",
                  "Find the letters big N and little n. 말한다.",
                  "Find the letters big N and little n. 영상을 본다.",
                  "Point to the big N and little n. ",
                  "Say the sound of the letter N. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big N and little n. ",
                  "Good Job!"
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상을 보며 노래, 율동 ",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "happy / sad / angry ",
                  "Are you happy?",
                  "I am happy",
                  "Circle the picture.",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "Dance for Your Daddy Song 영상 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Boats and Shapes Teaching Guide ",
                  "Boats and Shapes 제목을 말한다.",
                  "What shapes do you see? ",
                  "Find the (circles/triangles/rectangles) in the boat",
                  "Boats and Shapes 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "These are boats. ",
                  "How many (circles) do you see in the boat? ",
                  "I see (three) (circles). ",
                  "09PA107-212-02-4",
                  "What shapes do you see? ",
                  "Put the matching stickers on the circles. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Numbers Song. Teaching Guide ",
                  "Numbers Song. 제목을 말한다.",
                  "Can you count the numbers? ",
                  "Numbers Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "1, 2, 3, 4, 5. 6, 7, 8, 9, 10. ",
                  "One, Two, Three, Four, Five, ",
                  "Six, Seven, Eight, Nine, Ten",
                  "Let’s sing the Numbers Song! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Same Pictures Teaching Guide ",
                  "Same Pictures 제목을 말한다.",
                  "Can you find the same pictures? ",
                  "The (tractors/cars/trucks/boats) are the same. ",
                  "Same Pictures 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Can you find the same pictures? ",
                  "The (tractors/cars/trucks/boats) are the same. ",
                  "Let’s find the same pictures. ",
                  "Circle the same pictures. ",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Big N Little n Teaching Guide ",
                  "Big N Little n 제목을 말한다.",
                  "Let’s sing Big N little n. 영상을 본다.",
                  "Letter N 이 무슨 소리가 날까요? (‘느' 소리가 난다)",
                  "What letter is this? / Big N and little n.",
                  "nuts",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "Dance for Your Daddy Song 영상 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Big O Little o Teaching Guide ",
                  "Big O Little o 제목을 말한다.",
                  "Let’s sing Big O little o. 영상을 본다.",
                  "Letter O 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
                  "What letter is this? / Big O and little o."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little o in the word orange. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big O and little o Teaching Guide ",
                  "Find the letters big O and little o 말한다.",
                  "Find the letters big O and little o 영상을 본다.",
                  "Point to the big O and little o. ",
                  "Say the sound of the letter O."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big O and little o.",
                  "Good Job! (활동 마무리)",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "Dance for Your Daddy Song 영상 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16",
               sentences:[
                  "Guessing Game Teaching Guide ",
                  "Guessing Game 제목을 말한다.",
                  "Can you find the shapes? ",
                  "blue, circle, red, square, triangle, yellow ",
                  "Guessing Game 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"17",
               sentences:[
                  "Make your own transportation with the shapes. ",
                  "What did you make? ",
                  "Good Job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big O Little o Teaching Guide ",
                  "Big O Little o 제목을 말한다.",
                  "Let’s sing Big O little o. 영상을 본다.",
                  "Letter O 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
                  "What letter is this? / Big O and little o.",
                  "Orange",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "Dance for Your Daddy Song 영상 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big P Little p Teaching Guide ",
                  "Big P Little p 제목을 말한다.",
                  "Let’s sing Big P little p. 영상을 본다.",
                  "Letter P 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
                  "What letter is this? / Big P and little p."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little p in the word pizza.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Find the letters big P and little p. Teaching Guide ",
                  "Find the letters big P and little p. 말한다.",
                  "Find the letters big P and little p. 영상을 본다.",
                  "Say the sound of the letter P",
                  "Point to the big P and little p. "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big P and little p. ",
                  "Good Job!"
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"19",
               sentences:[
                  "Make the Words.",
                  "Let’s find the first letter of each word. ",
                  "(Nuts/Orange/Pizza) starts with (n/o/p). ",
                  "Put the matching stickers on the circles. ",
                  "Good job! 하이파이브!!"
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "Dance for Your Daddy Song 영상 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide ",
                  "Hidden Pictures 제목을 말한다.",
                  "We are going to Grandma’s house. ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "apple/ grapes / orange / bananas / boat / bicycle ",
                  "Find the hidden things. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Dance For Your Daddy의 의미",
                  "소녀가 아빠를 위해 춤을 춰요.",
                  "소년이 아빠를 위해 춤을 춰요.",
                  "물고기를 위해 춤을 춰요.",
                  "배가 오면 가족과 함께 생선을 먹을 거예요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Cross the Street Teaching Guide ",
                  "Cross the Street 제목을 말한다.",
                  "Robby wants to cross the street. ",
                  "Cross the Street 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s move Robby. ",
                  "Left / Right ",
                  "Put the sticker on the circle. ",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Dance for Your Daddy Teaching Guide ",
                  "Dance for Your Daddy Song 영상.",
                  "Let’s listen to the Story.",
                  "Dance for Your Daddy Story 영상 ",
                  "Let’s sing with motions. ",
                  "boat, daddy, fishy, lady, man ",
                  "Dance for Your Daddy Song 영상 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Stretching Song. Teaching Guide ",
                  "Stretching Song. 제목을 말한다.",
                  "Move your body while you are singing. ",
                  "Stretching Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions.",
                  "Stretch your arms up and down. ",
                  "Up, up, up, up, up! ",
                  "Down, down, down, down, down! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Right, right, right, right! ",
                  "Left, left, left, left! ",
                  "Stretch your neck round and round. ",
                  "Round and round and round! ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동) ",
                  "Good job! Singing"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상을 보며, 노래",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is windy. windy를 말한다.",
                  "It is snowy. snowy를 말한다. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Good job! Singing. ",
                  "Circle the picture.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 구두, 망치든 아저씨, 개라고 말한다. ",
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "This is Cobbler. He fixes shoes. ",
                  "There are some tools and shoes in the picture. ",
                  "Do you see ",
                  "(a hammer/shoes/popcorn/Cobbler)? ",
                  "Yes/no. I see (a hammer/shoes/Cobbler). ",
                  "Find the Pictures."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "My shoes are ( blue / red / brown / pink ). ",
                  "What color are your shoes? ",
                  "Show your shoes to your friends. ",
                  "Draw your shoes. ",
                  "Excellent!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "fix, popcorn, shoe ",
                  "Good job! Singing. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big P Little p. Teaching Guide ",
                  "Big P Little p. 제목을 말한다.",
                  "Let’s sing: Big P Little p. 영상을 본다.",
                  "Letter P 는 무슨 소리가 날까요? (‘프' 소리가 난다)",
                  "What letter is this? / Big P and little p.",
                  "pizza",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Cobbler’s Workshop Teaching Guide ",
                  "Cobbler’s Workshop 제목을 말한다.",
                  "What is Cobbler fixing in the story? ",
                  "Cobbler’s Workshop 영상으로 활동을 한다. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Is Cobbler fixing (a clock/a shoe/an umbrella/a camera)? ",
                  "Yes/No. Cobbler is fixing a shoe. ",
                  "Find the picture. ",
                  "Good Job ! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good Job ! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"9",
               sentences:[
                  "Numbers Song. Teaching Guide ",
                  "Numbers Song. 제목을 말한다.",
                  "Can you count the numbers? ",
                  "Numbers Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "1, 2, 3, 4, 5. 6, 7, 8, 9, 10. ",
                  "One, Two, Three, Four, Five, ",
                  "Let’s sing the Numbers Song! ",
                  "Six, Seven, Eight, Nine, Ten",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good job! Singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"9",
               sentences:[
                  "Missing Tools Teaching Guide ",
                  "Missing Tools 제목을 말한다.",
                  "What do you need?",
                  "Missing Tools 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "I need a ",
                  "(toothbrush/brush/fork/knife). ",
                  "Put the matching stickers on the circles. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "he ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Cobbler Cobbler Song 영상, 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big Q Little q. Teaching Guide ",
                  "Big Q Little q. 제목을 말한다.",
                  "Let’s sing Big Q little q. 영상을 본다.",
                  "Letter Q 가 무슨 소리가 날까요? (“크' 소리가 난다)",
                  "What letter is this? / Big Q and little q."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little q in the word queen.",
                  "Good job! (활동 마무리)"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Find the letters big Q and little q. Teaching Guide ",
                  "Find the letters big Q and little q. 말한다.",
                  "Find the letters big Q and little q. 영상을 본다.",
                  "Point to the big Q and little q. ",
                  "Say the sound of the letter Q. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big Q and little q. ",
                  "Good Job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상을 보며, 노래 율동 ",
                  "How do you feel today? ",
                  "How do you feel?",
                  "happy / sad / angry ",
                  "Are you happy? ",
                  "I am happy ",
                  "Circle the picture. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"12",
               sentences:[
                  "Same or Different Teaching Guide ",
                  "Same or Different 제목을 말한다.",
                  "What is (the same/different)? ",
                  "Find the (same/different) things in the picture. ",
                  "Same or Different 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "Find the (same/different) things in the picture. ",
                  "The (popcorn machine/oven/paint roller) is missing. ",
                  "Circle the three different things. ",
                  "Good job!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Stretching Song. Teaching Guide ",
                  "Stretching Song. 제목을 말한다.",
                  "Move your body while you are singing. ",
                  "Stretching Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions.",
                  "Stretch your arms up and down. ",
                  "Up, up, up, up, up! ",
                  "Down, down, down, down, down! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Right, right, right, right! ",
                  "Left, left, left, left! ",
                  "Stretch your neck round and round. ",
                  "Round and round and round! ",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Tools in the Kitchen Teaching Guide ",
                  "Tools in the Kitchen. 제목을 말한다.",
                  "Tools in the Kitchen. 영상을 본다.",
                  "Let’s find the tools in the kitchen.",
                  "Put the matching stickers on the circles."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Big Q Little q Teaching Guide ",
                  "Big Q Little q 제목을 말한다.",
                  "Let’s sing Big Q little q. 영상을 본다.",
                  "Letter Q 가 무슨 소리가 날까요? (“크' 소리가 난다)",
                  "What letter is this? / Big Q and little q.",
                  "queen",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Big R Little r. Teaching Guide ",
                  "Big R Little r. 제목을 말한다.",
                  "Let’s sing Big R little r. 영상을 본다.",
                  "Letter R 이 무슨 소리가 날까요? (‘으르' 소리가 난다)",
                  "What letter is this? / Big R and little r."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little r in the word rice.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big R and little r. Teaching Guide ",
                  "Find the letters big R and little r. 말한다.",
                  "Find the letters big R and little r. 영상을 본다.",
                  "Point to the big R and little r. ",
                  "Say the sound of the letter R. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big R and little r. ",
                  "Good Job! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "I Spy Teaching Guide ",
                  "I Spy 제목을 말한다.",
                  "Let’s look at the two cards.",
                  "Can you find the same picture? ",
                  "brush, clock, fork, frying pan, glasses, hair dryer, hammer",
                  "knife, phone, robot, spoon, toaster, toothbrush ",
                  "I Spy 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s play I Spy. ",
                  "Find the same picture. (How to play: 참조)",
                  "Good Job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big R Little r. Teaching Guide ",
                  "Big R Little r. 제목을 말한다.",
                  "Let’s sing Big R little r. 영상을 본다.",
                  "Letter R 이 무슨 소리가 날까요? (‘으르' 소리가 난다)",
                  "What letter is this? / Big R and little r.",
                  "rice",
                  "Good Job! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good Job! Singing",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big S Little s Teaching Guide ",
                  "Big S Little s 제목을 말한다.",
                  "Let’s sing Big S little s. 영상을 본다.",
                  "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다)",
                  "What letter is this? / Big S and little s."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little s in the word spoon.",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Find the letters big S and little s. Teaching Guide ",
                  "Find the letters big S and little s. 말한다.",
                  "Find the letters big S and little s. 영상을 본다.",
                  "Point to the big S and little s. ",
                  "Say the sound of the letter S. "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big S and little s. ",
                  "Good Job! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"19",
               sentences:[
                  "Make the Words.",
                  "Let’s find the first letter of each word. ",
                  "(Queen/Rice/Spoon) starts with (q/r/s). ",
                  "Put the matching stickers on the circles. ",
                  "Good Job! "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "Good Job! Singing",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide ",
                  "Hidden Pictures 제목을 말한다.",
                  "This is Cobbler’s workshop. Look around! ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "broccoli/ carrot / corn / tomato / glasses / hammer / robot / shovel",
                  "Find the hidden things. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Cobbler, Cobbler의 의미",
                  "구두장이, 구두장이, 내 신발을 고쳐주세요.",
                  "위로 꿰매고, 아래로 꿰매주세요.",
                  "그러면 나는 마을을 걸어 다닐 수 있어요.",
                  "만약 제 신발을 고쳐주신다면,",
                  "제가 팝콘을 드릴게요!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "What Is It? Teaching Guide ",
                  "What Is It? 제목을 말한다.",
                  "What Is It? 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Listen and find the answer. ",
                  "It’s round. ",
                  "It says “tick, tock!” ",
                  "It is a … clock. ",
                  "Put the matching sticker on the circle. ",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Cobbler Cobbler Teaching Guide ",
                  "Song: Cobbler Cobbler 말한다.",
                  "Cobbler Cobbler Song 영상.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "fix, popcorn, shoe ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Seasons Song. Teaching Guide ",
                  "Seasons Song. 제목을 말한다.",
                  "Pretend to be a tree while you are singing. ",
                  "Seasons Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions.",
                  "Spring, Summer, Fall, Winter ",
                  "Spring, Summer, Fall, Winter ",
                  "These are the four seasons. ",
                  "Spring, Summer, Fall, Winter ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Little Jack Horner Song 영상.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "Good job! Singing."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Song Teaching Guide ",
                  "The Weather Song 영상을 보며, 노래와 율동",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "It is windy. windy 말한다.",
                  "It is snowy. snowy 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. (날씨를 동그라미 해볼까요?)",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 그림을 가리키며, 말한다.",
                  "What do you see in the sea?",
                  "아이) 아이, 파이, 크리마스 트리, 선물... 라고 말한다. ",
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? (누가 나와서 해볼래요?)",
                  "Raise your hands. (누가 손들어 볼까요?)",
                  "민지, Come to the front. (민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat (들어가 앉으세요) "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "It’s Christmas! ",
                  "This is Jack Horner. ",
                  "Do you see ",
                  "(a Christmas tree/a pie/Jack/Santa)? ",
                  "Yes/no. I see (a Christmas tree/a pie/Jack). ",
                  "Find the Pictures.",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "I want a Christmas pie! ",
                  "I want to eat (turkey/cookies/pie/ham). ",
                  "Do you want to eat (turkey/cookies/pie/ham)? ",
                  "Draw what you want to eat. ",
                  "Excellent! (그리는 것으로 수업 마무리) ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big S Little s. Teaching Guide ",
                  "Big S Little s. 제목을 말한다.",
                  "Let’s sing: Big S Little s. 영상을 본다.",
                  "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다)",
                  "What letter is this? / Big S and little s.",
                  "spoon",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Be a Good Boy! Teaching Guide ",
                  "Be a Good Boy! 제목을 말한다.",
                  "Jack pulled out the plum with his thumb.",
                  "Be a Good Boy! 영상으로 활동을 한다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Is Jack a good boy? ",
                  "Can Jack get a Christmas present from Santa? ",
                  "Santa gives Christmas presents to good boys and girls. ",
                  "Put the sticker on the circle. ",
                  "Good Job !"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"9",
               sentences:[
                  "Stretching Song. Teaching Guide ",
                  "Stretching Song. 제목을 말한다.",
                  "Move your body while you are singing. ",
                  "Stretching Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. 노래와 율동을 해볼까요?",
                  "Stretch your arms up and down. ",
                  "Up, up, up, up, up! ",
                  "Down, down, down, down, down! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Right, right, right, right! ",
                  "Left, left, left, left! ",
                  "Stretch your neck round and round. ",
                  "Round and round and round! ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"9",
               sentences:[
                  "Winter Clothes Teaching Guide ",
                  "Winter Clothes 제목을 말한다.",
                  "What do we wear in the winter?",
                  "Winter Clothes 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "What do we wear in the winter? ",
                  "boots, coat, hat, mittens, pants, sandals, scarf, shorts, skirt, sweater, t-shirt ",
                  "This is a (coat)/These are (pants). ",
                  "Can you find the (coat)? ",
                  "Find and circle the winter clothes. ",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "Good job singing. ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Big S Little s. Teaching Guide ",
                  "Big S Little s. 제목을 말한다.",
                  "Let’s sing Big S little s. 영상을 본다.",
                  "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다)",
                  "What letter is this? / Big S and little s."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Big T Little t. Teaching Guide ",
                  "Big T Little t. 제목을 말한다.",
                  "Let’s sing Big T little t. 영상을 본다.",
                  "Letter What letter is this? / Big T and little t.",
                  "T 가 무슨 소리가 날까요? (“트” 소리가 난다)"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Trace the letter t in the word tomato. ",
                  "Good job! ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Find the letters big T and little t. Teaching Guide ",
                  "Find the letters big T and little t. 말한다.",
                  "Find the letters big T and little t. 영상을 본다.",
                  "Point to the big T and little t. ",
                  "Say the sound of the letter T. "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big T and little t. ",
                  "Good Job! (활동 마무리)",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상을 보며, 노래 율동 ",
                  "How do you feel today? ",
                  "How do you feel?",
                  "happy / sad / angry ",
                  "Are you happy? ",
                  "I am happy. ",
                  "Circle the picture. ",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "Good job singing. ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Small Pies Teaching Guide ",
                  "Small Pies 제목을 말한다.",
                  "Look at the pies.",
                  "Small Pies 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Circle the small pies. ",
                  "Draw a small pie. ",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Seasons Song. Teaching Guide ",
                  "Seasons Song. 제목을 말한다.",
                  "Pretend to be a tree while you are singing. ",
                  "Seasons Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "Spring, Summer, Fall, Winter ",
                  "Spring, Summer, Fall, Winter ",
                  "These are the four seasons. ",
                  "Spring, Summer, Fall, Winter ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb",
                  "Little Jack Horner Song 영상. 마무리. ",
                  "Good job singing. ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Patterns Teaching Guide ",
                  "Patterns 제목을 말한다.",
                  "This is a Christmas tree.",
                  "What shapes do you see in the pattern? ",
                  "Patterns 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "This is a Christmas tree.",
                  "What shapes do you see in the pattern? ",
                  "I see a (circle / triangle / square). ",
                  "A (circle/triangle/square) comes next. "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "What comes next? ",
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Big T Little t. Teaching Guide ",
                  "Big T Little t. 제목을 말한다.",
                  "Let’s sing Big T little t. 영상을 본다.",
                  "Letter T 가 무슨 소리가 날까요? (‘트' 소리가 난다)",
                  "What letter is this? / Big T and little t.",
                  "tomato",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동) ",
                  "boy, Christmas pie, good, plum, put, thumb",
                  "Little Jack Horner Song 영상. 마무리.",
                  "Good job singing. ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Big U Little u. Teaching Guide ",
                  "Big U Little u. 제목을 말한다.",
                  "Let’s sing Big U little u. 영상을 본다.",
                  "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
                  "What letter is this? / Big U and little u."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little u in the word up.",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big U and little u. Teaching Guide ",
                  "Find the letters big U and little u. 말한다.",
                  "Find the letters big U and little u. 영상을 본다.",
                  "Point to the big U and little u.",
                  "Say the sound of the letter U. "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big U and little u. ",
                  "Good Job! ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14",
               sentences:[
                  "Snowman Teaching Guide ",
                  "Snowman 제목을 말한다.",
                  "Make a snowman. ",
                  "Bring a snowman inside. ",
                  "Watch the snowman. ",
                  "Snowman 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Leave the snowman in the classroom for an hour. ",
                  "What happened to the snowman? ",
                  "The snowman melted. "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"15",
               sentences:[
                  "What happened to the snowman? ",
                  "Draw the snowman. ",
                  "Good Job! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Big U Little u. Teaching Guide ",
                  "Big U Little u. 제목을 말한다.",
                  "Let’s sing Big U little u. 영상을 본다.",
                  "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
                  "What letter is this? / Big U and little u.",
                  "up",
                  "Good job! "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big V Little v. Teaching Guide ",
                  "Big V Little v. 제목을 말한다.",
                  "Let’s sing Big V little v. 영상을 본다.",
                  "Letter V 가 무슨 소리가 날까요? (‘브' 소리가 난다)",
                  "What letter is this? / Big V and little v."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the little v in the word van.",
                  "Good job!"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Find the letters big V and little v. Teaching Guide ",
                  "Find the letters big V and little v. 말한다.",
                  "Find the letters big V and little v. 영상을 본다.",
                  "Point to the big V and little v. ",
                  "Say the sound of the letter V.",
                  "Find the letters big V and little v."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"19",
               sentences:[
                  "Make the Words.",
                  "Let’s find the first letter of each words. ",
                  "(Tomato/Up/Van) starts with (t/u/v). ",
                  "Put the matching stickers on the circles. ",
                  "Good job! 하이파이브!!"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide ",
                  "Hidden Pictures 제목을 말한다.",
                  "Merry Christmas! / Let’s have a party! ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "egg / ham / hot chocolate / turkey / boots / mittens / scarf / snowman",
                  "Find the hidden things. ",
                  "Good job!",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Jack Horner의 의미",
                  "어린 잭 호너가 구석에 앉아서",
                  "크리스마스 파이를 먹고 있어요.",
                  "엄지손가락을 파이에 쑥 넣어",
                  "자두를 꺼내고는",
                  "“나는 참 좋은 아이야!”라고 말했죠."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Same or Different Teaching Guide ",
                  "Same or Different 제목을 말한다.",
                  "Snowflakes have 6 points. ",
                  "Same or Different 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Same or Different.",
                  "One / Two /Tree / Four / Five / Six ",
                  "Find the same snowflakes and color them in. ",
                  "Good job!",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Jack Horner Teaching Guide ",
                  "Song: Little Jack Horner 말한다.",
                  "Let’s listen to the Story.",
                  "Little Jack Horner Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "boy, Christmas pie, good, plum, put, thumb ",
                  "Little Jack Horner Song 영상. 마무리.",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Friendship Song Teaching Guide ",
                  "Friendship Song 제목을 말한다.",
                  "Make heart shapes using our body parts. ",
                  "Friendship Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "One little heart to give away. ",
                  "One little heart for you today. ",
                  "“I love you” is what I say. ",
                  "I’ll give one to my friend today. ",
                  "배운 내용을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "Song: One, Two, Three, Four, Five 말한다.",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동) ",
                  "Good job singing."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상을 보며, 노래와 율동",
                  "Let’s sing with motions. ",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "It is windy. windy 말한다.",
                  "It is snowy. snowy 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 그림을 가리키며, 말한다.",
                  "What do you see ? ",
                  "아이) 낚시하는 아이, 강아지, 꽃 물고기…라고 말한다.",
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "I see a girl. ",
                  "She is camping. ",
                  "Do you see ",
                  "( trees / flowers / fish / a frog )? ",
                  "Yes/no. I see ( trees / flowers / fish / a frog ). ",
                  "Find the Pictures.(활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "what do you want to do when you go camping? ",
                  "I want to (barbecue / play /go fishing / sleep in a tent). ",
                  "Camping is fun! / What do you want to do? ",
                  "Draw the answer.",
                  "Excellent!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "Put the matching stickers on the circles. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics Big V Little v. Teaching Guide ",
                  "Big V Little v. 제목을 말한다.",
                  "Let’s sing: Big V Little v. 영상을 본다.",
                  "Letter V 가 무슨 소리가 날까요? (‘브' 소리가 난다)",
                  "What letter is this? / Big V and little v.",
                  "van"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"8-9",
               sentences:[
                  "Reuse! Teaching Guide ",
                  "Reuse! 제목을 말한다.",
                  "What can you make with a (bottle/box)? ",
                  "Reuse! 영상으로 활동을 한다. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Bottles and boxes! / What can you make? ",
                  "Let’s make a (vase/pencil holder). ",
                  "Reuse the bottle! / Vase! ",
                  "Reuse the box! / Pencil holder! ",
                  "Put the matching stickers on the circles. ",
                  "Good Job ! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Big W Little w. Teaching Guide ",
                  "Big W Little w. 제목을 말한다.",
                  "Let’s sing Big W little w. 영상을 본다.",
                  "Letter W 가 무슨 소리가 날까요? (“워' 소리가 난다)",
                  "What letter is this? / Big W and little w.",
                  "Trace the letter w in the word waffle.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"10",
               sentences:[
                  "Find the letters big W and little w. Teaching ”G”uide ",
                  "Find the letters big W and little w. 말한다.",
                  "Find the letters big W and little w. 영상을 본다.",
                  "Point to the big W and little w. ",
                  "Say the sound of the letter W. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big W and little w. ",
                  "Good Job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Big X Little x. Teaching Guide ",
                  "Big X Little x. 제목을 말한다.",
                  "Let’s sing Big X little x. 영상을 본다.",
                  "Letter X 가 무슨 소리가 날까요? (“스' 소리가 난다)",
                  "What letter is this? / Big W and little x.",
                  "Trace the letter x in the word fox.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Find the letters big X and little x. Teaching Guide ",
                  "Find the letters big X and little x. 말한다.",
                  "Find the letters big X and little x. 영상을 본다.",
                  "Point to the big X and little x. ",
                  "Say the sound of the letter X. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big X and little x. ",
                  "Good Job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide ",
                  "Feelings 영상을 보며, 노래",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "happy / sad / angry ",
                  "Are you happy?",
                  "I am happy",
                  "Circle the picture.",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "One to Five Teaching Guide ",
                  "One to Five 제목을 말한다.",
                  "Let’s count each animal. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "How many",
                  "(dogs/fish/frogs/rabbits/squirrels) do you see? ",
                  "I see (one) (frog). ",
                  "Put the sticker on the circle. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Friendship Song. Teaching Guide ",
                  "Friendship Song. 제목을 말한다.",
                  "Make heart shapes using our body parts. ",
                  "Friendship Song. 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions. ",
                  "One little heart to give away. ",
                  "One little heart for you today. ",
                  "“I love you” is what I say. ",
                  "I’ll give one to my friend today. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Finish the Shapes Teaching Guide ",
                  "Finish the Shapes 제목을 말한다.",
                  "This is the (earth/tree/flower/butterfly). ",
                  "What shape is this? ",
                  "Finish the Shapes 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "This is the (earth/tree/flower/butterfly). ",
                  "What shape is this? ",
                  "This is a (circle/heart/square/triangle). ",
                  "Complete the shapes. ",
                  "Let’s find the shapes. ",
                  "Trace and color the shapes. ",
                  "Good job! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Big X Little x. Teaching Guide ",
                  "Big X Little x. 제목을 말한다.",
                  "Let’s sing Big X little x. 영상을 본다.",
                  "Letter X 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
                  "What letter is this? / Big X and little x.",
                  "fox",
                  "Good job! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Big Y Little y. Teaching Guide ",
                  "Big Y Little y. 제목을 말한다.",
                  "Let’s sing Big Y little y. 영상을 본다.",
                  "Letter Y 가 무슨 소리가 날까요? (‘여' 소리가 난다)",
                  "What letter is this? / Big Y and little y.",
                  "Trace the little y in the word yogurt.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Find the letters big Y and little y. Teaching Guide ",
                  "Find the letters big Y and little y. 말한다.",
                  "Find the letters big Y and little y. 영상을 본다.",
                  "Point to the big Y and little y. ",
                  "Say the sound of the letter Y. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "Find the letters big Y and little y. ",
                  "Good Job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16",
               sentences:[
                  "Build the Cup Tower Teaching Guide ",
                  "Build the Cup Tower 제목을 말한다.",
                  "How many cups do you see? ",
                  "Let’s count and write the numbers. ",
                  "count, cup, down, left, right, up ",
                  "Build the Cup Tower 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"17",
               sentences:[
                  "Let’s build the cup tower together. ",
                  "Take a picture and paste it. ",
                  "Good Job! ( 활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Big Z Little z. Teaching Guide ",
                  "Big Z Little z. 제목을 말한다.",
                  "Let’s sing Big Z little z. 영상을 본다.",
                  "Letter Z 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
                  "What letter is this? / Big Z and little z.",
                  "Trace the little z in the word zucchini.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "Find the letters big Z and little z. Teaching Guide ",
                  "Find the letters big Z and little z. 말한다.",
                  "Find the letters big Z and little z. 영상을 본다.",
                  "Point to the big Z and little z. ",
                  "Say the sound of the letter Z. "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the letters big Z and little z. ",
                  "Good Job! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide ",
                  "Hidden Pictures 제목을 말한다.",
                  "Let’s go camping! ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "bread / cereal / doughnut/ bin / bottle / can / juice / newspaper",
                  "Find the hidden things. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "<One, Two, Three, Four, Five>의 의미",
                  "하나, 둘, 셋, 넷, 다섯,",
                  "나는 살아있는 물고기를 잡은 후,",
                  "여섯, 일곱, 여덟, 아홉, 열,",
                  "난 다시 물고기를 놓아 줬어요.",
                  "왜 물고기를 놓아줬죠?",
                  "내 손가락을 물어서요.",
                  "어느 손가락을 물었는데요?",
                  "오른쪽 새끼손가락이요."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Robby Teaching Guide ",
                  "Let’s Move Robby 제목을 말한다.",
                  "Robby wants to get some food. ",
                  "Let’s Move Robby 영상을 본다."
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Robby wants to get some food. ",
                  "Left / Right",
                  "Put the stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: One, Two, Three, Four, Five Teaching Guide ",
                  "One, Two, Three, Four, Five Song 영상.",
                  "Let’s listen to the Story.",
                  "One, Two, Three, Four, Five Story 영상. ",
                  "Let’s sing with motions. (노래가사, 율동)",
                  "bite, finger, fish, five, four, one, three, two ",
                  "Six, seven, eight, nine, ten ",
                  "One, Two, Three, Four, Five Song 영상. 마무리.",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "01",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Hello and Goodbye Teaching Guide ",
                  "Hello and Goodbye 말한다.",
                  "Hello 만났을 때 서로가 하는 인사에요. ",
                  "Hello Song 영상. ",
                  "Let's sing the Hello Song ",
                  "(영상을 보며 노래, 율동)",
                  "Put the matching sticker on the circle."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Ice Cream Teaching Guide ",
                  "Song: Ice Cream 말한다.",
                  "Ice Cream Song 영상.",
                  "Let‘s sing together. ",
                  "Ice Cream Story 영상, 노래가사, 율동",
                  "Look at me."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "I scream. ",
                  "( 나를 가리키고 소리 지르는 모습 ) ",
                  "You scream. ",
                  "(상대방을 가리키고 소리 지르는 모습 ) ",
                  "We all scream for ice cream! ",
                  "다 함께 소리치고, 아이스크림을 먹는 모습 ) ",
                  "He screams .",
                  "(남자 / 소리 지르는 모습 ) ",
                  "She screams. ",
                  "(여자 / 소리 지르는 모습 ) ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"3",
               sentences:[
                  "Goodbye 서로가 헤어질 때 하는 인사예요.",
                  "Goodbye Song 영상.",
                  "Let's sing the Goodbye Song ",
                  "(영상을 보며 노래, 율동)",
                  "Put the matching sticker on the circle.",
                  "Goodbye Song 영상.",
                  "배운 영상을 부모님과 같이 해 보세요",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine",
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상, 노래",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.) ",
                  "Look out the window.",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy",
                  "양손으로",
                  "비가 내리듯 손가락을 움직이며, rainy를 말한다. ",
                  "It is snowy",
                  "양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide ",
                  "Let’s Look at the Cover 말한다.",
                  "Let’s Look at the Cover ",
                  "그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 아이스크림이요, 아이요, 사람이요, 라고 말한다. ",
                  "Let’s Look at the Cover 영상, 상호작용."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "민지가 해 볼까요?",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s look at the picture.",
                  "He has ice cream. ",
                  "Do you see (a cookie / cake / ice cream/ an apple)? ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "P5",
                  "How does he feel? Teaching Guide ",
                  "Feelings 영상 활동, 그리기",
                  "How does he feel? ",
                  "Happy / sad / angry",
                  "Circle the picture. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.) ",
                  "How do you feel?",
                  "Draw your face. ",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Ice Cream Teaching Guide ",
                  "Song: Ice Cream 제목을 말한다.",
                  "Ice Cream Song 영상을 본다.",
                  "Let‘s sing together. 노래를 함께 불러본다.",
                  "Ice Cream Story 영상을 본다. (노래가사, 율동)",
                  "Look at me."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams .",
                  "She screams. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "Let‘s sing together.",
                  "Put the matching stickers on the circles. ",
                  "Good job! Singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8 ",
               sentences:[
                  "Daily Routine",
                  "How do you feel today? Teaching Guide ",
                  "Feelings 영상노래 율동",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "(아이의 얼굴을 보면서….)",
                  "happy / sad / angry",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Circle the picture. ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9 ",
               sentences:[
                  "Sound It Out Teaching Guide ",
                  "Sound It Out 말한다.",
                  "Sound It Out 영상을 본다. ",
                  "Can you say scream / ice cream?",
                  "scream / ice cream ",
                  "소리가 같음을 소리 내어 말한다. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Can you say (scream/ice cream)? ",
                  "(소리치다/ 아이스크림)",
                  "Let's say (scream/ice cream).",
                  "Let's play a game. 영상.",
                  "Raise your hand when you hear ice cream or scream.",
                  "(손을 들고 ice cream or scream 말한다)"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Look at the picture.",
                  "What are they doing?",
                  "They are screaming.",
                  "What are they screaming for?",
                  "우리- 다 같이 외쳐 볼까요?",
                  "We all scream!",
                  "(반 아이들이 모두 소리로 말한다)"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"06",
               page:"",
               sentences:[
                  "Circle the picture. ",
                  "Dog / Dinosaur / Ice cream/ Clown",
                  "(천천히 읽어준다)",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. ",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행한다",
                  "(아는 아이가 있어도…)",
                  "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? ",
                  "깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. ",
                  "해당하는 날을 클릭 하면,",
                  "(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Calendar 활동의 목적: Daily Routine으로 활용",
                  "날, 수에 대한 개념을 알 수 있다.",
                  "월, 주간, 날짜, 기분, 날씨, 인사 ",
                  "각 부분을 루틴 활동으로 활용 "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10 ",
               sentences:[
                  "Phonics",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래한다.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10 ",
               sentences:[
                  "A Says a. Teaching Guide ",
                  "A Says a. 말한다.",
                  "Let’s chant: A Says a. 영상.",
                  "Letter A ('애' 소리가 난다)",
                  "What letter is this? / Big A and little a.",
                  "Let’s chant: A Says a. 영상.",
                  "Trace the big A and little a.",
                  "A 로 시작하는 단어를 써보고, (단어 확인). ",
                  "A is for apple / A is for apron/ A is for acorn"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles.",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture A a Teaching Guide ",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with a.",
                  "Apple starts with a. Color it./ Apron starts with a. Color it.",
                  "Banana starts with b./ Acorn starts with a. Color it."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. (영상 상호작용)",
                  "Who wants to come up here? (누가 나와서 해볼래요?)",
                  "Raise your hands. (누가 손들어 볼까요?)",
                  "민지, Come to the front. (민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat (들어가 앉으세요) ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine The Weather Song Teaching Guide ",
                  "The Weather Song. 영상을 본다.",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가며, (경례 하듯이 시선은 창밖을 본다.) ",
                  "Look out the window.",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy를 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. ",
                  "It is snowy",
                  "양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12 ",
               sentences:[
                  "What Comes Next? Teaching Guide ",
                  "What Comes Next? 말한다.",
                  "This is ice cream.",
                  "Look at the pattern of the ice cream.",
                  "White, red, brown, white, red, brown, white...",
                  "What comes next? 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Look at the pattern of the ice cream.",
                  "White, red, brown, white, red, brown, white...",
                  "What comes next? Red. ",
                  "Color the ice cream. ",
                  "Put the word sticker on the circle. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16 ",
               sentences:[
                  "Tangram Teaching Guide ",
                  "Tangram 말한다.",
                  "Tangram 영상을 본다.",
                  "Let’s create shapes.",
                  "(탱그램 카드를 준비한다)"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "There are two pieces of tangram. ",
                  "What shapes are there?",
                  "Put the stickers on the circles when you are done.",
                  "Let’s make a square with two triangles.",
                  "Good job! 스티커를 붙인다."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"17",
               sentences:[
                  "Can you arrange the two tangram pieces?",
                  "Let’s make a triangle.",
                  "Good job! 스티커를 붙인다.",
                  "Let’s make a square. ",
                  "Excellent! 스티커를 붙인다.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine ",
                  "How do you feel today? Teaching Guide ",
                  "Feelings 영상을 보며, 노래와 율동.",
                  "How do you feel today? ",
                  "How do you feel?",
                  "(아이의 얼굴을 보면서….)",
                  "happy / sad / angry",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.)"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Circle the picture. ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "How Many Scoops? Teaching Guide ",
                  "How Many Scoops? 말한다.",
                  "How many scoops do you want?",
                  "그림의 내용에 대해서 이야기.",
                  "How Many Scoops? 영상을 본다."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Draw ice cream and write the number.",
                  "(숫자와 그림 그리는 것을 확인한다)",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18 ",
               sentences:[
                  "A Says a. Teaching Guide ",
                  "A Says a. 말한다.",
                  "Let’s chant: A Says a. 영상.",
                  "Letter A ('애' 소리가 난다)",
                  "What letter is this? / Big A and little a.",
                  "A is for apple / A is for apron/ A is for acorn",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "B Says b. Teaching Guide ",
                  "B Says b. 말한다.",
                  "Let’s chant: B Says b. 영상을 본다.",
                  "Letter B ('브' 소리가 난다)",
                  "What letter is this? Big B and little b."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big B and little b.",
                  "B 로 시작하는 단어를 쓴다.",
                  "B is for banana / B is for butter / B is for bun",
                  "Put the matching stickers on the circles.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Say the name of each picture B b Teaching Guide ",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with b.",
                  "Banana starts with b. Color it. / Butter starts with b. Color it.",
                  "Acorn starts with a. / Bun starts with b. Color it.",
                  "Say the name of each picture. "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "^^^^",
                  "The ABC Song. Teaching Guide ",
                  "The ABC Song. 영상.",
                  "Let’s sing the ABC Song. ",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "",
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "^^^^",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "What Will Happen? Teaching Guide ",
                  "What Will Happen? 말한다.",
                  "Let’s guess.",
                  "What Will Happen? 영상.",
                  "What will happen when it is hot? ",
                  "Color the pictures.",
                  "날씨가 더워지면 아이스크림이 어떻게 될까요?"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "What will happen when it is hot?",
                  "Color the pictures.",
                  "It's hot. ",
                  "It will melt.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "Patterns Teaching Guide ",
                  "Patterns 말한다.",
                  "Patterns 영상을 본다.",
                  "What comes next?",
                  "ice cream / cookie / lollipop"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민지, Come to the front. ",
                  "(민지가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What comes next?",
                  "Put the stickers on the circles.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Simon Says… Teaching Guide ",
                  "Simon Says… 말한다.",
                  "Simon Says… 영상을 본다.",
                  "Do you want to play a game?",
                  "Listen carefully and move."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the sticker on the circle when you are done.",
                  "\"사이몬 says\" 라고 말이 들리면 움직이도록 해요",
                  "\"사이몬 says\" 라는 말이 들리지 않으면 움직이지 않아요. ",
                  "Up / Down / Left / Right",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Ice cream의 의미",
                  "나는 외쳐요.",
                  "너도 외쳐요.",
                  "우리 모두 아이스크림을 달라고 외쳐요."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14-15",
               sentences:[
                  "Let’s Blow Bubbles! Teaching Guide ",
                  "Let’s Blow Bubbles! 말한다.",
                  "Let’s Blow Bubbles! 영상을 본다.",
                  "Can you make bubbles?"
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Put the bubble sticker on the circle.",
                  "Circle the bubble shape.",
                  "What shape is the bubbles?",
                  "Draw bubbles. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Ice Cream Song 영상.",
                  "Let‘s sing together.",
                  "I will sing with motions.",
                  "I scream. ",
                  "You scream. ",
                  "We all scream for ice cream! ",
                  "He screams.",
                  "She screams. ",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Feelings Teaching Guide",
                  "Feelings 영상을 본다.",
                  "Let’s sing the Feelings Song. ",
                  "영상을 보며 노래와 율동.",
                  "How do you feel today? ",
                  "Draw your feeling. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "(아이의 얼굴을 보면서….)",
                  "Are you happy? ",
                  "(기뻐요? 하고 물어보고,)",
                  "I am happy (선생님도 기뻐요.) ",
                  "Good job! Drawing ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상.",
                  "Let‘s sing together. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Tuffet, curds and whey, spider",
                  "Good job! Singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine",
                  "The Weather Teaching Guide ",
                  "The Weather Song 영상을 본다.",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.) ",
                  "Look out the window.",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy를 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. ",
                  "It is windy",
                  "양손을 바람 불듯이 움직이며, windy 를 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "(그림을 보면서 무엇이 보이는지 이야기 나눈다)",
                  "아이) 거미, 새, 다람쥐, 마을, 여자친구…. 아이들이 이야기 한다.",
                  "Let’s Look at the Cover 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Circle the pictures. ",
                  "Do you see a (bird/ spider / Squirrel/ dog)? ",
                  "읽고, 상호작용",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "What animals do you like? Teaching Guide",
                  "What animals do you like? ",
                  "Snake/ cat / dog/ spider ",
                  "Draw the animals you like. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "What animals do you like? ",
                  "(뱀, 고양이, 강아지, 거미를 말한다)",
                  "Do you like (Snakes/ cats / dogs/ spiders)?",
                  "Draw your like animals. ",
                  "Good job! Drawing ",
                  "배운 활동을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 제목을 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. 노래를 함께 불러본다.",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Tuffet, curds and whey, spider (단어를 알려주기) ",
                  "I will sing with motions.(노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Little Miss Muffet Story 영상을 본다. ",
                  "Put the matching stickers on the circles. 마무리한다.",
                  "",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Feelings Teaching Guide",
                  "Feelings 영상을 본다.",
                  "Let’s sing the Feelings Song. ",
                  "영상을 보며 노래와 율동.",
                  "How do you feel today? "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "(아이의 얼굴을 보면서….)",
                  "Are you happy? ",
                  "I am happy (선생님도 기뻐요.)",
                  "Circle the picture. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Sound It Out Teaching Guide",
                  "Sound It Out 말한다.",
                  "Sound It Out 영상을 본다.",
                  "Can you say Muffet / tuffet? ",
                  "Muffet / tuffet?",
                  "라임으로 같은 소리가 난다."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Can you say (Muffet / tuffet)? ",
                  "Let's say (Muffet / tuffet).",
                  "Let's play a game. 영상.",
                  "Clap your hands when you hear the words “Muffet” or “tuffet.”",
                  "(손 벽을 치면서 리듬에 맞춰서 Muffet / tuffet 말한다)"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"9",
               sentences:[
                  "Look at the picture.",
                  "Is she frightened by the (squirrel)? ",
                  "Circle the picture. ",
                  "Squirrel / bird / spider / dog",
                  "(천천히 읽어준다)",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "^^^^",
                  "The ABC Song Teaching Guide ",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Alphabet Song Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "C Says c. Teaching Guide",
                  "C Says c. 말한다.",
                  "Let’s chant: C Says c. 영상.",
                  "Letter C (‘크' 소리가 난다)",
                  "What letter is this? Big C and little c."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s chant: C Says c. 영상.",
                  "Trace the big C and little c. ",
                  "C is for cookie / C is for cake / C is for carrot",
                  "Put the matching stickers on the circles.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture C c Teaching Guide",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with c.",
                  "Cake starts with c. Color it.",
                  "Carrot starts with c. Color it.",
                  "Banana starts with b./ Cookie starts with c. Color it."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine",
                  "The Weather Song Teaching Guide ",
                  "The Weather Song 영상을 본다.",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny, sunny 말한다. ",
                  "It is cloudy, cloudy 말한다.",
                  "It is rainy, rainy 말한다. ",
                  "It is windy, windy 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "There Came a Spider Teaching Guide",
                  "Let’s talk about a spider. ",
                  "Spiders have two body parts. ",
                  "The spider has 8 legs.",
                  "There Came a Spider 영상을 보며 상호작용. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "How many legs are there? ",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Draw the legs. ",
                  "거미 다리 8개를 그리는 것을 둘러본다.",
                  "Put the matching sticker on the circle. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "What Am I? Teaching Guide",
                  "Let’s look at the shadows. ",
                  "We need a flashlight,",
                  "Forks and a rubber glove.",
                  "Can you make shadow animals?",
                  "Let’s look at the shadow animals. ",
                  "What Am I? 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Circle the animals you see. ",
                  "deer/ bird / dog / rooster",
                  "What animals can you make? ",
                  "Put the sticker on the circle. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13 ",
               sentences:[
                  "Feelings Teaching Guide",
                  "Feelings 영상을 본다.",
                  "Let’s sing the Feelings Song. ",
                  "영상을 보며 노래와 율동.",
                  "How do you feel today?"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy ",
                  "Circle the picture. ",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13 ",
               sentences:[
                  "Patterns Teaching Guide",
                  "Patterns 말한다.",
                  "Let’s look at the tuffet.",
                  "What colors do you see? ",
                  "I see orange/ I see white / I see black",
                  "Today we will learn the pattern."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Orange, white, black, white.",
                  "What comes next?",
                  "Orange comes next.",
                  "Patterns 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Can you make your own patterns? ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics C Says c. Teaching Guide",
                  "C Says c. 말한다.",
                  "Let’s chant: C Says c. 영상을 본다.",
                  "Letter C (‘크' 소리가 난다)",
                  "What letter is this? Big C and little c.",
                  "C is for cookie / C is for cake / C is for carrot",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "D Says d. Teaching Guide",
                  "D Says d. 제목을 말한다.",
                  "Let’s chant: D Says d. 영상을 본다.",
                  "Letter D 가 무슨 소리가 날까요? (‘드' 소리가 난다)",
                  "What letter is this? Big D and little d.",
                  "Trace the big D and little d. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "D is for doughnut / D is for dish / D is for dumpling",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "글씨 쓰는 것과 스티커 활동."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19 ",
               sentences:[
                  "Say the name of each picture. D d 영상을 본다.",
                  "Color the pictures starting with d.",
                  "Dish starts with d. Color it.",
                  "Doughnut starts with d. Color it.",
                  "Bun starts with b./ Dumpling starts with d. Color it.",
                  "Say the name of each picture. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. ",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행한다",
                  "(아는 아이가 있어도…)",
                  "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "^^^^",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "Who Am I? Teaching Guide",
                  "Who Am I? 말한다.",
                  "Let’s guess. Who Am I? ",
                  "I have 2 legs. / I have 2 eyes. ",
                  "I have 2 wings.",
                  "Who Am I? 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "I am a ...(무엇일까요?) ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "What Comes Next? Teaching Guide",
                  "What Comes Next? 말한다.",
                  "Do you see patterns? ",
                  "Little Miss Muffet / tuffet / squirrel / spider / bird ",
                  "What Comes Next? ",
                  "What Comes Next? 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Put the stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Little Miss Muffet Teaching Guide",
                  "Let’s Move Little Miss Muffet 말한다.",
                  "There is a spider. ",
                  "Left / Right ",
                  "Little Miss Muffet 은 어디로 갈까요?",
                  "Let’s Move Little Miss Muffet 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Put the sticker on the circle. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Miss Muffet의 의미",
                  "소녀 머팻이",
                  "낮은 의자에 앉아",
                  "코티지 치즈를 먹고 있어요.",
                  "그런데 큰 거미가 다가와서",
                  "소녀 머팻 옆에 앉았어요.",
                  "그래서 소녀 머팻은 놀라서 도망갔어요."
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Tangram Teaching Guide",
                  "Tangram 말한다.",
                  "Tangram 영상을 본다.",
                  "Let’s create shapes.",
                  "탱그램 카드를 준비"
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "There are three pieces of a tangram. ",
                  "What shapes are they?",
                  "Let’s make a square with two triangles.",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "There are three triangles. ",
                  "Can you put them together? ",
                  "What does it look like? ",
                  "Let’s make a square.",
                  "Excellent!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"song",
               script:"",
               page:"",
               sentences:[
                  "Song: Little Miss Muffet Teaching Guide",
                  "Song: Little Miss Muffet 말한다.",
                  "Little Miss Muffet 영상을 본다.",
                  "Let‘s sing together. ",
                  "Tuffet, curds and whey, spider",
                  "I will sing with motions.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3 ",
               sentences:[
                  "Daily Routine",
                  "The Weather Song. Teaching Guide ",
                  "The Weather Song. 영상을 본다.",
                  "What’s the weather like today? ",
                  "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.) ",
                  "Look out the window.",
                  "(손가락은 창문을 가리키며 동시에 말한다.)"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny",
                  "머리위로 두 손을 마주잡으며, sunny를 말한다. ",
                  "It is cloudy",
                  "양손으로 구름모양으로 만들며, cloudy를 말한다.",
                  "It is rainy",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다. ",
                  "It is snowy",
                  "양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다. ",
                  "It is windy",
                  "양손을 바람 불듯이 움직이며, windy 를 말한다. "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며, 말한다.",
                  "What is in the teapot? ",
                  "(teapot 에 무엇을 담을 수 있는지 묻는다)",
                  "아이) 물, 티, 주스, 등을 이야기한다.",
                  "Let’s Look at the Cover 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What do you see?",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What is in the teapot?",
                  "Circle the picture. ",
                  "tea / oil / juice / milk /",
                  "그림을 보며 문장을 읽는다.",
                  "Yes/no. (아이들에게 응답)확인",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "Who do you like to drink tea with? ",
                  "Mommy / Daddy / Brother / Sister",
                  "Draw a picture of your family. ",
                  "I like to drink tea with (Mommy). ",
                  "Draw your like Mommy.",
                  "Good job! Drawing ",
                  "부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "I’m a Little Teapot 제목을 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. 노래와 율동을 한다.",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Handle/ pour/ shout/ spout/ short/ stout/ teapot/ tip",
                  "I will sing with motions."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the handle and spout stickers on the circles.",
                  "스티커를 붙이는 것으로 마무리. ",
                  "Good job! Singing & motions",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine",
                  "What color is this? Teaching Guide",
                  "Colors Song 영상을 보며 노래와 율동.",
                  "What color is this? ",
                  "Red / Blue / Green",
                  "Circle the red.",
                  "This is (Red / Blue / Green)",
                  "Good job! Singing "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8 ",
               sentences:[
                  "Sound It Out Teaching Guide",
                  "Sound It Out 말한다.",
                  "Can you say (spout/shout)? ",
                  "Today we are going to learn rhymes.",
                  "Sound It Out 영상을 본다."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Can you say spout/shout?",
                  "Let's say spout/shout.",
                  "Let's play a game. 영상을 본다.",
                  "Shout “wheeee!” ",
                  "when you hear “spout” or “shout.” "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"9",
               sentences:[
                  "What do you see in the mirror? ",
                  "I see a… ",
                  "Circle the pictures. ",
                  "handle / Cup / spout / lid ",
                  "(천천히 읽어준다)",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. "
               ]
            },
            {
               level: "02",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. ",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행한다.",
                  "(아는 아이가 있어도…)",
                  "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10 ",
               sentences:[
                  "Phonics",
                  "Let’s chant D Says d. Teaching Guide",
                  "Let’s chant: D Says d. 영상을 본다.",
                  "Letter D (‘드' 소리가 난다)",
                  "What letter is this? Big D and little d .",
                  "D is for doughnut / D is for dish / D is for dumpling",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "E Says e. Teaching Guide",
                  "E Says e. 말한다.",
                  "Let’s chant: E Says e. 영상을 본다.",
                  "Letter E ( ‘에' 소리가 난다)",
                  "What letter is this? Big E and little e."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big E and little e. ",
                  "E is for egg / E is for eggplant / E is for eel",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11 ",
               sentences:[
                  "Say the name of each picture. 영상을 본ㄷ.",
                  "Color the pictures starting with e.",
                  "eggplant starts with e. Color it.",
                  "egg starts with e. Color it.",
                  "carrot starts with c./ eel starts with e. Color it."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Feelings Teaching Guide",
                  "Feelings 영상을 본다.",
                  "Let’s sing the Feelings Song. ",
                  "영상을 보며 노래와 율동.",
                  "How do you feel today? ",
                  "Circle the picture."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Teapot Family 말한다.",
                  "Let’s talk about Tall and short. ",
                  "This is Daddy / This is Mommy / This is Baby",
                  "This is the Teapot family."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Teapot Family 영상을 보며 상호작용.",
                  "Where is Daddy? / Is Daddy tall or short?",
                  "Where is Mommy? / Is Mommy tall or short?",
                  "Where is Baby? / Is Baby tall or short?"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "The ABC Song Teaching Guide ",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "",
                  "Daily Routine ",
                  "Numbers Song. Teaching Guide",
                  "Numbers Song. 영상을 본다.",
                  "영상에 맞추어 노래와 손 숫자를 같이 한다. ",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s Have Tea Teaching Guide",
                  "Let’s Have Tea 말한다.",
                  "Today we are going to count the numbers.",
                  "How many cups are there? ",
                  "Let’s Have Tea 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Count and write the number in the box. ",
                  "There are ( 7 ) cups. (그림의 컵을 센다)",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18 ",
               sentences:[
                  "E Says e. Teaching Guide",
                  "E Says e. 말한다.",
                  "Let’s chant: E Says e. 영상을 본다.",
                  "Letter E ( ‘에' 소리가 난다)",
                  "What letter is this? Big E and little e.",
                  "E is for egg / E is for eggplant / E is for eel",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18 ",
               sentences:[
                  "F Says f. Teaching Guide",
                  "F Says f. 말한다.",
                  "Let’s chant: F Says f. 영상을 본다.",
                  "Letter F (‘프' 소리가 난다)",
                  "What letter is this? / Big F and little f."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big F and little f. ",
                  "F is for fish / F is for fork / F is for fruit",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19 ",
               sentences:[
                  "Say the name of each picture F f Teaching Guide",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with f.",
                  "Fruit starts with f. Color it. ",
                  "Fish starts with f. Color it.",
                  "Eggplant starts with e.",
                  "Fork starts with f. Color it."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Raise your hands.",
                  "Who wants to come up here? ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Make Five Game 말한다.",
                  "Make Five Game 영상을 본다.",
                  "Let’s play the Make Five Game. ",
                  "Play the music. "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "When the music stops, ",
                  "Get in a group of (Five). ",
                  "Put the matching sticker on the circle. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "We need five teapots and cups.",
                  "How many are missing? ",
                  "Add more stickers to make five. ",
                  "Excellent!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "Alphabet Song. Teaching Guide ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Who Am I? Teaching Guide",
                  "Who Am I? 말한다.",
                  "Let’s guess. Who Am I? ",
                  "I have a handle. / I have a spout. ",
                  "I am stout. / I am short. ",
                  "Who Am I? 영상을 보며 상호작용. "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "I am a….(무엇일까요?)",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "Teapots with Shapes Teaching Guide",
                  "Teapots with Shapes 말한다.",
                  "Look, Mommy Teapot! ",
                  "Let’s decorate mommy teapot with shapes. ",
                  "Teapots with Shapes 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s decorate mommy teapot with shapes. ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Robby Teaching Guide",
                  "Let’s Move Robby 말한다.",
                  "Robby wants some tea! ",
                  "Up / Down ",
                  "Robby 는 어디로 갈까요?"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s Move Robby 영상을 보며 상호작용.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Put the sticker on the circle. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I’m a Little Teapot의 의미",
                  "나는 작고 통통한 차 주전자",
                  "나의 손잡이는 여기 있어요.",
                  "나의 주둥이도 여기 있어요.",
                  "내가 펄펄 끓을 때",
                  "지르는 소리를 들어보세요.",
                  "나를 기울여 차를 따라주세요!"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14-15 ",
               sentences:[
                  "Let’s Blow Bubbles! Teaching Guide",
                  "Let’s Blow Bubbles! 말한다.",
                  "Let’s make some wands. ",
                  "Trace each shape. ",
                  "다양한 막대모양도구"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "bear, blow, bubbles, heart, circle, ",
                  "shape, square, star, triangle, wand ",
                  "Are the bubbles (circles/triangles)? ",
                  "Do you see circle bubbles? ",
                  "The bubbles are circles. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s observe the bubbles! ",
                  "Can you make triangle bubbles? ",
                  "What shape are the bubbles? ",
                  "Circle the answer. ",
                  "Excellent!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I’m a Little Teapot Teaching Guide",
                  "Song: I’m a Little Teapot 말한다.",
                  "I’m a Little Teapot 영상을 본다.",
                  "Let’s sing with motions. ",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3 ",
               sentences:[
                  "Colors Teaching Guide",
                  "Let’s sing the Colors Song. ",
                  "Colors Song 영상을 보며 노래와 율동.",
                  "What color is this? Circle yellow. ",
                  "Red / Blue / Green / yellow "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Circle the red things in the picture. ",
                  "This is (Red / Blue / Green/ yellow)",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4 ",
               sentences:[
                  "Daily Routine The Weather Song. Teaching Guide",
                  "The Weather Song. 영상을 본다.",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "Circle the picture.",
                  "Good job! Singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4 ",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며 말한다.",
                  "What do you see?",
                  "아이) 요리사, 빵.. 등을 이야기한다.",
                  "Let’s Look at the Cover 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Do you see a (baker/ doctor/ ",
                  "police officer/ teacher)?",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Circle the picture.",
                  "baker / doctor / police officer /teacher ",
                  "Yes/no. (아이들에게 응답)확인",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5 ",
               sentences:[
                  "Do you know the Muffin Man? ",
                  "The Muffin Man! ",
                  "singer / firefighter / baker / police officer ",
                  "Who is the Muffin Man? ",
                  "He is a baker. ",
                  "Draw the Muffin Man. ",
                  "Good job! Drawing ",
                  "부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7 ",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 제목을 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. 노래와 율동을 한다.",
                  "Muffin Man, Who lives on Drury Lane? ",
                  "Let’s sing together."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the Muffin Man and Drury Lane stickers on the circles. ",
                  "Good job! Singing & motions",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Numbers Song. Teaching Guide",
                  "Numbers Song. 영상을 본다.",
                  "영상에 맞추어 노래와 손 숫자를 같이 한다. ",
                  "Let’s sing: Numbers Song. ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Counting Cars Teaching Guide",
                  "Counting Cars 말한다.",
                  "Look at the street. ",
                  "What kinds of cars do you see? ",
                  "ambulance / bus / fire truck / police car "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Counting Cars 영상을 보며 상호작용.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many police cars are there? ",
                  "Let’s color them. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "The ABC Song Teaching Guide ",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10 ",
               sentences:[
                  "F Says f. Teaching Guide",
                  "F Says f. 말한다.",
                  "Let’s chant: F Says f. 영상을 본다.",
                  "Letter F (‘프' 소리가 난다)",
                  "What letter is this? / Big F and little f.",
                  "F is for fish / F is for fork / F is for fruit",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "G Says g. Teaching Guide",
                  "G Says g. 말한다.",
                  "Let’s chant: G Says g. 영상을 본다.",
                  "Letter G ( ‘그' 소리가 난다)",
                  "What letter is this? Big G and little g."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big G and little g. ",
                  "G is for gum / G is for grapes / G is for garlic",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with g.",
                  "Garlic starts with g. Color it./ Grapes starts with g. Color it.",
                  "Cake starts with c./ Gum starts with g. Color it.",
                  "Say the name of each picture."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Feelings Teaching Guide",
                  "Feelings 영상을 본다.",
                  "Let’s sing the Feelings Song. ",
                  "영상을 보며 노래와 율동.",
                  "How do you feel today? "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy ",
                  "Circle the picture.",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "At the Market Teaching Guide",
                  "At the Market 말한다.",
                  "What do you see at the market? ",
                  "Apple/ strawberry/ banana/ watermelon/grapes",
                  "broccoli/ tomato/ carrot/ corn ",
                  "Milk/ ice cream/ cheese/ yogurt ",
                  "Bread/ doughnut/ muffin ",
                  "Fish/ meat/ egg/ chicken. "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "At the Market 영상을 보며 상호작용.",
                  "Do you see (an apple/ a broccoli/",
                  "a milk/ a bread/ a fish)?",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine ",
                  "Shapes Song. Teaching Guide",
                  "Shapes Song. 영상을 보며 노래와 율동.",
                  "Let’s sing: Shapes Song. ",
                  "circle / triangle / square",
                  "Good job singing."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "My Dream Teaching Guide",
                  "My Dream 말한다.",
                  "What do you want to be? ",
                  "(baker/ police officer/ teacher/ doctor/ singer)"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "My Dream 영상을 보며 상호작용.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "What do you want to be? ",
                  "Do you want to be a (baker/ ",
                  "police officer/ teacher/ doctor/ singer)? ",
                  "(하고 싶은 직업을 손을 들고, 그래프에 표시)",
                  "Color the boxes. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Circle all the words that start with the letter g. ",
                  "Let’s chant: G Says g. 영상을 본다.",
                  "Letter G 가 무슨 소리가 날까요? (‘그' 소리가 난다)",
                  "Circle all the words that start with the letter g.",
                  "garlic / grapes / gum / fish",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "H Says h. Teaching Guide",
                  "H Says h. 말한다.",
                  "Let’s chant: H Says h. 영상을 본다.",
                  "Letter H (‘흐' 소리가 난다)",
                  "What letter is this? / Big H and little h."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "",
                  "Trace the big H and little h. ",
                  "H is for ham / H is for honey / H is for hotdog",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with h.",
                  "Ham starts with h. Color it.",
                  "Honey starts with h. Color it.",
                  "Doughnut starts with d. ",
                  "Hotdog starts with h. Color it."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. 영상을 보며 상호작용.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. ",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행한다.",
                  "(아는 아이가 있어도…)",
                  "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Where Is the Muffin Man? Teaching Guide",
                  "Where Is the Muffin Man? 말한다.",
                  "Today we are going to find the Muffin Man.",
                  "Start / End / Right / Left / Up / Down ",
                  "Police station / Fire station / Hospital / Library / School / Bakery "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Find the Muffin Man 영상을 보며 상호작용. ",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s buy some muffins. ",
                  "Put Start/End stickers on the circles. ",
                  "Draw a line to the bakery. ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요. ",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Different Jobs and Belongings Teaching Guide",
                  "Different Jobs and Belongings 말한다.",
                  "Who are they? ",
                  "Different Jobs and Belongings 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Circle the items that belongs to each person. ",
                  "Good job",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Opposite Teaching Guide",
                  "Opposite 말한다.",
                  "Let’s read the letters and numbers with a mirror. ",
                  "Can you read the numbers and letters? ",
                  "Opposite 영상을 본다."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Use a mirror.",
                  "Find the letter. (C/D/B/E)? ",
                  "Find the number. (3/5/6/2)",
                  "Put the matching stickers on the circles. ",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Mirror, mirror, what do you see? ",
                  "What letters are in the mirror? ",
                  "Circle the letters you see in the mirror. ",
                  "I see the letters…",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Muffin Man의 의미",
                  "오, 머핀맨을 아나요?",
                  "머핀맨, 머핀맨.",
                  "오, 듀리길에서 사는 머핀맨을 아나요?",
                  "네, 머핀맨을 알아요.",
                  "머핀맨, 머핀맨.",
                  "네, 나는 듀리길에서 사는",
                  "그 머핀맨을 알아요."
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Memory Game: Front and Back Teaching Guide",
                  "Memory Game: Front and Back 말한다.",
                  "Let’s talk about front and back. ",
                  "What is different? ",
                  "Memory Game: Front and Back 영상"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is the front. ",
                  "It is the back. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s play the memory game. ",
                  "Can you find the matching cards? ",
                  "Discuss with your team.",
                  "How to play: 참조",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Muffin Man Teaching Guide",
                  "The Muffin Man 말한다.",
                  "The Muffin Man 영상을 본다.",
                  "Let’s sing with motions. ",
                  "Good job! Singing & motions"
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3 ",
               sentences:[
                  "Shapes Teaching Guide",
                  "Shapes 말한다.",
                  "Let’s sing: Shapes Song. ",
                  "Shapes Song 영상을 보며 노래와 율동.",
                  "Good job singing."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Can you make the shapes with your hands? ",
                  "circle / triangle / square",
                  "Color the triangles. ",
                  "This is (circle / triangle / square)",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine",
                  "The Weather Song. Teaching Guide",
                  "The Weather Song. 영상을 본다.",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is windy. windy 말한다.",
                  "It is rainy. rainy 말한다. ",
                  "Circle the picture.",
                  "Good job! Singing."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4 ",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "Let’s Look at the Cover ",
                  "스토리 북의 그림을 가리키며, 말한다.",
                  "What is he playing? ",
                  "아이) 바이올린을 켜요…등을 이야기한다.",
                  "Let’s Look at the Cover 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "This is Tommy Tucker. ",
                  "He is playing something. ",
                  "Is he playing the (piano / tambourine / recorder/ violin)? "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Circle the picture.",
                  "piano / tambourine / recorder/ violin ",
                  "Yes/no. (아이들에게 응답)확인",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"5 ",
               sentences:[
                  "Will you marry me? (그림책을 보면서, 말한다)",
                  "Tommy Tucker’s Wedding! ",
                  "Tommy Tucker wants to marry. ",
                  "Can you draw Tommy Tucker’s wedding picture? ",
                  "Draw a wedding picture. ",
                  "Good job! Drawing "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7 ",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Little Tommy Tucker",
                  "plays for his dinner",
                  "What does he play for",
                  "Brown bread and butter",
                  "How can he cut it without a knife? ",
                  "How can he marry without a wife?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Put the matching stickers on the circles.",
                  "Good job! Singing and dance.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Phonics",
                  "Alphabet Song. Teaching Guide ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Sound It Out Teaching Guide",
                  "Sound It Out 말한다.",
                  "Sound It Out 영상을 본다. ",
                  "Can you say Tucker/butter? ",
                  "Tucker and butter rhyme. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's play a game. 영상을 본다.",
                  "Stand up when you hear “Tucker.”",
                  "Sit down when you hear “butter.” ",
                  "누가 나와서 Tucker / butter 찾을 수 있나요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. ",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행한다.",
                  "(아는 아이가 있어도…)",
                  "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"9",
               sentences:[
                  "What do I need? Teaching Guide",
                  "What do I need? 말한다.",
                  "What does he need to cut the bread?",
                  "영상을 보고 상호작용한다. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Does he need ",
                  "(a key/a bow/a comb/a spoon/a fork/a knife/",
                  "a feather/a flower/chopsticks/a pencil/a branch)? ",
                  "Yes. He needs a knife. ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "H Says h. Teaching Guide",
                  "H Says h. 말한다.",
                  "Let’s chant: H Says h. 영상을 본다.",
                  "Letter H (‘흐' 소리가 난다)",
                  "What letter is this? / Big H and little h.",
                  "H is for ham / H is for honey / H is for hotdog",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "I Says I. Teaching Guide",
                  "I Says I. 말한다.",
                  "Let’s chant: I Says i. 영상을 본다.",
                  "Letter I (‘이' 소리가 난다)",
                  "What letter is this? / Big I and little i."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big I and little i ",
                  "I is for igloo / I is for iguana / I is for ice cream",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture I i Teaching Guide",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with i.",
                  "Iguana starts with i. Color it. ",
                  "Ice cream starts with i. Color it.",
                  "Egg starts with e. Igloo starts with i. Color it."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine ",
                  "Let’s sing: Shapes Song. Teaching Guide",
                  "Shapes 말한다.",
                  "Let’s sing: Shapes Song. 영상을 보며 노래와 율동.",
                  "This is (circle / triangle / square)",
                  "Good job singing."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Wedding Cake Teaching Guide",
                  "Wedding Cake 말한다.",
                  "Wedding Cake 영상을 보며 상호작용.",
                  "This is a wedding cake. ",
                  "I see a (circle/triangle/heart/square). "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "What shapes do you see? ",
                  "I see a (circle/triangle/heart/square). ",
                  "Decorate a wedding cake with stickers. ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine Numbers Song. Teaching Guide",
                  "Numbers Song. ",
                  "영상에 맞추어 노래와 손 숫자를 같이 한다. ",
                  "Let’s sing: Numbers Song. ",
                  "Good job singing."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s Count Teaching Guide",
                  "Let’s Count 말한다.",
                  "Which picture has more? ",
                  "(more, flower, key, pencil) ",
                  "Let’s Count 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Which picture has more? ",
                  "Circle the picture that has more. ",
                  "Draw a picture with more keys. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "I Says I. Teaching Guide",
                  "I Says I. 말한다.",
                  "Let’s chant: I Says i. 영상을 본다.",
                  "Letter I (‘이' 소리가 난다)",
                  "What letter is this? / Big I and little i."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s chant: I Says i. 영상을 본다.",
                  "I is for igloo / I is for iguana / ",
                  "I is for ice cream",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "J Says j. Teaching Guide",
                  "J Says j. 말한다.",
                  "Let’s chant: J Says j. 영상을 본다.",
                  "Letter J (‘ 즈 ' 소리가 난다)",
                  "What letter is this? / Big J and little j."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Trace the big J and little j. ",
                  "J is for jam / J is for juice / J is for jelly beans",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with j.",
                  "Jam starts with j. Color it / ",
                  "Juice starts with j. Color it.",
                  "Ice cream starts with I / ",
                  "Jelly beans starts with j. Color it."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "The ABC Song Teaching Guide ",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해 보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"16-17",
               sentences:[
                  "Dots and Boxes Teaching Guide",
                  "Dots and Boxes 말한다.",
                  "Let’s make squares. ",
                  "Can you draw a line from dot to dot? ",
                  "Dots and Boxes 영상"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "connect, dots, square ",
                  "Let’s play Dots and Boxes. ",
                  "Count how many squares you made. ",
                  "How to play: 참조",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "Alphabet Song Teaching Guide ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song.",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "음에 맞춰서 Alphabet을 한 글자씩 가리키며 노래.",
                  "Do you know the Alphabet Song?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "Who Am I? Teaching Guide",
                  "Who Am I? 말한다.",
                  "Let’s think who I am. ",
                  "I am a vegetable / I’m good for you!",
                  "I look like a tree / My name starts with “b” "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who Am I 영상을 보며 상호작용.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Find and circle the matching vegetable. ",
                  "I am a….(무엇일까요?)",
                  "a banana / corn / broccoli /an orange ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "How to Wash My Hands Teaching Guide",
                  "How to Wash My Hands 말한다.",
                  "I wet my hands / I add soap / I rub my hands / ",
                  "I rinse my hands / I dry my hands. ",
                  "How to Wash My Hands 영상을 보며 상호작용."
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Write the numbers in order.",
                  "순서를 바르게 적는 것을 확인",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Tucker Teaching Guide",
                  "Let’s Move Tucker 말한다.",
                  "Tommy Tucker wants to marry. ",
                  "Right / Left / Up /Down "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Will you marry me? ",
                  "Right / Left / Up / Down ",
                  "카드놀이 활동으로 마무리 ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Little Tommy Tucker의 의미",
                  "작은 토미 터커가",
                  "저녁 식사를 위해 노래하네요.",
                  "그는 무엇을 위해 노래할까요?",
                  "갈색 빵과 버터를 위해 노래하네요.",
                  "칼 없이 어떻게 빵을 자를까요?",
                  "아내 없이 어떻게 결혼을 할까요?"
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Little Tommy Tucker Teaching Guide",
                  "Little Tommy Tucker 말한다.",
                  "Little Tommy Tucker 영상을 본다.",
                  "Let’s sing and dance!",
                  "노래 가사를 알려주며 동작을 함께 만들어 본다.",
                  "Good job! Singing and dance. ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "What Day Is It Today? Teaching Guide",
                  "What Day Is It Today? 제목을 말한다.",
                  "Let’s sing the Days of the Week Song. ",
                  "Days of the Week Song 영상, 노래",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Put the sticker on today’s day. ",
                  "Sunday /Monday / Tuesday / Wednesday / ",
                  "Thursday / Friday / Saturday ",
                  "What day is it today? ",
                  "Today is ……",
                  "스티커를 붙이는 것으로 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Song Teaching Guide",
                  "The Weather Song 영상을 보며, 노래와 율동",
                  "What’s the weather like today?",
                  "Look out the window. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is windy. windy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "Good job! Singing. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 달력, 치킨. 앵무새요…등을 이야기한다.",
                  "This is Geneus / He is a cook. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "He wants to cook different food every day. ",
                  "Do you see (numbers/food/letters/a calendar)? ",
                  "Yes. ",
                  "I see numbers, food, letters and a calendar. ",
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "Find the pictures. ",
                  "numbers/food/letters/a calendar ",
                  "Yes/ no. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Are you hungry? Teaching Guide",
                  "Will you marry me? (그림책을 보면서, 말한다)",
                  "What do you want to eat? ",
                  "I want to eat (corn/ soup/ spaghetti/ chicken). "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "I am hungry!",
                  "Draw some food. ",
                  "Good job! Drawing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Sunday / Monday / Tuesday / Wednesday",
                  "Thursday / Friday / Saturday ",
                  "corn / spaghetti",
                  "soup /roast beef ",
                  "fresh fish / chicken / ice cream ",
                  "Today is Monday 영상을 본다.",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine Let’s sing: One Little Finger. Teaching Guide",
                  "One Little Finger. 영상을 본다.",
                  "Let’s sing One Little Finger (주의집중 챈트)",
                  "Do you know the One Little Finger song?"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing ‘One Little Finger song’",
                  "One Little Finger, tap, tap, tap. ",
                  "Point to the ceiling, ",
                  "point to the floor, ",
                  "put it on your head.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Food Calendar Teaching Guide",
                  "Food Calendar 제목을 말한다.",
                  "I am hungry. / This is a menu for the week. ",
                  "There are special days. ",
                  "Chuseok is on Friday. ",
                  "Sunday is your birthday. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What do you want to eat on (Monday)? ",
                  "Do you want to eat (spaghetti)? ",
                  "What do you want to eat? ",
                  "Draw and use stickers to plan your weekly menu. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 September를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나옴 (다 같이 노래한다)"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Let’s chant J Says j. Teaching Guide",
                  "Let’s chant: J Says j. 영상을 본다.",
                  "Letter J 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
                  "What letter is this? / Big J and little j.",
                  "J is for jam / J is for juice / J is for jelly beans.",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "K Says k. Teaching Guide",
                  "K Says k. 제목을 말한다.",
                  "Let’s chant: K Says k. 영상을 본다.",
                  "Letter K 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
                  "What letter is this? / Big K and little k.",
                  "Trace the big K and little k. ",
                  "K is for ketchup / K is for kimchi / K is for kiwi."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles.",
                  "쓰는 것과 스티커 활동으로 마무리",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Color the pictures starting with k.",
                  "각 그림의 이름을 말해보고, K로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Kimchi starts with k. Color it / Ketchup starts with k. Color it.",
                  "Kiwi starts with k. Color it.",
                  "색칠 활동하는 것으로 마무리"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Days of the Week Song. Teaching Guide",
                  "Days of the Week 제목을 말한다.",
                  "Let’s say the days. ",
                  "Sunday /Monday / Tuesday / Wednesday /",
                  "Thursday / Friday / Saturday ",
                  "How many days are in a week? ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. ",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Days of the Week Teaching Guide",
                  "Days of the Week 제목을 말한다.",
                  "Let’s say the days. ",
                  "Sunday /Monday / Tuesday / Wednesday /",
                  "Thursday / Friday / Saturday ",
                  "How many days are in a week? "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s count. (one, two, three, four……seven)",
                  "Seven days are in a week. ",
                  "Trace the numbers.",
                  "Put the matching sticker on the circle. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  " 민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Phonics Let’s chant K Says k. Teaching Guide",
                  "Let’s chant: K Says k. 영상을 본다.",
                  "Letter K 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
                  "What letter is this? / Big K and little K .",
                  "K is for ketchup / K is for kimchi / K is for kiwi."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. ",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "L Says l. Teaching Guide",
                  "L Says l. 제목을 말한다.",
                  "Let’s chant: L Says l. 영상을 본다.",
                  "Letter L 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
                  "What letter is this? / Big L and little l.",
                  "Trace the big L and little l. ",
                  "L is for lemon / L is for lettuce / L is for lollipop."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동으로 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Color the pictures starting withㅣ.",
                  "Say the name of each picture. 영상을 본다.",
                  "각 그림의 이름을 말해보고, L 로 시작하는 그림을 색칠한다.",
                  "Lettuce starts with l. Color it / Lemon starts with l. Color it.",
                  "Fish starts with f / Lollipop starts with l. Color it.",
                  "색칠 활동하는 것으로 마무리"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine Shapes Song Teaching Guide",
                  "Shapes Song 의 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Let’s sing: Shapes Song. ",
                  "triangle / heart / square / circle",
                  "Good job singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Party Banners Teaching Guide",
                  "Party Banners 제목을 말한다.",
                  "There are patterns. ",
                  "Some patterns are missing. ",
                  "Party Banners 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What (color/shape) comes next? ",
                  "각 그림의 색을 순차적으로 말한다. 빈 공간에 들어갈 색은 ?",
                  "What comes next? ",
                  "Make the patterns. ",
                  "그림 그리는 것으로 활동을 마무리.",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Look Inside! Teaching Guide",
                  "Look Inside! 제목을 말한다.",
                  "These are tomatoes. ",
                  "Cut the tomatoes in two ways. ",
                  "Guess how tomatoes look inside. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Look Inside! 영상을 보고 상호작용한다.",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s cut the tomatoes. ",
                  "What do we need? ",
                  "a knife / tomatoes ",
                  "Cut down the middle. ",
                  "Look inside and draw. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "그림을 그리도록 한다. ",
                  "다른 과일을 자르면 어떨까요?",
                  "Look Inside! 영상을 보고 상호작용한다.",
                  "아이들이 그림 그리는 것으로 활동을 마무리.",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Drawing Game Teaching Guide",
                  "Drawing Game 제목을 말한다.",
                  "Let’s play a game. ",
                  "Listen carefully. ",
                  "Draw / under / in / on",
                  "Square / triangle /circle / heart "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s listen and draw. ",
                  "What did you draw? ",
                  "What is it? ",
                  "활동을 하는 것으로 마무리 ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Let’s chant L Says l. Teaching Guide",
                  "Let’s chant: L Says l. 영상을 본다.",
                  "Letter L 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
                  "What letter is this? / Big L and little l.",
                  "L is for lemon / L is for lettuce / L is for lollipop."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"18",
               sentences:[
                  "M Says m. Teaching Guide",
                  "M Says m. 제목을 말한다.",
                  "Let’s chant: M Says m. 영상을 본다.",
                  "Letter M 가 무슨 소리가 날까요? (‘므' 소리가 난다)",
                  "What letter is this? / Big M and little m.",
                  "Trace the big M and little m.",
                  "M is for melon. M is for milk. M is for muffin."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18-19",
               sentences:[
                  "Color the pictures starting with m.",
                  "Say the name of each picture. 영상을 본다.",
                  "",
                  "Melon start with m. Color it / Milk start with m. Color it.",
                  "Kiwi start with k / Muffin start with m. Color it.",
                  "각 그림의 이름을 말해보고, M으로 시작하는 그림을 색칠한다. "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Word Train 제목을 말한다.",
                  "Say the sounds of the letters.",
                  "Put the matching stickers on the circles.",
                  "스티커 활동하는 것으로 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "This is Jay’s birthday party. ",
                  "Are you hungry? ",
                  "Come to the party! ",
                  "Find the hidden things. ",
                  "Hidden Pictures 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the hidden things. ",
                  "ice cream / milk / yogurt / cheese ",
                  "spaghetti/ corn / chicken / fish",
                  "올바르게 찾는지 확인하고 마무리 ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Today is Monday 의미",
                  "오늘은 월요일",
                  "월요일에는 옥수수를*",
                  "배고픈 친구들,",
                  "모두 와서 먹어요!",
                  "*화요일에는 스파게티/",
                  "수요일에는 스프/",
                  "목요일에는 쇠고기 구이/",
                  "금요일에는 신선한 생선/",
                  "토요일에는 닭고기/",
                  "일요일에는 아이스크림"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Robby Teaching Guide",
                  "Let’s Move Robby 제목을 말한다.",
                  "Robby wants some spaghetti. ",
                  "Right / Left / Up /Down ",
                  "Let’s Move Robby 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Robby wants some spaghetti. ",
                  "Put the stickers on the circles. ",
                  "Right / Left / Up / Down ",
                  "카드놀이 활동으로 마무리 ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Today is Monday Teaching Guide",
                  "Today is Monday 제목을 말한다.",
                  "Today is Monday 영상을 본다. (반복)",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Daily Routine Numbers Song. Teaching Guide",
                  "Numbers Song. 의 영상을 본다.",
                  "영상에 맞추어 노래와 손 숫자를 같이 한다. ",
                  "Let’s sing: Numbers Song. ",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"3",
               sentences:[
                  "How Many Astronauts Are There? Teaching Guide",
                  "How Many Astronauts Are There? 제목을 말한다.",
                  "Let’s sing the Numbers Song.",
                  "Numbers Song 영상을 본다.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Write the numbers in the boxes. ",
                  "(5) astronauts in a flying saucer- ",
                  "(2) astronauts in a flying saucer- ",
                  "숫자(5),(2) 쓰는 것으로 마무리한다.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Song Teaching ”G”uide",
                  "The Weather Song 영상을 보며, 노래",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is windy. windy 를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "Circle the picture.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 우주선, 5 사람… 등을 이야기한다.",
                  "Let’s look at the picture. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "I see a spaceship. ",
                  "I see five men. ",
                  "They are astronauts. ",
                  "Do you see (a train/an airplane/a spaceship/a car)? ",
                  "Yes. I see a spaceship. ",
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Find the pictures. ",
                  "(a train/an airplane/a spaceship/a car)? 그림 포인트, 말한다.",
                  "Yes/ no. ",
                  ""
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "We fly around the world! (그림책을 보면서, 말한다)",
                  "I am an astronaut! ",
                  "Draw your face. ",
                  "He is an astronaut. ",
                  "He likes spaceships. ",
                  "Do you want to be an astronaut? ",
                  "얼굴 그리는 것으로 마무리.",
                  "Good job! Drawing "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "five, men, / left, / right, / jump ",
                  "Five Little Men 영상을 본다.",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해 볼까요? ",
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "스티커를 붙이는 것으로 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine Let’s sing: One Little Finger. Teaching Guide",
                  "One Little Finger. 영상을 본다.",
                  "Let’s sing One Little Finger. (주의집중 시키는 챈트)",
                  "Do you know the One Little Finger song?"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing One Little Finger song.",
                  "One Little Finger, tap, tap, tap. ",
                  "Point to the ceiling, ",
                  "point to the floor, ",
                  "put it on your head.",
                  "Good job singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Where Are They? Teaching Guide",
                  "Where Are They? 제목을 말한다.",
                  "The car is on the land. ",
                  "The boat is in the sea. ",
                  "The airplane is in the sky. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s find the car, boat, and airplane. ",
                  "Put the matching stickers on the circles. ",
                  "Trace the words. ",
                  "쓰고, 스티커 붙이는 활동으로 마무리. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 October를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나옴 (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. (시간이 되면, 반복하는 노래)",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "N Says n. Teaching Guide",
                  "N Says n. 제목을 말한다.",
                  "Let’s chant: N Says n. 영상을 본다.",
                  "Letter N 가 무슨 소리가 날까요? (‘느' 소리가 난다)",
                  "What letter is this? / Big N and little n.",
                  "Trace the big N and little n. ",
                  "N is for nuts / N is for noodles / N is for napkin."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Say the name of each picture ‘N n' Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, N 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with n.",
                  "Noodles starts with n. Color it/ Nuts starts with n. Color it.",
                  "Milk starts with m / Napkin starts with n. Color it."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with n. ",
                  "색칠 활동하는 것으로 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide",
                  "Feelings 의 영상을 본다.",
                  "Let’s sing the Feelings Song. ",
                  "영상을 보며 노래와 율동.",
                  "Happy / Sad / Angry / sleeping",
                  "Circle your feeling. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel?",
                  "Are you happy? ",
                  "I am happy",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Same or Different Teaching Guide",
                  "Same or Different 제목을 말한다.",
                  "Let’s find the same picture. ",
                  "spaceship / ship / submarine / airplane ",
                  "Draw a triangle around the picture. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Tangram Teaching Guide",
                  "Tangram 제목을 말한다.",
                  "Tangram 영상을 본다.",
                  "Let’s create shapes.",
                  "교구 준비 (탱그램 카드를 준비한다)"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "There are four pieces of a tangram. ",
                  "Can you put the four tangram pieces together? ",
                  "Let’s make a shape.",
                  "그림 위에 카드를 맞추면 칭찬하며 마무리. ",
                  "Excellent!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "N Says n. Teaching Guide",
                  "N Says n. 제목을 말한다.",
                  "Let’s chant: N Says n. 영상을 본다.",
                  "Letter N 가 무슨 소리가 날까요? (‘느' 소리가 난다)",
                  "What letter is this? / Big N and little n.",
                  "Trace the big N and little n. ",
                  "N is for nuts / N is for noodles / N is for napkin."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "O Says o. Teaching Guide",
                  "O Says o. 제목을 말한다.",
                  "Let’s chant: O Says o. 영상을 본다.",
                  "Letter O 가 무슨 소리가 날까요? (‘아' 소리가 난다)",
                  "What letter is this? / Big O and little o.",
                  "Trace the big O and little o ",
                  "O is for orange / O is for onion / O is for octopus."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture. 영상을 본다.",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, O 로 시작하는 그림을 색칠한다.",
                  "Color the pictures starting with o.",
                  "Octopus starts with o. Color it / Orange starts with o. Color it.",
                  "Lemon starts with l / Onion starts with o. Color it."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with o. ",
                  "그리기 활동 하는 것으로 마무리",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine Numbers Song. Teaching Guide",
                  " Numbers Song. 의 영상을 본다.",
                  "영상에 맞추어 노래와 손 숫자를 같이 한다. ",
                  "Let’s sing: Numbers Song. ",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"story",
               script:"",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Left and Right Teaching Guide",
                  "Left and Right 제목을 말한다.",
                  "There are five little men in a spaceship. ",
                  "They look left and right. ",
                  "What do you see on the (left/right)? ",
                  "Boats are on the left. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Left and Right 영상을 보고 상호작용한다.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Draw what you see! ",
                  "On the left / On the right. ",
                  "그리는 것으로 활동을 마무리",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Alphabet Song. Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the ABC song?",
                  "Let's sing the ABC song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "O Says o. Teaching Guide",
                  "O Says o. 제목을 말한다.",
                  "Let’s chant: O Says o. 영상을 본다.",
                  "Letter O 가 무슨 소리가 날까요? (‘아' 소리가 난다)",
                  "What letter is this? / Big O and little o.",
                  "Trace the big O and little o. ",
                  "O is for orange / O is for onion / O is for octopus."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "P Says p. Teaching Guide",
                  "P Says p. 제목을 말한다.",
                  "Let’s chant: P Says p. 영상을 본다.",
                  "Letter P 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
                  "What letter is this? / Big P and little p.",
                  "Trace the big P and little p.",
                  "P is for pizza / P is for pot / P is for pineapple. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면 마무리",
                  "Good job! ",
                  "",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18-19",
               sentences:[
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with p.",
                  "Pot starts with p. Color it / Pizza starts with p. Color it.",
                  "Onion starts with o / Pineapple starts with p. Color it.",
                  "각 그림의 이름을 말해보고, P 로 시작하는 그림을 색칠한다."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Word Train 제목을 말한다.",
                  "Say the sounds of the letters.",
                  "Put the matching stickers on the circles.",
                  "스티커 활동하는 것으로 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "Look at the spaceships on the screen! ",
                  "Find the hidden things. "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Hidden Pictures 영상을 보고 상호작용한다.",
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Find the hidden things. ",
                  "apple / orange / strawberry /grapes",
                  "pineapple / car / truck / train ",
                  "올바르게 찾는지 확인하고 마무리 ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Little Men In a Flying Saucer의 의미",
                  "어느 날 다섯 명의 작은 우주인들이",
                  "우주선을 타고 세계를 날아 다녔어요.",
                  "왼쪽도 보고 오른쪽도 봤지요.",
                  "그 모습이 아주 마음에 들었어요.",
                  "그래서 한 명이 뛰어 내렸어요."
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Coding Memory Game Teaching Guide",
                  "Coding Memory Game 제목을 말한다.",
                  "Let’s play a game. ",
                  "Can you find the same cards? Discuss with your team. ",
                  "Discuss with your team. ",
                  "Start / Right /Left / Up / Down Turn Right / Turn Left / End "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "카드 활동을 해볼까요?",
                  "Make two teams. ",
                  "카드놀이 활동으로 마무리 ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Little Men Teaching Guide",
                  "Song: Five Little Men 제목을 말한다.",
                  "Five Little Men 영상을 본다.",
                  "Let’s sing! 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Months of the Year Teaching Guide",
                  "Months of the Year 제목을 말한다.",
                  "Let’s sing the Months Song.",
                  "Months Song영상을 본다.",
                  "영상에 맞추어 노래를 같이 한다.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What month is it? ",
                  "January / February / March / Apr",
                  "May / June / July / August",
                  "September / October / November / December",
                  "It is …. 쓰는 것으로 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing. (노래와 율동을 한다)",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보며, 노래",
                  "What’s the weather like today?",
                  "Look out the window. "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is windy. windy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is snowy. snowy를 말한다. ",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Let’s Look at the Picture Teaching Guide",
                  "Let’s Look at the Picture 제목을 말한다.",
                  "Let’s Look at the Picture 스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 빵, 칼, 잼, 등을 이야기한다.",
                  "Let’s look at the picture. ",
                  "Do you see",
                  "(a knife / jam / peanut butter / bread)? ",
                  "Yes, I see (a knife/jam/peanut butter/bread). "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Find the pictures. ",
                  "(a knife / jam / peanut butter / bread) 그림을 말한다.",
                  "Yes/ no. ",
                  "활동을 마무리",
                  "배운 영상을 부모님과 함께 해보세요."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Spread it! ",
                  "What do you want to spread? (그림을 보면서, 말한다)",
                  "Color the bread. ",
                  "cream cheese / butter / peanut butter / jam ",
                  "Do you like (cream cheese/butter/peanut butter/jam)? ",
                  "Do you want to spread",
                  "(cream cheese/butter/peanut butter/jam)",
                  "on the bread? ",
                  "Good job! Drawing "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)",
                  "Bread/ grapes / jelly / peanut/ /peanut butter / sandwich "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the peanut butter and jelly and sandwich stickers on the circles. ",
                  "Good job! ",
                  "스티커를 붙이는 것으로 마무리",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8-9",
               sentences:[
                  "Daily Routine Colors Song. Teaching Guide",
                  "Colors Song. 영상을 본다.",
                  "Let’s sing: Colors Song. (영상을 보며 노래, 율동)",
                  "Good job! Singing ",
                  "What color is this? ",
                  "Red / Blue / Green",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Simon Says Teaching Guide",
                  "Simon Says 제목을 말한다.",
                  "crunch, eat, spread, squish ",
                  "Let’s do the motions. ",
                  "Put the matching stickers on the circles. "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s play a game. ",
                  "How to play “Simon Says ” ",
                  "If you hear “Simon says,” do the motions. ",
                  "If you don’t hear “Simon says,” don’t move. "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 November를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Q Says q. Teaching Guide",
                  "Q Says q. 제목을 말한다.",
                  "Let’s chant: Q Says q. 영상을 본다.",
                  "Letter Q 가 무슨 소리가 날까요? (‘크' 소리가 난다.)",
                  "What letter is this? / Big Q and little q.",
                  "Trace the big Q and little q. ",
                  "Q is for queen. / Q is for quince. / Q is for quail."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Say the name of each picture Q q Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, Q 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with q.",
                  "Quince starts with q. Color it./ Quail starts with q. Color it.",
                  "Pineapple starts with p./ Queen starts with q. Color it."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with q. ",
                  "그리기 활동하는 것으로 마무리",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide",
                  "Feelings 의 영상을 본다.",
                  "Let’s sing the Feelings Song. (영상을 보며 노래, 율동)",
                  "Happy / Sad / Angry / Surprise",
                  "Circle your feeling. "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Same or Different Teaching Guide",
                  "Same or Different 제목을 말한다.",
                  "What is (the same/different)? ",
                  "Find the (same/different) things in the picture. ",
                  "Circle the five different things. ",
                  "다른 그림 찾는 활동으로 마무리 ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Same or Different 영상을 보고 상호작용한다.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Choose Your Tool Teaching Guide",
                  "Choose Your Tool 제목을 말한다.",
                  "Let’s think about what tools make it",
                  "easy to spread the peanut butter. ",
                  "Do you want to use a",
                  "(spoon/fork/chopstick/spatula/knife/straw)? ",
                  "Choose Your Tool 영상을 본다.",
                  "Let’s spread the peanut butter. ",
                  "What do you want to use? "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s crush the peanuts. ",
                  "What do you want to use? ",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine Shapes Song Teaching Guide",
                  "Shapes Song 의 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Let’s sing: Shapes Song . ",
                  "What shape is this? ",
                  "Color the star. ",
                  "triangle / heart / star / square",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "What Do We Need? Teaching Guide",
                  "What Do We Need? 제목을 말한다.",
                  "when we ",
                  "(toast the bread/wash the clothes)? ",
                  "We need a toaster. ",
                  "We need a washing machine. ",
                  "What Do We Need? 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Word bank: ",
                  "washing machine / toaster /blender / computer",
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Phonics Let’s chant Q Says q. Teaching Guide",
                  "Let’s chant: Q Says q. 영상을 본다.",
                  "Letter Q 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
                  "What letter is this? / Big Q and little q.",
                  "Q is for queen. / Q is for quince. / Q is for quail."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "R Says r. Teaching Guide",
                  "R Says r. 제목을 말한다.",
                  "Let’s chant: R Says r. 영상을 본다.",
                  "Letter R 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
                  "What letter is this? / Big R and little r.",
                  "Trace the big R and little r. ",
                  "R is for rice. / R is for rabbit. / R is for raisins."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture R r Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, R 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with r.",
                  "Rabbit starts with r. Color it. / Rabbit starts with r. Color it.",
                  "Nets starts with n. / Raisins starts with r. Color it."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with r. ",
                  "색칠 활동하는 것으로 마무리 한다.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Alphabet Song Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Let’s chant R Says r. Teaching Guide",
                  "Let’s chant: R Says r. 영상을 본다.",
                  "Letter R 가 무슨 소리가 날까요? (‘르' 소리가 난다.)",
                  "What letter is this? / Big R and little r.",
                  "Let’s chant: R Says r. 영상을 본다.",
                  "R is for rice./ R is for rabbit./ R is for raisins."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "S Says s. Teaching Guide",
                  "S Says s. 제목을 말한다.",
                  "Let’s chant: S Says s. 영상을 본다.",
                  "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
                  "What letter is this? / Big S and little s.",
                  "Trace the big S and little s.",
                  "S is for spoon. / S is for salad. / S is for soup. "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동으로 마무리한다.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with s.",
                  "Spoon starts with s. Color it. / Soup starts with s. Color it.",
                  "Napkin starts with n. / Salad starts with s. Color it.",
                  "각 그림의 이름을 말해보고, S 로 시작하는 그림을 색칠한다."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "The children are playing in the playground. ",
                  "Find the hidden things. ",
                  "Hidden Pictures 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the hidden things. ",
                  "Broccoli /carrot /corn / tomato ",
                  "Knife / hammer / nut / cracker pan",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peanuts Butter and Jelly의 의미",
                  "땅콩버터와 잼!",
                  "땅콩을 가져와서 부셔요!",
                  "그 다음 포도를 가져와서 으깨요!",
                  "그 다음 빵을 가져와서 발라요!",
                  "그리고 샌드위치를 가져와서 먹어요!",
                  "땅콩버터와 잼!"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "What Am I? Teaching Guide",
                  "What Am I? 제목을 말한다.",
                  "Let’s think.",
                  "Find and circle the matching picture.",
                  "I am a tool. ",
                  "You can find me in the kichen. ",
                  "I am sharp. ",
                  "I can cut food. ",
                  "I am (a hammer / a spoon / a knife / a fork) ",
                  "What Am I? 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peanut Butter and Jelly Teaching Guide",
                  "Song: Peanut Butter and Jelly 제목을 말한다.",
                  "Peanut Butter and Jelly 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing (노래와 율동을 한다)"
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"16-17",
               sentences:[
                  "Shape World Teaching Guide",
                  "Shape World 제목을 말한다.",
                  "Let’s play a game. ",
                  "Roll the dice and move. What shape is it? ",
                  "circle, end, roll, rice, shape, square, start, triangle, ",
                  "How do you play this game? ",
                  "How to play: 참조"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Seasons Song. Teaching Guide ",
                  "Seasons Song. 제목을 말한다.",
                  "Pretend to be a tree while you are singing. ",
                  "Seasons Song. 영상을 본다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing with motions.",
                  "Spring, Summer, Fall, Winter ",
                  "Spring, Summer, Fall, Winter ",
                  "These are the four seasons. ",
                  "Spring, Summer, Fall, Winter ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"3",
               sentences:[
                  "Seasons and Food Teaching Guide",
                  "Seasons and Food 제목을 말한다.",
                  "Let’s sing the Seasons Song. ",
                  "Seasons Song 영상을 본다.",
                  "영상에 맞추어 노래를 같이 한다. ",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Draw the food you want to eat in summer and winter. ",
                  "summer and winter. ",
                  "자기가 좋아하는 음식 그리기 활동.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보며, 노래",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy를 말한다.",
                  "It is windy. windy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is snowy. snowy를 말한다. ",
                  "Circle the picture.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "활동을 해 볼까요? ",
                  "Put the peas porridge and pot stickers on the circles. ",
                  "스티커를 붙이는 것으로 마무리",
                  "Good job!",
                  "오늘 교실에서 배운 영상을 부모님과 함께 해보세요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 엄마, 콩, 빵, 그릇 등을 이야기한다.",
                  "Let’s look at the picture. ",
                  "Do you see",
                  "(porridge / peas/ hot chocolate/a pot)? ",
                  "Yes, I see (peas/a pot). "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다.",
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Find the pictures. ",
                  "(porridge / peas/ hot chocolate/a pot) 그림을 말한다.",
                  "Yes/ no. ",
                  "배운 영상을 부모님과 함께 해보세요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "It’s winter. (그림을 보면서, 말한다)",
                  "What do you want to (eat/drink) in the winter? ",
                  "It’s cold! ",
                  "Do you want to (eat/drink)",
                  "(pie / hot chocolate / porridge / sweet potatoes)? ",
                  "I want to (eat/drink)",
                  "(pie / hot chocolate / porridge / sweet potatoes).",
                  "Draw what you want to eat or drink. ",
                  "그림 그리는 것으로 마무리한다.",
                  "Good job! Drawing "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine Shapes Song Teaching Guide",
                  "Shapes Song 의 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Let’s sing: Shapes Song.",
                  "square / circle / triangle",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"8-9",
               sentences:[
                  "Hot and Cold Teaching Guide",
                  "Hot and Cold 제목을 말한다.",
                  "Let’s trace the words. ",
                  "Put the matching stickers on the circles. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s play a game. ",
                  "How to play “Hot and Cold ” ",
                  "Hide the treasure star and have the player find it. ",
                  "When the player gets close to the treasure, say, “Hot!” ",
                  "When the player gets far from the treasure, say, “Cold!”",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "T Says t. Teaching Guide",
                  "T Says t. 제목을 말한다.",
                  "Let’s chant: T Says t. 영상을 본다.",
                  "Letter T 가 무슨 소리가 날까요? (‘트' 소리가 난다.)",
                  "What letter is this? / Big T and little t. ",
                  "Trace the big T and little t. ",
                  "T is for tent. / T is for toaster. / T is for tomato."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Say the name of each picture T t Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, T 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with t.",
                  "Tent starts with t. Color it./ Toaster starts with t. Color it.",
                  "Queen starts with q. / Tomato starts with t. Color it."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with t. ",
                  "색칠 활동하는 것으로 마무리한다.",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide",
                  "Feelings의 영상을 본다.",
                  "Let’s sing the Feelings Song. 영상을 보며 노래, 율동 ",
                  "Happy / Sad / Angry / Sleeping",
                  ""
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel?",
                  "Are you happy?",
                  "I am happy. ",
                  "Circle your feeling. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Play in the Winter Teaching Guide",
                  "Play in the Winter 제목을 말한다.",
                  "What do you like to do in the winter? ",
                  "Do you like to (go sledding/make snow angels) ",
                  "(/make snowmen/have snowball fights)? ",
                  "Play in the Winter 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many children like to (go sledding) in the winter? ",
                  "Let’s count. ",
                  "Come up and color the boxes. ",
                  "아이들이 그림 활동으로 마무리 ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Snowflakes Teaching Guide",
                  "Snowflakes 제목을 말한다.",
                  "Look at the snowflakes. ",
                  "What do we need? ",
                  "We need a magnifier. ",
                  "Snowflakes 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Put the snowflake stickers on the circles. ",
                  "This is a snowflake. ",
                  "How many points do snowflakes have? ",
                  "Let’s count the points on the snowflakes.",
                  "Snowflakes have 6 points.",
                  "Write the number. ",
                  "Good job! 숫자를 세어보고 쓰는 것으로 마무리한다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Daily Routine Stretching Song. Teaching Guide",
                  "Stretching Song. 의 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Let’s sing: Stretching Song. ",
                  "Stretch your arms.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Mittens Teaching Guide",
                  "Mittens 제목을 말한다.",
                  "These are mittens. ",
                  "Do you see shapes on the mittens? ",
                  "This is a (circle / triangle / square / heart / star). ",
                  "Let’s find the matching mittens. ",
                  "Mittens 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Decorate the mittens with shapes. ",
                  "그리는 것으로 활동을 마무리한다.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Phonics Let’s chant T Says t. Teaching Guide",
                  "Let’s chant: T Says t. 영상을 본다.",
                  "Letter T 가 무슨 소리가 날까요? (‘트' 소리가 난다.)",
                  "What letter is this? / Big T and little t.",
                  "T is for tent. / T is for toaster. / T is for tomato."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "U Says u. Teaching Guide",
                  "U Says u. 제목을 말한다.",
                  "Let’s chant: U Says u. 영상을 본다.",
                  "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다.)",
                  "What letter is this? / Big U and little u.",
                  "Trace the big U and little u. ",
                  "U is for up. / U is for umpire. / U is for umbrella."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 보면서 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture U u Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, U 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with u.",
                  "Up starts with u. Color it. / Umbrella starts with u. Color it.",
                  "Rice starts with r. / Umpire starts with u. Color it."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with u. ",
                  "색칠 활동하는 것으로 마무리한다.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다.what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 December를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 .Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Let’s chant U Says u. Teaching Guide",
                  "Let’s chant: U Says u. 영상을 본다.",
                  "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다.)",
                  "What letter is this? / Big U and little u. ",
                  "U is for up. / U is for umpire. / U is for umbrella."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "V Says v. Teaching Guide",
                  "V Says v. 제목을 말한다.",
                  "Let’s chant: V Says v. 영상을 본다.",
                  "Letter V 가 무슨 소리가 날까요? (‘브' 소리가 난다.)",
                  "What letter is this? / Big V and little v.",
                  "Trace the big V and little v.",
                  "V is for van. / V is for vine. / V is for vest. "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동한다.",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18-19",
               sentences:[
                  "Say the name of each picture ‘V v' Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고 , V 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with v.",
                  "Van starts with v. Color it. / Vine starts with v. Color it.",
                  "Soup starts with s. / Vest starts with v. Color it."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Word Train",
                  "Say the sounds of the letters. ",
                  "Put the matching stickers on the circles. ",
                  "스티커 활동하는 것으로 마무리한다.",
                  "Good job! ",
                  "배운 내용을 부모님과 함께 해보세요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "It’s winter! Let’s go ice skating.",
                  "Find the hidden things. ",
                  "Hidden Pictures 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the hidden things. ",
                  "Chicken /egg / fish / sausages",
                  "boots / coat / hat / scarf ",
                  "확인하고 마무리한다. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Peas Porridge Hot의 의미",
                  "뜨거운 완두콩 죽,",
                  "차가운 완두콩 죽,",
                  "냄비에 담긴 9일된 완두콩 죽,",
                  "어떤 사람들은 뜨거운 죽을 좋아하고,",
                  "어떤 사람들은 차가운 죽을 좋아하고,",
                  "어떤 사람들은 9일된 완두콩 죽을 좋아해요."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move the Boy Teaching Guide",
                  "Let’s Move the Boy 제목을 말한다.",
                  "It’s cold outside. ",
                  "The boy wants some porridge. ",
                  "Let’s move the boy. ",
                  "Start / Right / Left / Up / Down / End",
                  "Put the sticker on the circle. ",
                  "Let’s Move the Boy 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Peas Porridge Hot Teaching Guide",
                  "Song: Peas Porridge Hot 제목을 말한다.",
                  "Peas Porridge Hot 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing",
                  "cold, hot, peas, porridge, pot ",
                  "Good job! Singing. (노래, 율동)"
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"16-17",
               sentences:[
                  "Tic-Tac-Toe Teaching Guide",
                  "Tic-Tac-Toe 제목을 말한다.",
                  "Let’s play Tic-Tac-Toe. ",
                  "Let’s follow the directions. ",
                  "draw, mark, play, tic-tac-toe, space, ",
                  "(three) in a row, How do you play this game? ",
                  "Let’s play a game. ",
                  "Say tic-tac-toe! "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:" 3",
               sentences:[
                  "Thank You Teaching Guide",
                  "Thank You 제목을 말한다.",
                  "Let’s sing the Thank You Song. ",
                  "Thank You Song 영상을 보며 노래한다.",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Draw your teacher and your friend. ",
                  "Thank you, teacher! ",
                  "Thank you, friend! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보며, 노래한다.",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is windy. windy를 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"story",
               script:"",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.",
                  "Let’s Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
                  "What do you see? ",
                  "아이) 농부, 누나, 친구 채소,…. 등을 이야기한다.",
                  "Let’s look at the picture. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "This is a farm. ",
                  "These are farmers. ",
                  "Do you see (a farmer / seeds / lettuce / bugs)?",
                  "Let’s Look at the Cover 영상을 보여주며, 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Find the pictures. ",
                  "(a farmer / seeds / lettuce / bugs) 그림을 말한다.",
                  "Yes/ no. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "I want to grow lettuce. ",
                  "I want to grow lettuce. (그림을 보면서, 말한다)",
                  "Let’s grow vegetables. ",
                  "What do you want to grow?",
                  "I want to grow (broccoli / carrots / cucumbers / tomatoes). "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Draw the picture of the vegetables. ",
                  "(broccoli / carrots / cucumbers / tomatoes). ",
                  "I want to eat",
                  "(broccoli / carrots / cucumbers / tomatoes). ",
                  "그림 그리는 것으로 마무리.",
                  "Good job! Drawing ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "farmers, grow, leaves, pick up, plant, rain, seeds ",
                  "",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:" 8",
               sentences:[
                  "Daily Routine Roll Your Hands. Teaching Guide",
                  "Roll Your Hands. Song의 영상을 보며 노래와 율동.",
                  "Let’s sing: Roll Your Hands. ",
                  "roll /as slowly as you can.",
                  "clap /stomp / cross ",
                  "as fast as you can. ",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Let’s Grow Plants Teaching Guide",
                  "Let’s Grow Plants 제목을 말한다.",
                  "Watch the lettuce grow.",
                  "Put the matching stickers on the circles."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Plant the seeds. ",
                  "Water the plants. ",
                  "What do you see? ",
                  "The leaves grow! ",
                  "Lettuce!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 January를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다.) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. (시간이 되면, 반복하는 노래)",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "W Says w. Teaching Guide",
                  "W Says w. 제목을 말한다.",
                  "Let’s chant: W Says w. 영상을 본다.",
                  "Letter W 가 무슨 소리가 날까요? (‘우' 소리가 난다.)",
                  "What letter is this? / Big W and little w.",
                  "Trace the big W and little w. ",
                  "W is for waffle. / W is for water. / W is for window."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 한다.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Say the name of each picture W w Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, W로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with w.",
                  "Water starts with w. Color it./ Waffle starts with w. Color it.",
                  "Up starts with u./ Window starts with w. Color it."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with w. ",
                  "Good job! 색칠 활동으로 마무리한다.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Let’s sing Feelings Teaching Guide",
                  "Feelings 의 영상을 본다.",
                  "Let’s sing the Feelings Song. 영상을 보며 노래, 율동",
                  "Happy / Sad / Angry / Sleeping"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel? ",
                  "Are you happy? ",
                  "I am happy",
                  "Circle your feeling. ",
                  "Good job! "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-4",
               script:"01",
               page:"12",
               sentences:[
                  "Heavy Vegetables Teaching Guide",
                  "Heavy Vegetables 제목을 말한다.",
                  "Which one is heavy? ",
                  "Let’s measure the vegetables. ",
                  "Look at the scales. ",
                  "Heavy Vegetables 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "The (lettuce) is (5). The (carrot) is (3). ",
                  "Which one is heavy? ",
                  "The (lettuce) or the (carrot)? ",
                  "The (lettuce) is heavy.",
                  "Circle the heavy vegetable.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "X Says x. Teaching Guide",
                  "X Says x. 제목을 말한다.",
                  "Let’s chant: X Says x. 영상을 본다.",
                  "Letter X 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
                  "What letter is this? / Big X and little x.",
                  "Trace the big X and little x.",
                  "X is for fox. / X is for ox. / X is for box."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 한다.",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"11",
               sentences:[
                  "Say the name of each picture X x Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, X 로 끝나는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures ending with x. ",
                  "Fox end with x. Color it./ Box end with x. Color it.",
                  "Toaster starts with t./ Ox end with x. Color it."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures ending with x. ",
                  "Good job! 색칠 활동으로 마무리한다.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Phonics Let’s sing: Friendship Song. Teaching Guide",
                  "Friendship Song.의 영상을 본다.",
                  "Let’s sing: Friendship Song. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "One little heart to give away. ",
                  "One little heart for you today. ",
                  "“I love you” is what I say. ",
                  "I’ll give one to my friend today. ",
                  "Good job singing.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s Recycle Teaching Guide",
                  "Let’s Recycle 제목을 말한다.",
                  "This is trash. ",
                  "We can recycle paper and plastics. ",
                  "Can you find (paper / plastics)?",
                  "Match the trash. ",
                  "Let’s Recycle 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Put the stickers on the matching bins. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Let’s chant X Says x. Teaching Guide",
                  "Let’s chant: X Says x. 영상을 본다.",
                  "Letter X 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
                  "What letter is this? / Big X and little x.",
                  "Let’s chant: X Says x. 영상을 본다.",
                  "X is for fox. / X is for ox. / X is for box."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Y Says y. Teaching Guide",
                  "Y Says y. 제목을 말한다.",
                  "Let’s chant: Y Says y. 영상을 본다.",
                  "Letter Y 가 무슨 소리가 날까요? (‘ㅕ' 소리가 난다.)",
                  "What letter is this? / Big Y and little y.",
                  "Trace the big Y and little y. ",
                  "Y is for yogurt. / Y is for yarn. / Y is for yacht."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 한다.",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Say the name of each picture Y y Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, Y 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with y.",
                  "Yogurt starts with y. Color it. / Yarn starts with y. Color it.",
                  "Vest starts with v. / Yacht starts with y. Color it."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture.",
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with y. ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Build the Cup Tower Teaching Guide",
                  "Build the Cup Tower 제목을 말한다.",
                  "Look at the picture. ",
                  "Can you make the same towers? ",
                  "build, cup, down, left, move, right, stack, tower, up ",
                  "Now try making your own cup tower with your friends.",
                  "Take a picture and paste it. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Build the Cup Tower 영상을 보고 상호작용한다.",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민지, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Phonics Let’s chant Y Says y. Teaching Guide",
                  "Let’s chant: Y Says y. 영상을 본다.",
                  "Letter Y 가 무슨 소리가 날까요? (‘ㅕ' 소리가 난다.)",
                  "What letter is this? / Big Y and little y.",
                  "Y is for yogurt. / Y is for yarn. / Y is for yacht.",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "Z Says z. Teaching Guide",
                  "Z Says z. 제목을 말한다.",
                  "Let’s chant: Z Says z. 영상을 본다.",
                  "Letter Z 가 무슨 소리가 날까요? (‘즈' 소리가 난다.)",
                  "What letter is this? / Big Z and little z.",
                  "Trace the big Z and little z. ",
                  "Z is for zucchini. / Z is for zebra. / Z is for zipper."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "쓰는 것과 스티커 활동을 한다.",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"1",
               sentences:[
                  "Say the name of each picture Z z Teaching Guide",
                  "활동을 해볼까요?",
                  "각 그림의 이름을 말해보고, Z 로 시작하는 그림을 색칠한다.",
                  "Say the name of each picture. 영상을 본다.",
                  "Color the pictures starting with z.",
                  "Zucchini starts with Z. Color it. / Zebra starts with Z. Color it.",
                  "yogurt starts with Y / Zipper starts with Z. Color it."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the name of each picture. ",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Color the pictures starting with z. ",
                  "색칠 활동하는 것으로 마무리한다.",
                  "Good job!",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "Let’s pick up the trash! ",
                  "Find the hidden things. ",
                  "Hidden Pictures 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find the hidden things.",
                  "Bread /doughnut / muffin /rice",
                  "Bottle / box / can / paper",
                  "아이들이 함께 찾아보고 마무리한다. ",
                  "Good job!"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Farmers의 의미",
                  "농부는 씨를 심어요.",
                  "비가 내리기 시작해요.",
                  "식물이 자라기 시작해요.",
                  "잎이 제게 웃어요.",
                  "농부는 수확을 해요."
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move the Farmer Teaching Guide",
                  "‘Let’s Move the Farmer 제목을 말한다.",
                  "The farmer wants to plant the seeds. ",
                  "Let’s move the farmer. ",
                  "Start / Right / Left / Up / Down / End",
                  "Put the sticker on the circle. "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s Move the farmer 영상을 보고 상호작용한다.",
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민지, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Farmers Teaching Guide",
                  "Song: The Farmers 제목을 말한다.",
                  "The Farmers 영상을 본다.",
                  "Let’s sing with motions. 모두 함께 노래한다.",
                  "노래 가사를 알려주며 함께 노래를 만들어 본다.",
                  "Good job! Singing"
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Musical Kitchen Teaching Guide",
                  "Musical Kitchen 제목을 말한다.",
                  "Let’s make music with the things in the kitchen? ",
                  "Can you make soft or loud sounds? ",
                  "Make sounds softly! ",
                  "Make sounds loudly! "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let’s hit the (bottle/cutting board/tray) with the utensils. ",
                  "Let’s make some sounds. ",
                  "Circle what you want to use. ",
                  "Can you hear the sounds? ",
                  "What sounds does it make? ",
                  "Good job! ",
                  "배운 영상을 부모님과 함께 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "02",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Hello, How Are You? Teaching Guide",
                  "Hello, How Are You? 말한다.",
                  "Let’s sing the Hello Song. ",
                  "Hello Song 영상을 보며 노래와 율동.",
                  "Put the stickers on the circles. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Hello, School! ",
                  "How are you? ",
                  "Hello, teacher! ",
                  "Good job! singing ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 본다.",
                  "What’s the weather like today? ",
                  "손을 눈썹 밑으로 가며, (경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
                  "Look out the window. 가리키며, 말한다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny ",
                  "머리위로 두 손을 마주잡으며, sunny 말한다. ",
                  "It is cloudy ",
                  "양손으로 구름모양으로 만들며, cloudy 말한다.",
                  "It is rainy ",
                  "양손으로 비가 내리듯 손가락을 움직이며, rainy 말한다. ",
                  "It is windy ",
                  "양손으로 바람이 부는 동작 표현), windy 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4 ",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "What do you see? ",
                  "아이) 앵무새, 로봇, 쿠키 자, 강아지, 고양이… 라고 말한다. ",
                  "Let’s Look at the Cover 영상을 보면서 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s look at the picture. ",
                  "There is a cookie jar. ",
                  "Do you see ",
                  "(Geneus/ Robby/ Cricket/ Catty/ Doggy/ a cookie jar)? ",
                  "Circle all the pictures.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"5",
               sentences:[
                  "What are they doing? ",
                  "Are they (having a party/playing/crying)? ",
                  "They are having a party. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Draw your idea.",
                  "Surprise!",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Catty* stole the cookies from the cookie jar. ",
                  "* Cricket / Robby / Geneus ",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "Put the matching stickers on the circles.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine How do you feel today? Teaching Guide",
                  "Feelings 영상을 보며 노래와 율동.",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "happy / sad / angry / surprised ",
                  "Are you surprised? ",
                  "I am surprised.",
                  "Circle the picture. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Where is the Cookie Jar? Teaching Guide",
                  "Where is the Cookie Jar? 말한다.",
                  "Is It on/under the table? ",
                  "It is under the table. ",
                  "Is the cookie jar on/under the kitchen counter? ",
                  "It is on the kitchen counter."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "The cookie jar is on the kitchen counter. ",
                  "Put (something) on/under/in front of/behind (a place). ",
                  "The cookie jar is in front of the computer. ",
                  "Where is the Cookie Jar? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Put the cookie jar stickers on the matching place. ",
                  "Cookie Jar 스티커 활동.",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작하면서 노래한다.",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar? 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. ",
                  "(소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이)"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행 (아는 아이가 있어도…)",
                  "해당 요일을 클릭 하면, 소리가 나온다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? ",
                  "깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리 (선생님도 같이 한다)",
                  "해당하는 날을 클릭 한다. 다른 날 , 확장 활동 시",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Calendar 활동의 목적: Daily Routine으로 활용",
                  "날, 수 에 대한 개념을 알 수 있다.",
                  "월, 주간, 날짜, 기분, 날씨, 인사",
                  "각 부분을 루틴 활동으로 활용한다. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Alphabet Song. Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 한 글자씩 가리키며 노래한다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s sing the Alphabet Song. ",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Short a. Teaching Guide",
                  "Short a. 말한다.",
                  "Let’s sing: Short a. 영상을 본다.",
                  "Short a ('애' 소리가 난다.)",
                  "Short a says / a /",
                  "“a” can , “a” pan , “a” ham , “a” jam"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Say the words (/c/-/a/-/n/can), pan, ham, jam. ",
                  "Put the matching stickers on the circles. ",
                  "Good job! 스티커 활동한다. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Let’s say the word and find the first letter. A a Teaching Guide",
                  "Let’s say the word and find the first letter. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letter to complete the word.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "My Cookie Jar Teaching Guide",
                  "My Cookie Jar 말한다.",
                  "My Cookie Jar 영상을 본다.",
                  "Let’s draw cookies in the cookie jar. ",
                  "This is your cookie jar. ",
                  "Draw how many cookies you want. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요)"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "I want some cookies. ",
                  "I want five cookies.",
                  "Count your cookies and write the number. ",
                  "One, two, three, four, five.",
                  "There are five cookies in my cookie jar.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 한 글자씩 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s Share the Cookies Teaching Guide",
                  "Let’s Share the Cookies 말한다.",
                  "How many cookies can Geneus have? ",
                  "Here are cookies. ",
                  "Let’s count. ",
                  "One, two, three, four, five, six, seven, eight, nine, ten."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Geneus, doggy, catty, robby, cricket.",
                  "There are five characters. ",
                  "We have 10 cookies and there are 5 of us. ",
                  "How many cookies can (Geneus) have?",
                  "Let’s draw the cookies until everyone has 2 cookies. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s Share the Cookies 영상, 상호 작용.",
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Draw the cookies on the dishes. ",
                  "Write the number in the box. ",
                  "Geneus has (2) cookies. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Short a. Teaching Guide",
                  "Short a. 말한다.",
                  "Let’s sing: Short a. 영상을 본다.",
                  "Short a. ('애' 소리가 난다.)",
                  "Short a says / a /",
                  "“a” pan , “a” can , “a” ham , “a” jam",
                  "Say the words (/c/-/a/-/n/can), pan, ham, jam. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Sight Words: this, a Teaching Guide",
                  "Sight Words: this, a 말한다.",
                  "Let’s chant: The School. 영상을 본다.",
                  "What do you see in the picture? ",
                  "This is a ",
                  "(teacher / crayon / table / book / backpack / classroom). "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Listen and find the pictures.",
                  "Find the picture. This is a book.",
                  "Find the picture. This is a back pack. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words this and a? Teaching Guide",
                  "Can you find the words this and a? 말한다.",
                  "Can you find the words this and a? 영상을 본다.",
                  "Circle and write the words. (this, a)"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  " 민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "(들어가 앉으세요) ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "The Alphabet Song Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 한 글자씩 가리키며 노래한다.",
                  "Do you know the ABC song? "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "Sharing is Caring Teaching Guide",
                  "Sharing is Caring 말한다.",
                  "Share with friends! ",
                  "Talk about the things you can and cannot share. ",
                  "Share / Can’t share ",
                  "함께 사용할 수 있는 것과 없는 것 찾기. "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "(들어가 앉으세요) "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Circle things you can share. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"21",
               sentences:[
                  "Shape Patterns Teaching Guide",
                  "Shape Patterns 말한다.",
                  "What comes next?",
                  "circle / heart / triangle / square ",
                  "Shape Patterns 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "(누가 나와서 해볼래요?)",
                  "Raise your hands. ",
                  "(누가 손들어 볼까요?)",
                  "민호, Come to the front. ",
                  "(민호가 해 볼까요?)",
                  "Go back to your seat ",
                  "(들어가 앉으세요) ",
                  "Good job! 하이파이브!!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Complete the patterns. ",
                  "What comes next?",
                  "Put the matching stickers on the circles.",
                  "Draw shapes and make your own pattern. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  "Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Simon Says… Teaching Guide",
                  "Simon Says… 말한다.",
                  "Let’s play a game. ",
                  "Listen carefully and follow what the teacher said. ",
                  "Put the stickers on the circles."
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "\"사이몬 says\" 라고 말이 들리면 움직이도록 해요.",
                  "\"사이몬 says\" 라는 말이 안 들리면 움직이면 안돼요.",
                  "Up / Down / Left / Right / say (Hi) / Hop",
                  "Listen carefully and move. ",
                  "Put the stickers on the circles when you are done.",
                  "Good job! (How to play: 참조)",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Who stole the cookies from the cookie jar? ",
                  "Who, me? ",
                  "Yes, you! ",
                  "Not me! ",
                  "Then, who? ",
                  "Catty stole the cookies from the cookie jar. ",
                  "Cricket/Robby/Geneus ",
                  "Surprise!"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "",
                  "P14-15",
                  "08PA301-414-01-1",
                  "Let’s Make Bubbles Teaching Guide",
                  "Let’s Make Bubbles 말한다.",
                  "Let’s Make Bubbles 영상을 본다.",
                  "Let’s make bubbles with a spatula. ",
                  "Can you make bubbles with a spatula? "
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Put the bubble stickers on the circles. ",
                  "Draw bubbles. ",
                  "What shape is the bubbles?",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
                  "Song: Who Stole the Cookies from the Cookie Jar? 말한다.",
                  "Who Stole the Cookies from the Cookie Jar? 영상.",
                  "Let’s sing with motions. 동작, 노래",
                  "Who, me? Yes, you! Not me! Then, who?",
                  ".Let‘s sing together. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"01",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Feelings Teaching Guide",
                  "Let’s sing the Feelings Song. ",
                  "How do you feel? ",
                  "happy / sad / angry / surprised "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s draw different feelings. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song. 영상을 본다.",
                  "What’s the weather like today?",
                  "Look out the window."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny, 말한다. ",
                  "It is cloudy 말한다.",
                  "It is rainy 말한다. ",
                  "It is windy 말한다.",
                  "Circle the picture."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "What do you see? ",
                  "아이) 소, 돼지, 닭 병아리, 소녀….라고 말한다. ",
                  "맞아요, 잘 했어요.",
                  "These are farm animals. ",
                  "This is a (chicken/chick/cow/girl/pig). ",
                  "Let’s Look at the Cover 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Do you see (an egg/a chicken/a chick/a girl)? ",
                  "Circle the pictures. ",
                  "Yes, I see (an egg/a chicken/a chick/a girl). ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Guess what she wants. (옆에 그림을 보면서…. )",
                  "There is a girl. ",
                  "She wants something. ",
                  "Do you think she wants (milk / eggs / water / cookies)? ",
                  "She wants (eggs).",
                  "Draw your idea. ",
                  "Excellent! "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
                  "Let’s sing with motions. ",
                  "egg, tea, three "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Write the word egg and put the matching stickers on the circles.",
                  "Egg 쓰기와 스티커 활동한다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine ",
                  "How do you feel today? Teaching Guide",
                  "Feelings 영상을 보며 노래와 율동하기. ",
                  "How do you feel today? ",
                  "How do you feel? ",
                  "happy / sad / angry / surprised ",
                  "I am surprised",
                  "Circle the picture. ",
                  ""
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Sound it Out Teaching Guide",
                  "Sound it Out 말한다.",
                  "Let’s listen and say the sound. ",
                  "Me, tea, three.",
                  "Can you say “me,” “tea,” and “three”?",
                  "Rhymes are the same sounds. "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let’s play a game. ",
                  "Raise your hands when you hear the words ",
                  "“me,” “tea,” or “three.” ",
                  "Circle the words me, tea, and three. ",
                  "Sound it Out 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Can you make animal sounds? ",
                  "Say the sounds and put the stickers on the circles. ",
                  "chick, hen, rooster, cluck, cock-a-doodle-doo ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar? 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. ",
                  "(소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이)"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행 (아는 아이가 있어도…)",
                  "해당 요일을 클릭 하면, 소리가 나온다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? ",
                  "깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리 (선생님도 같이 한다)",
                  "해당하는 날을 클릭 한다. 다른 날 , 확장 활동 시",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Short a. 말한다.",
                  "Let’s sing: Short a. 영상을 본다.",
                  "Short a ('애' 소리가 난다.)",
                  "Short a says / a /",
                  "“a” pan, “a” can, “a” ham, e” hen “a” jam",
                  "pan, ham, jam. ",
                  "Circle all the words that have short a. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Short e. Teaching Guide",
                  "Short e. 말한다.",
                  "Let’s sing: Short e. 영상을 본다.",
                  "Short e (‘에' 소리가 난다.)",
                  "Short e says / e /",
                  "“e” hen, “e” ten, “e” web, “e” net"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Say the words",
                  "Put the matching stickers on the circles.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Let’s say the word and find the first letter. E e Teaching Guide",
                  "Let’s say the word and find the first letter. 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Go back to your seat ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letter to complete the word.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching ”G”uide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Shapes Teaching Guide",
                  "Shapes 말한다.",
                  "These are Easter eggs.",
                  "circle, square, triangle, oval. ",
                  "What shape do you see?",
                  "They are oval.",
                  "Circle the answer."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Shapes 영상을 보고 상호작용한다.",
                  "Look at the pictures. ",
                  "Pizza, egg, window, book, Valentine’s card, football.",
                  "What is oval? ",
                  "Egg. Football.",
                  "Circle things that are oval.",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "What Time Is It? Teaching Guide",
                  "What Time Is It? 말한다.",
                  "Let’s look at the clocks. ",
                  "There is a little hand and a big hand. ",
                  "The little hand is pointing to 3. ",
                  "The big hand is pointing to 6. "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What time is it? ",
                  "It is 3:30 (three thirty.)",
                  "What Time Is It? 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Write and say the time on the clock. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Riddle: What Am I? Teaching Guide",
                  "Riddle: What Am I? 말한다.",
                  "What Am I? 영상을 본다.",
                  "Let’s find the answer."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Look at the picture",
                  "I am oval. / I have a shell. ",
                  "I have an egg yolk and egg whites. ",
                  "What am I?",
                  "I am a(n) … Egg 쓰기",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "What can we get from the animals? ",
                  "What can we get from the animals? 제목을 말한다.",
                  "Put the matching stickers on the circles. ",
                  "We can get eggs./ We can get honey. ",
                  "Draw the picture and write the word. ",
                  "We can get ______.",
                  "Good job! 쓰기와 스티커 활동한다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Tangram Teaching Guide",
                  "Tangram 말한다.",
                  "Tangram 영상을 본다.",
                  "Let’s create an animal.",
                  "탱 그램 카드를 준비한다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Can you make a cat? ",
                  "Let’s make a cat",
                  "cat with the 7 pieces of the tangram. ",
                  "Put the sticker on the circle. ",
                  "Excellent!"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s create an animal. ",
                  "Let’s make a triangle.",
                  "Can you make a chicken? ",
                  "chicken with the 7 pieces of the tangram. ",
                  "Put the sticker on the circle.",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"02",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Sight Words: the, jump Teaching Guide",
                  "Sight Words: the, jump 말한다.",
                  "Let’s chant: The Animal Jumps. 영상을 본다.",
                  "The (dog / rabbit / sheep / cat / horse) jumps. ",
                  "The cow jumps over the moon. "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What do you see in the picture? ",
                  "The (dog / rabbit / sheep / cat / horse) jumps. ",
                  "The cow jumps over the moon. ",
                  "Put the matching stickers on the circles. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words the and jump? Teaching Guide",
                  "Can you find the words the and jump? 말한다.",
                  "Can you find the words the and jump? 영상을 본다.",
                  "Can you find the words the and jump? ",
                  "Circle and write the words."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "The Alphabet Song Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "Who Am I? Teaching Guide",
                  "Who Am I? 말한다.",
                  "Let’s guess what animal it is. ",
                  "I am ….",
                  "a rabbit / a dog / a cow / an elephant "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Find and circle the matching animal. ",
                  "I have 4 legs. ",
                  "I have two big ears. ",
                  "I have a trunk. ",
                  "I can walk. ",
                  "I am …. an elephant ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching ”G”uide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"21",
               sentences:[
                  "‘The’ and ‘Jump’ Teaching Guide",
                  "‘The’ and ‘Jump’ 말한다.",
                  "There are sight words, ‘the’ and ‘jump’ ",
                  "‘The’ and ‘Jump’ 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Follow the directions. ",
                  "Circle the word The. ",
                  "Box the word jump. "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "The cow jumps over the moon. ",
                  "The sheep jumps over the fence. ",
                  "The dog jumps over the fence. ",
                  "The cat jumps over the fence. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Chick, chick, chick, chick, chicken의 의미",
                  "꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요.",
                  "꼬 꼬 꼬 꼬 닭",
                  "차와 함께 달걀 하나 먹고 싶어요.",
                  "부활절 이후로는 달걀을 한 개도 먹지 못했어요.",
                  "지금 세시 반이거든요.",
                  "그러니까 꼬 꼬 꼬 꼬 닭",
                  "나한테 작은 달걀 하나만 낳아주세요."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move the Girl Teaching Guide",
                  "Let’s Move the Girl 말한다.",
                  "The girl wants to have an Easter egg. ",
                  "Right / Left / Up / Down",
                  "Let’s Move the Girl 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Put the sticker on the circle. ",
                  "Move right. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  " Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Chick Chick Chick Chick Chicken Teaching Guide",
                  "Song: Chick Chick Chick Chick Chicken 말한다.",
                  "Song: Chick Chick Chick Chick Chicken 영상.",
                  "Let’s sing with motions. ",
                  "egg, tea, three ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song Teaching Guide",
                  "The ABC Song. 영상",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 한 글자씩 가리키며 노래한다.",
                  "Do you know the ABC song? "
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"02",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "What Day Is It Today? Teaching Guide",
                  "Let’s sing the Days of the Week Song. 영상을 보며 노래와 율동.",
                  "Write the numbers. ",
                  "Draw a star on today’s date. ",
                  "Sunday / Monday / Tuesday / Wednesday / Thursday / Friday / Saturday ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상을 본다.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine ‘The Weather’ Teaching Guide",
                  "The Weather Song. 영상을 본다.",
                  "What’s the weather like today?",
                  "Look out the window."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny, 말한다. ",
                  "It is cloudy 말한다.",
                  "It is rainy 말한다. ",
                  "It is windy 말한다.",
                  "Circle the picture.",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "What do you see? ",
                  "아이) 빵, 계란, 버터… 말한다. ",
                  "맞아요, 잘했어요."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "There are buns in the basket. ",
                  "There are eggs and pennies. ",
                  "Let’s Look at the Cover 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Do you see (cookies / buns / pennies / eggs)?",
                  "Circle the pictures. ",
                  "Yes. I see buns, pennies, and eggs. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Look at the buns! ",
                  "bun / cross ",
                  "The buns have crosses. ",
                  "Let’s make buns. These are buns."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "The buns have crosses./ ",
                  "Crosses./ Crosses./ Crosses.",
                  "Can you draw shapes on the buns? ",
                  "Decorate the buns with shapes. ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상을 본다.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Write the word buns and put the matching stickers on the circles.",
                  "Good job! Singing",
                  "buns 쓰기와 스티커 활동한다. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Feelings Teaching Guide",
                  "Let’s sing the Feelings Song. Feelings 영상을 보며 노래와 율동.",
                  "How do you feel? ",
                  "happy / sad / angry / surprised ",
                  "Circle the picture. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "My House Teaching Guide",
                  "My House 말한다.",
                  "Say the name of the rooms. ",
                  "Look at the picture. ",
                  "This is a house. ",
                  "There are many rooms."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "This is a bathroom. I see a bathtub and a sink.",
                  "This is a bedroom. I see a bed and a wardrobe. ",
                  "This is a living room. I see a sofa and TV.",
                  "This is a kitchen. I see a table and chairs. ",
                  "I see daddy in the kitchen.",
                  "My House 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Where is Daddy? Find and circle Daddy. ",
                  "Can you draw your room? ",
                  "Put the matching sticker on the circle. ",
                  "Is this a (bathroom / bedroom / living room / kitchen)? ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상을 본다.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar? 영상을 본다.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. ",
                  "(소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이)"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이0",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행 (아는 아이가 있어도…)",
                  "해당 요일을 클릭 하면, 소리"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? ",
                  "깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리 (선생님도 같이 한다)",
                  "해당하는 날을 클릭 한다. 다른 날 , 확장 활동 시",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Short e. Teaching Guide",
                  "Short e. 말한다.",
                  "Let’s sing: Short e. 영상을 본다.",
                  "Short e (‘에' 소리가 난다.)",
                  "Short e says / e /",
                  "“e” hen, “e” ten, “e” web, “e” net"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Short I. Teaching Guide",
                  "Short I. 말한다.",
                  "Let’s sing: Short i. 영상을 본다.",
                  "Short i (‘이' 소리가 난다)",
                  "Short i says / i /",
                  "“I” bib / ”I” lip / ”I” pig / ”I”six "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Say the words. bib / lip / pig / six ",
                  "Put the matching stickers on the circles.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Let’s say the word and find the first letter.",
                  " I i Teaching Guide",
                  "Let’s say the word and find the first letter. ",
                  "영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letter to complete the word. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "The Alphabet Song Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Family Members Teaching Guide",
                  "Family Members 말한다.",
                  "This is a family. ",
                  "This is a (mommy/daddy/sister/brother/baby). ",
                  "Point to the (mommy). ",
                  "Family Members 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Look at the pictures. ",
                  "How many people are in the family? ",
                  "There are five people. ",
                  "Write the number in the box. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Let’s Buy Some Buns Teaching Guide",
                  "Let’s Buy Some Buns 말한다.",
                  "How much is one bun? ",
                  "It is one penny. ",
                  "Let’s Buy Some Buns 영상을 보고 상호 작용."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How much are four buns? ",
                  "They are four pennies. ",
                  "Draw four pennies in the box. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Make Five Game Teaching Guide",
                  "Make Five Game 말한다.",
                  "Make Five Game 영상을 본다.",
                  "Let’s play the Make Five Game. "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Can you make a Five Game.",
                  "Let’s make a Five.",
                  "When the music stops, get in a group of (five). ",
                  "Excellent!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "We need to make five things total. 말한다.",
                  "make five!",
                  "Draw how many you need to add. ",
                  "make five things total 영상",
                  "Draw the picture and write the word. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"01",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics ",
                  "Sight Words: the, jump Teaching Guide",
                  "Sight Words: the, jump 말한다.",
                  "Let’s chant: The Animal Jumps. 영상을 본다.",
                  "The (dog / rabbit / sheep / cat / horse) jumps. ",
                  "The cow jumps over the moon. ",
                  "Good job! singing."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Sight Words: is, my Teaching Guide",
                  "Sight Words: is, my 을 말한다.",
                  "Let’s chant: This Is My Family. 영상을 본다.",
                  "This is my….",
                  "(mommy / daddy / brother / sister/ grandma / grandpa). "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "What do you see in the picture? ",
                  "This is my….",
                  "(mommy / daddy / brother / sister/ grandma / grandpa). ",
                  "Put the matching stickers on the circles. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words is and my? Teaching Guide",
                  "Can you find the words is and my? 말한다.",
                  "Let’s chant : This Is My Family. 영상을 본다.",
                  "Can you find the words is and my? ",
                  "Circle and write the words. "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ ."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar? 영상.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. ",
                  "(소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이)"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이0",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행 (아는 아이가 있어도…)",
                  "해당 요일을 클릭 하면, 소리"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? ",
                  "깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리 (선생님도 같이 한다)",
                  "해당하는 날을 클릭 한다. 다른 날 , 확장 활동 시",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 한 글자씩 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"21",
               sentences:[
                  "Sight Word: my Teaching Guide",
                  "Sight Word: my 말한다.",
                  "This is my neighborhood. ",
                  "Sight Word: my 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Have the children find the same words and color them in. ",
                  "Color the houses that has the word “my.” ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Robby Teaching Guide",
                  "Let’s Move Robby 말한다.",
                  "Let’s Move the Girl 영상을 본다.",
                  "Right / Left / Up / Down",
                  "Robby wants to buy some buns. ",
                  "Put the sticker on the circle. "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Have the children move Robby by the using coding cards. ",
                  "Have them find the way to solve the problem. ",
                  "Hint: Move left. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns 영상",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"20",
               sentences:[
                  "Make Five Card Game Teaching Guide",
                  "Make Five Card Game 말한다.",
                  "Let’s play the Make Five Card Game. ",
                  "Can you make five (5) of the same fruit? ",
                  "make five things total 영상을 본다. "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "The player to collect all the cards wins. ",
                  "Have the children discuss all the numbers and fruits ",
                  "even though they are not the correct answers. ",
                  "(How to play: 참조) 카드게임으로 마무리",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Hot Cross Buns의 의미",
                  "핫 크로스 번!",
                  "핫 크로스 번!",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!",
                  "따님이 없으면, 아드님에게 주세요.",
                  "One a penny,",
                  "Two a penny,",
                  "핫 크로스 번!"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14-15",
               sentences:[
                  "Let’s Blow Bubbles Teaching Guide",
                  "Let’s Blow Bubbles 말한다.",
                  "Let’s blow bubbles with forks. ",
                  "Draw bubbles. ",
                  "Let’s Blow Bubbles 영상을 본다.",
                  "forks, bubble soap, soapy water "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Put the forks in the bubble soap.",
                  "Blow bubbles. ",
                  "Look at the bubbles. ",
                  "Do you see soapy water on the forks?"
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Can you blow bubbles with them? ",
                  "ladle / forks / spatula / spoon / whisk",
                  "Put the stickers in the correct box. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Hot Cross Buns Teaching Guide",
                  "Song: Hot Cross Buns 말한다.",
                  "Song: Hot Cross Buns 영상.",
                  "Let’s sing with motions.",
                  "bun, cross, hot, one, two, penny, son, daughter ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Let’s sing the Alphabet Song. (영상과 함께 노래)",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"03",
               week:"04",
               round:"03",
               scene:"hunt-4",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Colors Teaching Guide",
                  "Let’s sing the Colors Song. 영상을 보며 노래와 율동.",
                  "What colors do you like?",
                  "red / yellow / blue / green / orange ",
                  "Purple / pink / brown / black / white "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보며 노래와 율동.",
                  "What’s the weather like today?",
                  "Look out the window."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is rainy. rainy 말한다. ",
                  "It is windy. windy 말한다.",
                  "Circle the picture. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"3",
               sentences:[
                  "Colors Teaching Guide",
                  "Let’s sing the Colors Song. 영상을 보며 노래와 율동.",
                  "What colors do you like?",
                  "red / yellow / blue / green / orange ",
                  "Purple / pink / brown / black / white"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "Let’s color. ",
                  "I like…. (red) 내가 좋아하는 색 칠하기.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "",
                  "P4-5",
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "What do you see? )",
                  "아이) 성, 하트, 여왕… 말한다. ",
                  "맞아요, 잘 했어요.",
                  "This is the Queen of Hearts."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "I see hearts on her dress.",
                  "This is a castle. ",
                  "I see hearts on the flags.",
                  "This is a fountain. ",
                  "I see hearts on the fountain.",
                  "She lives in a castle.",
                  "Let’s Look at the Cover 영상을 보며 상호작용한다.",
                  "Do you see a (heart / chicken / castle / queen)?"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  " 민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "What do you see in the picture? ",
                  "Circle the pictures.",
                  "Yes. I see a … heart / chicken / castle / queen"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Where does the queen live?",
                  "I live in a… (옆에 그림을 보면서…. )",
                  "Library / castle / fire station / hospital). ",
                  "Where does she live? ",
                  "She lives in a castle. ",
                  "Draw a picture. ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Write the word Hearts and put the matching stickers on the circles. ",
                  "Hearts 쓰는 것과 스티커 붙이는 활동을 한다.",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine Feelings Teaching Guide",
                  "Feelings 영상을 본다.",
                  "Let’s sing the Feelings Song.영상을 보며 노래와 율동. ",
                  "How do you feel today? ",
                  "Draw your feeling. "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "How do you feel?",
                  "Are you happy? ",
                  "I am happy ",
                  "Good job! Singing ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "",
                  "P8-9",
                  "Things to Do Teaching Guide",
                  "Things to Do 말한다.",
                  "Where does the knight go? ",
                  "Things to Do 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Where does he (buy tarts)? At the (bakery). ",
                  "Put the matching stickers on the circles. ",
                  "Things to Do… buy tarts / bakery ",
                  "Things to Do… buy fruits / market "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Things to Do… return books / library ",
                  "Things to Do… send mail / post office ",
                  "Things to Do… see the doctor / hospital ",
                  "Things to Do… see the police officer / police station ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar? 영상.",
                  "Q를 누른다. what month is it? ",
                  "(소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 March를 누른다. ",
                  "(소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이)"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이0",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "아이들이 잘 모르면 선생님이 혼자 진행 (아는 아이가 있어도…)",
                  "해당 요일을 클릭 하면, 소리"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? ",
                  "깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리 (선생님도 같이 한다)",
                  "해당하는 날을 클릭 한다. 다른 날 , 확장 활동 시",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Short I. Teaching Guide",
                  "Short I. 말한다.",
                  "Let’s sing: Short i. 영상을 본다.",
                  "Short i (‘이' 소리가 난다)",
                  "Short i says / i /",
                  "“I” bib / ”I” lip / ”I” pig / ”I”six",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Short o. Teaching Guide",
                  "Short o. 제목을 말한다.",
                  "Let’s sing: Short o. 영상을 본다.",
                  "Short o. (‘오' 소리가 난다)",
                  "Short o says / h / o / p / hop",
                  "“o” hop /”o” pot / “o” mop / “o” hot "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Say the words hop /pot / mop / hot ",
                  "Put the matching stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Let’s say the word and find the first letter.",
                  "O o Teaching Guide",
                  "Let’s say the word and find the first letter. ",
                  "영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letter to complete the word.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  " Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Phonics Let’s write big G and little g. ",
                  "Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Let’s write big G and little g. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Favorite Jobs Teaching Guide",
                  "Favorite Jobs 말한다.",
                  "What do you want to be?",
                  "Do you want to be a",
                  "(queen/king/knight/soldier/police officer/doctor)? ",
                  "Point to the (queen). ",
                  "Favorite Jobs 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Come up and color the boxes.",
                  "How many children want to be a (queen)? ",
                  "Let’s count. ",
                  "There are (five) people. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Who Has More? Teaching Guide",
                  "Who Has More? 말한다.",
                  "Let’s play a game. ",
                  "Who Has More? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who has the highest number? ",
                  "Circle the winner. ",
                  "I have the highest number. (How to play: 참조)",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "What Is Wrong? Teaching Guide",
                  "What Is Wrong? 말한다.",
                  "Let’s talk about each picture.",
                  "What is wrong? ",
                  "boy, clock, flower, school bus, wrong. "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "What’s wrong with the flower/clock/boy/bus? ",
                  "It’s upside down! ",
                  "There are no hands.",
                  "The boy is brushing his teeth with a fork.",
                  "The car doesn’t have wheels! ",
                  "What Is Wrong? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"05",
               page:"",
               sentences:[
                  "What is wrong in the pictures? ",
                  "Draw a heart around what’s wrong. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics Sight Words: is, my Teaching Guide",
                  "Sight Words: is, my 말한다.",
                  "Let’s chant: This Is My Family. 영상을 본다.",
                  "This is my",
                  "(mommy / daddy / brother / ",
                  "sister/ grandma / grandpa). "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Sight Words: I, see Teaching Guide",
                  "Sight Words: I, see 말한다.",
                  "Let’s chant: I See Fruits and Vegetables. 영상을 본다.",
                  "I see a ",
                  "(tomato/strawberry/pineapple/carrot/pepper/watermelon). "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "What do you see in the picture? ",
                  "I see a ",
                  "(tomato/strawberry/pineapple/",
                  "carrot/pepper/watermelon). ",
                  "Put the matching stickers on the circles. ",
                  "영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words I and see? Teaching Guide",
                  "Can you find the words I and see? 말한다.",
                  " Let’s chant: I See Fruits and Vegetables. 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Can you find the words I and see? ",
                  "Circle and write the words. (I / see)",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet 한 글자씩 가리키며 노래한다.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "",
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "To the House Teaching Guide",
                  "To the House 말한다.",
                  "Can you help the boy find the way home? ",
                  "To the House 영상을 보며 상호작용한다.",
                  "Help! / I’m lost. "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Can you help the boy find the way home? ",
                  "Put the stickers on the circles. ",
                  "Start /Right /Left /Up / Down /Start /End ",
                  "Let’s move. ",
                  "Use the coding cards to find the way to the house. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "How to Brush My Teeth Teaching Guide",
                  "How to Brush My Teeth 말한다.",
                  "How to Brush My Teeth 영상을 본다.",
                  "Brush / gargle"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How do you brush your teeth? ",
                  "Put the number stickers on the circles in order. ",
                  "Trace the words. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상을 본다.",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "The Queen of Hearts의 의미",
                  "하트 여왕이 타르트를 만들었어요.",
                  "하트 기사가 타르트를 훔쳤어요.",
                  "하트 왕이 타르트가 너무 먹고 싶어서,",
                  "타르트를 가져오라고 했어요!"
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "",
                  "P14-15",
                  "Mirror Puzzle Teaching Guide",
                  "Mirror Puzzle 말한다.",
                  "Place the mirror on the line. ",
                  "Draw and write what you see.",
                  "What is it? ",
                  "Can you guess what it is? ",
                  "Use the mirror."
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "What do you see in the mirror? ",
                  "Do you see a heart? ",
                  "Do you see the word heart? ",
                  "Let’s mirror. ",
                  "Draw each shape. ",
                  "Excellent! "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: The Queen of Hearts Teaching Guide",
                  "Song: The Queen of Hearts 말한다.",
                  "Song: The Queen of Hearts 영상",
                  "Let’s sing with motions. ",
                  "hearts, king, queen, tarts ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"04",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "What Month Is It? Teaching Guide",
                  "Let’s sing the Month Song. 영상을 보며 노래와 율동.",
                  "",
                  "Special days. What Month Is It?",
                  "January /February /March /April /May /June ",
                  "July / August / September / ",
                  "October /November / December",
                  "Good job! singing"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Circle and write what month it is.",
                  "It is … (쓰는 것으로 마무리)",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상, 노래",
                  "What’s the weather like today?",
                  "Look out the window. "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny 말한다. ",
                  "It is rainy. rainy 말한다. ",
                  "It is windy. windy 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture. "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "",
                  "P4-5",
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 말한다.",
                  "What do you see?",
                  "아이) 팬케이크, 시럽, 버터, 포크, 빵 먹는 아이들…라고 말한다. ",
                  "맞아요, 잘했어요.",
                  "There is a girl and a boy. "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "They want to eat pancakes.",
                  "There is syrup on the table. ",
                  "There is butter on the table.",
                  "Let’s Look at the Cover 영상을 보며 상호작용.",
                  "Do you see (pancakes / butter / syrup / sugar)?",
                  "Yes. I see (pancakes / butter / syrup)",
                  "Circle the pictures."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "What do you want to eat? ",
                  "I want to eat… ",
                  "Apples / hotdogs /chicken / kimbap ",
                  "You want...",
                  "Draw your favorite food. ",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Write the word Five and put the matching stickers on the circles. ",
                  "Five 쓰는 것과 스티커 활동을 한다.",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine Feelings Teaching Guide",
                  "Let’s sing the Feelings Song. 영상을 보며 노래와 율동.",
                  "How do you feel? ",
                  "happy / sad / angry",
                  "Are you happy? ",
                  "I am happy.",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Musical Hugs Teaching Guide",
                  "Musical Hugs 말한다.",
                  "Let’s play Musical Hugs! 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s play musical hugs. ",
                  "Sing a song and move around. ",
                  "When the song stops, listen to the number. ",
                  "Hug in groups of (two). ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"9",
               sentences:[
                  "Flip the Pancakes Teaching Guide",
                  "Flip the Pancakes 말한다.",
                  "Let’s make pancakes. ",
                  "When do we flip the pancakes? ",
                  "Do you see the bubbles? ",
                  "It’s time to flip the pancakes. ",
                  "Flip the Pancakes 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s make pancakes. ",
                  "Color the pancakes that has bubbles and count them.",
                  "Write the number of pancakes you flipped. ",
                  "I flipped ( 5 ) pancakes. ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"01",
               round:"03",
               scene:"hunt-4",
               script:"01",
               page:"9",
               sentences:[
                  "Daily Routine Colors Teaching Guide",
                  "Let’s sing the Colors Song. 영상을 보며 노래와 율동.",
                  "What colors do you like? ",
                  "This is….",
                  "blue / yellow / green / orange ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Short o. Teaching Guide",
                  "Let’s sing: Short o. 영상을 본다.",
                  "Short o. 말한다.",
                  "Short o. (‘오' 소리가 난다)",
                  "Short o says / h / o / p / hop",
                  "Say the words hop / pot / mop / hot ",
                  "Circle all the words that have short o.",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Short u. Teaching Guide",
                  "Short u. 말한다.",
                  "Let’s sing: Short u. 영상",
                  "Short u. (‘어' 소리가 난다)",
                  "Short u says / b / u / s / bus",
                  "“u” bus / “u” cup / “u” sun / “u” nut"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Let’s say the word and find the first letter. ",
                  "U u Teaching Guide",
                  "Let’s say the word and find the first letter. (영상, 상호작용)"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letter to complete the word.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Phonics Let’s write big I and little i.",
                  "Let’s write big I and little i. ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Jay Wants Pancakes. Teaching Guide",
                  "Jay Wants Pancakes. 말한다.",
                  "Cross out 5 pancakes. ",
                  "How many pancakes are left?",
                  "There are 8 pancakes on the table. ",
                  "I ate 5 pancakes.",
                  "Three pancakes are left."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your sea"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Come up and color the boxes. ",
                  "How many pancakes are left? ",
                  "Put the matching sticker on the circle.",
                  "I ate 5 pancakes. ",
                  "Three pancakes are left.",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13 ",
               sentences:[
                  "Phonics Alphabet Song. ",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "Do you know the Alphabet song?"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Phonics Let’s write big J and little j. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Be Fair and Share Teaching Guide",
                  "Be Fair and Share 말한다.",
                  "How can we share it? ",
                  "Be Fair and Share 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "How can we share it? ",
                  "Draw a line to share.",
                  "Trace the word. ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics I See Fruits and Vegetables. Teaching Guide",
                  "I See Fruits and Vegetables. 을 말한다.",
                  "Let’s chant: I See Fruits and Vegetables. 영상을 본다.",
                  "I see a … ",
                  "(tomato / strawberry / pineapple / ",
                  "carrot / pepper / watermelon). ",
                  "Goodbye ~ ",
                  "",
                  ""
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Sight Words: play, with Teaching Guide",
                  "Sight Words: play, with 말한다.",
                  "Let’s chant: Let’s Play! 영상을 본다.",
                  "What do you see in the picture?"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Play with a … ",
                  "(baseball / basketball / soccer ball /",
                  "golf ball / beach ball / tennis ball).",
                  "Circle the words. Who wants to try? ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words play and with? ",
                  "Teaching Guide",
                  "Can you find the words play and with? 말한다.",
                  "Let’s chant: Let’s Play! 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Can you find the words play and with? ",
                  "Circle and write the words. (play / with)",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics",
                  "The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 한 글자씩 가리키며 노래한다.",
                  "Let's sing the ABC song."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the ABC song?",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Plants Teaching Guide",
                  "Plants 말한다.",
                  "Let’s grow plants. ",
                  "These are plants. ",
                  "What do plants need to grow? ",
                  "Plants need the sun. ",
                  "Plants need water. ",
                  "Plants need dirt.",
                  "Put the matching stickers on the circles.",
                  "Plants 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Let’s name the parts of the plant.",
                  "Put the matching stickers on the circles. ",
                  "leaf / roots / stem",
                  "Trace the word stem. ",
                  "쓰는 것과 스티커 활동한다.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move the Boy Teaching Guide",
                  "Let’s Move the Boy 말한다.",
                  "The boy wants some pancakes. ",
                  "Start / End / Right / Left / Up / Down ",
                  "Let’s Move the Boy 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "The boy wants some pancakes. ",
                  "Put the stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"16-17",
               sentences:[
                  "Dots and Boxes Teaching Guide",
                  "Dots and Boxes 제목을 말한다.",
                  "Let’s make triangles. ",
                  "Can you draw a line from dot to dot? ",
                  "connect, dots, draw, line, triangle "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Do rock, scissors, paper! ",
                  "Take turns connecting dots. ",
                  "When you made a triangle, write your initial. ",
                  "Count how many triangles you made. "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Dots and Boxes 영상을 본다.",
                  "영상, 진행을 4명 정도 한다.(How to play: 참조)",
                  "Let’s play Dots and Boxes. ",
                  "Count how many triangles you made. ",
                  "Excellent! (활동 마무리)",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Five Crispy Pancakes의 의미",
                  "프라이팬에 있는 다섯 개의",
                  "바삭한 팬케이크,",
                  "뒤집고, 던지고, 받아 보세요.",
                  "제이가 와서 팬케이크에",
                  "설탕을 뿌려서 가져가네요."
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Five Crispy Pancakes Teaching Guide",
                  "Song: Five Crispy Pancakes 말한다.",
                  "Song: Five Crispy Pancakes 영상을 본다.",
                  "Let’s sing with motions. ",
                  "five / frying pan / pancakes ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"05",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Let’s Count Teaching Guide",
                  "Let’s sing the Numbers Song. 영상을 보며 노래.",
                  "Good job! singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상을 본다.",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보며 노래.",
                  "What’s the weather like today? ",
                  "Look out the window."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is rainy. rainy를 말한다. ",
                  "It is windy. windy를 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"3",
               sentences:[
                  "Let’s Count Teaching Guide",
                  "Let’s sing the Numbers Song. 영상을 보며 노래.",
                  "Special days.",
                  "How many people do you see? ",
                  "Write the numbers. ",
                  "숫자 쓰는 것으로 마무리",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
                  "Where are they from? (어느 나라인지 말한다)",
                  "아이) 한국, 중국, 미국, 독일 이라고 말한다. ",
                  "맞아요, 잘 했어요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "There are many children from all over the world. ",
                  "Where is (he/she) from? ",
                  "(He/She) is from (Korea). ",
                  "Let’s Look at the Cover 영상을 보며, 상호작용"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  " 민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "There are many children from all over the world. ",
                  "Where are they from? ",
                  "America / China / Germany / Korea ",
                  "Find the pictures. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Where are you from? ",
                  "I’m from ... (옆에 그림을 보면서…. )",
                  "Where are you from? ",
                  "I’m from ... (KOREA)",
                  "Color the flag and write the word. ",
                  "Excellent! ( 각 모양을 그리는 것으로 마무리)",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles.",
                  "Good job! Singing",
                  "스티커 붙이는 활동으로 마무리",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine One Little Finger. Teaching Guide",
                  "One Little Finger. 의 영상을 본다.",
                  "Let’s sing: One Little Finger. 영상을 보며 노래, 율동",
                  "주의집중 시킬 때 반복해서 노래한다.",
                  "Finger, ceiling, floor, head",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8",
               sentences:[
                  "Hello, Friends! Teaching Guide",
                  "Hello, Friends! 제목을 말한다.",
                  "Say hello! ",
                  "Hello, Friends! 영상을 보며 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Hello, Friends! ",
                  "Put the matching stickers on the circles. ",
                  "I’m from Korea. 안녕! ",
                  "I’m from America. Hello!",
                  "I’m from Germany. GUTEN TAG! ",
                  "I’m from China. 니 하오!",
                  "Excellent! 쓰는 것으로 활동 마무리",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상을 본다.",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Short u. Teaching Guide",
                  "Short u. 제목을 말한다.",
                  "Let’s sing: Short u. 영상을 본다.",
                  "Short u. 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
                  "Short u says / b / u / s / bus",
                  "“u” bus / “u” cup / “u” sun / “u” nut",
                  "Say the words bus / cup / sun / nut ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상을 본다.",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Long a. Teaching Guide",
                  "Long a. 제목을 말한다.",
                  "Let’s sing: Long a. 영상을 본다.",
                  "Long a. 가 무슨 소리가 날까요? (‘애' 소리가 난다)",
                  "Long a says a. Say the words:",
                  "(-/ane/ cane), (-/ape/ cape), (-/ate/ mate) ",
                  "Say the words cane / cape / mate",
                  "Put the matching stickers on the circles. ",
                  "Good job! 활동 마무리",
                  ""
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "What letters are missing? 영상으로 상호작용",
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Write the letters and say the words.",
                  "cane, cape, mate ",
                  "쓰는 활동을 보면서 마무리한다. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Let’s Guess and Count Teaching Guide",
                  "Let’s Guess and Count 제목을 말한다.",
                  "How many do you see? ",
                  "There are (pears/chestnuts/peanuts/apples). ",
                  "How many (pears) are there?",
                  "Guess the number. Count the number. ",
                  "Let’s Guess and Count 영상을 보고, 상호작용 한다."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many do you see? ",
                  "Count and write the numbers. ",
                  "Now let’s count. ",
                  "There are (eight) (pears). ",
                  "Good job! ( 활동 마무리)",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상을 본다.",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Dragon Pattern Teaching Guide",
                  "Dragon Pattern 제목을 말한다.",
                  "Let’s find the pattern. ",
                  "Green, pink, blue, red, green, pink, blue, red.",
                  "Dragon Pattern 영상으로 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Make your own pattern. (교사가 예를 들어준다)",
                  "Yellow, green, blue, yellow, green, blue.",
                  "I can make my own pattern.",
                  "Make your own pattern.",
                  "Good job! (색칠하는 것으로 마무리)"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Drawing Game Teaching Guide",
                  "Drawing Game 제목을 말한다.",
                  "Let’s play a game.",
                  "Listen carefully. ",
                  "circle, draw, heart, in, on, square, triangle, under"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Draw a (circle)in the middle. ",
                  "Draw a (triangle) on the (circle)",
                  "Draw a (square) under the (circle)",
                  "Draw a (heart)in the (square). ",
                  "What is it? "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "영상 진행을 4명 정도 한다.",
                  "Drawing Game 영상을 본다.",
                  "Let’s listen and draw.",
                  "What did you draw? ",
                  "사랑의 집을 그리는 것으로 마무리",
                  "Excellent!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상을 본다.",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics play, with Teaching Guide",
                  "Sight Words: play, with 을 말한다.",
                  "Let’s chant: Let’s Play! (영상을 본다)",
                  "Play with a … ",
                  "(baseball / basketball / soccer ball / golf ball / beach ball / tennis ball). ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Sight Words: come, to Teaching Guide",
                  "Sight Words: come, to를 말한다.",
                  "Let’s chant: Celebration. 영상을 본다.",
                  "Come to (the)…",
                  "(party / wedding / parade / festival / Oktoberfest / grandma’s house). ",
                  "What do you see in the picture? ",
                  "Come to (the)…",
                  "(party / wedding / parade / festival / Oktoberfest / grandma’s house). ",
                  "오늘 교실에서 배운 영상을 부모님과 같이 해보세요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words come and to? ",
                  "Can you find the words come and to? 제목을 말한다.",
                  "Circle and write the words. "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics Alphabet Song. Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. (2번 반복)",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
                  "Do you know the Alphabet song?"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "Hidden Pictures 영상을 본다.",
                  "Look! Thanksgiving parade! ",
                  "They celebrate together!"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "I see a parade.",
                  "I see people. / I see Children",
                  "I see balloons. / I see foods.",
                  "ice cream / milk / yogurt / cheese ",
                  "lollipop /bird / butterfly / Hello! ",
                  "Find the hidden things. ",
                  ""
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Some things are hidden.",
                  "These are the hidden things.",
                  "ice cream / milk / yogurt / cheese ",
                  "lollipop /bird / butterfly / Hello! ",
                  "Find the hidden things. ",
                  "찾는 것으로 마무리한다.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"22",
               sentences:[
                  "Make a Flag Teaching Guide",
                  "Make a Flag 제목을 말한다.",
                  "Solve the puzzle to make a flag. "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "활동을 해볼까요?",
                  "Circle the pieces that belong to the flag. ",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 September 를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Autumn Festival의 의미",
                  "우리는 한국 사람입니다.",
                  "우리는 추석을 보냅니다.",
                  "우리는 송편을 먹습니다.",
                  "우리는 안녕! 이라고 인사합니다.",
                  "*중국인 - 중추절, 월병, 니 하오",
                  "독일 – 옥터버페스트, 소세지, 구텐탁",
                  "미국 – 추수감사절, 칠면조, 헬로우"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14-15",
               sentences:[
                  "Guess What Is Inside Teaching Guide",
                  "Guess What Is Inside 제목을 말한다.",
                  "How do you know what is inside? ",
                  "Let’s touch and feel songpyeon.",
                  "How to Make songpyeon. 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What is inside? ",
                  "Can you guess what is inside? ",
                  "(Look at/touch/push/poke/cut) it. ",
                  "Let’s cut the songpyeon. ",
                  "Let’s look inside. ",
                  "Do you like them? ",
                  "Draw your favorite songpyeon. ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Autumn Festival Teaching Guide",
                  "Song: Autumn Festival 말한다.",
                  "Song: Autumn Festival 영상",
                  "Let’s sing together. ",
                  "Chuseok, songpyeon, Moon Festival, moon cakes, Oktoberfest,",
                  "sausage, Thanksgiving, turkey ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"06",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Shapes Teaching Guide",
                  "Let’s sing the Shapes Song. 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Good job! singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread /",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  " P3",
                  "Shapes Teaching Guide",
                  "Let’s sing the Shapes Song. 의 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Good job! singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Circle all the shapes of the spaceship. ",
                  "circle / triangle /square / heart ",
                  "star / oval / pentagon / hexagon ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"01",
               page:"",
               sentences:[
                  " P4",
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song. 영상을 보며 노래와 율동.",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"01",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is windy. windy를 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4~5",
               sentences:[
                  "Let’s look at the picture. Teaching Guide",
                  "Let’s look at the picture. 제목을 말한다.(스토리 북의 그림을 보며)",
                  "What do you see? (무엇이 보이는지 말한다)",
                  "아이) 로켓, 나르는 사람, 달, 우주선…이라고 말한다. ",
                  "맞아요, 잘 했어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Aiken Drum lives in the moon. ",
                  "Do you see (a spaceship/the moon/the earth/a rocket)?",
                  "Yes, I see (a spaceship/the moon/the earth/a rocket). ",
                  "Let’s Look at the Cover 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Aiken Drum lives in the moon. ",
                  "Find the pictures.",
                  "spaceship / moon / earth / rocket",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "What shape is your spaceship? ",
                  "Spaceships!",
                  "",
                  "Draw your own spaceship! ",
                  "Excellent! (각자 모양을 그리는 것으로 마무리)"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6~7",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Write the word moon and put the matching stickers on the circles.",
                  "moon 쓰기와 스티커 활동으로 마무리한다.",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine What color is this? Teaching Guide",
                  "Colors Song의 영상을 본다.",
                  "Let’s sing: Colors Song 영상을 보며 노래와 율동.",
                  "Circle the purple shape.",
                  "Blue / purple / green / orange / red",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8~9",
               sentences:[
                  "Moon Cycle Teaching Guide",
                  "Moon Cycle 제목을 말한다.",
                  "Let’s say the names.",
                  "The moon moves around the sun. ",
                  "The (half-moon) changes into a (full moon). "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "The moon moves around the earth. ",
                  "The (full moon) changes to the (half-moon).",
                  "Let’s find the moon. ",
                  "Point to the half moon. ",
                  "new moon / half-moon / full moon "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Write full moon.",
                  "쓰는 것과 스티커 활동으로 마무리",
                  "Excellent! ",
                  ""
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 September를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Long a. Teaching Guide",
                  "Long a. 제목을 말한다.",
                  "Let’s sing: Long a. 영상을 본다.",
                  "Long a 가 무슨 소리가 날까요? (‘애' 소리가 난다)",
                  "Say the words. cane / cape / mate",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Long I. Teaching Guide",
                  "Long I. 제목을 말한다.",
                  "Let’s sing: Long I. 영상을 본다.",
                  "Long i 가 무슨 소리가 날까요? (‘아' 소리가 난다.)",
                  "Say the words:",
                  "(-/ine/, fine), (-/ite/, kite), (-/ine/, pine). ",
                  "Put the matching stickers on the circles. ",
                  "Say the words fine / kite / pine",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "What letters are missing? (영상으로 상호작용)",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Write the letters and say the words.",
                  "fine / kite / pine",
                  "쓰는 활동을 보면서 마무리 ",
                  "Good job!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Let’s sing the Numbers Song. Teaching Guide",
                  "Let’s sing the Numbers Song. 영상을 보며, 노래와 율동 ",
                  "One, Two, tree, Four, Five, Six , seven , Eight, Nine, Ten",
                  "Eleven, Twelve, thirteen, Fourteen, Fifteen, Sixteen, Seventeen,",
                  "Eighteen, Nineteen, and Twenty.",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "More or Less Teaching Guide",
                  "More or Less 제목을 말한다.",
                  "How many do you see? ",
                  "More or Less 영상을 보며, 상호작용)"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many do you see?",
                  "Put the transportation stickers to sort. ",
                  "Which one has (more/less) than three wheels? ",
                  "The (bus/train/car/truck) has more than 3 wheels. ",
                  "The (bicycle/boat/rocket/spaceship/airplane) has less than 3 wheels. ",
                  "Good job! 활동 마무리"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. (시간이 되면, 반복하는 노래)",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"13",
               sentences:[
                  "Phonics Let’s write N n O o P p. Teaching Guide",
                  "Let’s write N n O o P p.",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Biggest and Smallest Teaching Guide",
                  "Biggest and Smallest 제목을 말한다.",
                  "Find the biggest and smallest thing. ",
                  "biggest, hot air balloon, rocket, smallest, spaceship ",
                  "Biggest and Smallest 영상으로 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Look at the spaceships. ",
                  "The biggest one! ",
                  "The smallest one! ",
                  "Circle the pictures. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics: come, to Teaching Guide",
                  "Sight Words: come, to를 말한다.",
                  "Let’s chant: Celebration. 영상을 본다.",
                  "Come to (the)…",
                  "(party / wedding / parade / festival / Oktoberfest / grandma’s house). ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Sight Words: look, at Teaching Guide",
                  "Sight Words: look, at 을 말한다.",
                  "Let’s chant: Transportation. 영상을 본다.",
                  "What do you see in the picture? ",
                  "Look at the... ",
                  "(car / truck / boat / train / airplane / bicycle). ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상을 본다.",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "",
                  "Can you find the words look and at? Teaching Guide",
                  "Can you find the words look and at? 제목을 말한다.",
                  "Circle and write the words. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Good job! ",
                  ""
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16~17",
               sentences:[
                  "Tangram Teaching Guide",
                  "Tangram 제목을 말한다.",
                  "Tangram 영상을 본다.",
                  "Let’s create a transportation. ",
                  "탱그램 카드를 준비한다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "boat, make, rocket, tangram, piece ",
                  "Can you make a boat? ",
                  "Good job! ",
                  "Let’s finish creating the transportation. ",
                  "Can you make a rocket? ",
                  "Excellent!"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics Alphabet Song. Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. 영상을 보면서 노래한다.",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
                  "Do you know the Alphabet song?"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"20~21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "Look! It’s the Transportation Museum! ",
                  "There are people looking around the Transportation Museum. ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Look at the hot air balloon./ Look at the ship. ",
                  "Look at the hot air balloon./ Look at the car. ",
                  "Look at the truck. / Look at the spaceship.",
                  "Apple,/ grapes,/ orange,/ pineapple,/ strawberry,/ watermelon,/",
                  "airplane,/ car,/ rocket,/ Aiken Drum",
                  "Find the hidden things. ",
                  ""
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Some things are hidden.",
                  "These are the hidden things.",
                  "Apple,/ grapes,/ orange,/ pineapple,/ strawberry,/ watermelon,/",
                  "airplane,/ car,/ rocket,/ Aiken Drum",
                  "Find the hidden things. ( 찾는 것으로 마무리)",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Aiken Drum 제목을 말한다.",
                  "Aiken Drum wants to go to the moon. ",
                  "Let’s Move Aiken Drum 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Move the Aiken Drum by using coding cards. ",
                  "Start / Right /Left /Up /Down /End ",
                  "Put the stickers on the circles. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Aiken Drum의 의미",
                  "달에서 사는 남자가 있었어요.",
                  "그의 이름은 Aiken Drum이죠.",
                  "그는 국자로 연주를 해요.",
                  "그의 모자는 크림치즈로 만들어 졌고",
                  "버튼은 갈색 빵으로 만들어 졌고",
                  "바지는 상추로 만들어 졌고",
                  "조끼는 와플로 만들어 졌고",
                  "코트는 쇠고기 구이로 만들어져 있어요."
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"14~15",
               sentences:[
                  "Fast or Slow Teaching Guide",
                  "Fast or Slow 제목을 말한다.",
                  "Let the car go! ",
                  "Is the car fast or slow? ",
                  "What happened? ",
                  "How can you make the car go fast or slow? "
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up and make the ramp?",
                  "Draw what happened to the cars. ",
                  "It is high.(fast), It is low. (slow)",
                  "That’s a good answer. ",
                  "배운 내용을 부모님과 같이 해보세요.",
                  "Goodbye ~",
                  ""
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Aiken Drum Teaching Guide",
                  "Song: Aiken Drum 말한다.",
                  "Song: Aiken Drum 영상",
                  "Let’s sing together. ",
                  "Moon / ladle / cream cheese / brown bread / ",
                  "waffles / roast beef / lettuce. ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"07",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Stretch Your Body Teaching Guide",
                  "Let’s sing the Stretching Song. 영상을 보며, 노래와 율동",
                  "Good job! singing",
                  "Put the stickers on the motions that you liked. ",
                  "Up / Down / Left / Right / Round ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "",
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보면서 노래한다.",
                  "What’s the weather like today?",
                  "Look out the window."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is windy. windy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is snowy. snowy를 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4~5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
                  "What do you see? (무엇이 보이는지 말한다)",
                  "아이) 여자아이, 풀, 땅콩….라고 말한다. ",
                  "맞아요, 잘 했어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "This is a farm. There are peanuts in the ground. ",
                  "This is a girl. She has something. ",
                  "Does she have a (seed / worm / bean / peanut)? ",
                  "Yes. She has a peanut. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s Look at the Cover 영상을 보며, 상호작용한다.",
                  "Who wants to try?",
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "There are peanuts in the ground. ",
                  "Find the picture.",
                  "Seed / worm / bean / peanut ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Crack it open! ",
                  "What do you want to use? ",
                  "Do you want to use (a hammer/your hand/a spoon/a fork)? ",
                  "Yes. I want to use (a hammer/my hand/a spoon/a fork). ",
                  "Draw the tool and write the word. ",
                  "Excellent! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6~7",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "Good job! Singing",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine What color is this? Teaching Guide",
                  "Colors Song의 영상을 본다.",
                  "Let’s sing: Colors Song. (노래와 율동)",
                  "red / Blue / Yellow /purple / green / orange",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8~9",
               sentences:[
                  "Let’s Pretend Teaching Guide",
                  "What do you do when you are sick? ",
                  "Let’s Pretend 영상을 보며 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What do you do when you are sick? ",
                  "Let’s pretend to be a (mommy/daddy/girl/doctor). ",
                  "Do the motions. ",
                  "What’s wrong? ",
                  "I have a stomachache. ",
                  "Call the doctor. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "See a doctor. ",
                  "Get a shot. ",
                  "Take a rest. ",
                  "Put the matching stickers on the circles. ",
                  "Excellent! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Do you know the ABC song?",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  " P10",
                  "Phonics Long I. Teaching Guide",
                  "Long I. 제목을 말한다.",
                  "Let’s sing: Long I. 영상을 본다.",
                  "Long i 가 무슨 소리가 날까요? (‘아' 소리가 난다)",
                  "Long i says I. Say the words:",
                  "kite/ pin / pine / fin / fine",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Long o. Teaching Guide",
                  "Long o. 제목을 말한다.",
                  "Let’s sing: Long o. 영상을 본다.",
                  "Long o 가 무슨 소리가 날까요? (‘오' 소리가 난다)",
                  "Long o says o. Say the words:",
                  "hope, robe, mope. ",
                  "Put the matching stickers on the circles. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "What letters are missing? Teaching Guide ",
                  "활동을 해볼까요?",
                  "What letters are missing? 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letters and say the words.",
                  "hope / robe / mope. ",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 November를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Stretch Your Body Teaching Guide",
                  "Let’s sing the Stretching Song. 영상을 본다.",
                  "영상에 맞추어 노래와 율동을 같이 한다. ",
                  "Up / Down / Left / Right / Round ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Measuring Teaching Guide",
                  "Measuring 제목을 말한다.",
                  "Let’s measure our hands and feet. ",
                  "Write the numbers in the boxes. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Cut out the ruler and measure your (hand/foot). ",
                  "Let’s measure your foot. ",
                  "How long is your (hand/foot)? "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "What do you use when you measure your hands and feet? ",
                  "We use a ruler.",
                  "It is fifteen centimeters.",
                  "Write the numbers in the boxes. ",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Phonics Let’s write Q q R r S s Teaching Guide",
                  "Let’s write Q q R r S s.",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Same or Different Teaching Guide",
                  "Same or Different 제목을 말한다.",
                  "What is different? ",
                  "Clock/ measuring/ cup/ picture/ spoon/ squirrel/ teapot/ truck",
                  "Same or Different 영상으로 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "What is (the same/different)? ",
                  "Find the (same/different) things in the picture. ",
                  "The (clock/measuring cup/picture/spoon/squirrel/teapot/truck) is different. ",
                  "Circle the seven different things. ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"14~15",
               sentences:[
                  "Using Electricity 제목을 말한다.",
                  "What uses electricity? ",
                  "These are tools. ",
                  "Some tools need electricity. ",
                  "What uses electricity?",
                  "The (blender/camera/hair dryer/mobile phone/oven)…",
                  "Using Electricity. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Using Electricity 영상으로 상호 작용한다.",
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What uses electricity? ",
                  "Circle the answers. ",
                  "The (blender/camera/hair dryer/mobile phone/oven)…"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Some tools and machines need electricity power. ",
                  "Put the cord stickers next to the things",
                  "that need electricity.",
                  "Good job! 활동 마무리"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics: look, at Teaching Guide",
                  "Sight Words: look, at 을 말한다.",
                  "Let’s chant: Transportation. 영상을 본다.",
                  "Look at the",
                  "(car / truck / boat / train / bicycle). ",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Sight Words: one, red, blue, yellow Teaching Guide",
                  "Sight Words: one, red, blue, yellow 을 말한다.",
                  "Let’s chant: Tools and Machines. 영상을 본다.",
                  "One ( red / blue / yellow)…. ",
                  "(tablespoon / blender / spatula / frying pan / oven / ladle). ",
                  "What can you find in the kitchen? ",
                  "One ( red / blue / yellow)…. ",
                  "(tablespoon / blender / spatula / frying pan / oven / ladle).",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words one, red, blue, and yellow? Teaching Guide",
                  "Can you find the words one, red, blue, and yellow? 제목을 말한다.",
                  "Circle and write the words."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat ",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  ""
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics Alphabet Song. Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
                  "Do you know the Alphabet song?"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"20~21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "This is the doctor’s office. ",
                  "What do you see in the picture? ",
                  "There are some tools and machines. ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "I see one green broccoli./ I see one yellow corn.",
                  "I see one orange carrot./ I see one red tomato. ",
                  "I see one brown potato./ I see one brown hammer. ",
                  "I see one gray nut cracker./ I see one white blender.",
                  "I see one white computer./ I see one green telephone.",
                  "Find the hidden things. ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Teaching Guide",
                  "Let’s Move 제목을 말한다.",
                  "Catty! Turn right. Turn left.",
                  "Can you move Catty? ",
                  "Let’s Move 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Can you move Robby? ",
                  "Turn right. ",
                  "Put the Robby sticker in the correct circle. ",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "Found a Peanut의 의미",
                  "땅콩을 찾았어요.",
                  "껍질을 벗겼어요.",
                  "땅콩이 상했어요.",
                  "상한 것을 먹었어요.",
                  "배가 아팠어요.",
                  "의사에게 전화했어요.",
                  "의사에게 갔어요.",
                  "주사를 맞았어요.",
                  "푹 쉬었어요."
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16~17",
               sentences:[
                  "Manners Can Be Fun Teaching Guide",
                  "Manners Can Be Fun 제목을 말한다.",
                  "Use two Yut sticks. ",
                  "Throw the sticks to move. "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Say the manner expressions. ",
                  "manner, please, excuse me, ",
                  "I’m sorry, thank you. ",
                  "How do you play this game? "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How to play: (놀이 책 참조)",
                  "Throw the sticks and then move the game pieces. ",
                  "How to move: Move 1. Move 2. Move 3. ",
                  "When you are arrive at , you can choose to go any direction you like. ",
                  "Let’s play a game. ",
                  "Goodbye ~"
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: Found a Peanut Teaching Guide",
                  "Song: Found a Peanut 제목을 말한다.",
                  "Song: Found a Peanut 영상을 본다.",
                  "Let’s sing with motions. 동작하며, 노래",
                  "called, cracked, doctor, found, shot, stomachache ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"08",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "Seasons and Temperature Teaching Guide",
                  "Let’s sing the Seasons Song. (영상을 보며, 노래와 율동)",
                  "Spring / Summer / Fall / Winter ",
                  "Put the matching temperature stickers on the circles. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Write the words. ",
                  "It is warm. It is ( hot )",
                  "It is cool. It is ( cold )",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  " P4",
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song. 영상을 보며 노래한다.",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is windy. windy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is snowy. snowy를 말한다.",
                  "Good job! Singing. ",
                  "Circle the picture."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "",
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
                  "What do you see? ",
                  "아이) 요리사, 우유, 바나나, 피자….라고 말한다. ",
                  "맞아요, 잘 했어요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "This is a boy. ",
                  "He is a cook and he is making something. ",
                  "Do you see (eggs /a cook /milk / pizza)? ",
                  "Yes, I see (eggs /a cook /milk / pizza). ",
                  "Let’s Look at the Cover 영상을 보며, 상호작용"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "He is a cook and he is making something. ",
                  "Find the pictures.",
                  "eggs / a cook / milk / pizza",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "Are you hungry? ",
                  "What do you want to ( eat / drink) in the winter?",
                  "Do you want to ( eat / drink) ",
                  "(kimbap / milkshakes / tacos / pizza) in the winter?",
                  "Draw what you want to eat or drink! ",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상을 본다.",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"ut the matching stickers on the circles. ",
               sentences:[
                  "What do you want to cook? ",
                  "I am a super cook.",
                  "Good job! Singing ",
                  "스티커 활동으로 마무리한다.",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"8",
               sentences:[
                  "Daily Routine Shapes Song. Teaching Guide",
                  "Shapes Song.의 영상을 보며, 노래 율동",
                  "Let’s sing: Shapes Song. ",
                  "Circle the color shape.",
                  "red square / Yellow circle / Blue triangle",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Seasonal Activities Teaching Guide",
                  "Seasonal Activities 제목을 말한다.",
                  "What do you want to do? ",
                  "Seasonal Activities 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "What do you want to do? ",
                  "Put the matching stickers on the circles. ",
                  "Let’s pretend. It is (spring/summer/fall/winter). ",
                  "Do you want to go (on a picnic/to the beach/hiking/sledding)? ",
                  "Yes. I want to go (on a picnic) in the (spring). "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "What do you do in the winter? ",
                  "make snowmen / have snowball fights ",
                  "make snow angels /go sledding ",
                  "Draw a picture. ",
                  "Excellent! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 December를 누른다. (소리) (같이 읽는다)",
                  "음, 표시/그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시/그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Long o. Teaching Guide",
                  "Long o. 제목을 말한다.",
                  "Let’s sing: Long o. 영상을 본다.",
                  "Long o 가 무슨 소리가 날까요? (‘오' 소리가 난다)",
                  "Say the words hope / mop / robe / mope / hop ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상을 본다.",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Long u. Teaching Guide",
                  "Long u. 제목을 말한다.",
                  "Let’s sing: Long u. 영상을 본다.",
                  "Long u 가 무슨 소리가 날까요? (‘우-유' 소리가 난다)",
                  "Long u says. Say the words:",
                  "(-/ube/, cube), (-/uge/, huge), (-/ube/, tube). ",
                  "Put the matching stickers on the circles. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "What letters are missing? Teaching Guide",
                  "What letters are missing? 영상을 본다.(영상으로 상호작용)",
                  "영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Write the letters and say the words.",
                  "cube / huge / tube.",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Four Seasons Teaching Guide",
                  "Let’s sing: Seasons Song. 영상을 보며, 노래와 율동",
                  "spring / summer / Fall / winter ",
                  "These are the four seasons. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Four Seasons Teaching Guide",
                  "Four Seasons 제목을 말한다.",
                  "What is your favorite season? ",
                  "Four Seasons. 영상을 보고 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Do you like (spring/summer/fall/winter)? ",
                  "I like (spring/summer/fall/winter). ",
                  "Color the boxes. ",
                  "How many children like (spring)? ",
                  "Let’s count. Good job!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상을 본다.",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song. Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Phonics Let’s write T t, U u, V v. Teaching Guide",
                  "Let’s write T t, U u, V v. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Clothes Teaching Guide",
                  "Clothes 제목을 말한다.",
                  "Let’s find summer clothes and winter clothes. ",
                  "Clothes 영상으로 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Let’s find summer clothes and winter clothes. ",
                  "shirts, sandals, and shorts are summer clothes. Coats, boots, jackets, winter hats, and scarves are winter clothes. ",
                  "We can wear (pants/shirts/sunglasses) in the summer or winter. ",
                  "Put the matching clothes stickers in the Venn diagram. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics: : one, red, blue, yellow Teaching Guide",
                  "Sight Words: one, red, blue, yellow 을 말한다.",
                  "Let’s chant: Tools and Machines. 영상을 본다.",
                  "One ( red / blue / yellow)…. ",
                  "(tablespoon / blender / spatula / frying pan / oven / ladle). "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Sight Words: can, make Teaching Guide",
                  "Sight Words: can, make 을 말한다.",
                  "Let’s chant: Four Seasons. 영상을 본다.",
                  "What food can you make?",
                  "I can make (kimbap/milkshakes/tacos/pizza toast) in the (spring/summer/fall/winter). "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"19",
               sentences:[
                  "Can you find the words can and make? Teaching Guide",
                  "Can you find the words can and make? 제목을 말한다.",
                  "Let’s chant: Four Seasons. 영상을 본다.",
                  "Can you find the words can and make? 영상으로 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Circle and write the words. (can/make)",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics Alphabet Song. Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
                  "Do you know the Alphabet song?"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Teaching Guide",
                  "Let’s Move 제목을 말한다.",
                  "Let’s be Robby and Catty! ",
                  "Can you move (Robby / Catty)? ",
                  "Let’s Move 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Can you move ( Robby / Catty)? ",
                  "Turn ( right / Left )",
                  "Good job! 활동하는 것으로 마무리",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "It’s snowing! Let’s go sledding! ",
                  "What do you see in the picture? ",
                  "Bacon, fish, ham, nuts, turkey, boots, fan, hat, mitten, scarf. ",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "It is snowing. / I see children sledding. ",
                  "I see a boy making snow angels. ",
                  "I see a girl making a snow man.",
                  "I see one green broccoli. / I see one yellow corn.",
                  "These are the hidden things.",
                  "Find the hidden things. ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"01",
               page:"14-15",
               sentences:[
                  "Rub the Balloon Teaching Guide",
                  "Rub the Balloon 제목을 말한다.",
                  "What happened to the papers? ",
                  "Place the balloon near the pieces of paper. ",
                  "Rub the balloon on your clothes or hair. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"02",
               page:"",
               sentences:[
                  "What happened to the papers? ",
                  "The balloon picked up pieces of paper. ",
                  "Let’s rub the balloon on your head. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"02",
               scene:"hunt-4",
               script:"03",
               page:"",
               sentences:[
                  "What happened to the papers? ",
                  "Before rubbing.",
                  "After rubbing. ",
                  "Draw what you saw. ",
                  "Good job! 그리기 활동으로 마무리한다."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "I Am a Super Cook의 의미",
                  "봄이에요.",
                  "우리 함께 소풍을 가요.",
                  "배가 고픈가요?",
                  "무엇을 먹고 싶나요?",
                  "나는 김밥이요. 우리 함께 김밥을 만들어요.",
                  "나는야 최고 요리사."
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Alphabet Memory Game Teaching Guide",
                  "Alphabet Memory Game 제목을 말한다.",
                  "Let’s read the alphabets. ",
                  "Big letters and little letters. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "alphabets, big letter, little letter, match, ",
                  "The Alphabet Song. 영상을 본다.",
                  "Find big letters and little letters. ",
                  "Find the Matching cards",
                  "How do you play this game? "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How to play: (놀이 책 참조)",
                  "Face the cards down and spread them on the table. ",
                  "Take turns finding the same cards. ",
                  "Discuss which cards to turn over. "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "If a team has different cards, put them face down on the same place. ",
                  "If a team has matching cards, keep them. ",
                  "When the game is over, count how many cards you have. ",
                  "Let’s play the Alphabet Memory game. ",
                  "배운 내용을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: I Am a Super Cook Teaching Guide",
                  "Song: I Am a Super Cook 말한다.",
                  "Song: I Am a Super Cook 영상",
                  "Let’s sing together. ",
                  "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
                  "sledding, spring, summer, tacos, winter ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"09",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"3",
               sentences:[
                  "I Love You, My Friends! Teaching Guide",
                  "Let’s sing the Friendship Song. (영상을 보며, 노래와 율동)",
                  "Draw your friends. ",
                  "Give Love stickers to your friends.",
                  "Trace ‘I love you.’ ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"4",
               sentences:[
                  "Daily Routine The Weather Teaching Guide",
                  "The Weather Song 영상을 보며, 노래",
                  "What’s the weather like today? ",
                  "Look out the window. "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "It is sunny. sunny를 말한다. ",
                  "It is cloudy. cloudy 말한다.",
                  "It is windy. windy를 말한다.",
                  "It is rainy. rainy를 말한다. ",
                  "It is snowy. snowy를 말한다.",
                  "Good job! Singing.",
                  "Circle the picture. "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"4-5",
               sentences:[
                  "Let’s Look at the Cover Teaching Guide",
                  "Let’s Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
                  "What do you see? ",
                  "아이) 과자, 우유, 가지, …라고 말한다. ",
                  "맞아요, 잘 했어요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "The world is made of food. ",
                  "The children are riding a merry-go-round. ",
                  "Do you see (crackers / a pineapple / pie / milk)? ",
                  "Yes, I see (crackers /a pineapple / pie / milk).",
                  "Let’s Look at the Cover 영상을 보며, 상호작용"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "The world is covered with food.",
                  "Find the pictures.",
                  "Crackers / a pineapple / a pie / milk",
                  "Good job!",
                  ""
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-1",
               script:"05",
               page:"",
               sentences:[
                  "If all the world is made of… ",
                  "What do you want the world to be made of? ",
                  "All the world is made of",
                  "(glass bottles / boxes / cans / paper)",
                  "Draw what you want the world to be made of! ",
                  "Excellent!",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"song",
               script:"01",
               page:"6-7",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상을 본다.",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"song",
               script:"02",
               page:"",
               sentences:[
                  "Put the matching stickers on the circles. ",
                  "If all the world is made of... ",
                  "Grains / fruits and vegetables / dairy foods / ",
                  "protein foods",
                  "What will happen to the world? ",
                  "Good job! Singing 스티커 활동으로 마무리",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Daily Routine What color is this? Teaching Guide",
                  "Colors Song의 영상을 본다.",
                  "Let’s sing: Colors Song. (노래, 율동)",
                  "This is the iWonder rainbow",
                  "red / Blue / Yellow ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"8-9",
               sentences:[
                  "Food Group Teaching Guide",
                  "Food Group 제목을 말한다.",
                  "Let’s find the matching food group.",
                  "Food Group 영상을 보며 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Let’s find the matching food group.",
                  "A cracker. The cracker is in the Grains Group.",
                  "Broccoli. The broccoli is in the Vegetable Group.",
                  "A pineapple. The pineapple is in the Fruits Group.",
                  "Milk. The milk is in the Dairy Group.",
                  "An egg. The egg is in the Protein Food Group."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-1",
               script:"04",
               page:"",
               sentences:[
                  "Can you find the foods in the (Grains Group)? ",
                  "Grains Group / Vegetable Group ",
                  "Fruit Group / Dairy Group ",
                  "Protein Foods Group. ",
                  "Excellent! 활동 마무리",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Calendar Teaching Guide",
                  "Calendar 영상을 본다.",
                  "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
                  "깜박이는 January를 누른다. (소리) (같이 읽는다)",
                  "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "주(week)를 가리키는 bar를 누른다.",
                  "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
                  "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
                  "해당 요일을 클릭 하면, 다음으로 넘어간다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "what day is it Today? 깜박인다. 다음 클릭 한다.",
                  "클릭 후 Today is... 소리가 나온다. (선생님도 같이 한다)",
                  "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다) ",
                  "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
                  "Today is (주, 월, 날짜) 순으로 영어가 표현된다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"01",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"10",
               sentences:[
                  "Phonics Long u. Teaching Guide",
                  "Long u 제목을 말한다.",
                  "Let’s sing: Long u. 영상을 본다.",
                  "Long u 가 무슨 소리가 날까요? (‘우-유' 소리 난다)",
                  "Long u says, Say the words:",
                  "(-/ube/, cube), (-/uge/, huge), (-/ube/, tube).",
                  "cube / tube / tub / huge / hug ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"10",
               sentences:[
                  "Long Vowels Teaching Guide",
                  "Long Vowels 제목을 말한다.",
                  "Let’s sing: Long Vowels. 영상을 본다.",
                  "Long Vowels 은 어떤 것이 있을 까요?",
                  "Long a says ā. Cane / Cape/ Mate ",
                  "Long i says ī. Fine / Kite / Pine",
                  "Long o says ō. Hope / Robe / Mope",
                  "Long u says ū. Huge / Cube / Tube",
                  "Circle the matching pictures.",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"11",
               sentences:[
                  "Long Vowels ( a i o u ) Teaching Guide",
                  "활동을 해볼까요?",
                  "Long Vowels 영상으로 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "Read the words as fast as you can.",
                  "How long does it take? ",
                  "Let’s read the words. ",
                  "Good job!",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"12",
               sentences:[
                  "Daily Routine Roll Your Hands Teaching Guide",
                  "Let’s sing: Roll Your Hands Song. 영상을 보며, 노래와 율동",
                  "Let’s sing: Roll Your Hands.",
                  "roll / clap / stomp / cross ",
                  "as slowly as you can. ",
                  "as fast as you can. ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"12",
               sentences:[
                  "Add Fruits Teaching Guide",
                  "Add Fruits 제목을 말한다.",
                  "Add Fruits 영상을 보며 상호작용한다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands. ",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "How many (apples) do I have now?",
                  "I have (three) (apples). ",
                  "(Two) more apples, please! ",
                  "Let’s count the (apples). ",
                  "I have (five) (apples). ",
                  "Draw fruits. ",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상을 본다.",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"",
               sentences:[
                  "Phonics The ABC Song Teaching Guide",
                  "The ABC Song. 영상을 본다.",
                  "Let’s sing the ABC Song. ",
                  "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
                  "Do you know the ABC song?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the ABC song.",
                  "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
                  "I say A. You say BC! A, BC! A, BC! ",
                  "Now I know my ABCs, ABCs, ABCs! ",
                  "Everybody come on and sing the sounds! ",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"02",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"01",
               page:"13",
               sentences:[
                  "Phonics Let’s write W w, X x, Y y, Z z. Teaching Guide",
                  "Let’s write W w, X x, Y y, Z z. ",
                  "Good job! "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Recycling Bins Teaching Guide",
                  "Recycling Bins 제목을 말한다.",
                  "Where does the trash go? ",
                  "Recycling Bins 영상으로 상호 작용한다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Where does the trash go? ",
                  "Let’s sort the trash. ",
                  "Find all the (paper / glass / can / plastic) stickers. ",
                  "Put the stickers on the matching bins. ",
                  "Good job! 스티커 활동으로 마무리",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-1",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"18",
               sentences:[
                  "Phonics: Long Vowels ( a i o u ) Teaching Guide",
                  "활동을 해볼까요?",
                  "Long Vowels 영상으로 상호작용",
                  "Long a says ā. Cane / Cape/ Mate ",
                  "Long i says ī. Fine / Kite / Pine",
                  "Long o says ō. Hope / Mope/ Robe",
                  "Long u says ū. Huge / Cube / Tube",
                  "Good job!"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Sight Words: little, big Teaching Guide",
                  "Sight Words: little, big 을 말한다.",
                  "Let’s chant: The Earth. 영상을 본다.",
                  "This is a (big/little)",
                  "(sun / tree / whale / flower / bird / fish). ",
                  "Good job! Singing",
                  ""
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "Can you find the words little and big? Teaching Guide",
                  "Can you find the words little and big? 제목을 말한다.",
                  "Circle and write the words. ",
                  "Let’s chant: The Earth 영상을 본다.",
                  "Can you find little and big things?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"04",
               page:"",
               sentences:[
                  "Who wants to come up here? ",
                  "Raise your hands.",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-2",
               script:"05",
               page:"",
               sentences:[
                  "Can you find the words little and big?",
                  "Circle and write the words. (little / big)",
                  "Good job! ",
                  "배운 영상을 부모님과 같이 해보세요.",
                  "Goodbye ~ ",
                  ""
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"03",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"01",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"01",
               page:"",
               sentences:[
                  "Phonics Alphabet Song Teaching Guide",
                  "The Alphabet Song. 영상을 본다.",
                  "Let’s sing the Alphabet Song. ",
                  "(영상에 맞추어 노래를 같이 한다)",
                  "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
                  "Do you know the Alphabet song?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Let's sing the Alphabet song.",
                  "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
                  "Now, I know my ABC's.",
                  "Next time won't you sing with me.",
                  "Good job singing. "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"01",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-2",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"01",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"story",
               script:"01",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"01",
               page:"20-21",
               sentences:[
                  "Hidden Pictures Teaching Guide",
                  "Hidden Pictures 제목을 말한다.",
                  "Let’s recycle! ",
                  "Some things are hidden. ",
                  "Bread, cereal, cookie, doughnut, muffin, rice, glass bottle, box, can, newspaper.",
                  "Hidden Pictures 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands. ",
                  "민호, Come to the front. ",
                  "Good job! 하이파이브!!",
                  "Go back to your seat"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-1",
               script:"03",
               page:"",
               sentences:[
                  "These are the hidden things.",
                  "Find the hidden things. ",
                  "Good job! (활동마무리)",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"01",
               page:"22",
               sentences:[
                  "Let’s Move Robby Teaching Guide",
                  "Let’s Move Robby제목을 말한다.",
                  "Robby wants some fruit. ",
                  "Can you move Robby? ",
                  "Let’s Move 영상을 본다."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Who wants to come up here?",
                  "Raise your hands.",
                  "민호, Come to the front.",
                  "Good job! 하이파이브!!",
                  "Go back to your seat "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-2",
               script:"03",
               page:"",
               sentences:[
                  "활동을 해볼까요?",
                  "Can you move Robby ? ",
                  "Start /End /Right /Left / Up / Down ",
                  "Good job! ",
                  "배운 내용을 부모님과 같이 해보세요.",
                  "Goodbye ~ "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"02",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  "If All the World의 의미",
                  "만약 세상이 모두",
                  "곡식으로 만들어졌다면...",
                  "과일과 야채로 만들어졌다면...",
                  "유제품으로 만들어졌다면...",
                  "단백질 음식으로 만들어졌다면...",
                  "세상에 어떤 일이 벌어질까요?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"01",
               page:"16-17",
               sentences:[
                  "Build the Cup Tower Teaching Guide",
                  "Build the Cup Tower 제목을 말한다.",
                  "Build the Cup Tower. 영상을 본다.",
                  "Can you build the cup tower with your friends?",
                  "Put the stickers on the circles when you are done."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-1",
               script:"02",
               page:"",
               sentences:[
                  "",
                  "Now try making your own cup tower with your friends.",
                  "Let’s try making! ",
                  "Take a picture and paste it.",
                  "Good job! ",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"03",
               scene:"song",
               script:"01",
               page:"",
               sentences:[
                  "Song: If All the World Teaching Guide",
                  "Song: If All the World 말한다.",
                  "Song: If All the World 영상",
                  "Let’s sing together.",
                  "dairy foods, fruits, grains, protein foods, ",
                  "vegetables, world ",
                  "Good job! Singing "
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"01",
               page:"14-15",
               sentences:[
                  "Music Maker Teaching Guide",
                  "Music Maker 제목을 말한다.",
                  "Let’s make some sounds. ",
                  "Can we make sounds with ",
                  "(glass bottles, plastic containers, cans, and boxes)? ",
                  "Use rubber bands. ",
                  "Put a rubber band on things we can recycle.",
                  "Can you hear the sounds?"
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-2",
               script:"02",
               page:"",
               sentences:[
                  "Circle what you want to use. ",
                  "glass bottles, plastic containers, cans, and boxes",
                  "What sounds!",
                  " ",
                  "Good job! 확인 하는 것으로 마무리",
                  "Goodbye ~ 다음 시간에 만나요."
               ]
            },
            {
               level: "03",
               volume:"10",
               week:"04",
               round:"03",
               scene:"hunt-3",
               script:"",
               page:"",
               sentences:[
                  
               ]
            }
         ]
        // guides: [
        //     {
        //         level: '01', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Feelings Song Teaching Guide",
        //             "Feelings Song 영상을 보여준다.",
        //             "How does he feel today?",
        //             "I am happy!",
        //             "Happy / sad",
        //             "Circle the picture. (기분에 동그라미 해볼까요?)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Hello, how are you?",
        //             "How do you feel? ",
        //             "Happy, happy, I am happy.",
        //             "This is how I feel.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 케잌, 촛불, 모자,… 라고 말한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Let's look at the picture. / Today is Anna's birthday.",
        //             "Do you see a (cake/candle/hat/cup)?",
        //             "그림을 가리키며, 읽는다.",
        //             "Yes. I see a (cake/candle/hat/cup).",
        //             "Circle the pictures. (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "How do you feel? Teaching Guide",
        //             "Feelings 영상을 보며, 그림 그리기",
        //             "How do you feel?",
        //             "Happy / sad?",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "How do you feel?",
        //             "Draw your face. (얼굴을 그리게 한다)",
        //             "Excellent! (그리는 것으로 수업 마무리)",
        //             "Feelings 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Happy Birthday Teaching Guide",
        //             "Song: Happy Birthday 제목을 말한다.",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Happy Birthday Story 영상을 보며, 노래가사, 율동",
        //             "Look at me.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "I will sing with motions.",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday dear Anna. (두 손을 입 옆으로 가서 부른다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's sing with motions. 노래를 함께 불러본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "Let's put the stickers.",
        //             "Anna! Put the sticker on the circle.",
        //             "Cake! Put the sticker on the circle.",
        //             "Happy Birthday Song 영상 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? Teaching Guide",
        //             "Feelings 영상을 보며, 노래, 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "Happy / sad?",
        //             "Are you happy?",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 보며, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '09', page: '08', action: '01', scene: '01', sentences: [
        //             "Birthday Gift Teaching Guide",
        //             " Birthday Gift 제목을 말한다.",
        //             "It's my birthday. I want a gift.",
        //             "Teddy bear. I want a teddy bear!",
        //             "Birthday Gift 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '09', page: '08', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '09', page: '08', action: '01', scene: '03', sentences: [
        //             "What do you want?	",
        //             "Do you want",
        //             "(a book, a tea set, a camera, a teddy bear, stickers, a ball, a toy car)?",
        //             "I want (a teddy bear/ stickers /a toy car/...)",
        //             "Circle the gifts that you want. ( 동그라미 치는 것을 확인)",
        //             "Good Job! 활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '01', slide: '09', page: '08', action: '01', scene: '04', sentences: [
        //             "늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Happy Birthday Teaching Guide",
        //             "Song: Happy Birthday 제목을 말한다.",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Happy Birthday Story 영상을 보며, 노래가사, 율동",
        //             "Look at me.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "I will sing with motions.",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday dear Anna. (두 손을 입 옆으로 가서 부른다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's sing with motions. 노래를 함께 불러본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상노래)",
        //             "영상의 Alphabet을 짚어가며, 노래한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Big A Little a Teaching Guide",
        //             "Big A Little a 제목을 말한다.",
        //             "Let's sing: Big A Little a. 영상을 본다.",
        //             "Letter A 가 무슨 소리가 날까요? ('애' 소리가 난다)",
        //             "What letter is this? / Big A and little a.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big A Little a 영상을 본다.",
        //             "Trace the big A and little a.",
        //             "apple.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Find the letters big A and little a. Teaching Guide",
        //             "Find the letters big A and little a. 말한다.",
        //             "Point to the big A and little a.",
        //             "Find the letters big A and little a. 영상을 본다.",
        //             "Say the sound of the letter A.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Find the letters big A and little a.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? Teaching Guide",
        //             "Feelings 의 영상을 보며, 노래, 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "My Face Teaching Guide",
        //             "My Face 제목을 말한다.",
        //             "This is Anna. / This is a face.",
        //             "My Face 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "These are eyes.",
        //             "How many eyes? / Let's count.",
        //             "One, two. Two eyes. / There are two eyes.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "This is a nose. / How many noses?",
        //             "One. One nose. There is one nose.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '05', sentences: [
        //             "This is a mouth. / How many mouths?",
        //             "One. One mouth. / There is one mouth.",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Happy Birthday Teaching Guide",
        //             "Song: Happy Birthday 제목을 말한다.",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Happy Birthday Story 영상을 보며, 노래가사, 율동",
        //             "Look at me.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "I will sing with motions.",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday dear Anna. (두 손을 입 옆으로 가서 부른다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's sing with motions. 노래를 함께 불러본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's Have Tea Together. Teaching Guide",
        //             "Let's Have Tea Together. 제목을 말한다.",
        //             "How many cups do we need?",
        //             "Let's Have Tea Together. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "How many cups do we need?",
        //             "We need two cups.",
        //             "Circle the cups we need.",
        //             "Trace the number in the box.",
        //             "Good job! / (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Big A Little a Teaching Guide",
        //             "Big A Little a 제목을 말한다.",
        //             "Let's sing: Big A Little a. 영상을 본다.",
        //             "Letter A 가 무슨 소리가 날까요? ('애' 소리가 난다)",
        //             "What letter is this? / Big A and little a.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big A Little a 영상을 본다.",
        //             "Trace the big A and little a.",
        //             "apple.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Big B and little b. Teaching Guide",
        //             "Big B and little b. 제목을 말한다.",
        //             "Let's sing: Big B Little b.영상을 본다.",
        //             "Letter B 가 무슨 소리가 날까요? (‘브' 소리가 난다)",
        //             "What letter is this? / Big B and little b.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big B Little b.영상을 본다.",
        //             "Trace the big B and little b.",
        //             "banana.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Find the letters big B and little b. Teaching Guide",
        //             "Find the letters big B and little b. 말한다.",
        //             "Find the letters big B and little b. 영상을 본다.",
        //             "Point to the big B and little b.",
        //             "Say the sound of the letter B.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Find the letters big B and little b.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Treasure Hunt Teaching Guide",
        //             "Treasure Hunt 제목을 말한다.",
        //             "Let's play a game.",
        //             "Find the birthday hat.",
        //             "Treasure Hunt 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Happy Birthday Teaching Guide",
        //             "Song: Happy Birthday 제목을 말한다.",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Happy Birthday Story 영상을 보며, 노래가사, 율동",
        //             "Look at me.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "I will sing with motions.",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy birthday dear Anna. (두 손을 입 옆으로 가서 부른다.)",
        //             "Happy birthday to you. (오른쪽, 왼쪽으로 박수를 친다.)",
        //             "Happy Birthday Song 영상을 본다.",
        //             "Let's sing with motions. 노래를 함께 불러본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '04', page: '20', action: '01', scene: '01', sentences: [
        //             "What Do You See? Teaching Guide",
        //             "What Do You See? 제목을 말한다.",
        //             "What is hot and what is cold?",
        //             "What Do You See? 영상을 본다.",
        //             "What is hot?",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '04', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '04', page: '20', action: '01', scene: '03', sentences: [
        //             "If it is hot, color it red.",
        //             "It's hot.",
        //             "Good job! (빨간색 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '04', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '05', page: '21', action: '01', scene: '01', sentences: [
        //             "Patterns Teaching Guide",
        //             "Patterns 제목을 말한다.",
        //             "Do you see patterns?",
        //             "Circle, square, circle, square.",
        //             "Patterns 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '05', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '05', page: '21', action: '01', scene: '03', sentences: [
        //             "What comes next?",
        //             "(Triangle) comes next.",
        //             "Put the matching stickers on the circles. .(스티커활동)",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '05', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Simon Says… Teaching Guide",
        //             "Simon Says… 제목을 말한다.",
        //             "Listen and touch your body parts.",
        //             "Listen carefully and move.",
        //             "Simon Says… 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Simon says, touch your _______!",
        //             "'사이몬 says' 라고 말이 들리면 터치하도록 해요.",
        //             "ears! / nose! / eyes! / mouth!",
        //             "Put the sticker on the circle when you are done.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '07', page: '14', action: '01', scene: '01', sentences: [
        //             "Let's Blow Bubbles Teaching Guide",
        //             "Let's Blow Bubbles! 제목을 말한다.",
        //             "Let's Blow Bubbles! 영상을 본다.",
        //             "Can you make bubbles?",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '07', page: '14', action: '01', scene: '02', sentences: [
        //             "What do we need?",
        //             "a soap / an apron / a washbowl",
        //             "Wash your hands with soap.",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '07', page: '14', action: '01', scene: '03', sentences: [
        //             "Do you see bubbles?",
        //             "Put the bubble stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '01', week: '04', slide: '07', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래한다.",
        //             "Let's sing with motions.",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가면서 (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine How do you feel today Teaching Guide",
        //             " Feelings 영상을 본다.",
        //             "How do you feel today?",
        //             "I am happy!",
        //             "Happy / sad / angry",
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             " Hello, how are you?",
        //             "How do you feel?",
        //             "Happy, happy, I am happy.",
        //             "This is how I feel",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 남자아이, 여자아이, 개, 벌레… 라고 말한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "The boy and girl are looking for animals.",
        //             "There is a (dog / beehive) in the picture.",
        //             "Do you see a (dog / butterfly / beehive / bee)?",
        //             "Yes/no. I see a dog and a beehive.",
        //             "Circle the pictures. (동그라미 치는 것 확인)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Here is the beehive. Teaching Guide",
        //             "Here is the beehive. 영상을 본다. 활동을 그림으로 그리기",
        //             "Here is the beehive. (옆에 그림을 보면서…)",
        //             "bees",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Do you see bees in the pictures?",
        //             "Can you draw bees?",
        //             "Excellent! (그리는 것으로 수업 마무리)",
        //             "Here is the beehive 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '03', page: '06', action: '05', scene: '01', sentences: [
        //             "Song: Here Is the Beehive Teaching Guide",
        //             "Song: Here Is the Beehive 제목을 말한다.",
        //             "Here Is the Beehive Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Here Is the Beehive Story 영상을 본다. (노래가사, 율동)",
        //             "Let's sing with motions.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '03', page: '06', action: '05', scene: '02', sentences: [
        //             "beehive, bees, sees, hive, five",
        //             "Put the beehive and bee stickers on the circles.",
        //             "Here Is the Beehive Song 영상을 본다, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '03', page: '06', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big B and little b. Teaching Guide",
        //             "Big B and little b. 제목을 말한다.",
        //             "Let's sing: Big B Little b.영상을 본다.",
        //             "Letter B 가 무슨 소리가 날까요? (‘브' 소리가 난다)",
        //             "What letter is this? / Big B and little b.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big B Little b.영상을 본다.",
        //             "banana.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Let's Act It Out Teaching Guide",
        //             "Let's Act It Out 제목을 말한다.",
        //             "Can you make (bee / bird) sounds?",
        //             "Buzz! / Tweet!",
        //             "Let's Act It Out 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Can you make animal sounds?",
        //             "Bee say, Buzz!... / bird say, Tweet! …",
        //             "Put the matching stickers on the circles.",
        //             "Good Job! (활동을 마무리한다)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '09', action: '01', scene: '01', sentences: [
        //             "Let's Act It Out Teaching Guide",
        //             "Can you move like animals?",
        //             "Let's pretend.",
        //             "Can you fly like a butterfly?",
        //             "fly like a butterfly",
        //             "Can you bite like a squirrel?",
        //             "bite like a squirrel",
        //             "Put the matching stickers on the circles.",
        //             "Good Job! (활동을 마무리한다)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '09', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Here Is the Beehive Teaching Guide",
        //             "Song: Here Is the Beehive 제목을 말한다.",
        //             "Here Is the Beehive Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Here Is the Beehive Story 영상, 노래가사, 율동",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "beehive, bees, sees, hive, five",
        //             "Here Is the Beehive Song 영상을 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (노래같이)",
        //             "영상의 Alphabet을 짚어가며 ...",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Big C Little c Teaching Guide",
        //             "Big C Little c 제목을 말한다.",
        //             "Let's sing: Big C Little c. 영상을 본다.",
        //             "Letter C 가 무슨 소리가 날까요? (＇크' 소리가 난다)",
        //             "What letter is this? / Big C and little c.",
        //         ]
        //     }, {
        //         level: '01', volume: '012W02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big C Little c 영상을 본다.",
        //             "Trace the little c in the word cookie.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Find the letters big C and little c. Teaching Guide",
        //             "Find the letters big C and little c. 말한다.",
        //             "Find the letters big C and little c. 영상을 본다.",
        //             "Point to the big C and little c.",
        //             "Say the sound of the letter C.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Find the letters big C and little c.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine What's the weather like today? Teaching Guide",
        //             "The Weather Song 영상을 본다.( 같이 노래한다)",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가면서 (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. ( 오늘의 날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Count the Animals Teaching Guide",
        //             "Count the Animals 제목을 말한다.",
        //             "I see (birds / butterflies / bees).",
        //             "How many animals are there?",
        //             "Count the Animals 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many (birds) are there?",
        //             "Let's count.",
        //             "One, two, three. three birds.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "This is a butterflies/ How many butterflies?",
        //             "Four, four butterflies. / There is four butterflies.",
        //             "This is a bees/ How many bees?",
        //             "Five, five bees. / There is five bees.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '05', sentences: [
        //             "One, two, three, four, five",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Here Is the Beehive Teaching Guide",
        //             "Song: Here Is the Beehive 제목을 말한다.",
        //             "Here Is the Beehive Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Here Is the Beehive Story 영상을 보며, 노래가사, 율동",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '02 ', sentences: [
        //             "Let's sing with motions.",
        //             "beehive, bees, sees, hive, five",
        //             "Here Is the Beehive Song 영상을 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine How do you feel today Teaching Guide",
        //             " Feelings 영상을 보며, 노래, 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "Happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다. (노래와 율동)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '03 ', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Patterns Teaching Guide",
        //             "Patterns 제목을 말한다.",
        //             "Let's look at the bee.",
        //             "The bee has a pattern.",
        //             "Yellow, black, yellow, black.",
        //             "Patterns 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '05', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '05', page: '12', action: '02', scene: '03', sentences: [
        //             "The bee has a pattern.",
        //             "Yellow, black, yellow, black.",
        //             "What comes next?",
        //             "Color in the box.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '05', page: '12', action: '02', scene: '04', sentences: [
        //             "Yellow comes next.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '02', slide: '05', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Big C Little c Teaching Guide",
        //             "Big C Little c 제목을 말한다.",
        //             "Let's sing: Big C Little c. 영상을 본다.",
        //             "Letter C 가 무슨 소리가 날까요? (＇크' 소리가 난다)",
        //             "What letter is this? / Big C and little c.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big C Little c 영상을 본다.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Phonics Big D Little d Teaching Guide",
        //             "Big D Little d 제목을 말한다.",
        //             "Let's sing: Big D Little d. 영상을 본다.",
        //             "Letter D 가 무슨 소리가 날까요? (‘드' 소리가 난다)",
        //             "What letter is this? / Big D and little d.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big D Little d 영상을 본다.",
        //             "Trace the little d in the word doughnut.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Find the letters big D and little d. Teaching Guide",
        //             "Find the letters big D and little d. 말한다.",
        //             "Find the letters big D and little d. 영상을 본다.",
        //             "Point to the big D and little d.",
        //             "Say the sound of the letter D.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Find the letters big D and little d.",
        //             "Good Job! ( 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Here Is the Beehive Teaching Guide",
        //             "Song: Here Is the Beehive 제목을 말한다.",
        //             "Here Is the Beehive Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Here Is the Beehive Story 영상을 보며, 노래가사, 율동",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "beehive, bees, sees, hive, five",
        //             "Here Is the Beehive Song 영상을 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '04', page: '20', action: '01', scene: '01', sentences: [
        //             "What Do You See? Teaching Guide",
        //             "What Do You See? 제목을 말한다.",
        //             "Do you see animals or plants?",
        //             "What Do You See? 영상을 본다.",
        //             "Rabbit / Cat",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '04', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '04', page: '20', action: '01', scene: '03', sentences: [
        //             "Do you see animals or plants?",
        //             "Find and color only the animals.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '04', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '05', page: '21', action: '01', scene: '01', sentences: [
        //             "Circle Teaching Guide",
        //             "Circle 제목을 말한다.",
        //             "Draw a bee with the circles.",
        //             "head, / body, / wings.",
        //             "Circle 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '05', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '05', page: '21', action: '01', scene: '03', sentences: [
        //             "Draw a bee with the circles.",
        //             "head, / body, / wings.",
        //             "Trace the circles and draw wings on it.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '05', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Simon Says… Teaching Guide",
        //             "Simon Says… 제목을 말한다.",
        //             "Let's be Robby.",
        //             "Listen and do the actions.",
        //             "Simon Says… 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Simon says, Jump like a dog!",
        //             "'사이몬 says' 라고 말이 들리면",
        //             "Jump like a dog! / Run like a cat! / Sting like a bee!",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '07', page: '14', action: '01', scene: '01', sentences: [
        //             "What Am I? Teaching Guide",
        //             "What Am I? 제목을 말한다.",
        //             "Listen to the animal sounds.",
        //             "What Am I? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '07', page: '14', action: '01', scene: '02', sentences: [
        //             "What is it?",
        //             "Can you make ( cat / dog / pig ) sounds?",
        //             "Meow! Woof! Oink!",
        //             "I am a ( cat /dog / pig ).",
        //             "I say ( meow / woof / oink ).",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '07', page: '14', action: '01', scene: '03', sentences: [
        //             "Let‘s find the same animals.",
        //             "Bee / dog/ cat / bird / squirrel",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '07', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Bumblebees Teaching Guide",
        //             "Bumblebees 제목을 말한다.",
        //             "Let's chant and do a finger play.",
        //             "Can you guess which finger stung your neck?",
        //             "Bumblebees 영상을 본다.",
        //             "all around, bumblebee, finger, guess, sting",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's chant and do a finger play.",
        //             "Bumblebees / Bumblebees",
        //             "Buzz all around.",
        //             "Who‘s going to sting?",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Hello Song Teaching Guide",
        //             "Hello Song 제목을 말한다.",
        //             "Say hello to your friends!",
        //             "Hello, teacher!",
        //             "Hello Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions. 노래와 율동을 해볼까요?",
        //             "Hello, hello. Say it high. (Hi! Hi! Hi!)",
        //             "Hello, hello. Say it low. (Hello. Hello. Hello.)",
        //             "Hello, hello. Say it fast. (Hello, hello, hello, hello, hello.)",
        //             "Hello, hello. Say it slow. (Hello~. Hello~.)",
        //             "영상을 보며, 활동 마무리..",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "The Weather Teaching Guide",
        //             "The Weather Song 영상을 며, 노래한다.",
        //             "Let's sing with motions.",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가면서 (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 금붕어, 지렁이… 라고 말한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "There is a fish and a worm.",
        //             "There is a (dog / beehive) in the picture.",
        //             "Do you see a (fish/worm/frog/bear)?",
        //             "Yes/no. I see a fish and a worm.",
        //             "Circle the pictures. (동그라미, 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '05', action: '02', scene: '01', sentences: [
        //             "Fishy Fishy in a Brook Teaching Guide",
        //             "Fishy Fishy in a Brook 영상을 보고, 물고기 그리기",
        //             "Is the fish going to eat the worm? (옆에 그림을 보면서…. )",
        //             "fish",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '05', action: '02', scene: '02', sentences: [
        //             "Do you see fish in the picture?",
        //             "Draw the fish.",
        //             "Excellent! (그리는 것으로 수업 마무리)",
        //             "Fishy Fishy in a Brook 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '06', page: '05', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Fishy Fishy in a Brook Teaching Guide",
        //             "Fishy Fishy in a Brook제목을 말한다.",
        //             "Fishy Fishy in a Brook Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Fishy Fishy in a Brook Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "baby, daddy, mommy",
        //             "Put Daddy and Mommy stickers on the circles.",
        //             "Fishy Fishy in a Brook Song 영상을 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big D Little d Teaching Guide",
        //             "Big D Little d 제목을 말한다.",
        //             "Let's sing: Big D Little d. 영상을 본다.",
        //             "Letter D 가 무슨 소리가 날까요? (‘드' 소리가 난다)",
        //             "What letter is this? / Big D and little d.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big D Little d 영상을 본다.",
        //             "doughnut",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Let's Act It Out Teaching Guide",
        //             "Let's Act It Out 제목을 말한다.",
        //             "Mommy is cooking.",
        //             "Can you cook like Mommy?",
        //             "Let's Act It Out 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Can you act like Mommy?",
        //             "Does she need a(n) (pan / oven mitt / apron / fishing pole)?",
        //             "Circle the things Mommy needs.",
        //             "She needs a pan, an oven mitt, and an apron.",
        //             "Good Job! (활동을 마무리한다)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '01', slide: '08', page: '09', action: '01', scene: '01', sentences: [
        //             "Let's Act It Out Teaching Guide",
        //             "Let's Act It Out 영상 활동",
        //             "Daddy is fishing.",
        //             "Can you fish like Daddy?",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '09', action: '01', scene: '02', sentences: [
        //             "Does he need a(n) (pan/oven mitt/apron/fishing pole)?",
        //             "He needs a fishing pole and a worm.",
        //             "Circle the things Daddy needs.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '01', slide: '08', page: '09', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Fishy Fishy in a Brook Teaching Guide",
        //             "Fishy Fishy in a Brook제목을 말한다.",
        //             "Fishy Fishy in a Brook Song 영상을 본다.",
        //             "baby, daddy, mommy 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (노래 같이)",
        //             "영상의 Alphabet을 짚어가며...",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Big E Little e Teaching Guide",
        //             "Big E Little e 제목을 말한다.",
        //             "Let's sing Big E little e. 영상을 본다.",
        //             "Letter E 가 무슨 소리가 날까요? (‘에' 소리가 난다)",
        //             "What letter is this? / Big E and little e.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big E Little e 영상을 본다.",
        //             "Trace the little e in the word egg.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Find the letters big E and little e. Teaching Guide",
        //             "Find the letters big E and little e. 말한다.",
        //             "Find the letters big E and little e. 영상을 본다.",
        //             "Point to the big E and little e.",
        //             "Say the sound of the letter E.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Find the letters big E and little e.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? Teaching Guide",
        //             "Feelings 영상을 보며, 노래, 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 보며, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Bear Family. Teaching Guide",
        //             "Bear Family. 제목을 말한다.",
        //             "This is a bear family.",
        //             "This is (Mommy/Daddy/Baby).",
        //             "Point to (Mommy/Daddy/Baby).",
        //             "Bear Family. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many bears are there?",
        //             "Let's count.",
        //             "One, two, three.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "There are three bears.",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Fishy Fishy in a Brook Teaching Guide",
        //             "Fishy Fishy in a Brook제목을 말한다.",
        //             "Fishy Fishy in a Brook Song 영상을 본다.",
        //             "baby, daddy, mommy 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '04', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine What's the weather like today? Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래한다.",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가면서 (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '04', page: '12', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '04', page: '12', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '04', page: '12', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's make fish. Teaching Guide",
        //             "Let's make fish. 제목을 말한다.",
        //             "Can you make fish with shapes?",
        //             "This is a ( circle / square / triangle )",
        //             "Let's make fish. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Can you make fish with shapes?",
        //             "This is a ( circle / square / triangle )",
        //             "Let's make a fish.",
        //             "Let's make a (head/tail) with a (circle/triangle).",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Make your own fish with stickers.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Big E Little e Teaching Guide",
        //             "Big E Little e 제목을 말한다.",
        //             "Let's sing Big E little e. 영상을 본다.",
        //             "Letter E 가 무슨 소리가 날까요? (‘에' 소리가 난다)",
        //             "What letter is this? / Big E and little e.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big C Little c 영상을 본다.",
        //             "egg",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Big F Little f Teaching Guide",
        //             "Big E Little e 제목을 말한다.",
        //             "Let's sing Big F little f. 영상을 본다.",
        //             "Letter F 가 무슨 소리가 날까요? (‘푸' 소리가 난다)",
        //             "What letter is this? / Big F and little f.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big F Little f 영상을 본다.",
        //             "Trace the little f in the word fish",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Find the letters big E and little e. Teaching Guide",
        //             "Find the letters big E and little e. 말한다.",
        //             "Find the letters big E and little e. 영상을 본다.",
        //             "Point to the big E and little e.",
        //             "Say the sound of the letter E.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Find the letters big E and little e.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Walk Around the Garden Teaching Guide",
        //             "Walk Around the Garden 제목을 말한다.",
        //             "Let's chant and do a finger play.",
        //             "Walk Around the Garden 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Walk around the garden like a teddy bear.",
        //             "One step, Two step",
        //             "Tickling / Tickling",
        //             "Tickle you everywhere.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Fishy Fishy in a Brook Teaching Guide",
        //             "Fishy Fishy in a Brook 제목을 말한다.",
        //             "Fishy Fishy in a Brook Song 영상을 본다.",
        //             "baby, daddy, mommy 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '04', page: '20', action: '01', scene: '01', sentences: [
        //             "What Do You See? Teaching Guide",
        //             "What Do You See? 제목을 말한다.",
        //             "Do you see daddy's or mommy's things?",
        //             "What Do You See? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '04', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '04', page: '20', action: '01', scene: '03', sentences: [
        //             "Do you see daddy's or mommy's things?",
        //             "Find and color mommy's things",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '04', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '01', sentences: [
        //             "Cookie Cutters Teaching Guide",
        //             "Cookie Cutters 제목을 말한다.",
        //             "What shape do you see?",
        //             "Fish / triangle / circle / square",
        //             "Cookie Cutters 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '03', sentences: [
        //             "What shape do you see?",
        //             "Fish / triangle / circle / square",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "Simon Says… Teaching Guide",
        //             "Simon Says… 제목을 말한다.",
        //             "Let's be Teddy Bear.",
        //             "Listen and do the actions.",
        //             "Simon Says… 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "Simon says, stand UP/ Touch the ground / Turn around!",
        //             "'사이몬 says' 라고 말이 들리면",
        //             "stand UP/ Touch the ground / Turn around",
        //             "Listen and do the actions.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '02', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Bubbles! Bubbles! Bubbles! Teaching Guide",
        //             "Bubbles! Bubbles! Bubbles! 제목을 말한다.",
        //             "Can you make bubbles with your hands?",
        //             "What do we need?",
        //             "Bubbles! Bubbles! Bubbles! 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '08', page: '16', action: '01', scene: '02', sentences: [
        //             "Do we need (bubble soap, an apron, a bowl)?",
        //             "We need bubble soap, an apron, and a bowl.",
        //             "Rub your hands together.",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '08', page: '16', action: '01', scene: '03', sentences: [
        //             "Touch the bubbles with your hands!",
        //             "Circle the things we need.",
        //             "bubble soap, an apron, and a bowl.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '03', week: '04', slide: '08', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Shapes Song Teaching Guide",
        //             "Shapes Song 제목을 말한다.",
        //             "What shape is this?",
        //             "Square / Circle / Triangle",
        //             "Shapes Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions. 노래와 율동을 해볼까요?",
        //             "Square, square, square!",
        //             "Circle, circle, circle!",
        //             "Triangle, triangle, triangle!",
        //             "영상을 보면서 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "The Weather Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Picture Teaching Guide",
        //             "Let's Look at the Picture 제목을 말한다.",
        //             "Let's Look at the Picture",
        //             "What do you see?",
        //             "아이) 지니, 시장…라고 말한다.",
        //             "Let's Look at the Picture 영상을 보며, 상호작용",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Here's a market.",
        //             "I see many people. / Jenny. / fruits, / vegetables,",
        //             "/ cheese and milk / a big milk can.",
        //             "Do you see (Jenny/milk/a market/a milk can)?",
        //             "Yes/no. I see Jenny, milk, a market, and a milk can.",
        //             "Circle the pictures. (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "What do you see at a market? Teaching Guide",
        //             "What do you see at a market? 영상을 보고, 물고기 그리기",
        //             "What do you see at a market? (옆에 그림을 보면서…)",
        //             "fruits, / vegetables, / cheese and milk / a big milk can.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "What do you see at a market?",
        //             "Draw a picture. / I see...",
        //             "Excellent! 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Jenny Teaching Guide",
        //             "Song: Jenny 제목을 말한다.",
        //             "Jenny Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Jenny Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "hop, market, milk",
        //             "Put the market and hop stickers on the circles.",
        //             "Jenny Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big F Little f Teaching Guide",
        //             "Big F Little f 제목을 말한다.",
        //             "Let's sing: Big F Little f. 영상을 본다.",
        //             "Letter F 가 무슨 소리가 날까요? (‘푸' 소리가 난다)",
        //             "What letter is this? / Big F and little f.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big F Little f 영상을 본다.",
        //             "fish",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Let's Act It Out Teaching Guide",
        //             "Let's Act It Out 제목을 말한다.",
        //             "This is a (market / school / hospital / home).",
        //             "This is Jenny. / Can you act like Jenny?",
        //             "Let's Act It Out 영상 활동.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '03', sentences: [
        //             "Where is she going? / Let's act like Jenny.",
        //             "hurry to the market / 	walk to school",
        //             "Run to the hospital / hop home",
        //             "Good Job! 활동을 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Jenny Teaching Guide",
        //             "Song: Jenny 제목을 말한다.",
        //             "Jenny Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Jenny Story 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Let's sing with motions. (노래가사, 율동)",
        //             "hop, market, milk",
        //             "Jenny Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (노래 같이)",
        //             "영상의 Alphabet을 짚어가며...",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Big G Little g Teaching Guide",
        //             "Big G Little g 제목을 말한다.",
        //             "Let's sing Big G little g. 영상을 본다.",
        //             "Letter G 가 무슨 소리가 날까요? (‘그' 소리가 난다)",
        //             "What letter is this? / Big G and little g.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big G Little g 영상을 본다.",
        //             "Trace the little g in the word grapes.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Find the letters big G and little g. Teaching Guide",
        //             "Find the letters big G and little g. 말한다.",
        //             "Let's listen and find the letters. ‘G g' 영상을 본다.",
        //             "Point to the big G and little g.",
        //             "Say the sound of the letter G.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '034W0-', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Find the letters big G and little g.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 보며, 노래율동.",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 의 영상을 본다(노래, 율동)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Hop, Hop, Hop Teaching Guide",
        //             "Hop, Hop, Hop 제목을 말한다.",
        //             "This is Jenny.",
        //             "Jenny is hopping to the market.",
        //             "How many hops do you see? / I see 4 hops.",
        //             "Hop, Hop, Hop 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many hops?",
        //             "Let's count.",
        //             "One, two, three. Four / I see 4 hops.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '02', slide: '08', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Jenny Teaching Guide",
        //             "Song: Jenny 제목을 말한다.",
        //             "Jenny Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Jenny Story 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Let's sing with motions. (노래가사, 율동)",
        //             "hop, market, milk",
        //             "Jenny Song 영상을 본다, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine What's the weather like today? Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.활동 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '04', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '06', page: '13', action: '02', scene: '01', sentences: [
        //             "Whose Hat Is It? Teaching Guide",
        //             "Whose Hat Is It? 제목을 말한다.",
        //             "Hats are black.",
        //             "Can you guess whose hat it is?",
        //             "Whose Hat Is It? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "There are shadow hats.",
        //             "They are black. / Whose hat is it?",
        //             "Can you find it?",
        //             "firefighter / baker / police officer",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '07', page: '18', action: '01', scene: '01', sentences: [
        //             "Big G Little g Teaching Guide",
        //             "Big G Little g 제목을 말한다.",
        //             "Let's sing Big G little g. 영상을 본다.",
        //             "Letter G 가 무슨 소리가 날까요? (‘그' 소리가 난다)",
        //             "What letter is this? / Big G and little g.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big G Little g 영상을 본다.",
        //             "grapes",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '07', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Big H Little h Teaching Guide",
        //             "Big H Little h 제목을 말한다.",
        //             "Let's sing Big H little h. 영상을 본다.",
        //             "Letter H 가 무슨 소리가 날까요? (‘흐' 소리가 난다)",
        //             "What letter is this? / Big H and little h.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big H Little h 영상을 본다.",
        //             "Trace the little h in the word ham.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '09', page: '19', action: '01', scene: '01', sentences: [
        //             "Find the letters big H and little h. Teaching Guide",
        //             "Find the letters big H and little h. 말한다.",
        //             "Find the letters big H and little h. 영상을 본다.",
        //             "Point to the big H and little h.",
        //             "Say the sound of the letter H.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '09', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '09', page: '19', action: '01', scene: '03', sentences: [
        //             "Find the letters big H and little h.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '09', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '10', page: '16', action: '01', scene: '01', sentences: [
        //             "Who Has the Milk? Teaching Guide",
        //             "Who Has the Milk? 제목을 말한다.",
        //             "Let's play a game.",
        //             "Who Has the Milk? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '10', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '10', page: '16', action: '01', scene: '03', sentences: [
        //             "Jenny has to guess who has the milk.",
        //             "Play rock, paper, and scissors",
        //             "Who has the milk?",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '10', page: '16', action: '01', scene: '04', sentences: [
        //             "How to play: / 활동 책 참조",
        //             "Play rock, paper, and scissors",
        //             "Who has the milk?",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '03', slide: '10', page: '16', action: '01', scene: '05', sentences: [
        //             "늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Jenny Teaching Guide",
        //             "Song: Jenny 제목을 말한다.",
        //             "Jenny Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Jenny Story 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Let's sing with motions. (노래가사, 율동)",
        //             "hop, market, milk",
        //             "Jenny Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "To the Market Teaching Guide",
        //             "To the Market 제목을 말한다.",
        //             "Let's buy some milk.",
        //             "What Do You See? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Right / Left / Up / Down",
        //             "Home / Fire Station / Bakery / School / Market",
        //             "Draw a line to the market.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '06', page: '21', action: '01', scene: '01', sentences: [
        //             "How To Make Milkshake Teaching Guide",
        //             "How To Make Milkshake 제목을 말한다.",
        //             "How To Make Milkshake",
        //             "Milk / Ice cream / Orange/ Grape / Strawberry / Banana",
        //             "How To Make Milkshake 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '06', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '06', page: '21', action: '01', scene: '03', sentences: [
        //             "How To Make Milkshake.",
        //             "Milk / Ice cream / Orange/ Grape / Strawberry / Banana",
        //             "Put the stickers in the blender.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '06', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '01', sentences: [
        //             "Mirror. Mirror Teaching Guide",
        //             "Mirror. Mirror 제목을 말한다.",
        //             "This is a mirror",
        //             "What do you see?",
        //             "Mirror. Mirror 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '02', sentences: [
        //             "Draw a dot on your face.",
        //             "Where is the dot in the mirror?",
        //             "Point to the dot in the mirror.",
        //             "Do you see a (happy/sad) face?",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '03', sentences: [
        //             "Let's look at the mirror.",
        //             "Can you see the dot?",
        //             "Draw your face with the dot.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '03', action: '01', scene: '01', sentences: [
        //             "Colors Song Teaching Guide",
        //             "Colors Song 제목을 말한다.",
        //             "What colors are they?",
        //             "Red / Blue / Yellow",
        //             "Colors Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions. 노래와 율동을 해볼까요?",
        //             "The color red! Red! Red!",
        //             "The color blue! Blue! Blue!",
        //             "The color yellow! Yellow! Yellow!",
        //             "This is the iWonder rainbow!",
        //             "영상을 보면서 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             " Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Picture Teaching Guide",
        //             "Let's Look at the Picture 제목을 말한다.",
        //             "Let's Look at the Picture그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 감자, 집….라고 말한다.",
        //             "Let's Look at the Picture 영상을 보며, 상호작용.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "These are potatoes.",
        //             "They are holding food",
        //             "Do you see…",
        //             "(potatoes/a measuring cup/salt/mashed potatoes)?",
        //             "Yes, I see (the potatoes/mashed potatoes).",
        //             "Circle the pictures.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "What do you want? Teaching Guide",
        //             "What kind of potatoes do you want?",
        //             "Do you want",
        //             "(mashed potatoes / french fries / a baked potato)?",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "What do you want?",
        //             "Draw a picture.",
        //             "I want (mashed potatoes).",
        //             "Excellent! 그리며, 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: One Potato Two Potato Teaching Guide",
        //             "Song: One Potato Two Potato 제목을 말한다.",
        //             "One Potato Two Potato Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One Potato Two Potato Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "one, two, three, four, five, six, seven, potato",
        //             "Put the stickers on the circles.",
        //             "One Potato Two Potato Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big H Little h Teaching Guide",
        //             "Big H Little h 제목을 말한다.",
        //             "Let's sing: Big H Little h 영상을 본다.",
        //             "Letter H 가 무슨 소리가 날까요? (‘흐' 소리가 난다)",
        //             "What letter is this? / Big H and little h.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big H Little h 영상을 본다.",
        //             "ham",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Good Foods Teaching Guide",
        //             "Good Foods 제목을 말한다.",
        //             "What foods are good for us?",
        //             "Good Foods 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '003', sentences: [
        //             "What foods are good for us?",
        //             "Is (a lollipop / rice / a cookie / fish / an apple /",
        //             "a doughnut / a potato / chocolate / a carrot)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "Good for us?",
        //             "Rice, fish, apples, potatoes, and carrots are good for us.",
        //             "It's good for me!",
        //             "Circle the good foods.",
        //             "Good Job!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Good Foods Teaching Guide",
        //             "Good Foods 제목을 말한다.",
        //             "What foods are good for us?",
        //             "Good Foods 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '003', sentences: [
        //             "What foods are good for us?",
        //             "Is (a lollipop / rice / a cookie / fish / an apple /",
        //             "a doughnut / a potato / chocolate / a carrot)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "Good for us?",
        //             "Rice, fish, apples, potatoes, and carrots are good for us.",
        //             "It's good for me!",
        //             "Circle the good foods.",
        //             "Good Job!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '09', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: One Potato Two Potato Teaching Guide",
        //             "Song: One Potato Two Potato 제목을 말한다.",
        //             "One Potato Two Potato Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One Potato Two Potato Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "one, two, three, four, five, six, seven, potato",
        //             "One Potato Two Potato Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '04', page: '09', action: '01', scene: '01', sentences: [
        //             "Phonics Shapes Song Teaching Guide",
        //             "Shapes Song 제목을 말한다.",
        //             "What shape is this?",
        //             "Square / Circle / Triangle",
        //             "Shapes Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '04', page: '09', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "Square, square, square!",
        //             "Circle, circle, circle!",
        //             "Triangle, triangle, triangle!",
        //             "영상을 보며, 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '01', slide: '04', page: '09', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '05', page: '09', action: '02', scene: '01', sentences: [
        //             "Who Is Doing a Good job? Teaching Guide",
        //             "Who Is Doing a Good job? 제목을 말한다.",
        //             "Find the friends doing a good job.",
        //             "Who Is Doing a Good job? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '05', page: '09', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '05', page: '09', action: '02', scene: '03', sentences: [
        //             "Find the friends doing a good job.",
        //             "Sandy is eating a lollipop.",
        //             "John is brushing his teeth.",
        //             "John is washing his hands.",
        //             "Jane is doing jumping ropes.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '05', page: '09', action: '02', scene: '04', sentences: [
        //             "Nick is drinking milk.",
        //             "John has dirty hands.",
        //             "Is he/she doing a good job?",
        //             "Put the good job stickers on her/him.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '05', page: '09', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '06', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (노래 같이)",
        //             "영상의 Alphabet을 짚어가며...",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '06', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '06', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '07', page: '10', action: '02', scene: '01', sentences: [
        //             "Big I Little i Teaching Guide",
        //             "Big I Little i 제목을 말한다.",
        //             "Let's sing Big I little i. 영상을 본다.",
        //             "Letter I 가 무슨 소리가 날까요? (‘이' 소리가 난다)",
        //             "What letter is this? / Big I and little i.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '07', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big I Little i 영상을 본다.",
        //             "Trace the little i in the word igloo.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '07', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '08', page: '11', action: '01', scene: '01', sentences: [
        //             "Find the letters big I and little i. Teaching Guide",
        //             "Find the letters big I and little i. 말한다.",
        //             "Find the letters big I and little i. 영상을 본다.",
        //             "Point to the big I and little i.",
        //             "Say the sound of the letter I.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '08', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '08', page: '11', action: '01', scene: '03', sentences: [
        //             "Find the letters big I and little i.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '08', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 의 영상을 보며, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '01', sentences: [
        //             "Count the Vegetables Teaching Guide",
        //             "Count the Vegetables 제목을 말한다.",
        //             "There are",
        //             "Potatoes /tomatoes / peppers / carrots",
        //             "How many (potatoes) are there? / Let's count.",
        //             "Count the Vegetables 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '03', sentences: [
        //             "How many (potatoes) are there?",
        //             "Let's count. / One, two, three. Four, Five",
        //             "There are four potatoes.",
        //             "There are three tomatoes.",
        //             "There are four peppers.",
        //             "There are five carrots.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '04', sentences: [
        //             "Trace the numbers.",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '01', sentences: [
        //             "Count the Vegetables Teaching Guide",
        //             "Count the Vegetables 제목을 말한다.",
        //             "There are",
        //             "Potatoes /tomatoes / peppers / carrots",
        //             "How many (potatoes) are there? / Let's count.",
        //             "Count the Vegetables 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '03', sentences: [
        //             "How many (potatoes) are there?",
        //             "Let's count. / One, two, three. Four, Five",
        //             "There are four potatoes.",
        //             "There are three tomatoes.",
        //             "There are four peppers.",
        //             "There are five carrots.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '04', sentences: [
        //             "Trace the numbers",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '02', slide: '10', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: One Potato Two Potato Teaching Guide",
        //             "Song: One Potato Two Potato 제목을 말한다.",
        //             "One Potato Two Potato Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One Potato Two Potato Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "one, two, three, four, five, six, seven, potato",
        //             "One Potato Two Potato Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine What's the weather like today? Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.활동 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Big Vegetables Teaching Guide",
        //             "Big Vegetables 제목을 말한다.",
        //             "Which one is big?",
        //             "Circle the big vegetables.",
        //             "Big Vegetables 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Which one is big?",
        //             "Circle the big vegetables.",
        //             "big, carrot, potato, tomato",
        //             "Fire fighter / baker / police officer",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Draw a big potato.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Big I Little i Teaching Guide",
        //             "Big I Little i 제목을 말한다.",
        //             "Let's sing Big I little i. 영상을 본다.",
        //             "Letter I 가 무슨 소리가 날까요? (‘이' 소리가 난다)",
        //             "What letter is this? / Big I and little i.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big I Little i 영상을 본다.",
        //             "igloo",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Big J Little j Teaching Guide",
        //             "Big J Little j 제목을 말한다.",
        //             "Let's sing Big J little j. 영상을 본다.",
        //             "Letter J 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
        //             "What letter is this? / Big J and little j.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big J Little j 영상을 본다.",
        //             "Trace the little j in the word Jam.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Find the letters big J and little j Teaching Guide",
        //             "Find the letters big J and little j 말한다.",
        //             "Find the letters big J and little j 영상을 본다.",
        //             "Point to the big J and little j.",
        //             "Say the sound of the letter J.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Find the letters big J and little j",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Speed Number Game Teaching Guide",
        //             "Speed Number Game제목을 말한다.",
        //             "Let's look at the cards. / What do you see?",
        //             "Speed Number Game 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's play the Speed Number Game.",
        //             "One, two, three. Four, Five",
        //             "Listen and get it!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "How to play: / 활동 책 참조",
        //             "Find one apple!",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '03', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: One Potato Two Potato Teaching Guide",
        //             "Song: One Potato Two Potato 제목을 말한다.",
        //             "One Potato Two Potato Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One Potato Two Potato Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "one, two, three, four, five, six, seven, potato",
        //             "One Potato Two Potato Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '04', page: '20', action: '01', scene: '01', sentences: [
        //             "What Do You See? Teaching Guide",
        //             "What Do You See? 제목을 말한다.",
        //             "Do you see soft or hard food?",
        //             "What Do You See? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '04', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '04', page: '20', action: '01', scene: '03', sentences: [
        //             "potato, tomato ,banana, cake ice cream, carrot,",
        //             "Find and color the hard food.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '04', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '01', sentences: [
        //             "Hot Potato Game Teaching Guide",
        //             "Hot Potato Game 제목을 말한다.",
        //             "Let's play a game.",
        //             "Song: One Potato Two Potato 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '03', sentences: [
        //             "Let's play a game.",
        //             "Talk about how to play the game.",
        //             "How to play 활동 참조",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '01', sentences: [
        //             "Hot Potato Game Teaching Guide",
        //             "Hot Potato Game 제목을 말한다.",
        //             "Let's play a game.",
        //             "Song: One Potato Two Potato 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '03', sentences: [
        //             "Let's play a game.",
        //             "Talk about how to play the game.",
        //             "How to play 활동 참조",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '05', week: '04', slide: '05', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Thank You Song Teaching Guide",
        //             "Thank You Song 제목을 말한다.",
        //             "Say thank you to friends!",
        //             "Thank You Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions. 노래와 율동을 해볼까요?",
        //             "Let's all say, thank you.",
        //             "Let's all say, thank you, we thank you very much.",
        //             "Thank you, to teacher!",
        //             "Thank you, to friends!",
        //             "Let's all say, thank you, we thank you very much.",
        //             "영상을 보면서 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "The Weather Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래.",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Picture Teaching Guide",
        //             "Let's Look at the Picture 제목을 말한다.",
        //             "Let's Look at the Picture그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 칠면조, 모자…라고 말한다.",
        //             "Let's Look at the Picture 영상을 보며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "This is a farm.",
        //             "There are animals.",
        //             "Do you see a",
        //             "(cat/turkey/rabbit/dog)?",
        //             "Yes/no. I see a turkey.",
        //             "Find the animal.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "I have feathers! Teaching Guide",
        //             "Can you see feathers on the turkey?",
        //             "Turkeys have feathers.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Draw feathers on the turkey.",
        //             "Excellent! (그리는 것으로 수업 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Funny Turkey Teaching Guide",
        //             "Song: Funny Turkey 제목을 말한다.",
        //             "Funny Turkey Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Funny Turkey Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "bird, funny, gobble, turkey, wobble",
        //             "Put the matching stickers on the circles.",
        //             "Funny Turkey Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big J Little j Teaching Guide",
        //             "Big J Little j 제목을 말한다.",
        //             "Let's sing: Big J Little j 영상을 본다.",
        //             "Letter J 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
        //             "What letter is this? / Big J and little j.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big J Little j 영상을 본다.",
        //             "jam",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Move Your Body Teaching Guide",
        //             "Move Your Body 제목을 말한다.",
        //             "Let's act like Funny Turkey.",
        //             "Move Your Body 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Funny Turkey wobbles his head.",
        //             "He says gobble.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "Can you wobble your head?",
        //             "Can you say gobble?",
        //             "Wobble your head!",
        //             "Good Job! (활동을 마무리한다)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Funny Turkey Teaching Guide",
        //             "Song: Funny Turkey 제목을 말한다.",
        //             "Funny Turkey Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Funny Turkey Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "bird, funny, gobble, turkey, wobble",
        //             "Funny Turkey Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '04', page: '09', action: '01', scene: '01', sentences: [
        //             "Colors Song Teaching Guide",
        //             "Colors Song 제목을 말한다.",
        //             "What colors are they?",
        //             "Red / Blue / Yellow",
        //             "Colors Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '04', page: '09', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions. 노래와 율동을 해볼까요?",
        //             "The color red! Red! Red!",
        //             "The color blue! Blue! Blue!",
        //             "The color yellow! Yellow! Yellow!",
        //             "This is the iWonder rainbow!",
        //             "영상을 보면서 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '04', page: '09', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '05', page: '09', action: '02', scene: '01', sentences: [
        //             "'Say Hello!' Teaching Guide",
        //             "'Say Hello!' 제목을 말한다.",
        //             "'Say Hello!' to each other.",
        //             "'Say Hello!' 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '05', page: '09', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '05', page: '09', action: '02', scene: '03', sentences: [
        //             "Say 'Hello' to each other.",
        //             "Say 'Hello' in",
        //             "(Chinese, Japanese, Korean, English, French).",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '05', page: '09', action: '02', scene: '04', sentences: [
        //             "Ni hao! / Konnichiwa!",
        //             "안녕! / Hello! / Bonjour!",
        //             "Put the sticker on the circle.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '05', page: '09', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '06', page: '10', action: '01', scene: '01', sentences: [
        //             "The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '06', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '06', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '07', page: '10', action: '02', scene: '01', sentences: [
        //             "Big K Little k Teaching Guide",
        //             "Big K Little k 제목을 말한다.",
        //             "Let's sing Big K little k. 영상을 본다.",
        //             "Letter K 가 무슨 소리가 날까요? ('크' 소리가 난다)",
        //             "What letter is this? / Big K and little k.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '07', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big K Little k 영상을 본다.",
        //             "Trace the little k in the word kiwi.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '07', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '08', page: '10', action: '03', scene: '01', sentences: [
        //             "Find the letters big K and little k. Teaching Guide",
        //             "Find the letters big K and little k. 말한다.",
        //             "Find the letters big K and little k. 영상을 본다.",
        //             "Point to the big K and little k.",
        //             "Say the sound of the letter K.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '08', page: '10', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '08', page: '10', action: '03', scene: '03', sentences: [
        //             "Find the letters big K and little k.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '08', page: '10', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '09', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 보며, 노래율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '09', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 의 영상을 보며, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '09', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '10', page: '12', action: '02', scene: '01', sentences: [
        //             "Fall Foods Teaching Guide",
        //             "Fall Foods 제목을 말한다.",
        //             "How many do you see?",
        //             "pears /apples / peanuts / chestnuts",
        //             "These are foods you can see in the fall.",
        //             "Fall Foods 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '10', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '10', page: '12', action: '02', scene: '03', sentences: [
        //             "How many (pears) do you see?",
        //             "Let's count. / One, two, three. Four, Five, six",
        //             "There are (four) (pears).",
        //             "There are three apples",
        //             "There are five peanuts",
        //             "There are six chestnuts",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '10', page: '12', action: '02', scene: '04', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '10', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Funny Turkey Teaching Guide",
        //             "Song: Funny Turkey 제목을 말한다.",
        //             "Funny Turkey Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Funny Turkey Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "bird, funny, gobble, turkey, wobble",
        //             "Funny Turkey Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine What's the weather like today? Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '04', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Patterns: Hanbok Teaching Guide",
        //             "Patterns: Hanbok 제목을 말한다.",
        //             "These are hanboks.",
        //             "Say the colors in the pattern. What comes next?",
        //             "Patterns: Hanbok 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Say the colors in the pattern. / What comes next?",
        //             "Can you see patterns on the hanboks?",
        //             "Yes, I see patterns.",
        //             "Blue / Yellow / Green / Red",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Color in the boxes.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Big K Little k Teaching Guide",
        //             "Big K Little k 제목을 말한다.",
        //             "Let's sing Big K little k. 영상을 본다.",
        //             "Letter K 가 무슨 소리가 날까요? ('크' 소리가 난다)",
        //             "What letter is this? / Big K and little k.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big K Little k 영상을 본다.",
        //             "kiwi",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "Big L Little l Teaching Guide",
        //             "Big L Little l 제목을 말한다.",
        //             "Let's sing Big K little l. 영상을 본다.",
        //             "Letter L 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
        //             "What letter is this? / Big L and little l.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big L Little l 영상을 본다.",
        //             "Trace the little l in the word lemon.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Find the letters big L and little l. Teaching Guide",
        //             "Find the letters big L and little l. 말한다.",
        //             "Find the letters big L and little l. 영상을 본다.",
        //             "Point to the big L and little l.",
        //             "Say the sound of the letter L.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Find the letters big L and little l.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '01', sentences: [
        //             "Look Inside! Teaching Guide",
        //             "Look Inside! 제목을 말한다.",
        //             "What is in the sandwich?",
        //             "Cut the sandwich in half.",
        //             "Let's Make Chicken Sandwich 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '02', sentences: [
        //             "What is in the sandwich?",
        //             "Tomato, cheese, 김치, salad, banana, Chicken",
        //             "Cut the sandwich in half.",
        //             "Look inside!",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Funny Turkey Teaching Guide",
        //             "Song: Funny Turkey 제목을 말한다.",
        //             "Funny Turkey Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Funny Turkey Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "bird, funny, gobble, turkey, wobble",
        //             "Funny Turkey Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '04', page: '18', action: '01', scene: '01', sentences: [
        //             "Big L Little l Teaching Guide",
        //             "Big L Little l 제목을 말한다.",
        //             "Let's sing Big K little l. 영상을 본다.",
        //             "Letter L 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
        //             "What letter is this? / Big L and little l.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big L Little l 영상을 본다.",
        //             "lemon",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '04', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "Big M Little m Teaching Guide",
        //             "Big M Little m 제목을 말한다.",
        //             "Let's sing Big M little m. 영상을 본다.",
        //             "Letter M 가 무슨 소리가 날까요? (‘ 므' 소리가 난다)",
        //             "What letter is this? / Big M and little m.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big M Little m 영상을 본다.",
        //             "Trace the little m in the word milk.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Find the letters big M and little m. Teaching Guide",
        //             "Find the letters big M and little m. 말한다.",
        //             "Find the letters big M and little m. 영상을 본다.",
        //             "Point to the big M and little m.",
        //             "Say the sound of the letter M.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Find the letters big M and little m.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Make the Words .",
        //             "Let's find the first letter of each word.",
        //             "Kiwi/Lemon/Milk) starts with (k/l/m).",
        //             "Put the matching stickers on the circles.",
        //             "Good job! 하이파이브!",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "Today is Funny Turkey's wedding day.",
        //             "What Do You See? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "ice cream / milk / yogurt / cheese/ camera / wedding cake",
        //             "Find the hidden things.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '08', page: '21', action: '01', scene: '01', sentences: [
        //             "Same or Different? Teaching Guide",
        //             "Same or Different? 제목을 말한다.",
        //             "What is different?",
        //             "Same or Different? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '08', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '08', page: '21', action: '01', scene: '03', sentences: [
        //             "What is different?",
        //             "Circle what is different.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '08', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Memory Game Teaching Guide",
        //             "Memory Game 제목을 말한다.",
        //             "Look, they are the same!",
        //             "Memory Game 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's look at the cards.",
        //             "Can you find the same cards?",
        //             "Look, they are the same!",
        //             "cat, chicken, dog, rabbit, turkey",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "Let's play a memory game.",
        //             "Guess which cards are the same.",
        //             "How to play 교재 참조",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '04', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Numbers Song. Teaching Guide",
        //             "Numbers Song. 제목을 말한다.",
        //             "Can you count the numbers?",
        //             "Numbers Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "1, 2, 3, 4, 5. 6, 7, 8, 9, 10.",
        //             "One, Two, Three, Four, Five,",
        //             "Six, Seven, Eight, Nine, Ten",
        //             "Let's sing the Numbers Song!",
        //             "영상을 보며 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "The Weather Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy 말한다.",
        //             "It is windy. windy 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Picture Teaching Guide",
        //             "Let's Look at the Picture 제목을 말한다.",
        //             "Let's Look at the Picture그림을 가리키며, 말한다.",
        //             "What do you see in the sea?",
        //             "아이) 춤추는 엄마, 오빠, 언니, 배, 바다... 라고 말한다.",
        //             "Let's Look at the Picture 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "This is the sea.",
        //             "A mom, a girl and a boy are dancing.",
        //             "Do you see a",
        //             "(bike/truck/boat/car)?",
        //             "Yes/no. I see a boat.",
        //             "Find the Picture",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Do you see a",
        //             "(boat/fish/seashell/starfish) in the sea?",
        //             "I see a boat.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Draw some boats.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Dance for Your Daddy Teaching Guide",
        //             "Song: Dance for Your Daddy 제목을 말한다.",
        //             "Dance for Your Daddy Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Dance for Your Daddy Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "boat, daddy, fishy, lady, man",
        //             "Put the matching stickers on the circles.",
        //             "Dance for Your Daddy Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Big M Little m Teaching Guide",
        //             "Big M Little m 제목을 말한다.",
        //             "Let's sing Big M little m. 영상을 본다.",
        //             "Letter M 가 무슨 소리가 날까요? (‘ 므' 소리가 난다)",
        //             "What letter is this? / Big M and little m.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big M Little m 영상을 본다.",
        //             "milk",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Daddy Is Coming! Teaching Guide",
        //             "How is Daddy coming home?",
        //             "Daddy Is Coming! 제목을 말한다.",
        //             "Daddy Is Coming! 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Is Daddy coming by (car/motorcycle/tractor/boat)?",
        //             "Yes/No. Daddy is coming home by boat.",
        //             "Find the picture.",
        //             "Good Job!",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Dance for Your Daddy Teaching Guide",
        //             "Song: Dance for Your Daddy 제목을 말한다.",
        //             "Dance for Your Daddy Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Dance for Your Daddy Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "boat, daddy, fishy, lady, man",
        //             "Dance for Your Daddy Song 영상을 본다. 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '04', page: '09', action: '01', scene: '01', sentences: [
        //             "Daily Routine Thank You Song. Teaching Guide",
        //             "Thank You Song. 제목을 말한다.",
        //             "Let's all say, thank you.",
        //             "Teacher / friends",
        //             "Thank You Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '04', page: '09', action: '01', scene: '02', sentences: [
        //             "Let's sing: Thank You Song.",
        //             "Let's all say, thank you.",
        //             "we thank you very much.",
        //             "Thank you, to teacher!",
        //             "Thank you, to friends!",
        //         ]
        //     }, {
        //         level: '01', volume: '06', week: '02', slide: '04', page: '09', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '09', action: '02', scene: '01', sentences: [
        //             "Go and Stop Teaching Guide",
        //             "Go and Stop 제목을 말한다.",
        //             "Let's play a game!",
        //             "Say 'Hello!' 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '09', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '09', action: '02', scene: '03', sentences: [
        //             "Green for Go!",
        //             "Red for Stop!",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '09', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '10', action: '01', scene: '01', sentences: [
        //             "The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '05', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '06', page: '10', action: '02', scene: '01', sentences: [
        //             "Big N Little k Teaching Guide",
        //             "Let's sing Big N little n. 영상을 본다.",
        //             "Big N Little n 제목을 말한다.",
        //             "Letter N 가 무슨 소리가 날까요? ('느' 소리가 난다)",
        //             "What letter is this? / Big N and little n.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '06', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big N Little n 영상을 본다.",
        //             "Trace the little n in the word nuts.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '06', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '07', page: '10', action: '03', scene: '01', sentences: [
        //             "Find the letters big N and little n. Teaching Guide",
        //             "Find the letters big N and little n. 말한다.",
        //             "Find the letters big N and little n. 영상을 본다.",
        //             "Point to the big N and little n.",
        //             "Say the sound of the letter N.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '07', page: '10', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '07', page: '10', action: '03', scene: '03', sentences: [
        //             "Find the letters big N and little n.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '07', page: '10', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '08', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 보며 노래 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '08', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 영상을 보며, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '08', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Boats and Shapes Teaching Guide",
        //             "Boats and Shapes 제목을 말한다.",
        //             "What shapes do you see?",
        //             "Find the (circles/triangles/rectangles) in the boat",
        //             "Boats and Shapes 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "These are boats.",
        //             "How many (circles) do you see in the boat?",
        //             "I see (three) (circles).",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "What shapes do you see?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '02', slide: '09', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Dance for Your Daddy Teaching Guide",
        //             "Song: Dance for Your Daddy 제목을 말한다.",
        //             "Dance for Your Daddy Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Dance for Your Daddy Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "boat, daddy, fishy, lady, man",
        //             "Dance for Your Daddy Song 영상을 본다. 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Numbers Song. Teaching Guide",
        //             "Numbers Song. 제목을 말한다.",
        //             "Can you count the numbers?",
        //             "Numbers Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "1, 2, 3, 4, 5. 6, 7, 8, 9, 10.",
        //             "One, Two, Three, Four, Five,",
        //             "Six, Seven, Eight, Nine, Ten",
        //             "Let's sing the Numbers Song!",
        //             "영상을 보며, 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Same Pictures Teaching Guide",
        //             "Same Pictures 제목을 말한다.",
        //             "Can you find the same pictures?",
        //             "The (tractors/cars/trucks/boats) are the same.",
        //             "Same Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Can you find the same pictures?",
        //             "The (tractors/cars/trucks/boats) are the same.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Let's find the same pictures.",
        //             "Circle the same pictures.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Big N Little n Teaching Guide",
        //             "Big N Little n 제목을 말한다.",
        //             "Let's sing Big N little n. 영상을 본다.",
        //             "Letter N 가 무슨 소리가 날까요? (‘느' 소리가 난다)",
        //             "What letter is this? / Big N and little n.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big N Little n 영상을 본다.",
        //             "nuts",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "Big O Little o Teaching Guide",
        //             "Big O Little o 제목을 말한다.",
        //             "Let's sing Big O little o. 영상을 본다.",
        //             "Letter O 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
        //             "What letter is this? / Big O and little o.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big O Little o 영상을 본다.",
        //             "Trace the little o in the word orange.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Find the letters big O and little o Teaching Guide",
        //             "Find the letters big O and little o 말한다.",
        //             "Find the letters big O and little o 영상을 본다.",
        //             "Point to the big O and little o.",
        //             "Say the sound of the letter O.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Find the letters big O and little o.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Guessing Game Teaching Guide",
        //             "Guessing Game 제목을 말한다.",
        //             "Can you find the shapes?",
        //             "blue, circle, red, square, triangle, yellow",
        //             "Guessing Game 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's listen and find the shapes.",
        //             "blue, circle, red, square, triangle, yellow",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "Make your own transportation with the shapes.",
        //             "What did you make?",
        //             "Good Job! ( 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '03', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Dance for Your Daddy Teaching Guide",
        //             "Song: Dance for Your Daddy 제목을 말한다.",
        //             "Dance for Your Daddy Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Dance for Your Daddy Story 영상을 본다.",
        //             "Let's sing with motions.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "boat, daddy, fishy, lady, man",
        //             "Dance for Your Daddy Song 영상을 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '04', page: '19', action: '01', scene: '01', sentences: [
        //             "Big O Little o Teaching Guide",
        //             "Big O Little o 제목을 말한다.",
        //             "Let's sing Big O little o. 영상을 본다.",
        //             "Letter O 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
        //             "What letter is this? / Big O and little o.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '04', page: '19', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big O Little o 영상을 본다.",
        //             "Trace the little o in the word orange.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '04', page: '19', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "Big P Little p Teaching Guide",
        //             "Big P Little p 제목을 말한다.",
        //             "Let's sing Big P little p. 영상을 본다.",
        //             "Letter P 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
        //             "What letter is this? / Big P and little p.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big P Little p 영상을 본다.",
        //             "Trace the little p in the word pizza",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Find the letters big P and little p. Teaching Guide",
        //             "Find the letters big P and little p. 말한다.",
        //             "Find the letters big P and little p. 영상을 본다.",
        //             "Say the sound of the letter P",
        //             "Point to the big P and little p.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Find the letters big P and little p.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Make the Words.",
        //             "Let's find the first letter of each word.",
        //             "(Nuts/Orange/Pizza) starts with (n/o/p).",
        //             "Put the matching stickers on the circles.",
        //             "Good job! 하이파이브!",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "We are going to Grandma's house.",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "apple/ grapes / orange / bananas / boat / bicycle",
        //             "Find the hidden things.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '08', page: '21', action: '01', scene: '01', sentences: [
        //             "Cross the Street Teaching Guide",
        //             "Cross the Street 제목을 말한다.",
        //             "Robby wants to cross the street.",
        //             "Cross the Street 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '08', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '08', page: '21', action: '01', scene: '03', sentences: [
        //             "Let's move Robby.",
        //             "Left / Right",
        //             "Put the sticker on the circle.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '08', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Memory Game Teaching Guide",
        //             "Memory Game 제목을 말한다.",
        //             "Look, they are the same!",
        //             "Memory Game 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's look at the cards.",
        //             "Can you find the same cards?",
        //             "Look, they are the same!",
        //             "cat, chicken, dog, rabbit, turkey",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "Let's play a memory game.",
        //             "Guess which cards are the same.",
        //             "How to play 교재 참조",
        //         ]
        //     }, {
        //         level: '01', volume: '07', week: '04', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Stretching Song. Teaching Guide",
        //             "Stretching Song. 제목을 말한다.",
        //             "Move your body while you are singing.",
        //             "Stretching Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "Stretch your arms up and down.",
        //             "Up, up, up, up, up!",
        //             "Down, down, down, down, down!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "Right, right, right, right!",
        //             "Left, left, left, left!",
        //             "Stretch your neck round and round.",
        //             "Round and round and round!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "The Weather Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy를 말한다.",
        //             "It is snowy. snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 그림을 가리키며, 말한다.",
        //             "What do you see in the sea?",
        //             "아이) 구두, 망치든 아저씨, 개라고 말한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "This is Cobbler./ He fixes shoes.",
        //             "There are some tools and shoes in the picture.",
        //             "Do you see",
        //             "(a hammer/shoes/popcorn/Cobbler)?",
        //             "Yes/no. I see (a hammer/shoes/Cobbler).",
        //             "Find the Pictures. (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "What color are your shoes?",
        //             "My shoes are ( blue / red / brown / pink ).",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Show your shoes to your friends.",
        //             "Draw your shoes.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Cobbler Cobbler Teaching Guide",
        //             "Song: Cobbler Cobbler 제목을 말한다.",
        //             "Cobbler Cobbler Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Cobbler Cobbler Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "fix, popcorn, shoe",
        //             "Put the matching stickers on the circles.",
        //             "Cobbler Cobbler Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big P Little p Teaching Guide",
        //             "Big P Little p 제목을 말한다.",
        //             "Let's sing: Big P Little p 영상을 본다.",
        //             "Letter P 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
        //             "What letter is this? / Big P and little p.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big P Little p 영상을 본다.",
        //             "pizza",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Cobbler's Workshop Teaching Guide",
        //             "Cobbler's Workshop 제목을 말한다.",
        //             "What is Cobbler fixing in the story?",
        //             "Cobbler's Workshop 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Is Cobbler fixing (a clock/a shoe/an umbrella/a camera)?",
        //             "Yes/No. Cobbler is fixing a shoe.",
        //             "Find the picture.",
        //             "Good Job!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Cobbler Cobbler Teaching Guide",
        //             "Song: Cobbler Cobbler 제목을 말한다.",
        //             "Cobbler Cobbler Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Cobbler Cobbler Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "fix, popcorn, shoe",
        //             "Cobbler Cobbler Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '04', page: '09', action: '01', scene: '01', sentences: [
        //             "Numbers Song. Teaching Guide",
        //             "Numbers Song. 제목을 말한다.",
        //             "Can you count the numbers?",
        //             "Numbers Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '04', page: '09', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "1, 2, 3, 4, 5. 6, 7, 8, 9, 10.",
        //             "One, Two, Three, Four, Five,",
        //             "Let's sing the Numbers Song!",
        //             "Six, Seven, Eight, Nine, Ten",
        //             "영상을 보면서 활동 마무리",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '04', page: '09', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '05', page: '09', action: '02', scene: '01', sentences: [
        //             "Missing Tools Teaching Guide",
        //             "Missing Tools 제목을 말한다.",
        //             "What do you need?",
        //             "Missing Tools 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '05', page: '09', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '05', page: '09', action: '02', scene: '03', sentences: [
        //             "I need a",
        //             "(toothbrush/brush/fork/knife).",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '05', page: '09', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '05', page: '10', action: '01', scene: '01', sentences: [
        //             "The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '06', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '06', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '07', page: '10', action: '02', scene: '01', sentences: [
        //             "Big Q Little q Teaching Guide",
        //             "Big Q Little q 제목을 말한다.",
        //             "Let's sing Big Q little q. 영상을 본다.",
        //             "Letter Q 가 무슨 소리가 날까요? ('크' 소리가 난다)",
        //             "What letter is this? / Big Q and little q.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '07', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big Q Little q 영상을 본다.",
        //             "Trace the little q in the word queen.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '07', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '08', page: '10', action: '03', scene: '01', sentences: [
        //             "Find the letters big Q and little q. Teaching Guide",
        //             "Find the letters big Q and little q. 말한다.",
        //             "Find the letters big Q and little q. 영상을 본다.",
        //             "Point to the big Q and little q.",
        //             "Say the sound of the letter Q.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '08', page: '10', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '08', page: '10', action: '03', scene: '03', sentences: [
        //             "Find the letters big Q and little q.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '08', page: '10', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '09', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 보며, 노래 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '09', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 영상을 보며, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '08', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '01', sentences: [
        //             "Boats and Shapes Teaching Guide",
        //             "Boats and Shapes 제목을 말한다.",
        //             "What shapes do you see?",
        //             "Find the (circles/triangles/rectangles) in the boat",
        //             "Boats and Shapes 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '03', sentences: [
        //             "These are boats.",
        //             "How many (circles) do you see in the boat?",
        //             "I see (three) (circles).",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '04', sentences: [
        //             "What shapes do you see?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '01', sentences: [
        //             "Same or Different Teaching Guide",
        //             "Same or Different 제목을 말한다.",
        //             "What is (the same/different)?",
        //             "Find the (same/different) things in the picture.",
        //             "Same or Different 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '03', sentences: [
        //             "Find the (same/different) things in the picture.",
        //             "The (popcorn machine/oven/paint roller) is missing.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '04', sentences: [
        //             "Circle the three different things.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '02', slide: '10', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Cobbler Cobbler Teaching Guide",
        //             "Song: Cobbler Cobbler 제목을 말한다.",
        //             "Cobbler Cobbler Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Cobbler Cobbler Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "fix, popcorn, shoe",
        //             "Cobbler Cobbler Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Stretching Song. Teaching Guide",
        //             "Stretching Song. 제목을 말한다.",
        //             "Move your body while you are singing.",
        //             "Stretching Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "Stretch your arms up and down.",
        //             "Up, up, up, up, up!",
        //             "Down, down, down, down, down!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "Right, right, right, right!",
        //             "Left, left, left, left!",
        //             "Stretch your neck round and round.",
        //             "Round and round and round!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Same Pictures Teaching Guide",
        //             "Same Pictures 제목을 말한다.",
        //             "Can you find the same pictures?",
        //             "The (tractors/cars/trucks/boats) are the same.",
        //             "Same Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Can you find the same pictures?",
        //             "The (tractors/cars/trucks/boats) are the same.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Let's find the same pictures.",
        //             "Circle the same pictures.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Big Q Little q Teaching Guide",
        //             "Big Q Little q 제목을 말한다.",
        //             "Let's sing Big Q little q. 영상을 본다.",
        //             "Letter Q 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
        //             "What letter is this? / Big Q and little q.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big Q Little q 영상을 본다.",
        //             "Good job! (활동 마무리)",
        //             "queen",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "Big R Little r Teaching Guide",
        //             "Big R Little r 제목을 말한다.",
        //             "Let's sing Big R little r. 영상을 본다.",
        //             "Letter R 가 무슨 소리가 날까요? (‘으르' 소리가 난다)",
        //             "What letter is this? / Big R and little r.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big R Little r 영상을 본다.",
        //             "Trace the little r in the word rice.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Find the letters big R and little r Teaching Guide",
        //             "Find the letters big R and little r 말한다.",
        //             "Find the letters big R and little r 영상을 본다.",
        //             "Point to the big R and little r.",
        //             "Say the sound of the letter R.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Find the letters big R and little r.",
        //             "Good Job!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "I Spy Teaching Guide",
        //             "I Spy 제목을 말한다.",
        //             "Can you find the same picture?",
        //             "brush, clock, fork, frying pan, glasses, hair dryer, hammer",
        //             "knife, phone, robot, spoon, toaster, toothbrush",
        //             "I Spy 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's look at the two cards.",
        //             "brush, clock, fork, frying pan, glasses, hair dryer, hammer",
        //             " knife, phone, robot, spoon, toaster, toothbrush",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "Let's play I Spy.",
        //             "Find the same picture.",
        //             "Good Job! ( 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '03', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Cobbler Cobbler Teaching Guide",
        //             "Song: Cobbler Cobbler 제목을 말한다.",
        //             "Cobbler Cobbler Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Cobbler Cobbler Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "fix, popcorn, shoe",
        //             "Cobbler Cobbler Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '04', page: '18', action: '01', scene: '01', sentences: [
        //             "Big R Little r Teaching Guide",
        //             "Big R Little r 제목을 말한다.",
        //             "Let's sing Big R little r. 영상을 본다.",
        //             "Letter R 가 무슨 소리가 날까요? (‘으르' 소리가 난다)",
        //             "What letter is this? / Big R and little r.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '04', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big R Little r 영상을 본다.",
        //             "Trace the little r in the word rice.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '04', page: '18', action: '01', scene: '03', sentences: [
        //             " 오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "Big S Little s Teaching Guide",
        //             "Big S Little s 제목을 말한다.",
        //             "Let's sing Big S little s. 영상을 본다.",
        //             "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다)",
        //             "What letter is this? / Big S and little s.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big S Little s 영상을 본다.",
        //             "Trace the little s in the word spoon.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Find the letters big S and little s. Teaching Guide",
        //             "Find the letters big S and little s. 말한다.",
        //             "Find the letters big S and little s. 영상을 본다.",
        //             "Point to the big S and little s.",
        //             "Say the sound of the letter S.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Find the letters big S and little s.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Make the Words.",
        //             "Let's find the first letter of each word.",
        //             "(Queen/Rice/Spoon) starts with (q/r/s).",
        //             "Put the matching stickers on the circles.",
        //             "Good job! 하이파이브!",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "This is Cobbler's workshop. / Look around!",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "broccoli/ carrot / corn / tomato / glasses / hammer / robot / shovel",
        //             "Find the hidden things.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '08', page: '21', action: '01', scene: '01', sentences: [
        //             "What Is It? Teaching Guide",
        //             "What Is It? 제목을 말한다.",
        //             "Listen and find the answer.",
        //             "What Is It? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '08', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '08', page: '21', action: '01', scene: '03', sentences: [
        //             "Listen and find the answer.",
        //             "It's round.",
        //             "It says 'tick tock!'",
        //             "It is a … CLOCK ( 시계 스티커)",
        //             "Put the matching sticker on the circle.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '08', week: '04', slide: '08', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Seasons Song. Teaching Guide",
        //             "Seasons Song. 제목을 말한다.",
        //             "Pretend to be a tree while you are singing.",
        //             "Seasons Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "Spring, Summer, Fall, Winter",
        //             "Spring, Summer, Fall, Winter",
        //             "These are the four seasons.",
        //             "Spring, Summer, Fall, Winter",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래.",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy 말한다.",
        //             "It is windy. windy 말한다.",
        //             "It is snowy. snowy 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             " Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 그림을 가리키며, 말한다.",
        //             "What do you see in the sea?",
        //             "아이) 아이, 파이, 크리마스 트리, 선물... 라고 말한다.",
        //             " Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "It's Christmas!",
        //             "This is Jack Horner.",
        //             "Do you see .",
        //             "(a Christmas tree/a pie/Jack/Santa)?",
        //             "Yes/no. I see I see (a Christmas tree/a pie/Jack).",
        //             "Find the Pictures. (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "I want a Christmas pie!",
        //             "I want to eat (turkey/cookies/pie/ham).",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Do you want to eat (turkey/cookies/pie/ham)?",
        //             "Draw what you want to eat.",
        //             "Excellent! (그리는 것으로 수업 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Little Jack Horner Teaching Guide",
        //             "Song: Little Jack Horner 제목을 말한다.",
        //             "Little Jack Horner Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Little Jack Horner Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "boy, Christmas pie, good, plum, put, thumb",
        //             "Little Jack Horner Song 영상을 본다. 마무리한다.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big S Little s Teaching Guide",
        //             "Big S Little s 제목을 말한다.",
        //             "Let's sing: Big S Little s 영상을 본다.",
        //             "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다)",
        //             "What letter is this? / Big S and little s.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big S Little s 영상을 본다.",
        //             "spoon",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Be a Good Boy! Teaching Guide",
        //             "Be a Good Boy! 제목을 말한다.",
        //             "Jack pulled out the plum with his thumb.",
        //             "Be a Good Boy! 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Is Jack a good boy?",
        //             "Can Jack get a Christmas present from Santa?",
        //             "Santa gives Christmas presents to good boys and girls.",
        //             "Put the sticker on the circle.",
        //             "Good Job! (활동을 마무리한다)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '09', page: '09', action: '01', scene: '01', sentences: [
        //             "Stretching Song. Teaching Guide",
        //             "Stretching Song. 제목을 말한다.",
        //             "Move your body while you are singing.",
        //             "Stretching Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '09', page: '09', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions. 노래와 율동을 해볼까요?",
        //             "Stretch your arms up and down.",
        //             "Up, up, up, up, up!",
        //             "Down, down, down, down, down!",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '09', page: '09', action: '01', scene: '03', sentences: [
        //             "Right, right, right, right!",
        //             "Left, left, left, left!",
        //             "Stretch your neck round and round.",
        //             "Round and round and round!",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '09', page: '09', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '10', page: '09', action: '02', scene: '01', sentences: [
        //             "Winter Clothes Teaching Guide",
        //             "Winter Clothes 제목을 말한다.",
        //             "What do we wear in the winter?",
        //             " Winter Clothes 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '10', page: '09', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '10', page: '09', action: '02', scene: '03', sentences: [
        //             "What do we wear in the winter?",
        //             "boots, coat, hat, mittens, pants, sandals, scarf, shorts, skirt, sweater, t-shirt",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '10', page: '09', action: '02', scene: '04', sentences: [
        //             "This is a (coat)/These are (pants).",
        //             "Can you find the (coat)?",
        //             "Find and circle the winter clothes.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '01', slide: '10', page: '09', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Jack Horner Teaching Guide",
        //             "Song: Little Jack Horner 제목을 말한다.",
        //             "Little Jack Horner Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Little Jack Horner Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "boy, Christmas pie, good, plum, put, thumb",
        //             "Little Jack Horner Song 영상을 보며 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Big T Little t Teaching Guide",
        //             "Big T Little t 제목을 말한다.",
        //             "Let's sing Big T little t. 영상을 본다.",
        //             "Letter What letter is this? / Big T and little t.",
        //             "T 가 무슨 소리가 날까요? ('스' 소리가 난다)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big T Little t 영상을 본다.",
        //             "Trace the letter t in the word tomato.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '10', action: '03', scene: '01', sentences: [
        //             "Find the letters big T and little t. Teaching Guide",
        //             "Find the letters big T and little t. 말한다.",
        //             "Find the letters big T and little t. 영상을 본다.",
        //             "Point to the big T and little t.",
        //             "Say the sound of the letter T.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '10', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '10', action: '03', scene: '03', sentences: [
        //             "Find the letters big T and little t.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '10', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 보며, 노래 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 영상을 보며 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '08', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Small Pies Teaching Guide",
        //             "Small Pies 제목을 말한다.",
        //             "Look at the pies.",
        //             "Small Pies 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "Circle the small pies.",
        //             "Draw a small pie.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Jack Horner Teaching Guide",
        //             "Song: Little Jack Horner 제목을 말한다.",
        //             "Little Jack Horner Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Little Jack Horner Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "boy, Christmas pie, good, plum, put, thumb",
        //             "Little Jack Horner Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Seasons Song. Teaching Guide",
        //             "Seasons Song. 제목을 말한다.",
        //             "Pretend to be a tree while you are singing.",
        //             "Seasons Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "Spring, Summer, Fall, Winter",
        //             "Spring, Summer, Fall, Winter",
        //             "These are the four seasons.",
        //             "Spring, Summer, Fall, Winter",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Patterns Teaching Guide",
        //             "Patterns 제목을 말한다.",
        //             "This is a Christmas tree.",
        //             "What shapes do you see in the pattern?",
        //             "Patterns 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "This is a Christmas tree.",
        //             "What shapes do you see in the pattern?",
        //             "I see a (circle / triangle / square).",
        //             "A (circle/triangle/square) comes next.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "What comes next?",
        //             "Put the matching stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Big T Little t Teaching Guide",
        //             "Big T Little t 제목을 말한다.",
        //             "Let's sing Big T little t. 영상을 본다.",
        //             "Letter T 가 무슨 소리가 날까요? (‘트' 소리가 난다)",
        //             "What letter is this? / Big T and little t.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big T Little t 영상을 본다.",
        //             "tomato",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "Big U Little u Teaching Guide",
        //             "Big U Little u 제목을 말한다.",
        //             "Let's sing Big U little u. 영상을 본다.",
        //             "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
        //             "What letter is this? / Big U and little u.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big U Little u 영상을 본다.",
        //             "Trace the little u in the word up.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Find the letters big U and little u. Teaching Guide",
        //             "Find the letters big U and little u. 말한다.",
        //             "Find the letters big U and little u. 영상을 본다.",
        //             "Point to the big U and little u.",
        //             "Say the sound of the letter U.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Find the letters big U and little u.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Snowman Teaching Guide",
        //             "Snowman 제목을 말한다.",
        //             "Make a snowman.",
        //             "Bring a snowman inside.",
        //             "Watch the snowman.",
        //             "Snowman 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Leave the snowman in the classroom for an hour.",
        //             "What happened to the snowman?",
        //             " The snowman melted.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "What happened to the snowman?",
        //             "Draw the snowman.",
        //             "Good Job! ( 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '03', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Jack Horner Teaching Guide",
        //             "Song: Little Jack Horner 제목을 말한다.",
        //             "Little Jack Horner Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "Little Jack Horner Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "boy, Christmas pie, good, plum, put, thumb",
        //             "Little Jack Horner Song 영상을 본다. 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '04', page: '18', action: '01', scene: '01', sentences: [
        //             "Big U Little u Teaching Guide",
        //             "Big U Little u 제목을 말한다.",
        //             "Let's sing Big U little u. 영상을 본다.",
        //             "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
        //             "What letter is this? / Big U and little u.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '04', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big U Little u 영상을 본다.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '04', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "Big V Little v Teaching Guide",
        //             "Big V Little v 제목을 말한다.",
        //             "Let's sing Big V little v. 영상을 본다.",
        //             "Letter V 가 무슨 소리가 날까요? (‘브' 소리가 난다)",
        //             "What letter is this? / Big V and little v.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big V Little v 영상을 본다.",
        //             "Trace the little v in the word van.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Find the letters big V and little v. Teaching Guide",
        //             "Find the letters big V and little v. 말한다.",
        //             "Find the letters big V and little v. 영상을 본다.",
        //             "Point to the big V and little v.",
        //             "Say the sound of the letter V.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Find the letters big V and little v.",
        //             "Good Job!",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Make the Words.",
        //             "Let's find the first letter of each word.",
        //             "(Tomato/Up/Van) starts with (t/u/v).",
        //             "Put the matching stickers on the circles.",
        //             "Good job! 하이파이브!",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "Merry Christmas! / Let's have a party!",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "egg / ham / hot chocolate / turkey / boots / mittens / scarf / snowman",
        //             "Find the hidden things.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '08', page: '21', action: '01', scene: '01', sentences: [
        //             "Same or Different Teaching Guide",
        //             "Same or Different 제목을 말한다.",
        //             "Snowflakes have 6 points.",
        //             "Same or Different 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '08', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '08', page: '21', action: '01', scene: '03', sentences: [
        //             "Same or Different.",
        //             "One / Two /Tree / Four / Five / Six",
        //             "Find the same snowflakes and color them in.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '09', week: '04', slide: '08', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Friendship Song. Teaching Guide",
        //             "Friendship Song. 제목을 말한다.",
        //             " Make heart shapes using our body parts.",
        //             "Friendship Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "One little heart to give away.",
        //             "One little heart for you today.",
        //             "'I love you' is what I say.",
        //             "I'll give one to my friend today.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 내용을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "The Weather Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "Let's sing with motions.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy 말한다.",
        //             "It is windy. windy 말한다.",
        //             "It is snowy. snowy 말한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 낚시하는 아이, 강아지, 꽃 물고기…라고 말한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "I see a girl.",
        //             "She is camping.",
        //             "Do you see",
        //             "( trees / flowers / fish / a frog )?",
        //             "Yes/no. I see I see ( trees / flowers / fish / a frog ).",
        //             "Find the Pictures.(활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "what do you want to do when you go camping?",
        //             "I want to (barbecue / play /go fishing / sleep in a tent).",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Camping is fun! / What do you want to do?",
        //             "Draw the answer.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: One, Two, Three, Four, Five Teaching Guide",
        //             "Song: One, Two, Three, Four, Five 제목을 말한다.",
        //             "One, Two, Three, Four, Five Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One, Two, Three, Four, Five Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "bite, finger, fish, five, four, one, three, two",
        //             "Six, seven, eight, nine, ten",
        //             "Put the matching stickers on the circles.",
        //             "One, Two, Three, Four, Five Song 영상을 본다. 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Big V Little v Teaching Guide",
        //             "Big V Little s 제목을 말한다.",
        //             "Let's sing: Big V Little v 영상을 본다.",
        //             "Letter V 가 무슨 소리가 날까요? (‘브' 소리가 난다)",
        //             "What letter is this? / Big V and little v.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big V Little v 영상을 본다.",
        //             "van",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Reuse! Teaching Guide",
        //             "Reuse! 제목을 말한다.",
        //             "What can you make with a (bottle/box)?",
        //             "Reuse! 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Bottles and boxes! / What can you make?",
        //             "Let's make a (vase/pencil holder).",
        //             "Reuse the bottle! / Vase!",
        //             "Reuse the box! / Pencil holder!",
        //             "Put the matching stickers on the circles.",
        //             "Good Job! (활동을 마무리한다)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: One, Two, Three, Four, Five Teaching Guide",
        //             "Song: One, Two, Three, Four, Five 제목을 말한다.",
        //             "One, Two, Three, Four, Five Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One, Two, Three, Four, Five Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "bite, finger, fish, five, four, one, three, two",
        //             "Six, seven, eight, nine, ten",
        //             "Put the matching stickers on the circles.",
        //             "One, Two, Three, Four, Five Song 영상을 본다. 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Big W Little w Teaching Guide",
        //             "Big W Little w 제목을 말한다.",
        //             "Let's sing Big W little w. 영상을 본다.",
        //             "Letter W 가 무슨 소리가 날까요? ('워' 소리가 난다)",
        //             "What letter is this? / Big W and little w.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big W Little w 영상을 본다.",
        //             "Trace the letter w in the word waffle.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '01', sentences: [
        //             "Find the letters big W and little w. Teaching Guide",
        //             "Find the letters big W and little w. 말한다.",
        //             "Find the letters big W and little w. 영상을 본다.",
        //             "Point to the big W and little w.",
        //             "Say the sound of the letter W.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '03', sentences: [
        //             "Find the letters big W and little w.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "Big X Little x Teaching Guide",
        //             "Big X Little x 제목을 말한다.",
        //             "Let's sing Big X little x. 영상을 본다.",
        //             "Letter X 가 무슨 소리가 날까요? ('스' 소리가 난다)",
        //             "What letter is this? / Big W and little x.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big X Little x 영상을 본다.",
        //             "Trace the letter x in the word fox.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Find the letters big X and little x. Teaching Guide",
        //             "Find the letters big X and little x. 말한다.",
        //             "Find the letters big X and little x. 영상을 본다.",
        //             "Point to the big X and little x.",
        //             "Say the sound of the letter X.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Find the letters big X and little x.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '09', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 영상을 보며, 노래",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry?",
        //             "Are you happy?",
        //             "I am happy",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '09', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 영상을 보며, 노래, 율동)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '09', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '10', page: '12', action: '02', scene: '01', sentences: [
        //             "One to Five Teaching Guide",
        //             "Small Pies 제목을 말한다.",
        //             "Let's count each animal.",
        //             "Small Pies 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '10', page: '12', action: '02', scene: '02', sentences: [
        //             " Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '10', page: '12', action: '02', scene: '03', sentences: [
        //             "How many",
        //             "(dogs/fish/frogs/rabbits/squirrels) do you see?",
        //             "I see (one) (frog).",
        //             "Put the sticker on the circle.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '02', slide: '10', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: One, Two, Three, Four, Five Teaching Guide",
        //             "Song: One, Two, Three, Four, Five 제목을 말한다.",
        //             "One, Two, Three, Four, Five Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One, Two, Three, Four, Five Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "bite, finger, fish, five, four, one, three, two",
        //             "Six, seven, eight, nine, ten",
        //             "Put the matching stickers on the circles.",
        //             "One, Two, Three, Four, Five Song 영상을 본다. 마무리한다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Friendship Song. Teaching Guide",
        //             "Friendship Song. 제목을 말한다.",
        //             "Make heart shapes using our body parts.",
        //             "Friendship Song. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Let's sing with motions.",
        //             "One little heart to give away.",
        //             "One little heart for you today.",
        //             "'I love you' is what I say.",
        //             "I'll give one to my friend today.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '01', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Finish the Shapes Teaching Guide",
        //             "Finish the Shapes 제목을 말한다.",
        //             "This is the (earth/tree/flower/butterfly).",
        //             "What shape is this?",
        //             "Finish the Shapes 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "This is the (earth/tree/flower/butterfly).",
        //             "What shape is this?",
        //             "This is a (circle/heart/square/triangle).",
        //             "Complete the shapes.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "Let's find the shapes.",
        //             "Trace and color the shapes.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Big X Little x Teaching Guide",
        //             "Big X Little x 제목을 말한다.",
        //             "Let's sing Big X little x. 영상을 본다.",
        //             "Letter X 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
        //             "What letter is this? / Big X and little x.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big X Little x 영상을 본다.",
        //             "fox",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Big Y Little y Teaching Guide",
        //             "Big Y Little y 제목을 말한다.",
        //             "Let's sing Big Y little y. 영상을 본다.",
        //             "Letter Y 가 무슨 소리가 날까요? (‘여' 소리가 난다)",
        //             "What letter is this? / Big Y and little y.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big Y Little y 영상을 본다.",
        //             "Trace the little y in the word yogurt.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '01', sentences: [
        //             "Find the letters big Y and little y. Teaching Guide",
        //             "Find the letters big Y and little y. 말한다.",
        //             "Find the letters big Y and little y. 영상을 본다.",
        //             "Point to the big Y and little y.",
        //             "Say the sound of the letter Y.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '03', sentences: [
        //             "Find the letters big Y and little y.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Build the Cup Tower Teaching Guide",
        //             "Build the Cup Tower 제목을 말한다.",
        //             "How many cups do you see?",
        //             "Let's count and write the numbers.",
        //             "count, cup, down, left, right, up",
        //             "Build the Cup Tower 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's build the cup tower together.",
        //             "Take a picture and paste it.",
        //             "Good Job! ( 활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: One, Two, Three, Four, Five Teaching Guide",
        //             "Song: One, Two, Three, Four, Five 제목을 말한다.",
        //             "One, Two, Three, Four, Five Song 영상을 본다.",
        //             "Let's listen to the Story.",
        //             "One, Two, Three, Four, Five Story 영상을 본다.",
        //             "Let's sing with motions. (노래가사, 율동)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "bite, finger, fish, five, four, one, three, two",
        //             "Six, seven, eight, nine, ten",
        //             "Put the matching stickers on the circles.",
        //             "One, Two, Three, Four, Five Song 영상을 보며, 마무리.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '04', page: '19', action: '01', scene: '01', sentences: [
        //             "Big Y Little y Teaching Guide",
        //             "Big Y Little y 제목을 말한다.",
        //             "Let's sing Big Y little y. 영상을 본다.",
        //             "Letter Y 가 무슨 소리가 날까요? (‘여' 소리가 난다)",
        //             "What letter is this? / Big Y and little y.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '04', page: '19', action: '01', scene: '02', sentences: [
        //             "Let's sing: Big Y Little y 영상을 본다.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '04', page: '19', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '01', sentences: [
        //             "Big Z Little z Teaching Guide",
        //             "Big Z Little v 제목을 말한다.",
        //             "Let's sing Big Z little z. 영상을 본다.",
        //             "Letter Z 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
        //             "What letter is this? / Big Z and little z.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '02', sentences: [
        //             "Let's sing: Big Z Little z 영상을 본다.",
        //             "Trace the little z in the word zucchini.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '01', sentences: [
        //             "Find the letters big Z and little z. Teaching Guide",
        //             "Find the letters big Z and little z. 말한다.",
        //             "Find the letters big Z and little z. 영상을 본다.",
        //             "Point to the big Z and little z.",
        //             "Say the sound of the letter Z.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '03', sentences: [
        //             "Find the letters big Z and little z.",
        //             "Good Job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "Let's go camping!",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "bread / cereal / doughnut/ bin / bottle / can / juice / newspaper",
        //             "Find the hidden things.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '08', page: '21', action: '01', scene: '01', sentences: [
        //             "Let's Move Robby Teaching Guide",
        //             "Let's Move Robby 제목을 말한다.",
        //             "Robby wants to get some food.",
        //             "Let's Move Robby 영상을 본다.",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '08', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '08', page: '21', action: '01', scene: '03', sentences: [
        //             "Robby wants to get some food.",
        //             "Left / Right",
        //             "Put the stickers on the circles.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '01', volume: '10', week: '04', slide: '08', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             " Hello and Goodbye Teaching Guide",
        //             "Hello and Goodbye 제목을 말한다.",
        //             "Hello 만났을 때 서로가 하는 인사에요.",
        //             "Hello Song 영상을 본다.",
        //             "Let's sing the Hello Song (영상을 보며 노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Goodbye 서로가 헤어질 때 하는 인사예요.",
        //             "Goodbye Song 영상을 본다.",
        //             "Let's sing the Goodbye Song (영상을 보며 노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Put the matching stickers on the circles. 활동을 보면서 마무리",
        //             "Hello Song, / Goodbye Song 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다( 같이 노래한다.)",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is snowy 양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 아이스크림이요, 아이요, 사람이요, 라고 말한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용 한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '04', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Let's look at the picture. / He has ice cream.",
        //             "Do you see (a cookie / cake / ice cream/ an apple)? 그림을 가리키며, 읽는다.",
        //             "Circle the picture. (동그라미 치는 것 확인)",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "How does he feel? Teaching Guide",
        //             "Feelings 영상을 보여준다. 활동을 그림으로 그리기",
        //             "How does he feel? (옆에 그림을 보면서…. )",
        //             "Happy / sad / angry?",
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '05', action: '01', scene: '02', sentences: [
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "Are you happy? (행복하니?)",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '05', action: '01', scene: '03', sentences: [
        //             "How do you feel?",
        //             "Draw your face. (자기의 얼굴을 그리게 한다)",
        //             "Excellent!",
        //             "Feelings 영상을 보여주면서 수업을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '07', page: '05', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Ice Cream Teaching Guide",
        //             "Song: Ice Cream 제목을 말한다.",
        //             "Ice Cream Song 영상을 본다.",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Ice Cream Story 영상을 본다. (노래가사, 율동)",
        //             "Look at me.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "I will sing with motions.(노래, 율동)",
        //             "I scream. ( 나를 가리키고 소리 지르는 모습 )",
        //             "You scream. (상대방을 가리키고 소리 지르는 모습 )",
        //             "We all scream for ice cream! 다 함께 소리치고, 아이스크림을 먹는 모습 )",
        //             "He screams . 남자 아이를 가리키고, 소리 지르는 모습 )",
        //             "She screams. 여자 아이를 가리키고, 소리 지르는 모습 )",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "We all scream for ice cream! 다 함께 소리치고, 아이스크림을 먹는 모습)",
        //             "Ice Cream Song 영상을 본다.",
        //             "노래를 함께 불러본다 . Let s sing together.",
        //             "Good job! Singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "Let's change the song.",
        //             "What is your name?",
        //             "What is your friend's name?",
        //             "Let's write the name in the blank",
        //             "이름 쓰는 것이 어려울 때, 이니셜, 성, 혹은 한글로 대신 쓰도록",
        //             "형광펜으로 위에 써준다. (보고, 쓰도록 유도)",
        //             "Ice Cream Song 영상을 보여주며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '03', page: '06', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '08', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? Teaching Guide",
        //             "Feelings 의 영상을 보며, 노래 율동",
        //             "How do you feel today?",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry?",
        //             "Are you happy? (행복해요?)",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '08', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '08', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Sound It Out Teaching Guide",
        //             "Sound It Out 제목을 말한다.",
        //             "Can you say scream / ice cream?",
        //             "scream / ice cream 의 소리가 같음을 소리 내어 말한다.",
        //             "Sound It Out 영상으로 활동을 한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Can you say (scream/ice cream)? (소리치다/ 아이스크림)",
        //             "Let's say (scream/ice cream).",
        //             "Let's play a game. 영상을 본다.",
        //             "Raise your hand when you hear ice cream or scream.",
        //             "(손을 들고 ice cream or scream 말한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '09', page: '08', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "Look at the picture.",
        //             "What are they doing?",
        //             "They are screaming.",
        //             "What are they screaming for?",
        //             "우리~ 다 같이 외쳐 볼까요?",
        //             "We all scream! (반 아이들이 모두 소리로 말한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '09', page: '08', action: '02', scene: '05', sentences: [
        //             "활동을 해볼까요?",
        //             "Circle the picture. ( 동그라미 치는 것을 확인)",
        //             "Dog. / Dinosaur. / Ice cream./ Clown. (천천히 읽어준다)",
        //             "Excellent!",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '09', page: '08', action: '02', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '10', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 March를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나옴 (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '10', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나옴 (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '10', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '01', slide: '10', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '03', page: '06', action: '05', scene: '01', sentences: [
        //             "Song: Ice Cream Teaching Guide",
        //             "Song: Ice Cream 제목을 말한다.",
        //             "Ice Cream Song 영상을 본다.",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Ice Cream Story 영상을 보며, 노래 율동",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '03', page: '06', action: '05', scene: '02', sentences: [
        //             "Look at me.",
        //             "I will sing with motions. (노래, 모습을 같이 한다.)",
        //             "I scream. ( 나 / 소리 지르는 모습 )",
        //             "You scream. (상대방 / 소리 지르는 모습 )",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '03', page: '06', action: '05', scene: '03', sentences: [
        //             "He screams . (남자 /소리 지르는 모습 )",
        //             "She screams. (여자 /소리 지르는 모습 )",
        //             "We all scream for ice cream! (다 함께 소리치고, 아이스크림을 먹는 모습)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '03', page: '06', action: '05', scene: '04', sentences: [
        //             "Ice Cream Song 영상을 본다.",
        //             "노래를 함께 불러본다 . Let s sing together.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '03', page: '06', action: '05', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (2번 반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "A Says a Teaching Guide",
        //             "A Says a 제목을 말한다.",
        //             "Let's chant: A Says a. 영상을 본다.",
        //             "Letter A 가 무슨 소리가 날까요? ('애' 소리가 난다)",
        //             "What letter is this? / Big A and little a.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: A Says a. 영상을 본다.",
        //             "Trace the big A and little a.",
        //             "A 로 시작하는 단어를 써보고, (단어 확인).",
        //             "A is for apple. / A is for apron./ A is for acorn.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘A a' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , A 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with A.",
        //             "Apple starts with A. Color it./ Apron starts with A. Color it.",
        //             "Banana starts with b./ Acorn starts with a. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture. (영상으로 상호작용)",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다( 같이 노래한다)",
        //             "What's the weather like today? (창밖을 보면서…)",
        //             "손이 눈 위로 가며, (경례 하듯이) (시선은 창밖을 본다.)",
        //             "Look out the window. (손가락은 창문을 가리키며 동시에 말한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy를 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is snowy 양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "What Comes Next? Teaching Guide",
        //             "What Comes Next? 제목을 말한다.",
        //             "This is ice cream.",
        //             "Look at the patterns of ice cream.",
        //             "White, red, brown, white, red, brown, white. Red.",
        //             "What comes next? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Look at the pattern of the ice cream.",
        //             "White, red, brown, white, red, brown, white. Red.",
        //             "What comes next? Red.",
        //             "Color the ice cream.",
        //             "Put the word sticker on the circle.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Tangram Teaching Guide",
        //             "Tangram 제목을 말한다.",
        //             "Tangram 영상을 본다.",
        //             "Let's create shapes.",
        //             "교구 준비 (탱그램 카드를 준비한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "There are two pieces of tangram.",
        //             "What shapes are there?",
        //             "Put the stickers on the circles when you are done.",
        //             "Let's make a square with two triangles.",
        //             "그림 위에 카드를 맞추면 칭찬 스티커를 붙인다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Can you arrange the two tangram pieces?",
        //             "Let's make a triangle.",
        //             "그림 위에 카드를 맞추면 칭찬 스티커를 붙인다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "Let's make a square.",
        //             "카드를 맞추면 칭찬 스티커를 붙인다.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '02', slide: '09', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '03', page: '06', action: '05', scene: '01', sentences: [
        //             "Song: Ice Cream Teaching Guide",
        //             "Song: Ice Cream 제목을 말한다.",
        //             "Ice Cream Song 영상을 본다.",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Ice Cream Story 영상을 본다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '03', page: '06', action: '05', scene: '02', sentences: [
        //             "Look at me.",
        //             "I will sing with motions. (노래, 모습을 같이 한다.)",
        //             "I scream. ( 나 / 소리 지르는 모습 )",
        //             "You scream. (상대방 / 소리 지르는 모습 )",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '03', page: '06', action: '05', scene: '03', sentences: [
        //             "He screams . (남자 /소리 지르는 모습 )",
        //             "She screams. (여자 /소리 지르는 모습 )",
        //             "We all scream for ice cream! (다 함께 소리치고, 아이스크림을 먹는 모습)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '03', page: '06', action: '05', scene: '04', sentences: [
        //             "Ice Cream Song 영상을 본다.",
        //             "노래를 함께 불러본다. Let s sing together.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '03', page: '06', action: '05', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? Teaching Guide",
        //             "Feelings 의 영상을 벼, 노래 율동",
        //             "How do you feel today?",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry?",
        //             "Are you happy? (행복해요?)",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다. (반복 진행 시 잘하게 된다)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "How Many Scoops? Teaching Guide",
        //             "How Many Scoops? 제목을 말한다.",
        //             "How many scoops do you want?",
        //             "그림의 내용에 대해서 이야기를 나눈다.",
        //             "How Many Scoops? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Draw ice cream and write the number.(숫자와 그림 그리는 것을 확인한다)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics A Says a Teaching Guide",
        //             "A Says a 제목을 말한다.",
        //             "Let's chant: A Says a. 영상을 보면서 말한다.",
        //             "Letter A 가 무슨 소리가 날까요? ('애' 소리가 난다)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "What letter is this? Big A and little a.",
        //             "A 로 시작하는 단어를 영상에서 확인 시킨다.",
        //             "A is for apple. / A is for apron. / A is for acorn.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "B Says b Teaching Guide",
        //             "B Says b 제목을 말한다.",
        //             "Let's chant: B Says b. 영상을 보면서 말한다.",
        //             "Letter B 가 무슨 소리가 나는지 살펴본다.('브' 소리가 난다)",
        //             "What letter is this? Big B and little b",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: B Says b. 영상을 본다.",
        //             "Trace the big B and little b.",
        //             "B 로 시작하는 단어를 써보고, (단어 확인).",
        //             "B is for banana. / B is for butter. / B is for bun.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "글씨 쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture 'B b' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , b 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with B.",
        //             "Banana starts with b. Color it. / Better starts with b. Color it.",
        //             "Acorn starts with a. / Bun starts with b. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture. (2명 이상 영상으로 상호작용한다)",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '03', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '03', page: '06', action: '05', scene: '01', sentences: [
        //             "Song: Ice Cream Teaching Guide",
        //             "Song: Ice Cream 제목을 말한다.",
        //             "Ice Cream Song 영상을 본다.",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Ice Cream Story 영상을 본다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '03', page: '06', action: '05', scene: '02', sentences: [
        //             "Look at me.",
        //             "I will sing with motions. (노래, 모습을 같이 한다.)",
        //             "I scream. ( 나 / 소리 지르는 모습 )",
        //             "You scream. (상대방 / 소리 지르는 모습 )",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '03', page: '06', action: '05', scene: '03', sentences: [
        //             "He screams . (남자 /소리 지르는 모습 )",
        //             "She screams. (여자 /소리 지르는 모습 )",
        //             "We all scream for ice cream! (다 함께 소리치고, 아이스크림을 먹는 모습"
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '03', page: '06', action: '05', scene: '04', sentences: [
        //             "Ice Cream Song 영상을 본다.",
        //             "노래를 함께 불러본다. Let s sing together.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '03', page: '06', action: '05', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상, 노래)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "What Will Happen? Teaching Guide",
        //             "What Will Happen? 제목을 말한다.",
        //             "Let's guess.",
        //             "What Will Happen? 영상을 본다.",
        //             "What will happen when it is hot? / Color the pictures.",
        //             "날씨가 더워지면 아이스크림이 어떻게 될까요?",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "누가 나와서 영상을 터치 해볼까요?",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "What will happen when it is hot? / Color the pictures.",
        //             "It's hot.",
        //             "It will melt.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '01', sentences: [
        //             "Patterns Teaching Guide",
        //             "Patterns 제목을 말한다.",
        //             "What comes next?",
        //             "ice cream / cookie / lollipop",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '02', sentences: [
        //             "Patterns 영상을 본다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "What comes next?",
        //             "Put the stickers on the circles.(스티커 활동)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "Simon Says… Teaching Guide",
        //             "Simon Says… 제목을 말한다.",
        //             "Simon Says… 영상을 본다.",
        //             "Do you want to play a game?",
        //             "Listen carefully and move.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "Put the sticker on the circle when you are done.",
        //             "'사이몬 says' 라고 말이 들리면 움직이도록 해요",
        //             "'사이몬 says' 라는 말이 안 들리면 움직이면 안돼요.",
        //             "Up / Down / Left / Right",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Listen carefully and move.",
        //             "Put the sticker on the circle when you are done.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Let's Blow Bubbles Teaching Guide",
        //             "Let's Blow Bubbles! 제목을 말한다.",
        //             "Let's Blow Bubbles! 영상을 본다.",
        //             "Can you make bubbles?",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "Put the bubble sticker on the circle.",
        //             "Circle the bubble shape.",
        //             "What shape is the bubbles?",
        //         ]
        //     }, {
        //         level: '02', volume: '01', week: '04', slide: '08', page: '14', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song.",
        //             "영상을 보면서 노래, 율동.",
        //             "How do you feel today?",
        //             "Draw your feeling.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "Are you happy? (기뻐요? 하고 물어보고,)",
        //             "I am happy (선생님도 기뻐요.)",
        //             "Feelings 의 영상을 본다.(노래, 율동)",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다. (노래를 다 함께 한다)",
        //             "What's the weather like today? (창밖을 보면서, 지문을 읽는다)",
        //             "손을 눈썹 밑으로 가며(경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
        //             "Look out the window. 손가락은 창문을 가리키며, 행동과 동시에 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is snowy 양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다.",
        //             "It is windy 양손으로 바람이 부는 동작 표현), windy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "활동을 해볼까요?",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see? (그림을 보면서 무엇이 보이는지 이야기 나눈다)",
        //             "아이) 거미, 새, 다람쥐, 마을, 여자친구…. 아이들이 이야기 한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see? (그림을 보면서 무엇이 보이는지 이야기 나눈다)",
        //             "아이) 거미, 새, 다람쥐, 마을, 여자친구…. 아이들이 이야기 한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용 한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Circle the picture. (활동을 확인한다)",
        //             "Do you see (bird/ spider / Squirrel/ dog)? 읽고, 상호작용",
        //             "놀이 책 활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "What animals do you like? Teaching Guide",
        //             "What animals do you like? (그림을 보면서, 말한다)",
        //             "Snake/ cat / dog/ spider (그림을 포인트, 각 문장을 2번 읽는다.)",
        //             "Draw the animals you like.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "What animals do you like? (뱀, 고양이, 강아지, 거미를 말한다)",
        //             "Do you like(Snakes/ cats / dogs/ spiders/)?(네가 좋아하는 것이 무엇이에요?)",
        //             "Draw your like animals. (좋아하는 동물을 그리게 한다)",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 활동을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Little Miss Muffet Teaching Guide",
        //             "Song: Little Miss Muffet 제목을 말한다.",
        //             "Little Miss Muffet 영상을 본다.",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Tuffet, curds and whey, spider (단어를 알려주기)",
        //             "I will sing with motions.(노래, 율동)",
        //             "Little Miss Muffet 영상을 본다.",
        //             "노래를 함께 불러본다 . Let s sing together.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Little Miss Muffet Story 영상을 보며, 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Let's Sing Feelings' Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "영상을 보며 노래, 율동.",
        //             "How do you feel today?",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry?",
        //             "Are you happy? (기뻐요? 하고 물어보고,)",
        //             "I am happy (선생님도 기뻐요.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Sound It Out Teaching Guide",
        //             "Sound It Out 제목을 말한다.",
        //             "Can you say Muffet / tuffet?",
        //             "Muffet / tuffet?은 라임으로 같은 소리가 난다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Sound It Out 영상으로 활동을 한다.",
        //             "Can you say (Muffet / tuffet)?",
        //             "Let's say (Muffet / tuffet).",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Let's play a game. 영상을 본다.",
        //             "Clap your hands when you hear the words 'Muffet' or 'tuffet.'",
        //             "(손 벽을 치면서 리듬에 맞춰서 Muffet / tuffet 말한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "Look at the picture.",
        //             "Is she frightened by the (squirrel)? (그녀는 무엇이 무서웠을까요?)",
        //             "활동을 해볼까요?",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '06', sentences: [
        //             "Circle the picture. (동그라미 치는 것을 확인한다)",
        //             "Squirrel / bird / spider / dog (천천히 읽어준다)",
        //             "Excellent!",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '07', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '09', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래하기.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '09', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '01', slide: '09', page: '00', action: '09', scene: '03 ', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Miss Muffet Teaching Guide",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Little Miss Muffet 영상을 본다 (가사, 동작 표현)",
        //             "Let s sing together. (함께 노래한다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '04', page: '10', action: '00', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상, 노래)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "C Says c Teaching Guide",
        //             "C Says c 제목과 부제를 말한다.",
        //             "Let's chant: C Says c. 영상을 본다.",
        //             "Letter C 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
        //             "What letter is this? Big C and little c.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: C Says c. 영상을 본다.",
        //             "Trace the big C and little c.",
        //             "C 로 시작하는 단어를 써보고, (단어 확인)",
        //             "C is for cookie. / C is for cake. / C is for carrot.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "글씨 쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘C c' Teaching Guide",
        //             "놀이 책으로 활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , C 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with C.",
        //             "Cake starts with C. Color it./ Carrot starts with C. Color it.",
        //             "Banana starts with b./ Cookie starts with C. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다. (노래를 다 함께 한다)",
        //             "What's the weather like today? (창밖을 보면서, 지문을 읽는다)",
        //             "손을 눈썹 밑으로 가며(경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
        //             "Look out the window. 손가락은 창문을 가리키며, 행동과 동시에 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is snowy 양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다.",
        //             "It is windy 양손으로 바람이 부는 동작 표현), windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture. ( 오늘의 날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "There Came a Spider Teaching Guide",
        //             "Let's talk about a spider.",
        //             "Spiders have two body parts.",
        //             "The spider has 8 legs.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "There Came a Spider 영상을 본다. (상호작용)",
        //             "How many legs are there?",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "Draw the legs.",
        //             "거미 다리 8개를 그리는 것을 둘러본다.",
        //             "Put the matching sticker on the circle.",
        //             "스티커 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '09', page: '14', action: '01', scene: '01', sentences: [
        //             "What Am I? Teaching Guide",
        //             "Let's look at the shadows.",
        //             "We need a flashlight, forks and a rubber glove.",
        //             "Can you make shadow animals?",
        //             "Let's look at the shadow animals.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '09', page: '14', action: '01', scene: '02', sentences: [
        //             "What Am I? 영상을 본다. (상호작용)",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //             "Circle the animals you see.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '09', page: '14', action: '01', scene: '03', sentences: [
        //             "deer/ bird / dog / rooster",
        //             "What animals can you make?",
        //             "Put the sticker on the circle.",
        //             "스티커 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '09', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '02', slide: '09', page: '00', action: '00', scene: '01', sentences: [
        //             "Song: Little Miss Muffet Teaching Guide",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Little Miss Muffet 영상을 본다. (가사, 동작 표현)",
        //             "Let s sing together. (함께 노래한다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '09', page: '00', action: '00', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? ‘ Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "How do you feel today?",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry / surprised?",
        //             "Are you happy? (행복해요?)",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Patterns Teaching Guide",
        //             "Patterns 제목과 부제를 말한다.",
        //             "Let's look at the tuffet.",
        //             "What colors do you see?",
        //             "I see orange./ I see white./ I see black.",
        //             "Today we will learn the pattern.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Orange, white, black, white. (반복한다)",
        //             "What comes next?",
        //             "Orange comes next.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Patterns 영상을 본다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you make your own patterns?",
        //             "아이들이 패턴 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Let's chant. C Says c Teaching Guide",
        //             "C Says c 제목을 말한다.",
        //             "Let's chant: C Says c. 영상을 본다.",
        //             "Letter C 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
        //             "What letter is this? Big C and little c.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: C Says c. 영상을 본다.",
        //             "C is for cookie. / C is for cake. / C is for carrot.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "D Says d Teaching Guide",
        //             "D Says d 제목을 말한다.",
        //             "Let's chant: D Says d. 영상을 본다.",
        //             "Letter D 가 무슨 소리가 날까요? (‘드' 소리가 난다)",
        //             "What letter is this? Big D and little d .",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: D Says d. 영상을 본다.",
        //             "Trace the big D and little d.",
        //             "D 로 시작하는 단어를 써보고, (단어 확인)",
        //             "D is for doughnut. / D is for dish. / D is for dumpling.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "글씨 쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '07', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘D d' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , D 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with D.",
        //             "Dish starts with D. Color it./ Doughnut starts with D. Color it.",
        //             "Bun starts with b./ Dumpling starts with D. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture. (2명 이상 영상으로 상호작용한다)",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 April 를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "(차츰 2학기 즈음에 요일을 물어볼 수 있다)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '03', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Miss Muffet Teaching Guide",
        //             "Let s sing together. 노래를 함께 불러본다.",
        //             "Little Miss Muffet 영상을 본다.(가사, 동작 표현)",
        //             "Let's sing together. (함께 노래한다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상, 노래)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "‘Who Am I' Teaching Guide",
        //             "‘Who Am I' 제목을 말한다.",
        //             "Let's guess Who Am I?",
        //             "I have 2 legs. / I have 2 eyes. (그림을 본다)",
        //             "I have 2 wings. (그림을 본다)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Who Am I' 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "I am a….(무엇일까요?)",
        //             "패턴 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '06', page: '21', action: '01', scene: '01', sentences: [
        //             "‘What Comes Next?' Teaching Guide",
        //             "‘What Comes Next?' 제목을 말한다.",
        //             "Do you see patterns?",
        //             "Little Miss Muffet / tuffet / squirrel / spider / bird",
        //             "‘What Comes Next?'",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '06', page: '21', action: '01', scene: '02', sentences: [
        //             "‘What Comes Next?' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //             "활동을 해볼까요?",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '06', page: '21', action: '01', scene: '03', sentences: [
        //             "Put the stickers on the circles.",
        //             "스티커 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '06', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "‘Let's Move Little Miss Muffet' Teaching Guide",
        //             "‘Let's Move Little Miss Muffet' 제목을 말한다.",
        //             "There is a spider.",
        //             "Left / Right",
        //             "‘Little Miss Muffet' 은 어디로 갈까요?",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "‘Let's Move Little Miss Muffet' 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "놀이 책으로 활동을 해볼까요?",
        //             "Put the stickers on the circles.",
        //             "스티커 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '07', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Tangram Teaching Guide",
        //             "Tangram 제목을 말한다.",
        //             "Tangram 영상을 본다.",
        //             "Let's create shapes.",
        //             "교구 준비 (탱그램 카드를 준비한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '02', sentences: [
        //             "There are three pieces of a tangram.",
        //             "What shapes are they?",
        //             "Put the stickers on the circles when you are done.",
        //             "Let's make a square with two triangles.",
        //             "그림 위에 카드를 맞추면 칭찬 스티커를 붙인다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '03', sentences: [
        //             "There are three triangles.",
        //             "Can you put them together?",
        //             "What does it look like?",
        //             "Let's make a square.",
        //             "그림 위에 카드를 맞추면 칭찬 스티커를 붙인다.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '02', volume: '02', week: '04', slide: '08', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다. (노래를 다 함께 한다)",
        //             "What's the weather like today? (창밖을 보면서, 지문을 읽는다)",
        //             "손을 눈썹 밑으로 가며(경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
        //             "Look out the window. 손가락은 창문을 가리키며, 행동과 동시에 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is snowy 양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다.",
        //             "It is windy 양손으로 바람이 부는 동작 표현, windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Draw what the weather is like today.",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What is in the teapot? (teapot 에 무엇을 담을 수 있는지?)",
        //             "아이) 물, 티, 주스, 등을 이야기한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용 한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '04', action: '02', scene: '02', sentences: [
        //             "What do you see?",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '04', action: '02', scene: '03', sentences: [
        //             "What is in the teapot?",
        //             "활동을 해볼까요?",
        //             "Circle the picture. (동그라미 치는 것 확인)",
        //             "tea / oil / juice / milk / 그림을 포인트, 각 문장을 읽는다.",
        //             "Yes/no. (아이들에게 응답)확인",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '05', action: '01', scene: '01', sentences: [
        //             "Who do you like to drink tea with? Teaching Guide",
        //             "Who do you like to drink tea with? (그림책을 보면서, 말한다)",
        //             "Mommy / Daddy / Brother / Sister",
        //             "Draw a picture of your family.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '05', action: '01', scene: '02', sentences: [
        //             "Do you like to drink tea with (Mommy/ Daddy/ Brother/ Sister)?",
        //             "I like to drink tea with (Mommy).",
        //             "Draw your like Mommy.(엄마를 그린다)",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '05', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: I'm a Little Teapot Teaching Guide",
        //             "I'm a Little Teapot 제목을 말한다.",
        //             "I'm a Little Teapot 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Handle/ pour/ shout/ spout/ short/ stout/ teapot/ tip (단어 숙지",
        //             "I will sing with motions.",
        //             "Little Miss Muffet 영상을 본다.",
        //             "Let s sing together. (노래와 율동을 한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the handle and spout stickers on the circles.",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '06', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘What color is this?' Teaching Guide",
        //             "Colors Song 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "What color is this?",
        //             "Red / Blue / Green",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '06', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle red.",
        //             "This is (Red / Blue / Green)",
        //             "Red 에 동그라미 하는 것으로 마무리",
        //             "Colors Song 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '06', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '01', sentences: [
        //             "Sound It Out Teaching Guide",
        //             "Sound It Out 제목을 말한다.",
        //             "Can you say (spout/shout)?",
        //             "Today we are going to learn rhymes.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '02', sentences: [
        //             "Sound It Out 영상으로 활동을 한다.",
        //             "Can you say spout/shout?",
        //             "Let's say spout/shout.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '03', sentences: [
        //             "Let's play a game. 영상을 본다.",
        //             "Shout 'wheeee!'",
        //             "when you hear 'spout' or 'shout.'",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '04', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '05', sentences: [
        //             "What do you see in the mirror?",
        //             "I see a…",
        //             "활동을 해볼까요?",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '06', sentences: [
        //             "Circle the picture. (동그라미 치는 것을 확인한다)",
        //             "handle / Cup / spout / lid (천천히 읽어준다)",
        //             "Excellent!",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '07', page: '08', action: '02', scene: '07', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '08', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 May를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나옴 (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '08', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "주음, 표시 / 그림을 누르면 'week song'이 나옴 (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '08', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '01', slide: '08', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: I'm a Little Teapot Teaching Guide",
        //             "Song: I'm a Little Teapot 제목을 말한다.",
        //             "I'm a Little Teapot 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant D Says d Teaching Guide",
        //             "Let's chant: D Says d. 영상을 본다.",
        //             "Letter D 가 무슨 소리가 날까요? (‘드' 소리가 난다)",
        //             "What letter is this? Big D and little d .",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's chant: D Says d. 영상을 본다.",
        //             "D is for doughnut. / D is for dish. / D is for dumpling.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "E Says e Teaching Guide",
        //             "E Says e 제목을 말한다.",
        //             "Let's chant: E Says e . 영상을 본다.",
        //             "Letter E 가 무슨 소리가 날까요? ( ‘ 에 ' 소리가 난다)",
        //             "What letter is this? Big E and little e.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: E Says e . 영상을 본다.",
        //             "Trace the big E and little e.",
        //             "E 로 시작하는 단어를 써보고, (단어 확인)",
        //             "E is for egg. / E is for eggplant. / E is for eel.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "아이들이 글씨 쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘E e' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , E 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with E.",
        //             "eggplant starts with E. Color it./ egg starts with E. Color it.",
        //             "carrot starts with C./ eel starts with E. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "누가 나와서 첫 글자가 E로 시작하는 그림을 찾을 수 있나요?",
        //             "누가 손들어 볼까요?",
        //             "예~ 민지가 나와서 해 볼까요?",
        //             "Good job! 하이파이브!",
        //             "들어가 앉으세요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? ‘ Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "How do you feel today?",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry / surprised?",
        //             "Are you happy? (행복해요?)",
        //             "I am happy (선생님도 기뻐요.)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Teapot Family 제목을 말한다.",
        //             "Let's talk about Tall and short.",
        //             "This is Daddy. / This is Mommy. / This is Bay",
        //             "This is the Teapot family",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Teapot Family 영상을 본다. (상호작용)",
        //             "Where is Daddy? / Is Daddy tall or short?",
        //             "Where is Mommy? / Is Mommy tall or short?",
        //             "Where is Baby? / Is Baby tall or short?",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "Where is Daddy? / Is Daddy tall or short?",
        //             "Where is Mommy? / Is Mommy tall or short?",
        //             "Where is Baby? / Is Baby tall or short?",
        //             "Put the matching sticker on the circle.",
        //             "스티커 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '08', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song. (시간이 되면, 반복하는 노래)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '02', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: I'm a Little Teapot Teaching Guide",
        //             "Song: I'm a Little Teapot 제목을 말한다.",
        //             "I'm a Little Teapot 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ Numbers Song. ‘ Teaching Guide",
        //             "Numbers Song. ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 손 숫자를 같이 한다.",
        //             "Let's sing: Numbers Song.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's Have Tea Teaching Guide",
        //             "Let's Have Tea 제목과 부제를 말한다.",
        //             "Today we are going to count the numbers.",
        //             "How many cups are there?",
        //             "Let's Have Tea 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Count and write the number in the box.",
        //             "There are ( 7 ) cups. (그림의 컵을 센다)",
        //             " 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant ‘E Says e' Teaching Guide",
        //             "E Says e 제목을 말한다.",
        //             "Let's chant: E Says e . 영상을 본다.",
        //             "Letter E 가 무슨 소리가 날까요? (‘에' 소리가 난다)",
        //             "What letter is this? Big E and little e.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: E Says e . 영상을 본다.",
        //             "E is for egg. / E is for eggplant. / E is for eel.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "F Says f Teaching Guide",
        //             "F Says f 제목을 말한다.",
        //             "Let's chant: F Says f . 영상을 본다.",
        //             "Letter F 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
        //             "What letter is this? / Big F and little f .",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: F Says f . 영상을 본다.",
        //             "Trace the big F and little f.",
        //             "F 로 시작하는 단어를 써보고, (단어 확인)",
        //             "F is for fish. / F is for fork. / F is for fruit.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "글씨 쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '06', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘F f' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , F 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with F.",
        //             "Fruit starts with F. Color it. / Fish starts with F. Color it.",
        //             "Eggplant starts with E. / Fork starts with F. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture. (2명 이상 영상으로 상호작용 한다)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Make Five Game 제목을 말한다.",
        //             "Make Five Game 영상을 본다.",
        //             "Let's play the Make Five Game.",
        //             "Play the music.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "When the music stops,",
        //             "Get in a group of (Five).",
        //             "Put the matching sticker on the circle.",
        //             "(5) 스티커를 붙인다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "We need five teapots and cups.",
        //             "How many are missing?",
        //             "Add more stickers to make five.",
        //             "그림 위에 스티커를 붙인다.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '03', slide: '09', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: I'm a Little Teapot Teaching Guide",
        //             "Song: I'm a Little Teapot 제목을 말한다.",
        //             "I'm a Little Teapot 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (2번 반복)",
        //             "(영상, 노래) 영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "‘Who Am I' Teaching Guide",
        //             "‘Who Am I' 제목을 말한다.",
        //             "Let's guess Who Am I?",
        //             "I have a handle. / I have a spout. (그림을 본다)",
        //             "I am stout. / I am short. (그림을 본다)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Who Am I' 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "I am a….(무엇일까요?)",
        //             "동그라미 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '06', page: '21', action: '01', scene: '01', sentences: [
        //             "‘Teapots with Shapes' Teaching Guide",
        //             "‘Teapots with Shapes' 제목을 말한다.",
        //             "Look, Mommy Teapot!",
        //             "Let's decorate mommy teapot with shapes.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '06', page: '21', action: '01', scene: '02', sentences: [
        //             "‘Teapots with Shapes' 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '06', page: '21', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Let's decorate mommy teapot with shapes.",
        //             "그리기 활동을 하는 것으로 마무리 한다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '06', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Robby Teaching Guide",
        //             "‘Let's Move Robby' 제목을 말한다.",
        //             "Robby wants some tea!",
        //             "Up / Down",
        //             "‘Robby' 는 어디로 갈까요?",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "‘Let's Move Robby' 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민지, Come to the front. (민지가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Put the stickers on the circles.",
        //             "스티커 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '07', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Let's Blow Bubbles! Teaching Guide",
        //             "Let's Blow Bubbles! 제목을 말한다.",
        //             "Let's make some wands.",
        //             "Trace each shape.",
        //             "교구 준비 ( 다양한 막대모양도구)",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "bear, blow, bubbles, heart, circle, shape, square, star, triangle, wand",
        //             "Are the bubbles (circles/triangles)?",
        //             "Do you see circle bubbles?",
        //             "The bubbles are circles.",
        //             "그림 위에 카드를 맞추면 칭찬 스티커를 붙인다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '03', sentences: [
        //             "Let's observe the bubbles!",
        //             "Can you make triangle bubbles?",
        //             "What shape are the bubbles?",
        //             "Circle the answer.",
        //             "Circle 하면서 마무리",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '02', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Colors Teaching Guide",
        //             "Let's sing the Colors Song.",
        //             "Colors Song 영상을 보며, 노래율동",
        //             "What color is this? Circle yellow.",
        //             "Red / Blue / Green / yellow",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Circle the red things in the picture.",
        //             "This is (Red / Blue / Green/ yellow)",
        //             "Red 에 동그라미 하는 것으로 마무리",
        //             "Colors Song 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며 말한다.",
        //             "What do you see?",
        //             "아이) 요리사, 빵.. 등을 이야기한다.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용 한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Do you see a (baker/ doctor/ police officer/ teacher)?",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "baker / doctor / police officer /teacher 그림을 포인트, 각 문장을 읽는다.",
        //             "Yes/no. (아이들에게 응답)확인",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Do you know the Muffin Man? Teaching Guide",
        //             "Do you know the Muffin Man? (그림책을 보면서, 말한다)",
        //             "The Muffin Man!",
        //             "singer / firefighter / baker / police officer",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Who is the Muffin Man?",
        //             "Is she / he a (singer/ firefighter/ baker/ police officer)?",
        //             "He is a baker.",
        //             "Draw the Muffin Man.",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: The Muffin Man Teaching Guide",
        //             "The Muffin Man 제목을 말한다.",
        //             "The Muffin Man 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Muffin Man, / Who lives on Drury Lane?",
        //             "I will sing with motions.",
        //             "Little Miss Muffet 영상을 본다.",
        //             "Let's sing together. (노래와 율동을 한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the Muffin Man and Drury Lane stickers on the circles.",
        //             "Good job!",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ Numbers Song. ‘ Teaching Guide",
        //             "‘ Numbers Song. ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 손 숫자를 같이 한다.",
        //             "Let's sing: Numbers Song.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Counting Cars Teaching Guide",
        //             "Counting Cars 제목을 말한다.",
        //             "Look at the street.",
        //             "What kinds of cars do you see?",
        //             "ambulance / bus / fire truck / police car",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Counting Cars 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "How many police cars are there?",
        //             "Let's color them.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             " Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '01', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Muffin Man Teaching Guide",
        //             "The Muffin Man 제목을 말한다.",
        //             "The Muffin Man 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant F Says f Teaching Guide",
        //             "Let's chant: F Says f . 영상을 본다.",
        //             "Letter F 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
        //             "What letter is this? Big F and little f .",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's chant: F Says f . 영상을 본다.",
        //             "F is for fish. / F is for fruit. / F is for fork.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "G Says g Teaching Guide",
        //             "G Says g 제목을 말한다.",
        //             "Let's chant: G Says g . 영상을 본다.",
        //             "Letter G 가 무슨 소리가 날까요? ( ‘ 그 ' 소리가 난다)",
        //             "What letter is this? Big G and little g.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: E Says e . 영상을 본다.",
        //             "Trace the big G and little g.",
        //             "G 로 시작하는 단어를 써보고, (단어 확인)",
        //             "G is for gum. / G is for grapes. / G is for garlic.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘G g' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , G 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with G.",
        //             "Garlic starts with G. Color it./ Grapes starts with G. Color it.",
        //             "Cake starts with C./ Gum starts with G. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture. (상호작용한다)",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today?' Teaching Guide",
        //             "Feelings 의 영상을 보며, 노래 율동",
        //             "How do you feel today?",
        //             "How do you feel?",
        //             "happy / sad / angry / excited",
        //             "Are you happy?",
        //             "I am excited",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 의 영상을 보며, 노래율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "At the Market Teaching Guide",
        //             "At the Market 제목을 말한다.",
        //             "What do you see at the market?",
        //             "Apple/ strawberry/ banana/ watermelon/grapes",
        //             "broccoli/ tomato/ carrot/ corn",
        //             "Milk/ ice cream/ cheese/ yogurt",
        //             "Bread/ doughnut/ muffin",
        //             "Fish/ meat/ egg/ chicken.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "At the Market 영상을 본다. (상호작용)",
        //             "Do you see (an apple/ a broccoli/ a milk/ a bread/ a fish)?",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "스티커 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Muffin Man Teaching Guide",
        //             "The Muffin Man 제목을 말한다.",
        //             "The Muffin Man 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Shapes Song.‘ Teaching Guide",
        //             "‘Shapes Song.' 영상을 보며, 노래율동.",
        //             "Let's sing: Shapes Song.",
        //             "circle / triangle / square",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "My Dream Teaching Guide",
        //             "My Dream 제목을 말한다.",
        //             "What do you want to be?",
        //             "(baker/ police officer/ teacher/ doctor/ singer)",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "My Dream 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "What do you want to be?",
        //             "Do you want to be a (baker/ police officer/ teacher/ doctor/ singer)?",
        //             "(자기 되고 싶은 직업을 손을 들게 하여 그림에 표시한다)",
        //             "Color the boxes.",
        //             "아이들이 희망하는 직업에 표시하는 것으로 활동을 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Circle all the words that start with the letter g. Teaching Guide",
        //             "Let's chant: G Says g . 영상을 본다.",
        //             "Letter G 가 무슨 소리가 날까요? (‘그' 소리가 난다)",
        //             "What letter is this? Big F and little f .",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: G Says g . 영상을 본다.",
        //             "G is for garlic. / G is for grapes. / G is for gum.",
        //             "Circle all the words that start with the letter g",
        //             "garlic. / grapes. / gum / fish",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "H Says h Teaching Guide",
        //             "H Says h 제목을 말한다.",
        //             "Let's chant: H Says h . 영상을 본다.",
        //             "Letter H 가 무슨 소리가 날까요? (‘흐' 소리가 난다)",
        //             "What letter is this? / Big H and little h .",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: H Says h . 영상을 본다.",
        //             "Trace the big H and little h.",
        //             "H 로 시작하는 단어를 써보고, (단어 확인)",
        //             "H is for ham. / H is for honey. / H is for hotdog.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "글씨 쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '07', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘H h' Teaching Guid",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , H 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with H.",
        //             "Ham starts with H. Color it. / Honey starts with H. Color it.",
        //             "Doughnut starts with D. / Hotdog starts with H. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture. (영상으로 상호작용한다)",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '02', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 Jun을 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나옴 (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Muffin Man Teaching Guide",
        //             "The Muffin Man 제목을 말한다.",
        //             "The Muffin Man 영상을 본다.",
        //             "Let's sing with motions. 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing & motions",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상, 노래)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "‘Where Is the Muffin Man' Teaching Guide",
        //             "‘Where Is the Muffin Man' 제목을 말한다.",
        //             "Today we are going to find the Muffin Man.",
        //             "Start / End / Right / Left / Up / Down",
        //             "Police station / Fire station / Hospital / Library / School / Bakery",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Find the Muffin Man' 영상을 보고 상호작용 한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Let's buy some muffins.",
        //             "Put Start/End stickers on the circles.",
        //             "Draw a line to the bakery.",
        //             "활동을 해볼까요?",
        //             "선 그리기 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Different Jobs and Belongings Teaching Guide",
        //             "‘Different Jobs and Belongings' 제목을 말한다.",
        //             "Who are they?",
        //             "‘Different Jobs and Belongings ' 영상을 보고 상호작용 한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Circle the items that belongs to each person.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '06', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '01', sentences: [
        //             "Opposite 대본",
        //             "Opposite 제목을 말한다.",
        //             "Let's read the letters and numbers with a mirror.",
        //             "Can you read the numbers and letters?",
        //             "Opposite 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '02', sentences: [
        //             "Use a mirror",
        //             "Find the letter (C/D/B/E)?",
        //             "Find the number (3/5/6/2)",
        //             "Put the matching stickers on the circles.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '03', sentences: [
        //             "Mirror, mirror, what do you see?",
        //             "What letters are in the mirror?",
        //             "Circle the letters you see in the mirror.",
        //             "I see the Letters…",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '07', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Memory Game: Front and Back Teaching Guide",
        //             "Memory Game: Front and Back 제목을 말한다.",
        //             "Let's talk about front and back.",
        //             "What is different?",
        //             "Memory Game: Front and Back 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '08', page: '16', action: '01', scene: '02', sentences: [
        //             "It is the front.",
        //             "It is the back.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '08', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's play the memory game.",
        //             "Can you find the matching cards?",
        //             "Discuss with your team.",
        //             " How to play( 활동 하단 참조)",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '08', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "복습, 반복, 부모님과의 상호작용",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Shapes Teaching Guide",
        //             "Shapes 제목을 말한다.",
        //             "Let's sing: Shapes Song.",
        //             "Shapes Song 영상을 보며, 노래율동",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Can you make the shapes with your hands?",
        //             "circle / triangle / square",
        //             "Color the triangles.",
        //             "This is (circle / triangle / square)",
        //             "Triangle 에 색을 그리는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "Shapes Song 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather Song' Teaching Guide",
        //             "The Weather Song 영상을 본다.",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is windy. windy를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What is he playing?",
        //             "아이) 바이올린을 켜요…등을 이야기한다.",
        //             "Let's Look at the Cover 영상 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "This is Tommy Tucker.",
        //             "He is playing something.",
        //             "Is he playing the (piano / tambourine / recorder/ violin)?",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             " 민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "piano / tambourine / recorder/ violin그림을 포인트, 각 문장을 읽는다.",
        //             "Yes/no. (아이들에게 응답)확인",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Will you marry me? Teaching Guide",
        //             "Will you marry me? (그림책을 보면서, 말한다)",
        //             "Tommy Tucker's Wedding!",
        //             "Tommy Tucker wants to marry.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Can you draw Tommy Tucker's wedding picture?",
        //             "Draw a wedding picture.",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             " 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Little Tommy Tucker Teaching Guide",
        //             "Little Tommy Tucker 제목을 말한다.",
        //             "Little Tommy Tucker 영상을 본다.",
        //             "Let's sing and dance! 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing and dance.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "bread, / butter, / knife, / wife",
        //             "I will sing and dance!",
        //             "Little Miss Muffet 영상을 본다.",
        //             "Let s sing together. (노래와 율동을 한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Sound It Out Teaching Guide",
        //             "Sound It Out 제목을 말한다.",
        //             "Sound It Out 영상으로 활동을 한다.",
        //             "Can you say Tucker/butter?",
        //             "Tucker and butter rhyme.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Let's play a game. 영상을 본다.",
        //             "Stand up when you hear 'Tucker.'",
        //             "Sit down when you hear 'butter.'",
        //             "누가 나와서 Tucker / butter 찾을 수 있나요?",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your sea"
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 July를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나옴 (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Tommy Tucker Teaching Guide",
        //             "Little Tommy Tucker 제목을 말한다.",
        //             "Little Tommy Tucker 영상을 본다.",
        //             "Let's sing and dance! 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing and dance.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '04', page: '09', action: '01', scene: '01', sentences: [
        //             "What do I need? Teaching Guide",
        //             "What do I need? 제목을 말한다.",
        //             "What does he need to cut the bread?",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '04', page: '09', action: '01', scene: '02', sentences: [
        //             "What does he need to cut the bread?",
        //             "영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '04', page: '09', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Does he need",
        //             "(a key/a bow/a comb/a spoon/a fork/a knife/",
        //             "a feather/a flower/chopsticks/a pencil/a branch)?",
        //             "Yes. He needs a knife.",
        //             "그리기 활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '04', page: '09', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '05', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant H Says h Teaching Guide",
        //             "Let's chant: H Says h . 영상을 본다.",
        //             "Letter H 가 무슨 소리가 날까요? (‘흐' 소리가 난다)",
        //             "What letter is this? / Big H and little h .",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '05', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's chant: H Says h . 영상을 본다.",
        //             "H is for ham. / H is for honey. / H is for hotdog.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '05', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '06', page: '10', action: '02', scene: '01', sentences: [
        //             "I Says i Teaching Guide",
        //             "I Says i 제목을 말한다.",
        //             "Let's chant: I Says i . 영상을 본다.",
        //             "Letter I 가 무슨 소리가 날까요? (‘이' 소리가 난다)",
        //             "What letter is this? / Big I and little i .",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '06', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: I Says i . 영상을 본다.",
        //             "Trace the big I and little i",
        //             "H 로 시작하는 단어를 써보고, (단어 확인)",
        //             "I is for igloo. / I is for iguana. / I is for ice cream.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '06', page: '10', action: '02', scene: '03', sentences: [
        //             "Good job!"
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '06', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '07', page: '11', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘I i' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , I 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '07', page: '11', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with I.",
        //             "Iguana starts with I. Color it./ Ice cream starts with I. Color it.",
        //             "Egg starts with E./ Igloo starts with I. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '07', page: '11', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '07', page: '11', action: '01', scene: '04', sentences: [
        //             "Color the pictures starting with i.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '07', page: '11', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '08', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing: Shapes Song. Teaching Guide",
        //             "Shapes 제목을 말한다.",
        //             "Let's sing: Shapes Song"
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '08', page: '12', action: '01', scene: '02', sentences: [
        //             "Shapes Song 영상, 노래, 율동.",
        //             "Good job singing"
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '08', page: '12', action: '01', scene: '03', sentences: [
        //             "Shapes Song 영상을 본다.",
        //             "This is (circle / triangle / square"
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '08', page: '12', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Wedding Cake Teaching Guide",
        //             "Wedding Cake 제목을 말한다.",
        //             "This is a wedding cake.",
        //             "I see a (circle/triangle/heart/square).",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Wedding Cake 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "What shapes do you see?",
        //             "I see a (circle/triangle/heart/square).",
        //             "Decorate a wedding cake with stickers.",
        //             "Color the boxes.",
        //             "그리는 것으로 활동을 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Tommy Tucker Teaching Guide",
        //             "Little Tommy Tucker 제목을 말한다.",
        //             "Little Tommy Tucker 영상을 본다.",
        //             "Let's sing and dance! 노래와 율동을 한다.",
        //             "노래 가사를 알려주며 동작을 함께 만들어 본다.",
        //             "Good job! Singing and dance.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ Numbers Song. ‘ Teaching Guide",
        //             "‘ Numbers Song. ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 손 숫자를 같이 한다.",
        //             "Let's sing: Numbers Song.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's Count Teaching Guide",
        //             "Let's Count 제목을 말한다.",
        //             "Which picture has more?",
        //             "(more, flower, key, pencil) 설명한다.",
        //             "Let's Count 영상을 보고 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Which picture has more?",
        //             "숫자를 세며. 더 많은 것이 무엇인지 물어본다.",
        //             "Circle the picture that has more.",
        //             "Draw a picture with more keys.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant I Says i Teaching Guide",
        //             "Let's chant: I Says i. 영상을 본다.",
        //             "Letter I 가 무슨 소리가 날까요? (‘ 이' 소리가 난다)",
        //             "What letter is this? / Big I and little i.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: I Says i. 영상을 본다.",
        //             "I is for igloo. / I is for iguana. / I is for ice cream.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "J Says j Teaching Guide",
        //             "J Says j 제목을 말한다.",
        //             "Let's chant: J Says j . 영상을 본다.",
        //             "Letter J 가 무슨 소리가 날까요? (‘ 즈 ' 소리가 난다)",
        //             "What letter is this? / Big J and little j.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: J Says j . 영상을 본다.",
        //             "Trace the big I and little j",
        //             "J 로 시작하는 단어를 써보고, (단어 확인)",
        //             "J is for jam. / J is for juice. / J is for jelly beans.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '07', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘ J j ' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , J 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with J.",
        //             "Jam starts with J. Color it./ juice starts with J. Color it.",
        //             "Ice cream starts with I./ jelly beans starts with J. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "Color the pictures starting with j.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '08', page: '19', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Dots and Boxes Teaching Guide",
        //             "Dots and Boxes 제목을 말한다.",
        //             "Let's make squares.",
        //             "Can you draw a line from dot to dot?",
        //             "Dots and Boxes 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "connect, dots, square",
        //             "Let's play Dots and Boxes.",
        //             "Count how many squares you made.",
        //             "How to play( 활동 하단 참조)",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '10', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '10', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '03', slide: '10', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Little Tommy Tucker Teaching Guide",
        //             "Little Tommy Tucker 제목을 말한다.",
        //             "Little Tommy Tucker 영상을 본다.",
        //             "Let's sing and dance! 노래와 율동을 한다.",
        //             "Good job! Singing and dance.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '04', page: '00', action: '05', scene: '01', sentences: [
        //             " Alphabet Song",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (2번 반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '04', page: '00', action: '05', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '04', week: '04', slide: '04', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "‘Who Am I' Teaching Guide",
        //             "‘Who Am I' 제목을 말한다.",
        //             "Let's think who I am.",
        //             "I am a vegetable. / I'm good for you! (그림을 본다)",
        //             "I look like a tree. / My name starts with 'b' (그림을 본다)",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Who Am I' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Let's think who I am.",
        //             "Find and circle the matching vegetable.",
        //             "I am a….(무엇일까요?)",
        //             "a banana / corn / broccoli /an orange",
        //             "활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '07', page: '21', action: '01', scene: '01', sentences: [
        //             "How to Wash My Hands Teaching Guide",
        //             "‘How to Wash My Hands' 제목을 말한다.",
        //             "I wet my hands / I add soap / I Rub my hands /",
        //             "I rinse my hands / I dry my hands.",
        //             "‘How to Wash My Hands' 영상을 보고 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '07', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '07', page: '21', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Write the numbers in order.",
        //             "순서를 바르게 적는 것을 확인하고, 활동으로 마무리.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '07', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '08', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Tucker Teaching Guide",
        //             "‘Let's Move Tucker' 제목을 말한다.",
        //             "Tommy Tucker wants to marry.",
        //             "Right / Left / Up /Down",
        //             "‘How to Wash My Hands' 영상을 보고 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '08', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '08', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Will you marry me?",
        //             "Right / Left / Up / Down",
        //             "카드놀이 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '04', slide: '08', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "What Day Is It Today? Teaching Guide",
        //             "What Day Is It Today? 제목을 말한다.",
        //             "Let's sing the Days of the Week Song.",
        //             "Days of the Week Song 영상, 노래",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Put the sticker on today's day.",
        //             "Sunday /Monday / Tuesday / Wednesday /",
        //             "Thursday / Friday / Saturday",
        //             "What day is it today?",
        //             "Today is ……",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "Let's sing the Days of the Week Song. 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather Song' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 달력, 치킨. 앵무새요…등을 이야기한다.",
        //             "This is Geneus. / He is a cook.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "He wants to cook different food every day.",
        //             "Do you see (numbers/food/letters/a calendar)?",
        //             "Yes.",
        //             "I see numbers, food, letters and a calendar.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the pictures.",
        //             "numbers/food/letters/a calendar 단어 그림을 포인트, 말한다.",
        //             "Yes/ no. (아이들에게 응답)확인",
        //             "활동을 하고 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Are you hungry? Teaching Guide",
        //             "Will you marry me? (그림책을 보면서, 말한다)",
        //             "What do you want to eat?",
        //             "I want to eat (corn/ soup/ spaghetti/ chicken).",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "I am hungry!",
        //             "Draw some food.",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Today is Monday Teaching Guide",
        //             "Today is Monday 제목을 말한다.",
        //             "Today is Monday 영상을 본다.",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Sunday, / Monday, / Tuesday, / Wednesday",
        //             "Thursday, / Friday, / Saturday,",
        //             "corn, / spaghetti",
        //             "soup, /roast beef",
        //             "fresh fish, / chicken, / ice cream",
        //             "Today is Monday 영상을 본다.",
        //             "Good job! Singing. (노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing: One Little Finger. Teaching Guide",
        //             "One Little Finger. 영상을 본다.",
        //             "Let's sing One Little Finger (주의집중 챈트)",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Do you know the One Little Finger song?",
        //             "Let's sing ‘One Little Finger song'",
        //             "One Little Finger, tap, tap, tap.",
        //             "Point to the ceiling,",
        //             "point to the floor,",
        //             "put it on your head.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Food Calendar Teaching Guide",
        //             "Food Calendar 제목을 말한다.",
        //             "I am hungry. / This is a menu for the week.",
        //             "There are special days.",
        //             "Chuseok is on Friday.",
        //             "Sunday is your birthday.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "What do you want to eat on (Monday)?",
        //             "Do you want to eat (spaghetti)?",
        //             "What do you want to eat?",
        //             "Draw and use stickers to plan your weekly menu.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Food Calendar 영상을 본다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 September를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나옴 (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Today is Monday Teaching Guide",
        //             "Today is Monday 제목을 말한다.",
        //             "Today is Monday 영상을 본다. (반복)",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '04', page: '00', action: '08', scene: '01', sentences: [
        //             "The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '04', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '04', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant J Says j Teaching Guide",
        //             "Let's chant: J Says j . 영상을 본다.",
        //             "Letter J 가 무슨 소리가 날까요? (‘즈' 소리가 난다)",
        //             "What letter is this? / Big J and little j .",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's chant: J Says j . 영상을 본다.",
        //             "J is for jam. / J is for juice. / J is for jelly beans.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '05', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '10', action: '02', scene: '01', sentences: [
        //             "K Says k Teaching Guide",
        //             "K Says k 제목을 말한다.",
        //             "Let's chant: K Says k . 영상을 본다.",
        //             "Letter K 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
        //             "What letter is this? / Big K and little k .",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: K Says k . 영상을 본다.",
        //             "Trace the big K and little k",
        //             "K 로 시작하는 단어를 써보고, (단어 확인)",
        //             "K is for ketchup. / K is for kimchi. / K is for kiwi.",
        //             "Put the matching stickers on the circles.",
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '01', slide: '06', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '07', page: '11', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘K k' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , K 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '07', page: '11', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with I.",
        //             "Kimchi starts with K. Color it./ Ketchup starts with K. Color it.",
        //             "Ice cream starts with I./ Kiwi starts with K. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '07', page: '11', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '07', page: '11', action: '01', scene: '04', sentences: [
        //             "Color the pictures starting with K.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '07', page: '11', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '08', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing: Days of the Week Song. Teaching Guide",
        //             "Days of the Week Song 제목을 말한다.",
        //             "Let's sing: Days of the Week Song.",
        //             "Days of the Week Song영상을 본다.",
        //             "영상에 맞추어 노래를 함께 한다.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '08', page: '12', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Days of the Week Teaching Guide",
        //             "Days of the Week 제목을 말한다.",
        //             "Let's say the days.",
        //             "Sunday /Monday / Tuesday / Wednesday /",
        //             "Thursday / Friday / Saturday",
        //             "How many days are in a week?",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Let's count. (one, two, three, four……seven)",
        //             "Seven days are in a week.",
        //             "Trace the numbers",
        //             "Put the matching sticker on the circle.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             " 민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '03', page: '05', action: '05', scene: '01', sentences: [
        //             "Song: Today is Monday",
        //             "Today is Monday 제목을 말한다.",
        //             "Today is Monday 영상을 본다. (반복)",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '11', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant K Says k Teaching Guide",
        //             "Let's chant: K Says k. 영상을 본다.",
        //             "Letter K 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
        //             "What letter is this? / Big K and little K .",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's chant: K Says k. 영상을 본다.",
        //             "K is for ketchup. / K is for kimchi. / K is for kiwi.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '11', action: '02', scene: '01', sentences: [
        //             "L Says l Teaching Guide",
        //             "L Says l 제목을 말한다.",
        //             "Let's chant: L Says l. 영상을 본다.",
        //             "Letter L 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
        //             "What letter is this? / Big L and little l .",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's chant: L Says l. 영상을 본다.",
        //             "Trace the big L and little l",
        //             "L 로 시작하는 단어를 써보고, (단어 확인)",
        //             "L is for lemon. / L is for lettuce. / L is for lollipop.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '11', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '11', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '12', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘L l' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , L 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '12', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with L.",
        //             "lettuce starts with L. Color it. / lemon starts with L. Color it.",
        //             "Fish starts with F. / lollipop starts with L. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '12', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '12', action: '01', scene: '04', sentences: [
        //             "Color the pictures starting with L.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '12', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Shapes Song‘ Teaching Guide",
        //             "‘Shapes Song ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Let's sing: Shapes Song .",
        //             "triangle / heart / square / circle",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '13', action: '02', scene: '01', sentences: [
        //             "Party Banners Teaching Guide",
        //             "Party Banners 제목을 말한다.",
        //             "There are patterns.",
        //             "Some patterns are missing.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '13', action: '02', scene: '02', sentences: [
        //             "Party Banners 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '13', action: '02', scene: '03', sentences: [
        //             "What (color/shape) comes next?",
        //             "각 그림의 색을 순차적으로 말한다. 빈 공간에 들어갈 색은?",
        //             "What comes next?",
        //             "Make the patterns.",
        //             "그림 그리는 것으로 활동을 마무리.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '01', sentences: [
        //             "Look Inside! Teaching Guide",
        //             "Look Inside! 제목을 말한다.",
        //             "These are tomatoes.",
        //             "Cut the tomatoes in two ways.",
        //             "Guess how tomatoes look inside.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '02', sentences: [
        //             "Look Inside! 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '03', sentences: [
        //             "Let's cut the tomatoes.",
        //             "What do we need?",
        //             "a knife / tomatoes",
        //             "Cut down the middle.",
        //             "Look inside and draw.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '04', sentences: [
        //             "그림을 그리도록 한다.",
        //             "다른 과일을 자르면 어떨까요?",
        //             "Look Inside! 영상을 보고 상호작용한다.",
        //             "아이들이 그림 그리는 것으로 활동을 마무리.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '14', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Today is Monday Teaching Guide",
        //             "Today is Monday 제목을 말한다.",
        //             "Today is Monday 영상을 본다. (반복)",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '16', action: '01', scene: '01', sentences: [
        //             "‘Drawing Game' Teaching Guide",
        //             "‘Drawing Game' 제목을 말한다.",
        //             "Let's play a game.",
        //             "Listen carefully.",
        //             "Draw / under / in / on",
        //             "Square / triangle /circle / heart",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '16', action: '01', scene: '02', sentences: [
        //             "‘Who Am I' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '16', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Let's listen and draw.",
        //             "What did you draw?",
        //             "What is it?",
        //             "활동을 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '04', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant L Says l Teaching Guide",
        //             "Let's chant: L Says l. 영상을 본다.",
        //             "Letter L 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
        //             "What letter is this? / Big L and little l .",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: L Says l 영상을 본다.",
        //             "L is for lemon. / L is for lettuce. / L is for lollipop.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '05', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '18', action: '02', scene: '01', sentences: [
        //             "M Says m Teaching Guide",
        //             "M Says m 제목을 말한다.",
        //             "Let's chant: M Says m. 영상을 본다.",
        //             "Letter M 가 무슨 소리가 날까요? (‘므' 소리가 난다)",
        //             "What letter is this? / Big M and little m.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: M Says m. 영상을 본다.",
        //             "Trace the big M and little m",
        //             "M 로 시작하는 단어를 써보고, (단어 확인)",
        //             "M is for",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '18', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '06', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '19', action: '01', scene: '01', sentences: [
        //             "Say the name of each picture ‘M m' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , M 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '19', action: '01', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with M",
        //             "melon start with M. Color it. / milk start with M. Color it.",
        //             "Kiwi start with K. / muffin start with M. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '19', action: '01', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '19', action: '01', scene: '04', sentences: [
        //             "Color the pictures starting with M.",
        //             "Say the sounds of the letters.",
        //             "Put the matching stickers on the circles.",
        //             "숫자, 스티커 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '07', page: '19', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "‘Hidden Pictures ' 제목을 말한다.",
        //             "This is Jay's birthday party.",
        //             "Are you hungry?",
        //             "Come to the party!",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Hidden Pictures ' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the hidden things.",
        //             "ice cream / milk / yogurt / cheese",
        //             "spaghetti/ corn / chicken / fish",
        //             "Write the numbers in order.",
        //             "순서를 바르게 적는 것을 확인하고 활동 마무리.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '08', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Robby Teaching Guide",
        //             "‘Let's Move Robby' 제목을 말한다.",
        //             "Robby wants some spaghetti.",
        //             "Right / Left / Up /Down",
        //             "‘Let's Move Robby' 영상을 보고 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Robby wants some spaghetti.",
        //             "Put the stickers on the circles.",
        //             "Right / Left / Up / Down",
        //             "카드놀이 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '06', week: '03', slide: '09', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '04', page: '03', action: '00', scene: '01', sentences: [
        //             "Daily Routine ‘ Numbers Song. ‘ Teaching Guide",
        //             "‘ Numbers Song. ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 손 숫자를 같이 한다.",
        //             "Let's sing: Numbers Song.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '05', week: '01', slide: '04', page: '03', action: '00', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '05', page: '03', action: '01', scene: '01', sentences: [
        //             "How Many Astronauts Are There? Teaching Guide",
        //             "How Many Astronauts Are There? 제목을 말한다.",
        //             "Let's sing the Numbers Song.",
        //             "Numbers Song영상을 본다.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '05', page: '03', action: '01', scene: '02', sentences: [
        //             "Write the numbers in the boxes.",
        //             "(5) astronauts in a flying saucer~",
        //             "(2) astronauts in a flying saucer~",
        //             "숫자(5),(2) 쓰는 것으로 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '05', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather Song' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is windy. windy 를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 우주선, 5 사람… 등을 이야기한다.",
        //             "Let's look at the picture.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '02', sentences: [
        //             "I see a spaceship.",
        //             "I see five men.",
        //             "They are astronauts.",
        //             "Do you see (a train/an airplane/a spaceship/a car)?",
        //             "Yes. I see a spaceship.",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the pictures.",
        //             "(a train/an airplane/a spaceship/a car)? 그림 포인트, 말한다.",
        //             "Yes/ no.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "We fly around the world! Teaching Guide",
        //             "We fly around the world! (그림책을 보면서, 말한다)",
        //             "I am an astronaut!",
        //             "Draw your face.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '05', action: '01', scene: '02', sentences: [
        //             "He is an astronaut.",
        //             "He likes spaceships.",
        //             "Do you want to be an astronaut?",
        //             "얼굴 그리는 것으로 마무리.",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '07', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '08', page: '08', action: '00', scene: '01', sentences: [
        //             "Daily Routine Let's sing: One Little Finger. Teaching Guide",
        //             "One Little Finger. 영상을 본다.",
        //             "Let's sing One Little Finger (주의집중 시키는 챈트)",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '08', page: '08', action: '00', scene: '02', sentences: [
        //             "Do you know the One Little Finger song?",
        //             "Let's sing ‘One Little Finger song'",
        //             "One Little Finger, tap, tap, tap.",
        //             "Point to the ceiling,",
        //             "point to the floor,",
        //             "put it on your head.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '08', page: '08', action: '00', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '03', page: '06', action: '05', scene: '01', sentences: [
        //             "Song: Five Little Men Teaching Guide",
        //             "Song: Five Little Men 제목을 말한다.",
        //             "Five Little Men 영상을 본다.",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '03', page: '06', action: '05', scene: '02', sentences: [
        //             "five, men, / left, / right, / jump",
        //             "Five Little Men 영상을 본다.",
        //             "Good job! Singing. (노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '03', page: '06', action: '05', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '10', page: '06', action: '07', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Where Are They? Teaching Guide",
        //             "Where Are They? 제목을 말한다.",
        //             "The car is on the land.",
        //             "The boat is in the sea.",
        //             "The airplane is in the sky.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Let's find the car, boat, and airplane.",
        //             "Put the matching stickers on the circles.",
        //             "Trace the words.",
        //             "쓰고, 스티커 붙이는 활동으로 마무리.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '03', sentences: [
        //             "Where Are They? 영상을 본다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '10', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 October를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)"
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '10', page: '00', action: '00', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나옴 (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '01', slide: '10', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Five Little Men Teaching Guide",
        //             "Song: Five Little Men 제목을 말한다.",
        //             "Five Little Men 영상을 본다.",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song. . (시간이 되면, 반복하는 노래)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "N Says n Teaching Guide",
        //             "N Says n 제목을 말한다.",
        //             "Let's chant: N Says n . 영상을 본다.",
        //             "Letter N 가 무슨 소리가 날까요? (‘느' 소리가 난다)",
        //             "What letter is this? / Big N and little n .",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: N Says n . 영상을 본다.",
        //             "Trace the big N and little n",
        //             "N 로 시작하는 단어를 써보고, (단어 확인)",
        //             "N is for nuts. / N is for noodles. / N is for napkin.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '06', page: '10', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘N n' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , N 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '06', page: '10', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with N.",
        //             "noodles starts with N. Color it./ nuts starts with N. Color it.",
        //             "Milk starts with M./ napkin starts with N. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '06', page: '10', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '06', page: '10', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with N.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '06', page: '10', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song.",
        //             "영상, 노래 율동",
        //             "Happy / Sad / Angry / sleeping",
        //             "Circle your feeling.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy",
        //             "Feelings 의 영상을 본다.(노래, 율동)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Same or Different Teaching Guide",
        //             "Same or Different 제목을 말한다.",
        //             "Let's find the same picture.",
        //             "spaceship / ship / submarine / airplane",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Draw a triangle around the picture.",
        //             "그림 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "Same or Different 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Tangram Teaching Guide",
        //             "Tangram 제목을 말한다.",
        //             "Tangram 영상을 본다.",
        //             "Let's create shapes.",
        //             "교구 준비 (탱그램 카드를 준비한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "There are four pieces of a tangram.",
        //             "Can you put the four tangram pieces together?",
        //             "Let's make a shape.",
        //             "그림 위에 카드를 맞추면 칭찬 하며 마무리.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '02', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Five Little Men Teaching Guide",
        //             "Song: Five Little Men 제목을 말한다.",
        //             "Five Little Men 영상을 본다.",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '04', page: '11', action: '05', scene: '01', sentences: [
        //             "Phonics Let's chant N Says n Teaching Guide",
        //             "Let's chant: N Says n . 영상을 본다.",
        //             "Letter N 가 무슨 소리가 날까요? (‘느' 소리가 난다)",
        //             "What letter is this? / Big N and little n .",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '04', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's chant: N Says n . 영상을 본다.",
        //             "N is for nuts. / N is for noodles. / N is for napkin.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '04', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '05', page: '11', action: '02', scene: '01', sentences: [
        //             "O Says o 대본",
        //             "O Says o 제목을 말한다.",
        //             "Let's chant: O Says o. 영상을 본다.",
        //             "Letter O 가 무슨 소리가 날까요? (‘아' 소리가 난다)",
        //             "What letter is this? / Big O and little o .",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '05', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's chant: O Says o. 영상을 본다.",
        //             "Trace the big L and little O",
        //             "L 로 시작하는 단어를 써보고, (단어 확인)",
        //             "O is for orange. / O is for onion. / O is for octopus.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '05', page: '11', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '05', page: '11', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '06', page: '11', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘O o' 대본",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , O 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '06', page: '11', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with O.",
        //             "Octopus starts with O. Color it. / Orange starts with O. Color it.",
        //             "Lemon starts with L. / Onion starts with O. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '06', page: '11', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '06', page: '11', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with O.",
        //             "그리기 활동 하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '06', page: '11', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '07', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ Numbers Song. ‘ Teaching Guide",
        //             "‘ Numbers Song. ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 손 숫자를 같이 한다.",
        //             "Let's sing: Numbers Song.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '07', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '08', page: '13', action: '02', scene: '01 ', sentences: [
        //             "Left and Right Teaching Guide",
        //             "Left and Right 제목을 말한다.",
        //             "There are five little men in a spaceship.",
        //             "They look left and right.",
        //             "What do you see on the (left/right)?",
        //             "Boats are on the left.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '08', page: '13', action: '02', scene: '02', sentences: [
        //             "Left and Right 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '08', page: '13', action: '02', scene: '03', sentences: [
        //             "Draw what you see!",
        //             "On the left. / On the right.",
        //             "그리는 것으로 활동을 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '08', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '09', page: '00', action: '00', scene: '01', sentences: [
        //             "Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song.",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '09', page: '00', action: '00', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '03', slide: '09', page: '00', action: '00', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Five Little Men Teaching Guide",
        //             "Song: Five Little Men 제목을 말한다.",
        //             "Five Little Men 영상을 본다.",
        //             "Let's sing! 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '04', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant O Says o Teaching Guide",
        //             "Let's chant: O Says o . 영상을 본다.",
        //             "Letter O 가 무슨 소리가 날까요? (‘아' 소리가 난다)",
        //             "What letter is this? / Big O and little l .",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '04', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: O Says o 영상을 본다.",
        //             "O is for orange. / O is for onion. / O is for octopus.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '04', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "P Says p Teaching Guide",
        //             "P Says p 제목을 말한다.",
        //             "Let's chant: P Says p. 영상을 본다.",
        //             "Letter P 가 무슨 소리가 날까요? (‘프' 소리가 난다)",
        //             "What letter is this? / Big P and little p.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: P Says p. 영상을 본다.",
        //             "Trace the big P and little p",
        //             "P 로 시작하는 단어를 써보고, (단어 확인)",
        //             "P is for pizza. / P is for pot. / P is for pineapple.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '05', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘P p' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , P 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with P",
        //             "Pot starts with P. Color it. / Pizza starts with P. Color it.",
        //             "Onion starts with O. / Pineapple starts with P. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with P.",
        //             "Say the sounds of the letters.",
        //             "Put the matching stickers on the circles.",
        //             "숫자, 스티커 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "‘Hidden Pictures ' 제목을 말한다.",
        //             "Look at the spaceships on the screen!",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Hidden Pictures ' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the hidden things.",
        //             "apple / orange / strawberry /grapes",
        //             "pineapple / car / truck / train",
        //             "올바르게 찾는지 확인하고 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '08', page: '22', action: '01', scene: '01', sentences: [
        //             " Coding Memory Game Teaching Guide",
        //             "‘Coding Memory Game' 제목을 말한다.",
        //             "Let's play a game.",
        //             "Can you find the same cards? Discuss with your team.",
        //             "Discuss with your team.",
        //             "Start / Right /Left / Up / Down Turn Right / Turn Left / End",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '08', page: '22', action: '01', scene: '02', sentences: [
        //             "카드 활동을 해볼까요?",
        //             "Make two teams.",
        //             "카드놀이 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '07', week: '04', slide: '08', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Months of the Year Teaching Guide",
        //             "Months of the Year 제목을 말한다.",
        //             "Let's sing the Months Song.",
        //             "Months Song영상을 본다.",
        //             "영상에 맞추어 노래를 같이 한다.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "What month is it?",
        //             "January / February / March / Apr",
        //             "May / June / July / August",
        //             "September / October / November / December",
        //             "It is …. 쓰는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is windy. windy를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is snowy. snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Picture Teaching Guide",
        //             "Let's Look at the Picture 제목을 말한다.",
        //             "Let's Look at the Picture 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 빵, 칼, 잼, 등을 이야기한다.",
        //             "Let's look at the picture.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Do you see",
        //             "(a knife / jam / peanut butter / bread)?",
        //             "Yes, I see (a knife/jam/peanut butter/bread).",
        //             "Let's Look at the Picture 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the pictures.",
        //             "(a knife / jam / peanut butter / bread) 그림을 말한다.",
        //             "Yes/ no.",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Spread it! Teaching Guide",
        //             "What do you want to spread? (그림을 보면서, 말한다)",
        //             "Color the bread.",
        //             "cream cheese / butter / peanut butter / jam",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Do you like (cream cheese/butter/peanut butter/jam)?",
        //             "Do you want to spread",
        //             "(cream cheese/butter/peanut butter/jam)",
        //             "on the bread?",
        //             "빵 그리는 것으로 마무리.",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 함께해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Peanut Butter and Jelly Teaching Guide",
        //             "Song: Peanut Butter and Jelly 제목을 말한다.",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Bread/ grapes / jelly / peanut/ /peanut butter / sandwich",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Good job! Singing. (노래와 율동을 한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "Put the peanut butter and jelly and sandwich stickers on the circles.",
        //             "Good job!",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Colors Song Teaching Guide",
        //             "Colors Song 영상을 본다.",
        //             "Let's sing: Colors Song.(영상, 노래, 율동)",
        //             "Good job! Singing",
        //             "What color is this?",
        //             "Red / Blue / Green",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Simon Says Teaching Guide",
        //             "Simon Says 제목을 말한다.",
        //             "crunch, eat, spread, squish",
        //             "Let's do the motions.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Let's play a game.",
        //             "How to play 'Simon Says '",
        //             "If you hear 'Simon says,' do the motions.",
        //             "If you don't hear 'Simon says,' don't move.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Simon Says 영상을 본다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 November를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Peanut Butter and Jelly Teaching Guide",
        //             "Song: Peanut Butter and Jelly 제목을 말한다.",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Bread/ grapes / jelly / peanut/",
        //             "peanut butter / sandwich",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Good job! Singing. (노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song. ( 반복 )",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Q Says q Teaching Guide",
        //             "Q Says q 제목을 말한다.",
        //             "Let's chant: Q Says q . 영상을 본다.",
        //             "Letter Q 가 무슨 소리가 날까요? (‘크' 소리가 난다.)",
        //             "What letter is this? / Big Q and little q .",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: Q Says q. 영상을 본다.",
        //             "Trace the big Q and little q",
        //             "Q 로 시작하는 단어를 써보고, (단어 확인)",
        //             "Q is for queen. / Q is for quince. / Q is for quail.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '06', page: '10', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘Q q' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , Q 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '06', page: '10', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with Q.",
        //             "quince starts with Q. Color it./ quail starts with Q. Color it.",
        //             "Pineapple starts with P./ queen starts with Q. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '06', page: '10', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '06', page: '10', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with Q.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '06', page: '10', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song. (영상, 노래 율동)",
        //             "Happy / Sad / Angry / Surprise",
        //             "Circle your feeling.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy",
        //             "Feelings 의 영상을 본다.(노래와 함께 율동)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Same or Different Teaching Guide",
        //             "Same or Different 제목을 말한다.",
        //             "What is (the same/different)?",
        //             "Find the (same/different) things in the picture.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Circle the five different things.",
        //             "그림 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "Same or Different 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '09', page: '14', action: '01', scene: '01', sentences: [
        //             "Choose Your Tool Teaching Guide",
        //             "Choose Your Tool 제목을 말한다.",
        //             "Let's think about what tools make it",
        //             "easy to spread the peanut butter.",
        //             "Do you want to use a",
        //             "(spoon/fork/chopstick/spatula/knife/straw)?",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '09', page: '14', action: '01', scene: '02', sentences: [
        //             "Choose Your Tool 영상을 본다.",
        //             "Let's spread the peanut butter.",
        //             "What do you want to use?",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '09', page: '14', action: '01', scene: '03', sentences: [
        //             "Choose Your Tool 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '02', slide: '09', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Peanut Butter and Jelly Teaching Guide",
        //             "Song: Peanut Butter and Jelly 제목을 말한다.",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Bread/ grapes / jelly / peanut/",
        //             "peanut butter / sandwich",
        //             "Good job! Singing. (노래와 율동을 한다.)",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Shapes Song‘ Teaching Guide",
        //             "‘Shapes Song ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Let's sing: Shapes Song .",
        //             "What shape is this? Color the star.",
        //             "triangle / heart / star / square",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "What Do We Need? Teaching Guide",
        //             "What Do We Need? 제목을 말한다.",
        //             "when we",
        //             "(toast the bread/wash the clothes)?",
        //             "We need a toaster.",
        //             "We need a washing machine.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "What Do We Need? 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Word bank:",
        //             "washing machine / toaster /blender / computer",
        //             "Put the matching stickers on the circles.",
        //             "그리는 것으로 활동을 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant Q Says q Teaching Guide",
        //             "Let's chant: Q Says q. 영상을 본다.",
        //             "Letter Q 가 무슨 소리가 날까요? (‘크' 소리가 난다)",
        //             "What letter is this? / Big Q and little q .",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's chant: Q Says q. 영상을 본다.",
        //             "Q is for queen. / Q is for quince. / Q is for quail.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "R Says r Teaching Guide",
        //             "R Says r 제목을 말한다.",
        //             "Let's chant: R Says r . 영상을 본다.",
        //             "Letter R 가 무슨 소리가 날까요? (‘르' 소리가 난다)",
        //             "What letter is this? / Big R and little r .",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's chant: R Says r . 영상을 본다.",
        //             "Trace the big R and little r",
        //             "R 로 시작하는 단어를 써보고, (단어 확인)",
        //             "R is for rice. / R is for rabbit. / R is for raisins.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '07', page: '11', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘R r' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , R 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with R.",
        //             "Rabbit starts with R. Color it. / Rabbit starts with R. Color it.",
        //             "Nets starts with N / Raisins starts with R. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with R.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '08', page: '11', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '09', page: '00', action: '09', scene: '01', sentences: [
        //             "Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song.",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '09', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '03', slide: '09', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Peanut Butter and Jelly Teaching Guide",
        //             "Song: Peanut Butter and Jelly 제목을 말한다.",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Bread/ grapes / jelly / peanut/",
        //             "peanut butter / sandwich",
        //             "Peanut Butter and Jelly 영상을 본다.",
        //             "Good job! Singing. (노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '04', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant R Says r Teaching Guide",
        //             "Let's chant: R Says r . 영상을 본다.",
        //             "Letter R 가 무슨 소리가 날까요? (‘르' 소리가 난다.)",
        //             "What letter is this? / Big R and little l .",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '04', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: R Says r 영상을 본다.",
        //             "R is for rice. / R is for rabbit. / R is for raisins.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '04', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "S Says s Teaching Guide",
        //             "S Says s 제목을 말한다.",
        //             "Let's chant: S Says s . 영상을 본다.",
        //             "Letter S 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
        //             "What letter is this? / Big S and little s.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: S Says s . 영상을 본다.",
        //             "Trace the big S and little s",
        //             "S 로 시작하는 단어를 써보고, (단어 확인)",
        //             "S is for spoon. / S is for salad. / S is for soup.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '05', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘S s' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , S 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with S",
        //             "Spoon starts with S. Color it. / Soup starts with S. Color it.",
        //             "Napkin starts with N. / Salad starts with S . Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with S.",
        //             "Say the sounds of the letters.",
        //             "Put the matching stickers on the circles.",
        //             "숫자, 스티커 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "‘Hidden Pictures ' 제목을 말한다.",
        //             "The children are playing in the playground.",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Hidden Pictures ' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the hidden things.",
        //             "Broccoli /carrot /corn / tomato",
        //             "Knife / hammer / nut / cracker pan",
        //             "찾는 것 확인하고 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '08', page: '22', action: '01', scene: '01', sentences: [
        //             "What Am I? Teaching Guide",
        //             "‘What Am I?' 제목을 말한다.",
        //             "Let's think.",
        //             "I am a tool.",
        //             "You can find me in the kichen.",
        //             "I am sharp.",
        //             "I can cut food.",
        //             "I am (a hammer / a spoon / a knife / a fork)",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '08', page: '22', action: '01', scene: '02', sentences: [
        //             "‘What Am I?' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '08', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '08', week: '04', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Shape World Teaching Guide",
        //             "Shape World 제목을 말한다.",
        //             "Let's play a game.",
        //             "Roll the dice and move. What shape is it?",
        //             "circle, end, roll, rice, shape, square, start, triangle,",
        //             "How do you play this game?",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Seasons and Food Teaching Guide",
        //             "Seasons and Food 제목을 말한다.",
        //             "Let's sing the Seasons Song.",
        //             "Seasons Song 영상을 본다.",
        //             "영상에 맞추어 노래를 같이 한다.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Seasons and Food 영상을 본다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "활동을 해볼까요?",
        //             "Draw the food you want to eat in summer and winter.",
        //             "summer and winter.",
        //             "Yes/ no.",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is windy. windy 를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is snowy. snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 엄마, 콩, 빵, 그릇 등을 이야기한다.",
        //             "Let's look at the picture.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '04', action: '02', scene: '02', sentences: [
        //             "Do you see",
        //             "(porridge / peas/ hot chocolate/a pot)?",
        //             "Yes, I see (peas/a pot).",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the pictures.",
        //             "(porridge / peas/ hot chocolate/a pot) 그림을 말한다.",
        //             "Yes/ no.",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //             "It's winter. Teaching Guide",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "It's winter. (그림을 보면서, 말한다)",
        //             "What do you want to (eat/drink) in the winter?",
        //             "It's cold!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '05', action: '01', scene: '02', sentences: [
        //             "Do you want to (eat/drink)",
        //             "(pie / hot chocolate / porridge / sweet potatoes)?",
        //             "I want to (eat/drink)",
        //             "(pie / hot chocolate / porridge / sweet potatoes).",
        //             "그림 그리는 것으로 마무리",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '07', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 함께 해보세요.",
        //             "복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Peas Porridge Hot Teaching Guide",
        //             "Song: Peas Porridge Hot 제목을 말한다.",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "cold, hot, peas, porridge, pot",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Good job! Singing. (노래, 율동)",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the peas porridge and pot stickers on the circles.",
        //             "Good job!",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '09', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Shapes Song‘ Teaching Guide",
        //             "‘Shapes Song ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Let's sing: Shapes Song .",
        //             "square / circle / triangle",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '09', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '10', page: '08', action: '02', scene: '01', sentences: [
        //             "Hot and Cold Teaching Guide",
        //             "Hot and Cold 제목을 말한다.",
        //             "Let's trace the words.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '01', slide: '10', page: '08', action: '02', scene: '02', sentences: [
        //             "Let's play a game.",
        //             "How to play 'Hot and Cold '",
        //             "Hide the treasure star and have the player find it.",
        //             "When the player gets close to the treasure, say, 'Hot!'",
        //             "When the player gets far from the treasure, say, 'Cold!'",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Peas Porridge Hot Teaching Guide",
        //             "Song: Peas Porridge Hot 제목을 말한다.",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "cold, hot, peas, porridge, pot",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Good job! Singing. (노래와 율동을 한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "T Says t Teaching Guide",
        //             "T Says t 제목을 말한다.",
        //             "Let's chant: T Says t . 영상을 본다.",
        //             "Letter T 가 무슨 소리가 날까요? (‘트' 소리가 난다.)",
        //             "What letter is this? / Big T and little t .",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: T Says t . 영상을 본다.",
        //             "Trace the big T and little t",
        //             "T 로 시작하는 단어를 써보고, (단어 확인)",
        //             "T is for tent. / T is for toaster. / T is for tomato",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '06', page: '10', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘T t' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , T 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '06', page: '10', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with T.",
        //             "Tent starts with T. Color it./ Toaster starts with T. Color it.",
        //             "Queen starts with Q./ Tomato starts with T. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '06', page: '10', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '06', page: '10', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with T.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '06', page: '10', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song.영상, 노래, 율동",
        //             "Happy / Sad / Angry / Sleeping",
        //             "Circle your feeling.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy",
        //             "Feelings 의 영상을 본다(노래와 함께 율동)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Play in the Winter Teaching Guide",
        //             "Play in the Winter 제목을 말한다.",
        //             "What do you like to do in the winter?",
        //             "Do you like to (go sledding/make snow angels)",
        //             "(/make snowmen/have snowball fights)?",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Play in the Winter 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many children like to (go sledding) in the winter?",
        //             "Let's count.",
        //             "Come up and color the boxes.",
        //             "아이들이 그림 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '09', page: '14', action: '01', scene: '01', sentences: [
        //             "Snowflakes Teaching Guide",
        //             "Snowflakes 제목을 말한다.",
        //             "Look at the snowflakes.",
        //             "What do we need?",
        //             "We need a magnifier",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '09', page: '14', action: '01', scene: '02', sentences: [
        //             "Snowflakes영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '09', page: '14', action: '01', scene: '03', sentences: [
        //             "Put the snowflake stickers on the circles.",
        //             "This is a snowflake.",
        //             "How many points do snowflakes have?",
        //             "Snowflakes have 6 points",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '02', slide: '09', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //             "Song: Peas Porridge Hot Teaching Guide",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Peas Porridge Hot 제목을 말한다.",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "cold, hot, peas, porridge, pot",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Good job! Singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Stretching Song. ‘ Teaching Guide",
        //             "‘Stretching Song. ‘ 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Let's sing: Stretching Song.",
        //             "Stretch your arms",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Mittens Teaching Guide",
        //             "Mittens 제목을 말한다.",
        //             "These are mittens.",
        //             "Do you see shapes on the mittens?",
        //             "This is a (circle / triangle / square / heart / star).",
        //             "Let's find the matching mittens.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Mittens 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Decorate the mittens with shapes.",
        //             "그리는 것으로 활동을 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant T Says t Teaching Guide",
        //             "Let's chant: T Says t. 영상을 본다.",
        //             "Letter T 가 무슨 소리가 날까요? (‘트' 소리가 난다.)",
        //             "What letter is this? / Big T and little t .",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Let's chant: T Says t. 영상을 본다.",
        //             "T is for tent. / T is for toaster. / T is for tomato.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '01', sentences: [
        //             "U Says u Teaching Guide",
        //             "U Says u제목을 말한다.",
        //             "Let's chant: U Says u. 영상을 본다.",
        //             "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다.)",
        //             "What letter is this? / Big U and little u .",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '02', sentences: [
        //             "Let's chant: U Says u. 영상을 본다.",
        //             "Trace the big U and little u",
        //             "U 로 시작하는 단어를 써보고, (단어 확인)",
        //             "U is for up. / U is for umpire. / U is for umbrella.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '07', page: '11', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘U u' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , U 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with U.",
        //             "Up starts with U. Color it. / Umbrella starts with U. Color it.",
        //             "Rice starts with R / Umpire starts with U. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with U.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '08', page: '11', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다.what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 December를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '03', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 .Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Peas Porridge Hot Teaching Guide",
        //             "Song: Peas Porridge Hot 제목을 말한다.",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "cold, hot, peas, porridge, pot",
        //             "Peas Porridge Hot 영상을 본다.",
        //             "Good job! Singing. (노래와 율동을 한다.)",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(Goodbye~ 다음 시간에 만나요.",
        //             "복습, 반복, 부모님과의 상호작용)",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '04', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant U Says u Teaching Guide",
        //             "Let's chant: U Says u. 영상을 본다.",
        //             "Letter U 가 무슨 소리가 날까요? (‘어' 소리가 난다.)",
        //             "What letter is this? / Big U and little u .",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '04', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: U Says ur 영상을 본다.",
        //             "U is for up. / U is for umpire. / U is for umbrella.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '04', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '01', sentences: [
        //             "V Says v Teaching Guide",
        //             "S Says s 제목을 말한다.",
        //             "Let's chant: V Says v . 영상을 본다.",
        //             "Letter V 가 무슨 소리가 날까요? (‘브' 소리가 난다.)",
        //             "What letter is this? / Big V and little V.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: V Says v . 영상을 본다.",
        //             "Trace the big V and little V",
        //             "V 로 시작하는 단어를 써보고, (단어 확인)",
        //             "V is for van. / V is for vine. / V is for vest.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '05', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘V v' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , V 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with V",
        //             "Van starts with V. Color it. / Vine. starts with V. Color it.",
        //             "Soup starts with S. / Vest starts with V . Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with V.",
        //             "Say the sounds of the letters.",
        //             "Put the matching stickers on the circles.",
        //             "숫자, 스티커 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '06', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "‘Hidden Pictures ' 제목을 말한다.",
        //             "It's winter! Let's go ice skating.",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Hidden Pictures ' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the hidden things.",
        //             "Chicken /egg / fish / sausages",
        //             "boots / coat / hat / scarf",
        //             "확인하고 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '07', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '08', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move the Boy Teaching Guide",
        //             "‘Let's Move the Boy' 제목을 말한다.",
        //             "It's cold outside.",
        //             "The boy wants some porridge.",
        //             "Let's move the boy.",
        //             "Start / Right / Left / Up / Down / End",
        //             "Put the sticker on the circle..",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '08', page: '22', action: '01', scene: '02', sentences: [
        //             "‘Let's Move the Boy ‘ 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '08', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Tic-Tac-Toe Teaching Guide",
        //             "Tic-Tac-Toe 제목을 말한다.",
        //             "Let's play Tic-Tac-Toe.",
        //             "Let's follow the directions.",
        //         ]
        //     }, {
        //         level: '02', volume: '09', week: '04', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "draw, mark, play, tic-tac-toe, space,",
        //             "(three) in a row, How do you play this game?",
        //             "Let's play a game.",
        //             "Say tic-tac-toe!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Thank You Teaching Guide",
        //             "Thank You 제목을 말한다.",
        //             "Let's sing the Thank You Song.",
        //             "Thank You Song 영상, 노래",
        //             " Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "활동을 해볼까요?",
        //             "Draw your teacher and your friend.",
        //             "Thank you, teacher!",
        //             "Thank you, friend!",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 보며, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.",
        //             "Let's Look at the Cover 스토리 북의 그림을 가리키며, 말한다.",
        //             "What do you see?",
        //             "아이) 농부, 누나, 친구 채소,…. 등을 이야기한다.",
        //             "Let's look at the picture.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "This is a farm.",
        //             "These are farmers.",
        //             "Do you see (a farmer / seeds / lettuce / bugs)?",
        //             "Let's Look at the Cover 영상을 보여주며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the pictures.",
        //             "(a farmer / seeds / lettuce / bugs) 그림을 말한다.",
        //             "Yes/ no.",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "I want to grow lettuce. Teaching Guide",
        //             "I want to grow lettuce. (그림을 보면서, 말한다)",
        //             "Let's grow vegetables.",
        //             "What do you want to grow?",
        //             "I want to grow (broccoli / carrots / cucumbers / tomatoes).",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Draw the picture of the vegetables.",
        //             "(broccoli / carrots / cucumbers / tomatoes).",
        //             "I want to eat",
        //             "(broccoli / carrots / cucumbers / tomatoes).",
        //             "그림 그리는 것으로 마무리.",
        //             "Good job! Drawing",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: The Farmers Teaching Guide",
        //             "Song: The Farmers 제목을 말한다.",
        //             "The Farmers 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "farmers, grow, leaves, pick up, plant, rain, seeds",
        //             "The Farmers 영상을 본다.",
        //             "Good job! Singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "활동을 해 볼까요?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //             "스티커를 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘Roll Your Hands. Teaching Guide",
        //             "‘Roll Your Hands. Song' 의 영상, 노래 율동",
        //             "Let's sing: Roll Your Hands.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Roll /as slowly as you can.",
        //             " clap /stomp / cross",
        //             " as fast as you can.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Let's Grow Plants Teaching Guide",
        //             "Let's Grow Plants 제목을 말한다.",
        //             "Watch the lettuce grow.",
        //             "Put the matching stickers on the circles	",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Plant the seeds.",
        //             "Water the plants.",
        //             "What do you see?",
        //             "The leaves grow!",
        //             "Lettuce!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "박이는 January를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다.)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Farmers Teaching Guide",
        //             "Song: The Farmers 제목을 말한다.",
        //             "The Farmers 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "farmers, grow, leaves, pick up, plant, rain, seeds",
        //             "The Farmers 영상을 본다.",
        //             "Good job! Singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song. (시간이 되면, 반복하는 노래)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "W Says w Teaching Guide",
        //             "W Says w 제목을 말한다.",
        //             "Let's chant: W Says w. 영상을 본다.",
        //             "Letter W 가 무슨 소리가 날까요? (‘우' 소리가 난다.)",
        //             "What letter is this? / Big W and little w .",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's chant: W Says w. 영상을 본다.",
        //             "Trace the big W and little w",
        //             "W 로 시작하는 단어를 써보고, (단어 확인)",
        //             "W is for waffle. / W is for water. / W is for window",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘W w' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , W로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with W.",
        //             "Water starts with W. Color it./ Waffle starts with W. Color it.",
        //             "Up starts with U./ Window starts with W. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with W.",
        //             "Good job! 그리기 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '06', page: '10', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Let's sing Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song.영상노래 율동",
        //             "Happy / Sad / Angry / Sleeping",
        //             "Circle your feeling.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy",
        //             "Feelings 의 영상을 본다. (노래, 율동)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Heavy Vegetables Teaching Guide",
        //             "Heavy Vegetables 제목을 말한다.",
        //             "Which one is heavy?",
        //             "Let's measure the vegetables.",
        //             "Look at the scales.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Heavy Vegetables 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "The (lettuce) is (5). The (carrot) is (3).",
        //             "Which one is heavy?",
        //             "The (lettuce) or the (carrot)?.",
        //             "The (lettuce) is heavy",
        //             "그림 활동으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '09', page: '11', action: '02', scene: '01', sentences: [
        //             "X Says x Teaching Guide",
        //             "X Says x 제목을 말한다.",
        //             "Let's chant: X Says x . 영상을 본다.",
        //             "Letter X 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
        //             "What letter is this? / Big X and little x.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '09', page: '11', action: '03', scene: '01', sentences: [
        //             "Let's chant: X Says x . 영상을 본다.",
        //             "Trace the big X and little x",
        //             "X 로 시작하는 단어를 써보고, (단어 확인)",
        //             "X is for fox. / X is for ox. / X is for box",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '09', page: '11', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '09', page: '11', action: '02', scene: '04', sentences: [
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '10', page: '11', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘X x' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , x 로 끝나는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '10', page: '11', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures ending with x.",
        //             "fox end with x . Color it./ box end with x . Color it.",
        //             "Toaster starts with t./ ox end with x . Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '10', page: '11', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '10', page: '11', action: '03', scene: '04', sentences: [
        //             "Color the pictures ending with x.",
        //             "Good job! 그리기 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '02', slide: '10', page: '11', action: '03', scene: '05', sentences: [
        //             "오늘(복습, 반복, 부모님과의 상호작용)",
        //             "교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Farmers Teaching Guide",
        //             "Song: The Farmers 제목을 말한다.",
        //             "The Farmers 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "farmers, grow, leaves, pick up, plant, rain, seeds",
        //             "The Farmers 영상을 본다.",
        //             "Good job! Singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's sing: Friendship Song. Teaching Guide",
        //             "Friendship Song. 의 영상을 본다.",
        //             "Let's sing: Friendship Song.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "One little heart to give away.",
        //             "One little heart for you today.",
        //             "'I love you' is what I say.",
        //             "I'll give one to my friend today.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's Recycle Teaching Guide",
        //             "Let's Recycle 제목을 말한다.",
        //             "This is trash.",
        //             "We can recycle paper and plastics.",
        //             "Can you find (paper / plastics)?",
        //             "Match the trash.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Let's Recycle 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Put the stickers on the matching bins.",
        //             "Good job! 그리기 활동 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant X Says x Teaching Guide",
        //             "Let's chant: X Says x. 영상을 본다.",
        //             "Letter X 가 무슨 소리가 날까요? (‘스' 소리가 난다.)",
        //             "What letter is this? / Big X and little x .",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: X Says x. 영상을 본다.",
        //             "X is for fox. / X is for ox. / X is for box",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Y Says y Teaching Guide",
        //             "Y Says y 제목을 말한다.",
        //             "Let's chant: Y Says y . 영상을 본다.",
        //             "Letter Y 가 무슨 소리가 날까요? (‘ㅕ' 소리가 난다.)",
        //             "What letter is this? / Big Y and little y .",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: Y Says y . 영상을 본다.",
        //             "Trace the big Y and little y",
        //             "Y 로 시작하는 단어를 써보고, (단어 확인)",
        //             "Y is for yogurt / Y is for yarn. / Y is for yacht.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '07', page: '18', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘Y y' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , Y 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with Y.",
        //             "yogurt starts with Y. Color it. / yarn starts with Y. Color it.",
        //             "Vest starts with V / yacht starts with Y. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with Y.",
        //             "Good job! 활동을 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '08', page: '18', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '01', sentences: [
        //             "Build the Cup Tower Teaching Guide",
        //             "Build the Cup Tower 제목을 말한다.",
        //             "Look at the picture.",
        //             "Can you make the same towers?",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '02', sentences: [
        //             "build, cup, down, left, move, right, stack, tower, up",
        //             "Now try making your own cup tower with your friends.",
        //             "Take a picture and paste it.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '03', slide: '09', page: '16', action: '01', scene: '03', sentences: [
        //             "Build the Cup Tower 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '03', page: '00', action: '00', scene: '01', sentences: [
        //             "Song: The Farmers Teaching Guide",
        //             "Song: The Farmers 제목을 말한다.",
        //             "The Farmers 영상을 본다.",
        //             "Let's sing with motions. 모두 함께 노래한다.",
        //             "노래 가사를 알려주며 함께 노래를 만들어 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '03', page: '00', action: '00', scene: '02', sentences: [
        //             "farmers, grow, leaves, pick up, plant, rain, seeds",
        //             "The Farmers 영상을 본다.",
        //             "Good job! Singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '03', page: '00', action: '00', scene: '023', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '04', page: '19', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant Y Says y Teaching Guide",
        //             "Let's chant: Y Says y . 영상을 본다.",
        //             "Letter Y 가 무슨 소리가 날까요? (‘ㅕ' 소리가 난다.)",
        //             "What letter is this? / Big Y and little y .",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '04', page: '19', action: '01', scene: '02', sentences: [
        //             "Let's chant: Y Says y . 영상을 본다.",
        //             "Y is for yogurt / Y is for yarn. / Y is for yacht.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '04', page: '19', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '01', sentences: [
        //             "Z Says z Teaching Guide",
        //             "Z Says z 제목을 말한다.",
        //             "Let's chant: Z Says z. 영상을 본다.",
        //             "Letter Z 가 무슨 소리가 날까요? (‘즈' 소리가 난다.)",
        //             "What letter is this? / Big Z and little z.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '02', sentences: [
        //             "Let's chant: Z Says z. 영상을 본다.",
        //             "Trace the big Z and little z",
        //             "Z 로 시작하는 단어를 써본다.",
        //             "Z is for zucchini / Z is for zebra. / Z is for zipper.",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '03', sentences: [
        //             "쓰는 것과 스티커 활동을 보면서 마무리",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '05', page: '19', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해 보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '01', sentences: [
        //             "Say the name of each picture ‘Z z' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "각 그림의 이름을 말해보고 , Z 로 시작하는 그림을 색칠한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '02', sentences: [
        //             "Say the name of each picture. 영상을 본다.",
        //             "Color the pictures starting with Z.",
        //             "Zucchini starts with Z. Color it. / Zebra starts with Z. Color it.",
        //             "yogurt starts with Y / Zipper starts with Z. Color it.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '03', sentences: [
        //             "Say the name of each picture.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '04', sentences: [
        //             "Color the pictures starting with Z.",
        //             "그리기 활동하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '06', page: '19', action: '03', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '07', page: '00', action: '09', scene: '01', sentences: [
        //             "Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song.",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '07', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '07', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '08', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "‘Hidden Pictures ' 제목을 말한다.",
        //             "Let's pick up the trash!",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '08', page: '20', action: '01', scene: '02', sentences: [
        //             "‘Hidden Pictures' 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '08', page: '20', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Find the hidden things.",
        //             "Bread /doughnut / muffin /rice",
        //             "Bottle / box / can / paper",
        //             "아이들이 올바르게 찾는지 확인하고 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '08', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '09', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move the Farmer Teaching Guide",
        //             "‘Let's Move the Farmer제목을 말한다.",
        //             "The farmer wants to plant the seeds.",
        //             "Let's move the farmer.",
        //             "Start / Right / Left / Up / Down / End",
        //             "Put the sticker on the circle..",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '09', page: '22', action: '01', scene: '02', sentences: [
        //             "‘Let's Move the farmer‘ 영상을 보고 상호작용한다.",
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민지, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '09', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 함께 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '10', page: '14', action: '01', scene: '01', sentences: [
        //             "Musical Kitchen Teaching Guide",
        //             "Musical Kitchen 제목을 말한다.",
        //             "Let's make music with the things in the kitchen?",
        //             "Can you make soft or loud sounds?",
        //             "Make sounds softly!",
        //             "Make sounds loudly!",
        //         ]
        //     }, {
        //         level: '02', volume: '10', week: '04', slide: '10', page: '14', action: '01', scene: '02', sentences: [
        //             "Let's hit the (bottle/cutting board/tray) with the utensils.",
        //             "Let's make some sounds.",
        //             "Circle what you want to use.",
        //             "Can you hear the sounds?",
        //             "What sounds does it make?",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             " Hello, How Are You? Teaching Guide",
        //             "Hello, How Are You? 제목을 말한다.",
        //             "Let's sing the Hello Song.",
        //             "Hello Song 영상, 노래, 율동.",
        //             "Put the stickers on the circles.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Hello, School!",
        //             "How are you?",
        //             "Hello, teacher!",
        //             "Hello Friends 의 영상을 본다.(노래, 율동)",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다. (노래를 다 함께 한다)",
        //             "What's the weather like today? (창밖을 보면서, 지문을 읽는다)",
        //             "손을 눈썹 밑으로 가며(경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
        //             "Look out the window. 손가락은 창문을 가리키며, 행동과 동시에 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is snowy 양손으로 눈이 내리듯 손가락을 움직이며, snowy를 말한다.",
        //             "It is windy 양손으로 바람이 부는 동작 표현), windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "활동을 해볼까요?",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //             "Let's Look at the Cover",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북 그림 보며)",
        //             "What do you see? (그림을 보면서, 무엇인지 나눈다)",
        //             "아이) 앵무새, 로봇, 쿠키 자, 강아지, 고양이… 라고 말한다.",
        //             "맞아요, 잘 했어요.",
        //             "Let's Look at the Cover 영상을 보며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Let's look at the picture.",
        //             "There is a cookie jar.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "Do you see",
        //             "(Geneus/ Robby/ Cricket/ Catty/ Doggy/ a cookie jar)?",
        //             "Circle the picture.",
        //             "Yes/no. / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "What are they doing? Teaching Guide",
        //             "What are they doing? (옆에 그림을 보면서…. )",
        //             "Are they (having a party/playing/crying)?",
        //             "They are having a party.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Draw your idea.",
        //             "Surprise!",
        //             "Excellent!",
        //             "Who Stole the Cookies from the Cookie Jar?",
        //             "영상을 보며, 수업을 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '03', page: '06', action: '06', scene: '01', sentences: [
        //             "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
        //             "Song: Who Stole the Cookies from the Cookie Jar? 제목을 말한다.",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래",
        //             "Who, me? Yes, you! Not me! Then, who?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '03', page: '06', action: '06', scene: '02', sentences: [
        //             "Catty* stole the cookies from the cookie jar.",
        //             "* Cricket / Robby / Geneus",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let s sing together. (노래를 함께 불러본다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '03', page: '06', action: '06', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? ‘ Teaching Guide",
        //             "Feelings 의 영상, 노래 율동",
        //             " How do you feel today?",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry / surprised",
        //             "Are you surprised? (깜짝 놀랐나요?)",
        //             "I am surprised (선생님도 놀랬어요.)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Where is the Cookie Jar? Teaching Guide",
        //             "Where is the Cookie Jar? 제목을 말한다.",
        //             "Is It on/under the table?",
        //             "It is under the table.",
        //             "Is the cookie jar on/under the kitchen counter?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Put the cookie jar stickers on the matching place.",
        //             "The cookie jar is on the kitchen counter.",
        //             "Put (something) on/under/in front of/behind (a place).",
        //             "The cookie jar is in front of the computer.",
        //             "Where is the Cookie Jar? 영상으로 상호작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "Cookie Jar 스티커 활동으로 마무리",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 March를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다.)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '01', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수 에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
        //             "Song: Who Stole the Cookies from the Cookie Jar? 제목을 말한다.",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "Who, me? Yes, you! Not me! Then, who?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Catty* stole the cookies from the cookie jar.",
        //             "* Cricket / Robby / Geneus",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let s sing together. (노래를 함께 불러본다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Short a Teaching Guide",
        //             "Short a 제목을 말한다.",
        //             "Let's sing: Short a. 영상을 본다.",
        //             "Short a 가 무슨 소리가 날까요? ('애' 소리가 난다.)",
        //             "Short a says / a /",
        //             "'a' can , 'a' pan , 'a' ham , 'a' jam",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Short a. 영상을 본다.",
        //             "Say the words (/c/-/a/-/n/can), pan, ham, jam.",
        //             "Put the matching stickers on the circles.",
        //             "Good job! / 스티커 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Let's say the word and find the first letter. ‘A a' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "Let's say the word and find the first letter.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letter to complete the word.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big A and little a. Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's write big A and little a.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "My Cookie Jar Teaching Guide",
        //             "My Cookie Jar 제목과 부제를 말한다.",
        //             "Let's draw cookies in the cookie jar.",
        //             "This is your cookie jar.",
        //             "Draw how many cookies you want.",
        //             "My Cookie Jar 영상을 본다. (2명 이상 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             " I want some cookies.",
        //             "I want five cookies",
        //             "Count your cookies and write the number.",
        //             "One, two, three, four, five.",
        //             "There are five cookies in my cookie jar.",
        //             "Good job! 숫자 쓰는 활동으로 마무리 .",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '09', page: '00', action: '08', scene: '01 ', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '02', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
        //             "Song: Who Stole the Cookies from the Cookie Jar? 제목을 말한다.",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "Who, me? Yes, you! Not me! Then, who?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Catty* stole the cookies from the cookie jar.",
        //             "* Cricket / Robby / Geneus",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let s sing together. (노래를 함께 불러본다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '05', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big B and little b. Teaching Guide",
        //             "Let's write big B and little b.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's Share the Cookies Teaching Guide",
        //             "Let's Share the Cookies 제목을 말한다.",
        //             "How many cookies can Geneus have?",
        //             "Here are cookies.",
        //             "Let's count.",
        //             "One, two, three, four, five, six, seven, eight, nine, ten.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Geneus, doggy, catty, robby, cricket.",
        //             "There are five characters.",
        //             "We have 10 cookies and there are 5 of us.",
        //             "How many cookies can (Geneus) have?",
        //             "Let's draw the cookies until everyone has 2 cookies.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Let's Share the Cookies 영상으로 상호 작용한다.",
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "활동을 해볼까요?",
        //             "Draw the cookies on the dishes. Write the number in the box.",
        //             "Geneus has (2) cookies.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '05', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Short a Teaching Guide",
        //             "Short a 제목을 말한다.",
        //             "Let's sing: Short a . 영상을 본다.",
        //             "Short a 가 무슨 소리가 날까요? ('애' 소리가 난다.)",
        //             "Short a says / a /",
        //             "'a' pan , 'a' can , 'a' ham , 'a' jam",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's sing: Short a 영상을 본다.",
        //             "Say the words (/c/-/a/-/n/can), pan, ham, jam.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: this, a ' Teaching Guide",
        //             "Sight Words: this, a ‘ 을 말한다.",
        //             "Let's chant: The School. 영상을 본다.",
        //             "What do you see in the picture?",
        //             "This is a",
        //             "(teacher / crayon / table / book / backpack / classroom).",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: The School. 영상을 본다.",
        //             "Listen and find the pictures.",
        //             "Find the picture. This is a book.(교사가 가리킨다.)",
        //             "Find the picture. This is a back pack.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '07', page: '18', action: '01', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '07', page: '18', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words this and a? Teaching Guide",
        //             "Can you find the words this and a? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Can you find the words this and a? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you find the words this and a?",
        //             "Circle and write the words. (this, a)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '09', page: '00', action: '09', scene: '01', sentences: [
        //             " Phonics Let's write big A and little a. Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '03', slide: '09', page: '00', action: '09', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Who Stole the Cookies from the Cookie Jar? Teaching Guide",
        //             "Song: Who Stole the Cookies from the Cookie Jar? 제목을 말한다.",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "Who, me? Yes, you! Not me! Then, who?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Catty* stole the cookies from the cookie jar.",
        //             "* Cricket / Robby / Geneus",
        //             "Who Stole the Cookies from the Cookie Jar? 영상을 본다.",
        //             "Let‘s sing together. (노래를 함께 불러본다.)",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '04', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '04', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '04', page: '08', action: '00', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Sharing is Caring Teaching Guide",
        //             "Sharing is Caring 제목을 말한다.",
        //             "Share with friends!",
        //             "Talk about the things you can and cannot share.",
        //             "Share / Can't share ( 함께 사용 할 수 있는 것 / 없는 것)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Circle things you can share.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '01', sentences: [
        //             "Shape Patterns Teaching Guide",
        //             "Shape Patterns 제목을 말한다.",
        //             "What comes next?",
        //             "circle / heart / triangle / square",
        //             "Shape Patterns 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Go back to your seat (들어가 앉으세요)",
        //             "Good job! 하이파이브!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '03', sentences: [
        //             "Complete the patterns.",
        //             "What comes next?",
        //             "Put the matching stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '06', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "Simon Says… Teaching Guide",
        //             "Simon Says… 제목과 부제목을 말한다.",
        //             "Let's play a game.",
        //             "Listen carefully and follow what the teacher said.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "Put the stickers on the circles.",
        //             "'사이몬 says' 라고 말이 들리면 움직이도록 해요.",
        //             "'사이몬 says' 라는 말이 안 들리면 움직이면 안돼요.",
        //             "Up / Down / Left / Right / say (Hi) / Hop",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "Listen carefully and move.",
        //             "Put the sticker on the circle when you are done.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '07', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Let's Make Bubbles Teaching Guide",
        //             "Let's Make Bubbles 제목을 말한다.",
        //             "Let's Make Bubbles 영상을 본다.",
        //             "Let's make bubbles with a spatula.",
        //             "Can you make bubbles with a spatula?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "Put the bubble stickers on the circles.",
        //             "Draw bubbles.",
        //             "What shape is the bubbles?",
        //         ]
        //     }, {
        //         level: '03', volume: '01', week: '04', slide: '08', page: '14', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Feelings Teaching Guide",
        //             "Let's sing the Feelings Song. Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry / surprised?",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's draw different feelings.",
        //             "각 기분의 표정을 그리는 것을 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다. (노래를 다 함께 한다)",
        //             "What's the weather like today? (창밖을 보면서, 지문을 읽는다)",
        //             "손을 눈썹 밑으로 가며(경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
        //             "Look out the window. 손가락은 창문을 가리키며, 행동과 동시에 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is windy 양손으로 바람이 부는 동작 표현), windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Circle the picture. ( 날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see? (무엇이 보이는지 이야기 나눈다.)",
        //             "아이) 소, 돼지, 닭 병아리, 소녀….라고 말한다.",
        //             "맞아요, 잘 했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "These are farm animals.",
        //             "This is a (chicken/chick/cow/girl/pig).",
        //             "Let's Look at the Cover 영상을 보며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "Do you see (an egg/a chicken/a chick/a girl)?",
        //             "Circle the picture.",
        //             "Yes, I see (an egg/a chicken/a chick/a girl).",
        //             "활동을 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Guess what she wants. Teaching Guide",
        //             "Guess what she wants. (옆에 그림을 보면서…. )",
        //             "There is a girl.",
        //             "She wants something.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Do you think she wants (milk / eggs / water / cookies)?",
        //             "She wants (eggs).",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken Teaching Guide",
        //             "Song: Chick Chick Chick Chick Chicken 제목을 말한다.",
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "egg, tea, three",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Write the word egg and put the matching stickers on the Song: Chick Chick Chick Chick Chicken 영상을 본	다.",
        //             "circles.",
        //             "Good job! Singing",
        //             "Egg (쓰기, 스티커, 활동 마무리.)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘ How do you feel today? ‘ Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "How do you feel today?",
        //             "How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry / surprised",
        //             "I am surprised (선생님도 놀랬어요.)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle the picture. (기분을 동그라미를 해볼까요?)",
        //             "Feelings 의 영상, 노래 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             " Goodbye~ 다음 시간에 만나요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Sound it Out Teaching Guide",
        //             "Sound it Out 제목을 말한다.",
        //             "Let's listen and say the sound.",
        //             "Me, tea, three.",
        //             "Can you say 'me,' 'tea,' and 'three'?",
        //             "Rhymes are the same sounds.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Let's play a game.",
        //             "Raise your hands when you hear the words 'me,' 'tea,' or 'three.'",
        //             "Circle the words me, tea, and three.",
        //             "Sound it Out 영상으로 상호작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "Can you make animal sounds?",
        //             "Say the sounds and put the stickers on the circles.",
        //             "chick, hen, rooster, cluck, cock-a-doodle-doo",
        //             "Excellent! / 스티커, 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 April를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '01', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수 에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken Teaching Guide",
        //             "Song: Chick Chick Chick Chick Chicken 제목을 말한다.",
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "egg, tea, three",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Short a 제목을 말한다.",
        //             "Let's sing: Short a. 영상을 본다.",
        //             "Short a 가 무슨 소리가 날까요? ('애' 소리가 난다.)",
        //             "Short a says / a /",
        //             "'a' pan , 'a' can , 'a' ham , 'a' hen 'a' jam",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Short a. 영상을 본다.",
        //             "Say the words (/c/-/a/-/n/can), pan, ham, jam.",
        //             "Circle all the words that have short a.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Short e Teaching Guide",
        //             "Short e 제목을 말한다.",
        //             "Let's sing: Short e. 영상을 본다.",
        //             "Short e 가 무슨 소리가 날까요? (‘에' 소리가 난다.)",
        //             "Short e says / e /",
        //             "'e' hen , 'e' ten , 'e' web , 'e' net",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Short e. 영상을 본다.",
        //             "Say the words",
        //             "Circle all the words that have short e.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //             "Let's say the word and find the first letter. ‘E e' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "Let's say the word and find the first letter. (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Go back to your seat (들어가 앉으세요)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letter to complete the word.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big C and little c. Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's write big C and little c.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Shapes Teaching Guide",
        //             "Shapes 제목을 말한다.",
        //             "These are Easter eggs.",
        //             "Circle, square, triangle, oval.",
        //             "What shape do you see?",
        //             "They are oval.",
        //             "Shapes 영상, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "Look at the pictures.",
        //             "Pizza, egg, window, book, Valentine's card, football.",
        //             "What is oval? Egg. Football.",
        //             "Circle things that are oval.",
        //             "Good job! ( 활동, 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken Teaching Guide",
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "egg, tea, three",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big D and little d. Teaching Guide",
        //             "Let's write big D and little d.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '04', page: '13', action: '02', scene: '01', sentences: [
        //             "What Time Is It? Teaching Guide",
        //             "What Time Is It? 제목을 말한다.",
        //             "Let's look at the clock.",
        //             "There is a little hand and a big hand.",
        //             "The little hand is pointing to 3.",
        //             "The big hand is pointing to 6.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '04', page: '13', action: '02', scene: '02', sentences: [
        //             "What time is it?",
        //             "It is 3:30 (three thirty.)",
        //             "What Time Is It? 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '04', page: '13', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '04', page: '13', action: '02', scene: '04', sentences: [
        //             "Write and say the time on the clock.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '04', page: '13', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '14', action: '01', scene: '01', sentences: [
        //             "Riddle: What Am I? Teaching Guide",
        //             "Riddle: What Am I? 제목을 말한다.",
        //             "What Am I? 영상을 본다.",
        //             "Let's find the answer.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '14', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '14', action: '01', scene: '03', sentences: [
        //             "Look at the picture",
        //             "I am oval. / I have a shell.",
        //             "I have an egg yolk and egg whites.",
        //             "What am I",
        //             "I am a(n) … Egg 쓰는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '15', action: '01', scene: '01', sentences: [
        //             "What can we get from the animals? Teaching Guide",
        //             "What can we get from the animals? 제목을 말한다.",
        //             "Put the matching stickers on the circles.",
        //             "We can get eggs. / We can get honey.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '15', action: '01', scene: '02', sentences: [
        //             "Draw the picture and write the word.",
        //             "We can get",
        //             "Good job! ( 스티커, 쓰는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '05', page: '15', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '06', page: '16', action: '01', scene: '01', sentences: [
        //             "Tangram Teaching Guide",
        //             "Tangram 제목과 부제목을 말한다.",
        //             "Tangram 영상을 본다.",
        //             "Let's create shapes.",
        //             "교구 준비 (탱 그램 카드를 준비한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '06', page: '16', action: '01', scene: '02', sentences: [
        //             "Can you make a cat?",
        //             "Let's make a cat",
        //             "cat with the 7 pieces of the tangram.",
        //             "Put the sticker on the circle 칭찬 스티커 붙이기",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '06', page: '16', action: '01', scene: '03', sentences: [
        //             "Let's create an animal.",
        //             "Let's make a triangle.",
        //             "Can you make a chicken?",
        //             "chicken with the 7 pieces of the tangram.",
        //             "Put the sticker on the circle 칭찬 스티커 붙이기",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '06', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '07', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Let's chant: The School. Teaching Guide",
        //             "The School. 을 말한다.",
        //             "This is a",
        //             "(teacher / crayon / table / book / backpack / classroom).",
        //             "Let's chant: The School. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Find the picture. This is a book.(교사가 가리킨다.)",
        //             "Find the picture. This is a back pack.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '07', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: the, jump Teaching Guide",
        //             "Sight Words: the, jump 을 말한다.",
        //             "Let's chant: The Animal Jumps. (영상을 본다)",
        //             "The (dog / rabbit / sheep / cat / horse) jumps.",
        //             "The cow jumps over the moon.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: The School. 영상을 본다.",
        //             "What do you see in the picture?",
        //             "The (dog / rabbit / sheep / cat / horse) jumps.",
        //             "The cow jumps over the moon.",
        //             "Put the matching stickers on the circles. 수업 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '09', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words the and jump? Teaching Guide",
        //             "Can you find the words the and jump? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Can you find the words the and jump? 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '09', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '09', page: '19', action: '01', scene: '03', sentences: [
        //             "Can you find the words the and jump?",
        //             "Circle and write the words. (the jump? )",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '09', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken Teaching Guide",
        //             "Song: Chick Chick Chick Chick Chicken 제목을 말한다.",
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "egg, tea, three",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Chick Chick Chick Chick Chicken 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Let's write big A and little a. Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Who Am I? Teaching Guide",
        //             "Who Am I? 제목을 말한다.",
        //             "Let's guess what animal it is.",
        //             "I am ….",
        //             "a rabbit / a dog / a cow / an elephant",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Find and circle the matching animal.",
        //             "I have 4 legs.",
        //             "I have two big ears.",
        //             "I have a trunk.",
        //             "I can walk.",
        //             "I am …. an elephant",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '07', page: '21', action: '01', scene: '01', sentences: [
        //             "‘The' and ‘Jump' Teaching Guide",
        //             "‘The' and ‘Jump' 제목을 말한다.",
        //             "There are sight words, ‘the' and ‘jump'",
        //             "‘The' and ‘Jump' 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '07', page: '21', action: '01', scene: '02', sentences: [
        //             "Follow the directions.",
        //             "Circle the word The.",
        //             "Box the word jump.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '07', page: '21', action: '01', scene: '03', sentences: [
        //             "The cow jumps over the moon.",
        //             "The sheep jumps over the fence.",
        //             "The dog jumps over the fence.",
        //             "The cat jumps over the fence.",
        //             "Good job! ( 동그라미 네모 활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '07', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: the, jump Teaching Guide",
        //             "Sight Words: the, jump 을 말한다.",
        //             "Let's chant: The Animal Jumps. (영상을 본다)",
        //             "The (dog / rabbit / sheep / cat / horse) jumps.",
        //             "The cow jumps over the moon.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: The School. 영상을 본다.",
        //             "What do you see in the picture?",
        //             "The (dog / rabbit / sheep / cat / horse) jumps.",
        //             "The cow jumps over the moon.",
        //             "Put the matching stickers on the circles. 수업 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move the Girl Teaching Guide",
        //             "Let's Move the Girl 제목을 말한다.",
        //             "The girl wants to have an Easter egg.",
        //             "Right / Left / Up / Down",
        //             "Let's Move the Girl 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '22', action: '01', scene: '03', sentences: [
        //             "Put the stickers on the circles.",
        //             "Move right.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '08', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '04', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Days of the Week Teaching Guide",
        //             "Let's sing the Days of the Week Song. 영상 노래, 율동",
        //             "Write the numbers.",
        //             "Draw a star on today's date.",
        //             "Sunday / Monday / Tuesday / Wednesday / Thursday / Friday / Saturday",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's draw different feelings.",
        //             "Let's sing the Days of the Week Song. ( 영상을 보며, 노래와 율동을 ..)",
        //             "Good job! / 카렌다의 숫자를 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상을 본다. (노래를 다 함께 한다)",
        //             "What's the weather like today? (창밖을 보면서, 지문을 읽는다)",
        //             "손을 눈썹 밑으로 가며(경례하듯이) (시선은 창밖을 본다. 행동과 동시에..)",
        //             "Look out the window. 손가락은 창문을 가리키며, 행동과 동시에 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny 머리위로 두 손을 마주잡으며, sunny를 말한다.",
        //             "It is cloudy 양손으로 구름모양으로 만들며, cloudy 말한다.",
        //             "It is rainy 양손으로 비가 내리듯 손가락을 움직이며, rainy를 말한다.",
        //             "It is windy 양손으로 바람이 부는 동작 표현), windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture. (날씨를 동그라미를 해볼까요?)",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see? (무엇이 보이는지 이야기 나눈다)",
        //             "아이) 빵, 계란, 버터…라고 말 할 것.",
        //             "맞아요, 잘했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "There are buns in the basket.",
        //             "There are eggs and pennies.",
        //             "Let's Look at the Cover 영상을 보며, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "Do you see (cookies / buns / pennies / eggs)?",
        //             "Circle the picture. (동그라미 치는 것을 확인한다)",
        //             "Yes. I see buns, pennies, and eggs.",
        //             " 활동을 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Look at the buns! Teaching Guide",
        //             "Look at the buns! (옆에 그림을 보면서…. )",
        //             "bun / cross",
        //             "The buns have crosses.",
        //             "Let's make buns. These are buns.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "The buns have crosses./ Crosses./ Crosses./ Crosses.",
        //             "Can you draw shapes on the buns?",
        //             "Decorate the buns with shapes.",
        //             "Excellent! (그리는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Hot Cross Buns Teaching Guide",
        //             "Song: Hot Cross Buns 제목을 말한다.",
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "bun, cross, hot, one, two, penny, son, daughter",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Write the word buns and put the matching stickers on the circles.",
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Good job! Singing",
        //             "buns (쓰는 것과 스티커로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Feelings Teaching Guide",
        //             "Let's sing the Feelings Song. Feelings 영상 , 노래, 율동",
        //             " How do you feel? (아이의 얼굴을 보면서….)",
        //             "happy / sad / angry / surprised",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "Circle the picture.",
        //             "Feelings 영상 , 노래, 율동",
        //             "각각 기분 표정 그리는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "My House Teaching Guide",
        //             "My House 제목을 말한다.",
        //             "Say the name of the rooms.",
        //             "Look at the picture. 	",
        //             "This is a house.",
        //             "There are many rooms.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "This is a bathroom. I see a bathtub and a sink.",
        //             "This is a bedroom. I see a bed and a wardrobe.",
        //             "This is a living room. I see a sofa and TV.",
        //             "This is a kitchen. I see a table and chairs.",
        //             "I see daddy in the kitchen.",
        //             "My House 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "Where is Daddy? Find and circle Daddy.",
        //             "Can you draw your room?",
        //             "Put the matching sticker on the circle.",
        //             "Is this a (bathroom / bedroom / living room / kitchen)?",
        //             "Excellent! (스티커 활동으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '09', page: '00', action: '00', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 May를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '09', page: '00', action: '00', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '09', page: '00', action: '00', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '01', slide: '09', page: '00', action: '00', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수 에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Hot Cross Buns Teaching Guide",
        //             "Song: Hot Cross Buns 제목을 말한다.",
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "bun, cross, hot, one, two, penny, son, daughter",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Short e Teaching Guide",
        //             "Short e 제목을 말한다.",
        //             "Let's sing: Short e. 영상을 본다.",
        //             "Short e 가 무슨 소리가 날까요? (‘에' 소리가 난다)",
        //             "Short e says / e /",
        //             "'e' hen , 'e' ten , 'e' web , 'e' net",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Short e. 영상을 본다.",
        //             "Say the words hen , ten , web , net",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Phonics Short i Teaching Guide",
        //             "Short i 제목을 말한다.",
        //             "Let's sing: Short i. 영상을 본다.",
        //             "Short i 가 무슨 소리가 날까요? (‘이' 소리가 난다)",
        //             "Short i says / i /",
        //             "' I ' bib /' I ' lip /' I ' pig /' I 'six",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Short i. 영상을 본다.",
        //             "Say the words bib / lip / pig / six",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Let's say the word and find the first letter. ‘I i' Teaching Guide",
        //             "Let's say the word and find the first letter. (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letter to complete the word.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big E and little e. Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's write big E and little e.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Family Members Teaching Guide",
        //             "Family Members 제목을 말한다.",
        //             "This is a family.",
        //             "This is a (mommy/daddy/sister/brother/baby).",
        //             "Point to the (mommy).",
        //             "Family Members 영상, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Look at the pictures.",
        //             "How many people are in this family?",
        //             "There are five people. 	",
        //             "Write the number in the box.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Hot Cross Buns Teaching Guide",
        //             "Song: Hot Cross Buns 제목을 말한다.",
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "bun, cross, hot, one, two, penny, son, daughter",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '02', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big F and little f. Teaching Guide",
        //             "Let's write big F and little f.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '04', page: '13', action: '02', scene: '01', sentences: [
        //             "Let's Buy Some Buns Teaching Guide",
        //             "Let's Buy Some Buns 제목을 말한다.",
        //             "Let's look at the clock.",
        //             "How much is one bun?",
        //             "It is one penny.",
        //             "Let's Buy Some Buns 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '04', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '04', page: '13', action: '02', scene: '03', sentences: [
        //             "How much are four buns?",
        //             "They are four pennies.",
        //             "Draw four pennies in the box.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '04', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '05', page: '16', action: '01', scene: '01', sentences: [
        //             "Make Five Game Teaching Guide",
        //             "Make Five Game 제목을 말한다.",
        //             "Make Five Game 영상을 본다.",
        //             "Let's play the Make Five Game.",
        //             "대 그룹 활동을 진행한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '05', page: '16', action: '01', scene: '02', sentences: [
        //             "Can you make a Five Game.",
        //             "Let's make a Five.",
        //             "When the music stops, get in a group of (five).",
        //             "2~3번 정도 게임을 진행하고 마무리",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '05', page: '17', action: '01', scene: '01', sentences: [
        //             "We need to make five things total. Teaching Guide",
        //             "We need to make five things total. 제목을 말한다.",
        //             "make five!",
        //             "Draw how many you need to add.",
        //             "make five things total 영상을 본다.",
        //             "Draw the picture and write the word.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '05', page: '17', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Sight Words: the, jump Teaching Guide",
        //             "Sight Words: the, jump 을 말한다.",
        //             "Let's chant: The Animal Jumps. (영상을 본다)",
        //             "The (dog / rabbit / sheep / cat / horse) jumps.",
        //             "The cow jumps over the moon.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: The Animal Jumps. 영상을 본다.",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: is, my Teaching Guide",
        //             "Sight Words: is, my 을 말한다.",
        //             "Let's chant: This Is My Family... (영상을 본다)",
        //             "This is my….",
        //             "(mommy / daddy / brother / sister/ grandma / grandpa).",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: This Is My Family... (영상을 본다)",
        //             "What do you see in the picture?",
        //             "This is my….",
        //             "(mommy / daddy / brother / sister/ grandma / grandpa).",
        //             "Put the matching stickers on the circles. 수업 마무리.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '08', page: '19', action: '02', scene: '01', sentences: [
        //             "Can you find the words is and my? Teaching Guide",
        //             " Can you find the words is and my? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant : This Is My Family. (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '08', page: '19', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '08', page: '19', action: '02', scene: '03', sentences: [
        //             "Can you find the words is and my?",
        //             "Circle and write the words. (is / my)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '08', page: '19', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 May를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다"
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다"
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '03', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수 에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Hot Cross Buns Teaching Guide",
        //             "Song: Hot Cross Buns 제목을 말한다.",
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "bun, cross, hot, one, two, penny, son, daughter",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Hot Cross Buns 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '04', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song. (반복)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '04', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '04', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //             "Sight Word: my Teaching Guide",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '01', sentences: [
        //             "Sight Word: my 제목을 말한다.",
        //             "This is my neighborhood.",
        //             "Sight Word: my 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '03', sentences: [
        //             "Have the children find the same words and color them in.",
        //             "Color the houses that has the word 'my.'",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '05', page: '21', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Robby Teaching Guide",
        //             "Let's Move Robby 제목을 말한다.",
        //             "Robby wants to buy some buns.",
        //             "Right / Left / Up / Down",
        //             "Let's Move the Girl 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here? (누가 나와서 해볼래요?)",
        //             "Raise your hands. (누가 손들어 볼까요?)",
        //             "민호, Come to the front. (민호가 해 볼까요?)",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat (들어가 앉으세요)",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "Have the children move Robby by the using coding cards.",
        //             "Have them find the way to solve the problem.",
        //             "Hint: Move left.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '06', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '07', page: '17', action: '01', scene: '01', sentences: [
        //             "Make Five Card Game Teaching Guide",
        //             "Make Five Card Game 제목을 말한다.",
        //             "Let's play the Make Five Card Game.",
        //             "Can you make five (5) of the same fruit?",
        //             "make five things total 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '07', page: '17', action: '01', scene: '02', sentences: [
        //             "The player to collect all the cards wins.",
        //             "Have the children discuss all the numbers and fruits",
        //             "even though they are not the correct answers.",
        //             "카드게임으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '07', page: '17', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Let's Blow Bubbles Teaching Guide",
        //             "Let's Blow Bubbles 제목을 말한다.",
        //             "Let's blow bubbles with forks.",
        //             "Draw bubbles.",
        //             "Let's Blow Bubbles 영상을 본다.",
        //             "forks, bubble soap, soapy water",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "Put the forks in the bubble soap.",
        //             "Blow bubbles.",
        //             "Look at the bubbles.",
        //             "Do you see soapy water on the forks?",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '03', sentences: [
        //             "Can you blow bubbles with them?",
        //             "ladle / forks / spatula / spoon / whisk",
        //             "Put the stickers in the correct box.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '08', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '09', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '09', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '03', week: '04', slide: '09', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Colors Teaching Guide",
        //             "Let's sing the Colors Song. 영상, 노래, 율동",
        //             "What colors do you like?",
        //             "Let's color.",
        //             "red / yellow / blue / green / orange",
        //             "Purple / pink / brown / black / white",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Colors Song. 영상, 노래, 율동",
        //             "I like….",
        //             "색 칠하는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래 율동",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see? (무엇이 보이는지 이야기 나눈다)",
        //             "아이) 성, 하트, 여왕…라고 말 할 것.",
        //             "맞아요, 잘 했어요.",
        //             "This is the Queen of Hearts.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '04', action: '02', scene: '02', sentences: [
        //             "I see hearts on her dress.",
        //             "This is a castle.",
        //             "I see hearts on the flags.",
        //             "This is a fountain.",
        //             "I see hearts on the fountain.",
        //             "She lives in a castle.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '04', action: '02', scene: '03', sentences: [
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //             "Do you see a (heart / chicken / castle / queen)?",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '04', action: '02', scene: '04', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '04', action: '02', scene: '05', sentences: [
        //             "What do you see in the picture?",
        //             "Circle the picture",
        //             "Yes. I see… heart / chicken / castle / queen",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '04', action: '02', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "Where does the queen live? Teaching Guide",
        //             "I live in a… (옆에 그림을 보면서…. )",
        //             "Library / castle / fire station / hospital)?",
        //             "Where does she live?",
        //             "She lives in a castle.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '05', action: '01', scene: '02', sentences: [
        //             "Draw a picture.",
        //             "She lives in a castle.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '07', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: The Queen of Hearts Teaching Guide",
        //             "Song: The Queen of Hearts 제목을 말한다.",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래",
        //             "hearts, king, queen, tarts",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Write the word Hearts and put the matching stickers on the circles.",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //             "Hearts 쓰는 것과 스티커 붙이는 것을 확인하며 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '08', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song.영상, 노래, 율동",
        //             "How do you feel today?",
        //             "Draw your feeling.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '08', page: '08', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy",
        //             "Feelings 영상, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '08', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Things to Do Teaching Guide",
        //             "Things to Do 제목을 말한다.",
        //             "Where does the knight go?",
        //             "Things to Do 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '03', sentences: [
        //             "Where does he (buy tarts)? At the (bakery).",
        //             "Put the matching stickers on the circles.",
        //             "Things to Do… buy tarts / bakery",
        //             "Things to Do… buy fruits / market",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "Things to Do… return books / library",
        //             "Things to Do… send mail / post office",
        //             "Things to Do… see the doctor / hospital",
        //             "Things to Do… see the police officer / police station",
        //             "Excellent! ( 스티커 활동으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '01', slide: '09', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Queen of Hearts Teaching Guide",
        //             "Song: The Queen of Hearts 제목을 말한다.",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "hearts, king, queen, tarts",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Short i Teaching Guide",
        //             "Short i 제목을 말한다.",
        //             "Let's sing: Short i. 영상을 본다.",
        //             "Short i 가 무슨 소리가 날까요? (‘이' 소리가 난다)",
        //             "Short i says / i /",
        //             "' I ' bib /' I ' lip /' I ' pig /' I 'six",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Short i. 영상을 본다.",
        //             "Say the words bib / lip / pig / six",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Short o Teaching Guide",
        //             "Short o 제목을 말한다.",
        //             "Let's sing: Short o. 영상을 본다.",
        //             "Short o 가 무슨 소리가 날까요? (‘오' 소리가 난다)",
        //             "Short o says / h / o / p /, hop",
        //             "' o ' hop /' o ' pot / ' o ' mop / ' o ' hot",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Short o 영상을 본다.",
        //             "Say the words hop /pot / mop / hot",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Good job! / 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Let's say the word and find the first letter. ‘O o' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "Let's say the word and find the first letter. (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letter to complete the word.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big E and little e. Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's write big G and little g.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Favorite Jobs Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's write big G and little g.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Favorite Jobs Teaching Guide",
        //             "Favorite Jobs 제목을 말한다.",
        //             "What do you want to be?",
        //             "Do you want to be a",
        //             "(queen/king/knight/soldier/police officer/doctor)?",
        //             "Point to the (queen).",
        //             "Favorite Jobs 영상, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "Come up and color the boxes.",
        //             " How many children want to be a (queen)?",
        //             "Let's count.",
        //             "There are (five) people.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Queen of Hearts Teaching Guide",
        //             "Song: The Queen of Hearts 제목을 말한다.",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "hearts, king, queen, tarts",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big H and little h. Teaching Guide",
        //             "Let's write big H and little h.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '04', page: '13', action: '02', scene: '01', sentences: [
        //             "Who Has More? Teaching Guide",
        //             "Who Has More? 제목을 말한다.",
        //             "Let's play a game.",
        //             "Who Has More? 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '04', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '04', page: '13', action: '02', scene: '03', sentences: [
        //             "Who has the highest number?",
        //             "Circle the winner.",
        //             "I have the highest number.",
        //             "Good job! 쓰는 것 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '04', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '05', page: '16', action: '01', scene: '01', sentences: [
        //             "What Is Wrong? Teaching Guide",
        //             "What Is Wrong? 제목을 말한다.",
        //             "Let's talk about each picture.",
        //             "What is wrong?",
        //             "boy, clock, flower, school bus, wrong,",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '05', page: '16', action: '01', scene: '02', sentences: [
        //             "What's wrong with the flower/clock/boy/bus?",
        //             "It's upside down!",
        //             "There are no hands.",
        //             "The boy is brushing his teeth with a fork.",
        //             "The car doesn't have wheels!",
        //             "What Is Wrong? 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '05', page: '16', action: '01', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '05', page: '16', action: '01', scene: '04', sentences: [
        //             "What is wrong in the pictures?",
        //             "Draw a heart around what's wrong.",
        //             "틀린 그림 부분을 찾는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '05', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics Sight Words: is, my Teaching Guide",
        //             "Sight Words: is, my 을 말한다.",
        //             "Let's chant: This Is My Family. (영상을 본다)",
        //             "This is my",
        //             "(mommy / daddy / brother / sister/ grandma / grandpa).",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: This Is My Family. (영상을 본다)",
        //             "This is my",
        //             "(mommy / daddy / brother / sister/ grandma / grandpa).",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '07', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: I, see Teaching Guide",
        //             "Sight Words: I, see 을 말한다.",
        //             "Let's chant: I See Fruits and Vegetables. (영상을 본다)",
        //             "I see a",
        //             "(tomato/strawberry/pineapple/carrot/pepper/watermelon).",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '07', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: I See Fruits and Vegetables. (영상을 본다)",
        //             "What do you see in the picture?",
        //             "I see a",
        //             "(tomato/strawberry/pineapple/carrot/pepper/watermelon).",
        //             "Put the matching stickers on the circles. 수업 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '07', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '08', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words I and see? Teaching Guide",
        //             "Can you find the words I and see? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: I See Fruits and Vegetables. (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '08', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '08', page: '19', action: '01', scene: '03', sentences: [
        //             "Can you find the words I and see?",
        //             "Circle and write the words. (I / see)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '08', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 June 을 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '03', slide: '09', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수 에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: The Queen of Hearts Teaching Guide",
        //             "Song: The Queen of Hearts 제목을 말한다.",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "hearts, king, queen, tarts",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '04', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '04', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '04', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "To the House Teaching Guide",
        //             "To the House 제목을 말한다.",
        //             "Can you help the boy find the way home? .",
        //             "To the House 영상을 본다.",
        //             "Help! / I'm lost.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Can you help the boy find the way home?",
        //             "Put the stickers on the circles.",
        //             "Start /Right /Left /Up / Down /Start /End",
        //             "Let's move.",
        //             "Use the coding cards to find the way to the house.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "How to Brush My Teeth Teaching Guide",
        //             "How to Brush My Teeth 제목을 말한다.",
        //             "How to Brush My Teeth",
        //             "Brush / gargle",
        //             "How to Brush My Teeth 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "How do you brush your teeth?",
        //             "Put the number stickers on the circles in order..",
        //             "Trace the words.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '07', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Mirror Puzzle Teaching Guide",
        //             "Mirror Puzzle 제목을 말한다.",
        //             "Place the mirror on the line.",
        //             "What is it?",
        //             "Can you guess what it is?",
        //             "Use the mirror.",
        //             "What do you see in the mirror?",
        //             "Do you see a heart?",
        //             "Do you see the word heart?",
        //         ]
        //     }, {
        //         level: '03', volume: '04', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "Mirror Puzzle 영상을 본다.",
        //             "Let's mirror.",
        //             "Draw each shape.",
        //             "Excellent! / 각 선을 그리는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "What Month Is It? Teaching Guide",
        //             "Let's sing the Month Song. 영상, 노래",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Month Song. ( 영상을 보며 노래, 율동)",
        //             "Special days. What Month Is It?",
        //             "January /February /March /April /May /June",
        //             "July / August / September / October /November / December",
        //             "It is … (쓰는 것으로 마무리)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             " Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see? (무엇이 보이는지 이야기 나눈다)",
        //             "아이) 팬케이크, 시럽, 버터, 포크, 빵 먹는 아이들…라고 말 할 것.",
        //             "맞아요, 잘했어요.",
        //             "There is a girl and a boy.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "They want to eat pancakes.",
        //             "There is syrup on the table.",
        //             "There is butter on the table.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //             "Do you see (pancakes / butter / syrup / sugar)?",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "They want pancakes.",
        //             "Do you see (pancakes / butter / syrup / sugar)?",
        //             "Circle the picture",
        //             "Yes. I see (pancakes / butter / syrup).",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '04', action: '02', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "What do you want to eat? Teaching Guide",
        //             "I went eat….. (옆에 그림을 보면서…. )",
        //             "Apple / hotdogs /chicken / kimbap",
        //             "You went...",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "Draw your favorite food.",
        //             "Excellent! ( 각 모양을 그리는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '06', page: '05', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Five Crispy Pancakes Teaching Guide",
        //             "Song: Five Crispy Pancakes 제목을 말한다.",
        //             "Song: Five Crispy Pancakes영상을 본다.",
        //             "Let's sing with motions. 동작, 노래",
        //             "five, / frying pan, / pancakes",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Write the word Five and put the matching stickers on the circles.",
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //             "Five 쓰는 것과 스티커 붙이는 것을 확인하며, 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Feelings Teaching Guide",
        //             "Feelings 의 영상을 본다.",
        //             "Let's sing the Feelings Song. 영상, 노래, 율동",
        //             "How do you feel?",
        //             "happy / sad / angry",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "How do you feel?",
        //             "Are you happy?",
        //             "I am happy",
        //             "Feelings 의 영상, 노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '07', page: '08', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Musical Hugs Teaching Guide",
        //             "Musical Hugs 제목을 말한다.",
        //             "Let's play Musical Hugs!",
        //             "Musical Hugs 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Let's play musical hugs.",
        //             "Sing a song and move around.",
        //             "When the song stops, listen to the number.",
        //             "Hug in groups of (two).",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '09', page: '09', action: '01', scene: '01', sentences: [
        //             "Daily Routine Colors Teaching Guide",
        //             "Let's sing the Colors Song. 영상 노래 율동",
        //             " What colors do you like?",
        //             "This is….",
        //             "blue / yellow / green / orange",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '09', page: '09', action: '01', scene: '02', sentences: [
        //             "Let's sing the Colors Song. ( 영상을 보며 노래, 율동)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '09', page: '09', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '10', page: '09', action: '02', scene: '01', sentences: [
        //             "Flip the Pancakes Teaching Guide",
        //             "Flip the Pancakes 제목을 말한다.",
        //             "Let's make pancakes.",
        //             "When do we flip the pancakes?",
        //             "Do you see the bubbles?",
        //             "It's time to flip the pancakes.",
        //             "Flip the Pancakes영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '10', page: '09', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '10', page: '09', action: '02', scene: '03', sentences: [
        //             "Let's make pancakes.",
        //             "Color the pancakes that has bubbles and count them.",
        //             "Write the number of pancakes you flipped.",
        //             "I flipped ( 5 ) pancakes.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '01', slide: '10', page: '09', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Five Crispy Pancakes Teaching Guide",
        //             "Song: Five Crispy Pancakes 제목을 말한다.",
        //             "Song: Five Crispy Pancakes영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "five, / frying pan, / pancakes",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Short o Teaching Guide",
        //             "Let's sing: Short o. 영상을 본다.",
        //             "Short o 제목을 말한다.",
        //             "Short o 가 무슨 소리가 날까요? (‘오' 소리가 난다)",
        //             "Short o says / h / o / p /, hop",
        //             "' o ' hop /' o ' pot / ' o ' mop / ' o ' hot",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Short o. 영상을 본다.",
        //             "Say the words hop / pot / mop / hot",
        //             "Good job! (활동을 보면서.. 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Phonics Short u Teaching Guide",
        //             "Short u 제목을 말한다.",
        //             "Let's sing: Short u. 영상을 본다.",
        //             "Short u 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
        //             "Short u says / b / u / s / bus",
        //             "' u 'bus / ' u 'cup / ' u ' sun / ' u 'nut",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Short u 영상을 본다.",
        //             "Say the words bus / cup / sun / nut.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Let's say the word and find the first letter. ‘U u' Teaching Guide",
        //             "활동을 해볼까요?",
        //             "Let's say the word and find the first letter. (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letter to complete the word.",
        //             "Good job! 활동마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write big I and little i . Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (영상과 함께 노래)",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's write big G and little g.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '01', sentences: [
        //             "Jay Wants Pancakes. Teaching Guide",
        //             "Jay Wants Pancakes. 제목을 말한다.",
        //             "Cross out 5 pancakes.",
        //             "How many pancakes are left?",
        //             "There are 8 pancakes on the table.",
        //             "I ate 5 pancakes.	",
        //             "Three pancakes are left.",
        //             "Favorite Jobs 영상, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your sea",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '03', sentences: [
        //             "Come up and color the boxes. 	",
        //             "How many pancakes are left?",
        //             "Put the matching sticker on the circle",
        //             "I ate 5 pancakes.",
        //             "Three pancakes are left.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '09', page: '12', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '10', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar? 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 July를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '10', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "아이들이 잘 모르면 선생님이 혼자 진행한다. (아는 아이가 있어도…)",
        //             "( 차츰 2학기 즈음에 요일을 물어볼 수 있다.)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '10', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '02', slide: '10', page: '00', action: '07', scene: '04', sentences: [
        //             "Calendar 활동의 목적: Daily Routine으로 활용",
        //             "날, 수 에 대한 개념을 알 수 있다.",
        //             "월, 주간, 날짜, 기분, 날씨, 인사",
        //             "각 부분을 루틴 활동으로 활용 (영어 활용 효과가 크다)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Five Crispy Pancakes Teaching Guide",
        //             "Song: Five Crispy Pancakes 제목을 말한다.",
        //             "Song: Five Crispy Pancakes영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "five, / frying pan, / pancakes",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '03', sentences: [
        //             "Phonics Let's write big H and little h.",
        //             "Let's write big J and little j.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '04', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Be Fair and Share Teaching Guide",
        //             "Be Fair and Share 제목을 말한다.",
        //             "How can we share it?",
        //             "Be Fair and Share 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "How can we share it?",
        //             "Draw a line to share.",
        //             "Trace the word.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '06', page: '16', action: '01', scene: '01', sentences: [
        //             "Dots and Boxes Teaching Guide",
        //             "Dots and Boxes 제목을 말한다.",
        //             "Let's make triangles.",
        //             "Can you draw a line from dot to dot?",
        //             "connect, dots, draw, line, triangle",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '06', page: '16', action: '01', scene: '02', sentences: [
        //             "Do rock, scissors, paper!",
        //             "Take turns connecting dots.",
        //             "When you made a triangle, write your initial.",
        //             "Count how many triangles you made.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '06', page: '16', action: '01', scene: '03', sentences: [
        //             "Dots and Boxes 영상을 본다.",
        //             "영상 진행을 4명 정도 한다.",
        //             "Let's play Dots and Boxes.",
        //             "Count how many triangles you made.",
        //             "Excellent! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '06', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '07', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics I See Fruits and Vegetables. Teaching Guide",
        //             "I See Fruits and Vegetables. 을 말한다.",
        //             "Let's chant: I See Fruits and Vegetables. (영상을 본다)",
        //             "I see a …",
        //             "(tomato / strawberry / pineapple / carrot / pepper / watermelon).",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: I See Fruits and Vegetables. (영상을 본다)",
        //             "I see a …",
        //             "(tomato / strawberry / pineapple / carrot /pepper / watermelon).",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '07', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: play, with Teaching Guide",
        //             "Sight Words: play, with 을 말한다.",
        //             "Let's chant: Let's Play! (영상을 본다)",
        //             "Play with a …",
        //             "(baseball / basketball / soccer ball / golf ball / beach ball / tennis ball)>.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: Let's Play (영상을 본다)",
        //             "What do you see in the picture?",
        //             "Play with a …",
        //             "(baseball / basketball / soccer ball / golf ball / beach ball / tennis ball).",
        //             "Circle the words. Who wants to try? 수업 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '09', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words play and with? Teaching Guide",
        //             "Can you find the words play and with? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: Let's Play! (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '09', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '09', page: '19', action: '01', scene: '03', sentences: [
        //             "Can you find the words play and with?",
        //             "Circle and write the words. (play / with)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '03', slide: '09', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Five Crispy Pancakes Teaching Guide",
        //             "Song: Five Crispy Pancakes 제목을 말한다.",
        //             "Song: Five Crispy Pancakes영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "five, / frying pan, / pancakes",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: The Queen of Hearts 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '04', page: '00', action: '00', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '04', page: '00', action: '00', scene: '02', sentences: [
        //             "Let's sing the ABC song.",
        //             "Do you know the ABC song?",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '04', page: '00', action: '00', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '05', page: '14', action: '01', scene: '01', sentences: [
        //             "Plants Teaching Guide",
        //             "Plants 제목을 말한다.",
        //             "Let's grow plants.",
        //             "These are plants.",
        //             "What do plants need to grow?",
        //             "Plants need the sun.",
        //             "Plants need water.",
        //             "Plants need dirt.",
        //             "Put the matching stickers on the circles.",
        //             "Plants 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '05', page: '14', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '05', page: '14', action: '01', scene: '03', sentences: [
        //             "Let's name the parts of the plant.",
        //             "Put the matching stickers on the circles.",
        //             "leaf / roots / stem",
        //             "Trace the word stem.",
        //             "글 쓰는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '05', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move the Boy Teaching Guide",
        //             "Let's Move the Boy 제목을 말한다.",
        //             "The boy wants some pancakes.",
        //             "Start / End / Right / Left / Up / Down",
        //             "Let's Move the Boy 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "The boy wants some pancakes.",
        //             "Put the stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '05', week: '04', slide: '06', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Let's Count Teaching Guide",
        //             "Let's sing the Numbers Song.영상 노래",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Numbers Song. 영상, 노래",
        //             "Special days.",
        //             " How many people do you see?",
        //             "Write the numbers.",
        //             "숫자 쓰는 것으로 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "Where are they from? (어느 나라인지 말한다)",
        //             "아이) 한국, 중국, 미국, 독일 이라고 말한다..",
        //             "맞아요, 잘 했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '04', action: '02', scene: '02', sentences: [
        //             "There are many children from all over the world.",
        //             "Where is (he/she) from?",
        //             "(He/She) is from (Korea).",
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '04', action: '02', scene: '04', sentences: [
        //             "There are many children from all over the world.",
        //             "Where are they from?",
        //             "America / China / Germany / Korea",
        //             "Find the pictures.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "Where are you from? Teaching Guide",
        //             "I'm from ... (옆에 그림을 보면서…. )",
        //             "Where are you from?",
        //             "I'm from ... (KOREA)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "Color the flag and write the word.",
        //             "Excellent! ( 각 모양을 그리는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '05', action: '01', scene: '01', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Autumn Festival Teaching Guide",
        //             "Song: Autumn Festival 제목을 말한다.",
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Chu seok, song pyeon, Moon Festival, moon cakes, Oktoberfest,",
        //             "sausage, Thanksgiving, turkey",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Good job! Singing",
        //             "스티커 붙이는 것으로 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '08', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine One Little Finger. Teaching Guide",
        //             "One Little Finger. 의 영상을 본다.",
        //             "Let's sing: One Little Finger. 영상 , 노래 , 율동",
        //             "주의집중 시킬 때 반복 노래한다.",
        //             "Finger, celling, floor, head",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '08', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Hello, Friends! Teaching Guide",
        //             "Hello, Friends! 제목을 말한다.",
        //             "Say hello! 	",
        //             "Hello, Friends! 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '09', page: '08', action: '02', scene: '03', sentences: [
        //             "Hello, Friends!",
        //             "Put the matching stickers on the circles.",
        //             "I'm from Korea. 안녕!",
        //             "I'm from America. Hello!",
        //             "I'm from Germany. GUTEN TAG!",
        //             "I'm from China. 니 하오!",
        //             "Excellent! (쓰는 것으로 활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Autumn Festival Teaching Guide",
        //             "Song: Autumn Festival 제목을 말한다.",
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Chu seok, song pyeon, Moon Festival, moon cakes, Oktoberfest,",
        //             "sausage, Thanksgiving, turkey",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Short u Teaching Guide",
        //             "Short u 제목을 말한다.",
        //             "Let's sing: Short u 영상을 본다.",
        //             "Short u 가 무슨 소리가 날까요? (‘어' 소리가 난다)",
        //             "Short u says / b / u / s / bus",
        //             "' u ' bus / ' u ' cup / ' u ' sun / ' u 'nut",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Short u 영상을 본다.",
        //             "Say the words bus / cup / sun / nut.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Phonics Long a Teaching Guide",
        //             "Long a 제목을 말한다.",
        //             "Let's sing: Long a 영상을 본다.",
        //             "Long a 가 무슨 소리가 날까요? (‘애' 소리가 난다)",
        //             "Long a says a. Say the words:",
        //             "(-/ane/ cane), (-/ape/ cape), (-/ate/ mate).",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Long a 영상을 본다.",
        //             "Say the words cane / cape / mate",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '05', page: '10', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "What letters are missing? Teaching Guide",
        //             "활동을 해볼까요?",
        //             "What letters are missing? 영상으로 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letters and say the words.",
        //             "cane, cape, mate",
        //             "글자 쓰는 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Let's Guess and Count Teaching Guide",
        //             "Let's Guess and Count 제목을 말한다.",
        //             "How many do you see?",
        //             "There are (pears/chestnuts/peanuts/apples).",
        //             "How many (pears) are there?",
        //             "Guess the number. / Count the number.",
        //             "Let's Guess and Count 영상 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many do you see?",
        //             "Count and write the numbers.",
        //             "Now let's count.",
        //             "There are (eight) (pears).",
        //             "Good job! ( 활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Autumn Festival Teaching Guide",
        //             "Song: Autumn Festival 제목을 말한다.",
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Chu seok, song pyeon, Moon Festival, moon cakes, Oktoberfest,",
        //             "sausage, Thanksgiving, turkey",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write K k, L l, M m. Teaching Guide",
        //             "Let's write K k, L l, M m.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Dragon Pattern Teaching Guide",
        //             "Dragon Pattern 제목을 말한다.",
        //             "Let's find the pattern.",
        //             "Green, pink, blue, red, green, pink, blue, red.",
        //             "Dragon Pattern 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Make your own pattern. (교사가 예를 들어준다)",
        //             "Yellow, green, blue, yellow, green, blue.",
        //             "I can make my own pattern.",
        //             "Make your own pattern.",
        //             "Good job! (그리는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '06', page: '16', action: '02', scene: '01', sentences: [
        //             "Drawing Game Teaching Guide",
        //             "Drawing Game 제목을 말한다.",
        //             "Let's play a game.",
        //             "Listen carefully.",
        //             "circle, draw, heart, in, on, square, triangle, under",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '06', page: '16', action: '02', scene: '02', sentences: [
        //             "Draw a (circle)in the middle.",
        //             "Draw a (triangle) on the (circle)",
        //             "Draw a (square) under the (circle)",
        //             "Draw a (heart)in the (square).",
        //             "What is it?",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '06', page: '16', action: '02', scene: '03', sentences: [
        //             "영상 진행을 4명 정도 한다.",
        //             "Drawing Game 영상을 본다.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '06', page: '16', action: '02', scene: '04', sentences: [
        //             "Let's listen and draw.",
        //             "What did you draw?",
        //             "사랑의 집을 그리는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '06', page: '16', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '07', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics play, with Teaching Guide",
        //             "Sight Words: play, with 을 말한다.",
        //             "Let's chant: Let's Play! (영상을 본다)",
        //             "Play with a …",
        //             "(baseball / basketball / soccer ball / golf ball / beach ball / tennis ball).",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: Let's Play (영상을 본다)",
        //             "What do you see in the picture?",
        //             "Play with a …",
        //             "(baseball / basketball / soccer ball / golf ball / beach ball / tennis ball).",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '07', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: come, to Teaching Guide",
        //             "Sight Words: come, to 을 말한다.",
        //             "Let's chant: Celebration. (영상을 본다)",
        //             "Come to (the)…",
        //             "(party / wedding / parade / festival / Oktoberfest / grandma's house).",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: Let's Play (영상을 본다)",
        //             "What do you see in the picture?",
        //             "Come to (the)…",
        //             "(party / wedding / parade / festival / Oktoberfest / grandma's house).",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '09', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words come and to? Teaching Guide",
        //             "Can you find the words come and to? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: Celebration. (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '09', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '09', page: '19', action: '01', scene: '03', sentences: [
        //             "Can you find the words come and to?",
        //             "Circle and write the words. (come / to)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '03', slide: '09', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Autumn Festival Teaching Guide",
        //             "Song: Autumn Festival 제목을 말한다.",
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Chu seok, song pyeon, Moon Festival, moon cakes, Oktoberfest,",
        //             "sausage, Thanksgiving, turkey",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Autumn Festival 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (2번 반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "Look! Thanksgiving parade!",
        //             "They celebrate together!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "I see a parade.",
        //             "I see people. / I see Children",
        //             "I see balloons. / I see foods.",
        //             "ice cream / milk / yogurt / cheese",
        //             "lollipop /bird / butterfly / Hello!",
        //             "Find the hidden things.",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "Some things are hidden.",
        //             "These are the hidden things.",
        //             "ice cream / milk / yogurt / cheese",
        //             "lollipop /bird / butterfly / Hello!",
        //             "Find the hidden things.",
        //             "찾는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '05', page: '20', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Make a Flag Teaching Guide",
        //             "Make a Flag 제목을 말한다.",
        //             "Solve the puzzle to make a flag.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "활동을 해볼까요?",
        //             "Circle the pieces that belong to the flag.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '07', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 September 를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '07', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '07', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Guess What Is Inside Teaching Guide",
        //             "Guess What Is Inside 제목을 말한다.",
        //             "How do you know what is inside?",
        //             "Let's touch and feel song pyeon.",
        //             "How to Make song pyeon. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '08', page: '14', action: '01', scene: '03', sentences: [
        //             "What is inside?",
        //             "Can you guess what is inside?",
        //             "(Look at/touch/push/poke/cut) it.",
        //             "Let's cut the song pyeon.",
        //             "Let's look inside.",
        //             "Do you like them?",
        //             "Draw your favorite song pyeon.",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '04', slide: '08', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Shapes Teaching Guide",
        //             "Let's sing the Shapes Song. 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Shapes Song. 영상 노래율동",
        //             "Circle all the shapes of the spaceship.",
        //             "circle / triangle /square / heart",
        //             "star / oval / pentagon / hexagon",
        //             "숫자 쓰는 것으로 마무리한다.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래 율동",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is windy. windy 를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's look at the picture. Teaching Guide",
        //             "Let's look at the picture. 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see? (무엇이 보이는지 말한다)",
        //             "아이) 로켓, 나르는 사람, 달, 우주선…이라고 말 할 것.",
        //             "맞아요, 잘 했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '02', sentences: [
        //             "Aiken Drum lives in the moon.",
        //             "Do you see (a spaceship/the moon/the earth/a rocket)?",
        //             "Yes, I see (a spaceship/the moon/the earth/a rocket).",
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '04', sentences: [
        //             "Aiken Drum lives in the moon.",
        //             "Find the pictures",
        //             "spaceship / moon / earth / rocket",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '07', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '08', page: '05', action: '01', scene: '01', sentences: [
        //             "Spaceships! Teaching Guide",
        //             "What shape is your spaceship?",
        //             "Draw your own spaceship!",
        //             "Excellent! ( 각 모양을 그리는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '08', page: '05', action: '01', scene: '01', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Aiken Drum Teaching Guide",
        //             "Song: Aiken Drum 제목을 말한다.",
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Moon / ladle / cream cheese / brown bread / waffles / roast beef / lettuce.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Write the word moon and put the matching stickers on the circles.",
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //             "스티커 붙이는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '08', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine What color is this? Teaching Guide",
        //             "Colors Song의 영상을 본다.",
        //             "Let's sing: Colors Song 영상 노래 율동",
        //             "Circle the purple shape.",
        //             "Blue / purple / green / orange / red",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '08', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '01', sentences: [
        //             "Moon Cycle Teaching Guide",
        //             "Moon Cycle 제목을 말한다.",
        //             "Let's say the names",
        //             "The moon moves around the sun.",
        //             "The (half-moon) changes into a (full moon).",
        //             "Hello, Friends! 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '03', sentences: [
        //             "The moon moves around the earth.",
        //             "The (full moon) changes to the (half-moon).",
        //             "Let's find the moon.",
        //             "Point to the half moon.",
        //             "new moon / half-moon / full moon",
        //             "Put the matching stickers on the circles.",
        //             "Excellent! (쓰는 것 활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '09', page: '08', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '10', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 September를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '10', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '01', slide: '10', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Aiken Drum Teaching Guide",
        //             "Song: Aiken Drum 제목을 말한다.",
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Let's sing together. 동작, 노래",
        //             "Moon / ladle / cream cheese / brown bread / waffles / roast beef / lettuce.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Long a Teaching Guide",
        //             "Long a 제목을 말한다.",
        //             "Let's sing: Long a 영상을 본다.",
        //             "Long a 가 무슨 소리가 날까요? (‘애' 소리가 난다)",
        //             "Long a says a. Say the words:",
        //             "(-/ane/, cane), (-/ape/, cape), (-/ate/, mate).",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Long a 영상을 본다.",
        //             "Say the words cane / cape / mate",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Long i Teaching Guide",
        //             "Long i 제목을 말한다.",
        //             "Let's sing: Long i 영상을 본다.",
        //             "Long i 가 무슨 소리가 날까요? (‘아' 소리가 난다.)",
        //             "Long i says . Say the words:",
        //             "(-/ine/, fine), (-/ite/, kite), (-/ine/- pine).",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Let's sing: Long i 영상을 본다.",
        //             "Put the matching stickers on the circles.",
        //             "Say the words fine / kite / pine",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "What letters are missing? Teaching Guide",
        //             "활동을 해볼까요?",
        //             "What letters are missing? (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letters and say the words",
        //             "fine / kite / pine",
        //             "쓰는 활동을 보면서 마무리",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Let's Count Teaching Guide",
        //             "Let's sing the Numbers Song. 영상 노래 율동",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Numbers Song. ( 영상을 보며, 노래와 율동 )",
        //             "One, Two, tree, Four, Five, Six , seven , Eight, Nine, Ten",
        //             "Eleven, Twelve, thirteen, Fourteen, Fifteen, Sixteen, Seventeen,",
        //             "Eighteen, Nineteen, and Twenty",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '06', week: '01', slide: '07', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "More or Less Teaching Guide",
        //             "More or Less 제목을 말한다.",
        //             "How many do you see?",
        //             "More or Less 영상, 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many do you see?",
        //             "Which one has (more/less) than three wheels?",
        //             "The (bus/train/car/truck) has more than 3 wheels.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "The (bicycle/boat/rocket/spaceship/airplane) has less than 3 wheels.",
        //             "Count the number.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '08', page: '12', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song. (시간이 되면, 반복하는 노래)",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '02', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Aiken Drum Teaching Guide",
        //             "Song: Aiken Drum 제목을 말한다.",
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Moon / ladle / cream cheese / brown bread / waffles / roast beef / lettuce.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '09', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write N n O o P p. Teaching Guide",
        //             "Let's write N n O o P p.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '04', page: '13', action: '02', scene: '01', sentences: [
        //             "Biggest and Smallest Teaching Guide",
        //             "Biggest and Smallest 제목을 말한다.",
        //             "Find the biggest and smallest thing.",
        //             "biggest, hot air balloon, rocket, smallest, spaceship",
        //             "Biggest and Smallest 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '04', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '04', page: '13', action: '02', scene: '03', sentences: [
        //             "Look at the spaceships.",
        //             "The biggest one!",
        //             "The smallest one!",
        //             "Circle the pictures.",
        //             "Good job! (그리는 것, 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '04', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '05', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics: come, to Teaching Guide",
        //             "Sight Words: come, to 을 말한다.",
        //             "Let's chant: Celebration. (영상을 본다)",
        //             "Come to (the)…",
        //             "(party / wedding / parade / festival / Oktoberfest / grandma's house).",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '05', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: Let's Play (영상을 본다)",
        //             "What do you see in the picture?",
        //             "Come to (the)…",
        //             "(party / wedding / parade / festival / Oktoberfest / grandma's house).",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '05', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '06', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: look, at Teaching Guide",
        //             "Sight Words: look, at을 말한다.",
        //             "Let's chant: Transportation. (영상을 본다)",
        //             "Look at the...",
        //             "(car / truck / boat / train / airplane / bicycle).",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '06', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: Transportation. (영상을 본다)",
        //             "What do you see in the picture?",
        //             "Look at the...",
        //             "(car / truck / boat / train / airplane / bicycle).",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '06', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '07', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words look and at? Teaching Guide",
        //             "Can you find the words look and at? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: Transportation. (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '07', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '07', page: '19', action: '01', scene: '03', sentences: [
        //             "Can you find the words look and at?",
        //             "Circle and write the words. ( look / at)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '07', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Tangram Teaching Guide",
        //             "Tangram 제목을 말한다.",
        //             "Tangram 영상을 본다.",
        //             "Let's create a transportation.",
        //             "탱 그램 카드를 준비한다",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "boat, make, rocket, tangram, piece",
        //             "Can you make a boat?",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Let's finish creating the transportation.",
        //             "Can you make a rocket?",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '03', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Aiken Drum Teaching Guide",
        //             "Song: Aiken Drum 제목을 말한다.",
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "Moon / ladle / cream cheese / brown bread / waffles / roast beef / lettuce.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. 영상 노래",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "Look! It's the Transportation Museum!",
        //             "There are people looking around the Transportation Museum.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Look at the hot air balloon. / Look at the ship.",
        //             "Look at the hot air balloon. / Look at the car.",
        //             "Look at the truck. / Look at the spaceship.",
        //             "Apple,/ grapes,/ orange,/ pineapple,/ strawberry,/ watermelon,/",
        //             " airplane/, car,/ rocket,/ Aiken Drum",
        //             "Find the hidden things.",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "Some things are hidden.",
        //             "These are the hidden things.",
        //             "Apple,/ grapes,/ orange,/ pineapple,/ strawberry,/ watermelon,/",
        //             "airplane/, car,/ rocket,/ Aiken Drum",
        //             "Find the hidden things. ( 찾는 것 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '05', page: '20', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Aiken Drum 제목을 말한다.",
        //             "Aiken Drum wants to go to the moon.",
        //             "Let's Move Aiken Drum 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "Move the Aiken Drum by using coding cards.",
        //             "Start / Right /Left /Up /Down /End",
        //             "Put the stickers on the circles.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '06', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '07', page: '14', action: '01', scene: '01', sentences: [
        //             "Fast or Slow Teaching Guide",
        //             "Fast or Slow제목을 말한다.",
        //             "Let the car go!",
        //             "Is the car fast or slow?",
        //             "What happened?",
        //             "How can you make the car go fast or slow?",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '07', page: '14', action: '01', scene: '02', sentences: [
        //             "Who wants to come up and make the ramp?",
        //             "Draw what happened to the cars.",
        //             "It is high.(fast), It is low. (slow)",
        //             "That's a good answer.",
        //         ]
        //     }, {
        //         level: '03', volume: '07', week: '04', slide: '07', page: '14', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Stretch Your Body Teaching Guide",
        //             "Let's sing the Stretching Song. 영상, 율동, 노래",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Stretching Song. ( 영상을 보며, 노래와 율동)",
        //             " Put the stickers on the motions that you liked.",
        //             "Up / Down / Left / Right / Round",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is windy. windy를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is snowy. snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '04', action: '01', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see? (무엇이 보이는지 말한다)",
        //             "아이) 여자아이, 풀, 땅콩….라고 말 할 것.",
        //             "맞아요, 잘 했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '04', action: '01', scene: '02', sentences: [
        //             "This is a farm. There are peanuts in the ground.",
        //             "This is a girl. She has something.",
        //             "Does she have a (seed / worm / bean / peanut)?",
        //             "Yes. She has a peanut.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '04', action: '01', scene: '03', sentences: [
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //             "Who wants to try?",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '04', action: '01', scene: '04', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '04', action: '01', scene: '05', sentences: [
        //             "There are peanuts in the ground.",
        //             "Find the pictures",
        //             "Seed / worm / bean / peanut",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '04', action: '01', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "Crack it open! Teaching Guide",
        //             "What do you want to use?",
        //             "Do you want to use (a hammer/your hand/a spoon/a fork)?",
        //             "Yes. I want to use (a hammer/my hand/a spoon/a fork).",
        //             "Draw the tool and write the word.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: Found a Peanut Teaching Guide",
        //             "Song: Found a Peanut 제목을 말한다.",
        //             "Song: Found a Peanut 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래",
        //             "called, cracked, doctor, found, shot, stomachache",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing (스티커로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine What color is this? Teaching Guide",
        //             "Colors Song의 영상을 본다.",
        //             "Let's sing: Colors Song / (노래와 율동)",
        //             "Circle the purple shape.",
        //             "red / Blue / Yellow /purple / green / orange",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Let's Pretend Teaching Guide",
        //             "What do you do when you are sick?",
        //             "Let's Pretend 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "What do you do when you are sick?",
        //             "Let's pretend to be a (mommy/daddy/girl/doctor).",
        //             "Do the motions.",
        //             "What's wrong?",
        //             "I have a stomachache.",
        //             "Call the doctor.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "See a doctor.",
        //             "Get a shot.",
        //             "Take a rest.",
        //             "Put the matching stickers on the circles.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '01', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Found a Peanut Teaching Guide",
        //             "Song: Found a Peanut 제목을 말한다.",
        //             "Song: Found a Peanut 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래",
        //             "called, cracked, doctor, found, shot, stomachache",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Long i Teaching Guide",
        //             "Long i 제목을 말한다.",
        //             "Let's sing: Long i 영상을 본다.",
        //             "Long i 가 무슨 소리가 날까요? (‘아' 소리가 난다)",
        //             "Long i says . Say the words:",
        //             "(-/ine/, fine), (-/ite/, kite), (-/ine/, pine).",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Long i 영상을 본다.",
        //             "Say the words kite/ pin / pine / fin / fine /",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Long o Teaching Guide",
        //             "Long o 제목을 말한다.",
        //             "Let's sing: Long o 영상을 본다.",
        //             "Long o 가 무슨 소리가 날까요? (‘오' 소리가 난다)",
        //             "Long o says . Say the words:",
        //             "(-/ope/, hope), robe, mope.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Let's sing: Long o 영상을 본다.",
        //             "Say the words hope / robe / mope.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "What letters are missing? Teaching Guide",
        //             "활동을 해볼까요?",
        //             "What letters are missing? (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letters and say the words.",
        //             "hope / robe / mope.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '07', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 November를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '07', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '07', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '08', page: '12', action: '01', scene: '01', sentences: [
        //             "Stretch Your Body Teaching Guide",
        //             "Let's sing the Stretching Song. 의 영상을 본다.",
        //             "영상에 맞추어 노래와 율동을 같이 한다.",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '08', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's sing the Stretching Song. 영상 노래 율동",
        //             "Up / Down / Left / Right / Round",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '08', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '09', page: '12', action: '02', scene: '01', sentences: [
        //             "Measuring Teaching Guide",
        //             "Measuring 제목을 말한다.",
        //             "Let's measure our hands and feet.",
        //             "Write the numbers in the boxes.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '09', page: '12', action: '02', scene: '02', sentences: [
        //             "Cut out the ruler and measure your (hand/foot).",
        //             "Let's measure your foot.",
        //             "How long is your (hand/foot)?",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '09', page: '12', action: '02', scene: '03', sentences: [
        //             "What do you use when you measure your hands and feet?",
        //             "We use a ruler.",
        //             "It is fifteen centimeters.",
        //             "Write the number in the box.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '02', slide: '09', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Found a Peanut Teaching Guide",
        //             "Song: Found a Peanut 제목을 말한다.",
        //             "Song: Found a Peanut 영상을 본다.",
        //             "Let's sing with motions. 동작, 노래와 함께 불러본다.",
        //             "called, cracked, doctor, found, shot, stomachache",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write Q q R r S s Teaching Guide",
        //             "Let's write Q q R r S s",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Same or Different Teaching Guide",
        //             "Same or Different 제목을 말한다.",
        //             "What is different?",
        //             "Clock/ measuring/ cup/ picture/ spoon/ squirrel/ teapot/ truck",
        //             "Same or Different 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "What is (the same/different)?",
        //             "Find the (same/different) things in the picture.",
        //             "The (clock/measuring cup/picture/spoon/squirrel/teapot/truck) is different.",
        //             "Circle the seven different things.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '06', page: '14', action: '01', scene: '01', sentences: [
        //             "Using Electricity 제목을 말한다.",
        //             "What uses electricity?",
        //             "These are tools.",
        //             "Some tools need electricity.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '06', page: '14', action: '01', scene: '02', sentences: [
        //             "What uses electricity?",
        //             " The (blender/camera/hair dryer/mobile phone/oven)…",
        //             "Using Electricity.",
        //             "Using Electricity 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '06', page: '14', action: '01', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '06', page: '14', action: '01', scene: '04', sentences: [
        //             "What uses electricity?",
        //             "Circle the answers.",
        //             "The (blender/camera/hair dryer/mobile phone/oven)…",
        //             "uses electricity.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '06', page: '14', action: '01', scene: '05', sentences: [
        //             "Some tools and machines need electricity power.",
        //             "Put the cord stickers next to the things …",
        //             "that need electricity.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '06', page: '14', action: '01', scene: '06', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '07', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics: look, at Teaching Guide",
        //             "Sight Words: look, at 을 말한다.",
        //             "Let's chant: Transportation. (영상을 본다)",
        //             "Look at the...",
        //             "(car / truck / boat / train / bicycle).",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: Transportation. (영상을 본다)",
        //             "What do you see in the picture?",
        //             "Look at the...",
        //             "(car / truck / boat / train / airplane / bicycle).",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '07', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: one, red, blue, yellow Teaching Guide",
        //             "Sight Words: one, red, blue, yellow 을 말한다.",
        //             "Let's chant: Tools and Machines. (영상을 본다)",
        //             "One ( red / blue / yellow)….",
        //             "(tablespoon / blender / spatula / frying pan / oven / ladle).",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: Tools and Machines. (영상을 본다)",
        //             "What can you find in the kitchen?",
        //             "One ( red / blue / yellow)….",
        //             "(tablespoon / blender / spatula / frying pan / oven / ladle).",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '09', page: '19', action: '02', scene: '01', sentences: [
        //             "Can you find the words one, red, blue, and yellow? Teaching Guide",
        //             "Can you find the words one, red, blue, and yellow? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: Tools and Machines. (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '09', page: '19', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '09', page: '19', action: '02', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you find the words one, red, blue, and yellow?",
        //             "Circle and write the words. (one, red, blue, yellow)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '03', slide: '09', page: '19', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: Found a Peanut Teaching Guide",
        //             "Song: Found a Peanut 제목을 말한다.",
        //             "Song: Found a Peanut 영상을 본다.",
        //             "Let's sing with motions. 동작 노래",
        //             "called, cracked, doctor, found, shot, stomachache",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: Aiken Drum 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song.",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "This is the doctor's office.",
        //             "What do you see in the picture?",
        //             "There are some tools and machines.",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "I see one green broccoli. / I see one yellow corn.",
        //             "I see one orange carrot. / I see one red tomato.",
        //             "I see one brown potato. / I see one brown hammer.",
        //             "I see one gray nut cracker. / I see one white blender.",
        //             "I see one white computer. / I see one green telephone.",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Teaching Guide",
        //             "Let's Move 제목을 말한다.",
        //             "Catty! Turn right. Turn left.",
        //             "Can you move Catty?",
        //             "Let's Move 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "Can you move Robby?",
        //             "Turn right.",
        //             "Put the Robby sticker in the correct circle.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '06', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '07', page: '16', action: '01', scene: '01', sentences: [
        //             "Manners Can Be Fun Teaching Guide",
        //             "Manners Can Be Fun 제목을 말한다.",
        //             "Use two Yut sticks.",
        //             "Throw the sticks to move.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '07', page: '16', action: '01', scene: '02', sentences: [
        //             "Say the manner expressions.",
        //             "manner, please, excuse me,",
        //             "I'm sorry, thank you.",
        //             "How do you play this game?",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '07', page: '16', action: '01', scene: '03', sentences: [
        //             "How to play: (놀이 책 참조)",
        //             "Throw the sticks and then move the game pieces.",
        //             "How to move: Move 1. Move 2. Move 3.",
        //             "When you are arrive at , you can choose to go any direction you like.",
        //             "Let's play a game.",
        //         ]
        //     }, {
        //         level: '03', volume: '08', week: '04', slide: '07', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "Seasons and Temperature Teaching Guide",
        //             "Let's sing the Seasons Song. 영상 노래",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Seasons Song. (영상을 보며, 노래와 율동)",
        //             "Put the matching temperature stickers on the circles.",
        //             "Spring / Summer / Fall / Winter",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "Write the words.",
        //             "It is warm. It is ( hot )",
        //             "It is cool. It is ( cold )",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is windy. windy를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is snowy. snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see?",
        //             "아이) 요리사, 우유, 바나나, 피자….라고 말한다.",
        //             "맞아요, 잘 했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "This is a boy.",
        //             "He is a cook and he is making something.",
        //             "Do you see (eggs /a cook /milk / pizza)?",
        //             "Yes, I see (eggs /a cook /milk / pizza).",
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "He is a cook and he is making something.",
        //             "Find the pictures",
        //             "eggs / a cook / milk / pizza",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '05', action: '02', scene: '01', sentences: [
        //             "Are you hungry? Teaching Guide",
        //             "What do you want to ( eat / drink) in the winter?",
        //             "Do you want to ( eat / drink)",
        //             "(kimbap / milkshakes / tacos / pizza) in the winter?",
        //             "Draw what you want to eat or drink!",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '06', page: '05', action: '02', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '03', page: '00', action: '01', scene: '01', sentences: [
        //             "Song: I Am a Super Cook Teaching Guide",
        //             "Song: I Am a Super Cook 제목을 말한다.",
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
        //             "sledding, spring, summer, tacos, winter",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '03', page: '00', action: '01', scene: '02', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "What do you want to cook?",
        //             "I am a super cook.",
        //             "Good job! Singing (스티커로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '03', page: '00', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine Shapes Song. Teaching Guide",
        //             "Shapes Song. 의 영상, 노래 율동",
        //             "Let's sing: Shapes Song.",
        //             "Circle the color shape.",
        //             "red square / Yellow circle / Blue triangle",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Seasonal Activities Teaching Guide",
        //             "Seasonal Activities 제목을 말한다.",
        //             "What do you want to do?",
        //             "Seasonal Activities 영상, 상호작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "What do you want to do?",
        //             "Let's pretend. It is (spring / summer / fall / winter).",
        //             "Do you want to go (on a picnic/ to the beach /hiking / sledding)?",
        //             "Yes. I want to go (on a picnic) in the (spring).",
        //             "Put the matching stickers on the circles.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "What do you do in the winter?",
        //             "make snowmen / have snowball fights",
        //             "make snow angels / go sledding",
        //             "Draw a picture.",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '09', page: '00', action: '07', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 December를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '09', page: '00', action: '07', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '01', slide: '09', page: '00', action: '07', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: I Am a Super Cook Teaching Guide",
        //             "Song: I Am a Super Cook 제목을 말한다.",
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "Let's sing together. 동작, 노래",
        //             "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
        //             "sledding, spring, summer, tacos, winter",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "What do you want to cook?",
        //             "I am a super cook.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Long o Teaching Guide",
        //             "Long o 제목을 말한다.",
        //             "Let's sing: Long o 영상을 본다.",
        //             "Long o 가 무슨 소리가 날까요? (‘오' 소리가 난다)",
        //             "Long o says . Say the words:",
        //             "(-/ope/, hope), robe, mope.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Long o 영상을 본다.",
        //             "Say the words hope / mop / robe / mope/ hop",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Long u Teaching Guide",
        //             "Long u 제목을 말한다.",
        //             "Let's sing: Long u 영상을 본다.",
        //             "Long u 가 무슨 소리가 날까요? (‘우~유' 소리가 난다)",
        //             "Long u says . Say the words:",
        //             "(-/ube/, cube), (-/uge/, huge), (-/ube/, tube).",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "Let's sing: Long u 영상을 본다.",
        //             "Say the words cube / huge / tube.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "What letters are missing? Teaching Guide",
        //             "활동을 해볼까요?",
        //             "What letters are missing? (영상으로 상호작용)",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Write the letters and say the words.",
        //             "cube / huge / tube.",
        //             " Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Four Seasons Teaching Guide",
        //             "Let's sing: Seasons Song. 영상 노래",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's sing: Seasons Song 영상, 노래, 율동",
        //             "spring / summer / Fall / winter",
        //             "These are the four seasons.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Four Seasons Teaching Guide",
        //             "Four Seasons 제목을 말한다.",
        //             "What is your favorite season?",
        //             "Four Seasons. 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "Do you like (spring/summer/fall/winter)?",
        //             "I like (spring/summer/fall/winter).",
        //             "Color the boxes.",
        //             "How many children like (spring)?",
        //             "Let's count. Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '02', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: I Am a Super Cook Teaching Guide",
        //             "Song: I Am a Super Cook 제목을 말한다.",
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
        //             "sledding, spring, summer, tacos, winter",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "What do you want to cook?",
        //             "I am a super cook.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '04', page: '13', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write T t U u V v Teaching Guide",
        //             "Let's write T t U u V v",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '01', sentences: [
        //             "Clothes Teaching Guide",
        //             "Clothes 제목을 말한다.",
        //             "Let's find summer clothes and winter clothes.",
        //             "Clothes 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '03', sentences: [
        //             "Let's find summer clothes and winter clothes.",
        //             "shirts, sandals, and shorts are summer clothes. Coats, boots, jackets, winter hats, and scarves are winter clothes.",
        //             "We can wear (pants/shirts/sunglasses) in the summer or winter.",
        //             "Put the matching clothes stickers in the Venn diagram.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '05', page: '13', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '06', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics: : one, red, blue, yellow Teaching Guide",
        //             "Sight Words: one, red, blue, yellow 을 말한다.",
        //             "Let's chant: Tools and Machines. (영상을 본다)",
        //             "One ( red / blue / yellow)….",
        //             "(tablespoon / blender / spatula / frying pan / oven / ladle).",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '06', page: '18', action: '01', scene: '02', sentences: [
        //             "Let's chant: Tools and Machines. (영상을 본다)",
        //             "What can you find in the kitchen?",
        //             "One ( red / blue / yellow)….",
        //             "(tablespoon / blender / spatula / frying pan / oven / ladle).",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '06', page: '18', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: can, make Teaching Guide",
        //             "Sight Words: can, make 을 말한다.",
        //             "Let's chant: Four Seasons. (영상을 본다)",
        //             "I can make (kimbap / milkshakes / tacos / pizza toast)",
        //             "I can make in the (spring/summer/fall/winter).",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: Four Seasons. (영상을 본다)",
        //             "What food can you make?",
        //             "I can make (kimbap / milkshakes / tacos / pizza toast)",
        //             "I can make in the (spring/summer/fall/winter).",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '09', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words can and make? Teaching Guide",
        //             "Can you find the words can and make? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: Four Seasons. (영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '09', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '09', page: '19', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you find the words can and make?",
        //             "Circle and write the words. (can / make)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '03', slide: '09', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: I Am a Super Cook Teaching Guide",
        //             "Song: I Am a Super Cook 제목을 말한다.",
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "Let's sing together. 동작, 노래",
        //             "beach, fall, hiking, kimbap, milkshakes, picnic, pizza",
        //             " sledding, spring, summer, tacos, winter",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: I Am a Super Cook 영상을 본다.",
        //             "What do you want to cook?",
        //             "I am a super cook.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song.",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "It's snowing! Let's go sledding!",
        //             "What do you see in the picture?",
        //             "Bacon, fish, ham, nuts, turkey, boots, fan, hat, mitten, scarf.",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "It is snowing. / I see children sledding.",
        //             "I see a boy making snow angels.",
        //             "I see a girl making a snow man.",
        //             "I see one green broccoli. / I see one yellow corn.",
        //             "These are the hidden things.",
        //             "Find the hidden things.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '06', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Teaching Guide",
        //             "Let's Move 제목을 말한다.",
        //             "Let's be Robby and Catty!",
        //             "Can you move (Robby / Catty)?",
        //             "Let's Move 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '06', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '06', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you move ( Robby / Catty)?",
        //             "Turn ( right. / Left )",
        //             "Good job! 활동하는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '06', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '07', page: '16', action: '01', scene: '01', sentences: [
        //             "Alphabet Memory Game Teaching Guide",
        //             "Alphabet Memory Game 제목을 말한다.",
        //             "Let's read the alphabets.",
        //             "Big letters and little letters.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '07', page: '16', action: '01', scene: '02', sentences: [
        //             "alphabets, big letter, little letter, match,",
        //             "The Alphabet Song. 영상을 본다.",
        //             " Find big letters and little letters.",
        //             "Find the Matching cards",
        //             "How do you play this game?",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '07', page: '16', action: '01', scene: '03', sentences: [
        //             "How to play: (놀이 책 참조)",
        //             "Face the cards down and spread them on the table.",
        //             "Take turns finding the same cards.",
        //             "Discuss which cards to turn over.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '07', page: '16', action: '01', scene: '04', sentences: [
        //             "If a team has different cards, put them face down on the same place.",
        //             "If a team has matching cards, keep them.",
        //             "When the game is over, count how many cards you have.",
        //             "Let's play the Alphabet Memory game.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '07', page: '16', action: '01', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '08', page: '14', action: '01', scene: '01', sentences: [
        //             "Rub the Balloon Teaching Guide",
        //             "Rub the Balloon 제목을 말한다.",
        //             "What happened to the papers?",
        //             "Place the balloon near the pieces of paper.",
        //             "Rub the balloon on your clothes or hair.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '08', page: '14', action: '01', scene: '02', sentences: [
        //             "What happened to the papers?",
        //             "The balloon picked up pieces of paper.",
        //             "Let's rub the balloon on your head.",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '08', page: '14', action: '01', scene: '03', sentences: [
        //             "What happened to the papers?",
        //             "Before rubbing",
        //             "After rubbing",
        //             "Draw what you saw.",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '09', week: '04', slide: '08', page: '14', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '01', sentences: [
        //             "I Love You, My Friends! Teaching Guide",
        //             "Let's sing the Friendship Song. 영상 노래 율동",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '02', sentences: [
        //             "Let's sing the Friendship Song. (영상을 보며, 노래와 율동)",
        //             " Draw your friends.",
        //             "Give stickers to your friends.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '03', sentences: [
        //             "Trace ‘I love you.'",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '04', page: '03', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '01', sentences: [
        //             "Daily Routine ‘The Weather' Teaching Guide",
        //             "The Weather Song 영상, 노래",
        //             "What's the weather like today?",
        //             "Look out the window.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '02', sentences: [
        //             "It is sunny. sunny를 말한다.",
        //             "It is cloudy. cloudy 말한다.",
        //             "It is windy. windy를 말한다.",
        //             "It is rainy. rainy를 말한다.",
        //             "It is snowy. snowy를 말한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '03', sentences: [
        //             "The Weather Song 영상을 본다.",
        //             "Good job! Singing.",
        //             " 활동을 해볼까요?",
        //             "Circle the picture.",
        //             "활동을 마무리한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '05', page: '04', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '01', sentences: [
        //             "Let's Look at the Cover Teaching Guide",
        //             "Let's Look at the Cover 제목을 말한다.(스토리 북의 그림을 보며)",
        //             "What do you see?",
        //             "아이) 과자, 우유, 가지, …라고 말한다.",
        //             "맞아요, 잘 했어요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '02', sentences: [
        //             "The world is made of food.",
        //             "The children are riding a merry-go-round.",
        //             "Do you see (crackers / a pineapple / pie / milk)?",
        //             "Yes, I see (crackers /a pineapple / pie / milk).",
        //             "Let's Look at the Cover 영상을 보며, 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '03', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '04', sentences: [
        //             "The world is covered with food.",
        //             "Find the pictures",
        //             "Crackers / a pineapple / a pie / milk",
        //             "Good job! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '04', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '01', sentences: [
        //             "If all the world is made of… Teaching Guide",
        //             "What do you want the world to be made of?",
        //             "All the world is made of",
        //             "(glass bottles / boxes / cans / paper)",
        //             "Draw what you want the world to be made of!",
        //             "Excellent!",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '06', page: '05', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '01', sentences: [
        //             "Song: If All the World Teaching Guide",
        //             "Song: If All the World 제목을 말한다.",
        //             "Song: If All the World 영상을 본다.",
        //             "Let's sing together. 동작, 노래.",
        //             "dairy foods, fruits, grains, protein foods, vegetables, world",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '02', sentences: [
        //             "Put the matching stickers on the circles.",
        //             "If all the world is made of...",
        //             "Grains / fruits and vegetables / dairy foods / protein foods",
        //             " What will happen to the world?",
        //             "Good job! Singing (스티커, 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '03', page: '06', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '01', sentences: [
        //             "Daily Routine What color is this? Teaching Guide",
        //             "Colors Song의 영상을 본다.",
        //             "Let's sing: Colors Song (노래, 율동)",
        //             "This is the iWonder rainbow",
        //             "red / Blue / Yellow /",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '07', page: '08', action: '01', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '01', sentences: [
        //             "Food Group Teaching Guide",
        //             "Food Group 제목을 말한다.",
        //             "Let's find the matching food group.",
        //             "Food Group 영상을 보며 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '03', sentences: [
        //             "Let's find the matching food group.",
        //             "A cracker. The cracker is in the Grains Group.",
        //             "Broccoli. The broccoli is in the Vegetable Group.",
        //             "A pineapple. The pineapple is in the Fruits Group.",
        //             "Milk. The milk is in the Dairy Group.",
        //             "An egg. The egg is in the Protein Food Group.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '04', sentences: [
        //             "Can you find the foods in the (Grains Group)?",
        //             "Grains Group / Vegetable Group",
        //             "Fruit Group / Dairy Group",
        //             "Protein Foods Group",
        //             "Excellent! 활동 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '08', page: '08', action: '02', scene: '05', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '09', page: '00', action: '00', scene: '01', sentences: [
        //             "Calendar Teaching Guide",
        //             "Calendar 영상을 본다.",
        //             "Q를 누른다. what month is it? (소리) 같이 읽는다.(몇 월 인가요?)",
        //             "깜박이는 January를 누른다. (소리) (같이 읽는다)",
        //             "음, 표시 / 그림을 누르면 'month song'이 나온다. (다 같이 노래한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '09', page: '00', action: '00', scene: '02', sentences: [
        //             "주(week)를 가리키는 bar를 누른다.",
        //             "음, 표시 / 그림을 누르면 'week song'이 나온다. (다 같이 노래한다)",
        //             "천천히 요일을 읽는다. (Sunday/ Monday/…….)",
        //             "해당 요일을 클릭 하면, 다음으로 넘어간다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '01', slide: '09', page: '00', action: '00', scene: '03', sentences: [
        //             "what day is it Today? 깜박인다. 다음 클릭 한다.",
        //             "클릭 후 Today is…… 소리가 나온다. (선생님도 같이 한다)",
        //             "해당하는 날을 클릭 하면,(다른 날일 경우 Try again 소리가 나온다)",
        //             "하단에 오늘의 날을 표시하는 글과 말이 나온다.",
        //             "Today is (주, 월, 날짜) 순으로 영어가 표현된다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: If All the World Teaching Guide",
        //             "Song: If All the World 제목을 말한다.",
        //             "Song: If All the World 영상을 본다.",
        //             "Let's sing together. 동작, 노래와 함께 불러본다.",
        //             "dairy foods, fruits, grains, protein foods, vegetables, world",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: If All the World 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '01', sentences: [
        //             "Phonics Long u Teaching Guide",
        //             "Long u 제목을 말한다.",
        //             "Let's sing: Long u 영상을 본다.",
        //             "Long u 가 무슨 소리가 날까요? (‘우~유' 소리 난다)",
        //             "Long u says . Say the words:",
        //             "(-/ube/, cube), (-/uge/, huge), (-/ube/, tube).",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '02', sentences: [
        //             "Let's sing: Long u 영상을 본다.",
        //             "Say the words cube / tube. / tub / huge / hug",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '04', page: '10', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '01', sentences: [
        //             "Long Vowels Teaching Guide",
        //             "Long Vowels 제목을 말한다.",
        //             "Let's sing: Long Vowels 영상을 본다.",
        //             "Long Vowels 은 어떤 것이 있을 까요?",
        //             "Long Vowels is…",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '02', sentences: [
        //             "Long a says ā Cane / Cape/ Mate",
        //             "Long i says ī. Fine / Kite / Pine",
        //             "Long o says ō. Hope / Robe / Mope",
        //             "Long u says ū. Huge / Cube / Tube",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '05', page: '10', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '06', page: '11', action: '01', scene: '01', sentences: [
        //             "Long Vowels ( a I o u ) Teaching Guide",
        //             "활동을 해볼까요?",
        //             "Long Vowels 영상으로 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '06', page: '11', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '06', page: '11', action: '01', scene: '03', sentences: [
        //             "Read the words as fast as you can.",
        //             "How long does it take?",
        //             "Let's read the words.",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '06', page: '11', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '07', page: '12', action: '01', scene: '01', sentences: [
        //             "Daily Routine Roll Your Hands Teaching Guide",
        //             "Let's sing: Roll Your Hands Song. 영상 노래 율동",
        //             "Good job! singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '07', page: '12', action: '01', scene: '02', sentences: [
        //             "Let's sing: Roll Your Hands Song.",
        //             "roll / clap / stomp / cross",
        //             "as slowly as you can.",
        //             "as fast as you can.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '07', page: '12', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '01', sentences: [
        //             "Add Fruits Teaching Guide",
        //             "Add Fruits 제목을 말한다.",
        //             "What is your favorite season?",
        //             "Add Fruits . 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '03', sentences: [
        //             "How many (apples) do I have now?",
        //             "I have (three) (apples).",
        //             "(Two) more apples, please!",
        //             "Let's count the (apples).",
        //             "I have (five) (apples).",
        //             "Draw fruits. / Good job! ( 활동, 마무리한다)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '08', page: '12', action: '02', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '09', page: '00', action: '08', scene: '01', sentences: [
        //             "Phonics The ABC Song Teaching Guide",
        //             "The ABC Song. 영상을 본다.",
        //             "Let's sing the ABC Song.",
        //             "영상의 Alphabet을 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '09', page: '00', action: '08', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG, HIJKLMNOP, QRS, TUV, WX and Y and Z.",
        //             "I say A. You say BC! A, BC! A, BC!",
        //             "Now I know my ABCs, ABCs, ABCs!",
        //             "Everybody come on and sing the sounds!",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '02', slide: '09', page: '00', action: '08', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: If All the World Teaching Guide",
        //             "Song: If All the World 제목을 말한다.",
        //             "Song: If All the World 영상을 본다.",
        //             "Let's sing together. 동작, 노래",
        //             "dairy foods, fruits, grains, protein foods, vegetables, world",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: If All the World 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '04', page: '12', action: '01', scene: '01', sentences: [
        //             "Phonics Let's write W w X x Y y Z z Teaching Guide",
        //             "Let's write W w X x Y y Z z",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '05', page: '13', action: '01', scene: '01', sentences: [
        //             "Recycling Bins Teaching Guide",
        //             "Recycling Bins 제목을 말한다.",
        //             "Where does the trash go?",
        //             "Recycling Bins 영상으로 상호 작용한다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '05', page: '13', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '06', page: '13', action: '01', scene: '03', sentences: [
        //             "Where does the trash go?",
        //             "Let's sort the trash.",
        //             "Find all the (paper / glass / can / plastic) stickers.",
        //             "Put the stickers on the matching bins.",
        //             "Good job! (스티커, 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '06', page: '13', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '07', page: '18', action: '01', scene: '01', sentences: [
        //             "Phonics: Long Vowels ( a I o u ) Teaching Guide",
        //             "활동을 해볼까요?",
        //             "Long Vowels 영상으로 상호작용",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '07', page: '18', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '07', page: '18', action: '01', scene: '	03', sentences: [
        //             "Let's read the words.",
        //             "Long a says ā Cane / Cape/ Mate",
        //             "Long i says ī. Fine / Kite / Pine",
        //             "Long o says ō. Hope / Mope/ Robe",
        //             "Long u says ū. Huge / Cube / Tube",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '07', page: '18', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '08', page: '18', action: '02', scene: '01', sentences: [
        //             "Sight Words: little, big Teaching Guide",
        //             "Sight Words: little, big을 말한다.",
        //             "Let's chant: The Earth. (영상을 본다)",
        //             "This is a (big/little)",
        //             "(sun / tree / whale / flower / bird / fish).",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '08', page: '18', action: '02', scene: '02', sentences: [
        //             "Let's chant: The Earth. (영상을 본다)",
        //             "Good job! Singing",
        //             "Can you find little and big things?",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '08', page: '18', action: '02', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '09', page: '19', action: '01', scene: '01', sentences: [
        //             "Can you find the words little and big? Teaching Guide",
        //             "Can you find the words little and big? 제목을 말한다.",
        //             "Circle and write the words.",
        //             "Let's chant: The Earth(영상을 본다)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '09', page: '19', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '09', page: '19', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you find the words little and big",
        //             "Circle and write the words. (little / big)",
        //             "Good job!",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '03', slide: '09', page: '19', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '03', page: '00', action: '05', scene: '01', sentences: [
        //             "Song: If All the World Teaching Guide",
        //             "Song: If All the World 제목을 말한다.",
        //             "Song: If All the World 영상을 본다.",
        //             "Let's sing together. 동작, 노래",
        //             "dairy foods, fruits, grains, protein foods, vegetables, world",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '03', page: '00', action: '05', scene: '02', sentences: [
        //             "Song: If All the World 영상을 본다.",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '03', page: '00', action: '05', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '04', page: '00', action: '09', scene: '01', sentences: [
        //             "Phonics Alphabet Song Teaching Guide",
        //             "The Alphabet Song. 영상을 본다.",
        //             "Let's sing the Alphabet Song. (반복)",
        //             "(영상에 맞추어 노래를 같이 한다)",
        //             "영상의 Alphabet을 지시봉으로 음가대로 글자를 가리키며 노래.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '04', page: '00', action: '09', scene: '02', sentences: [
        //             "Do you know the ABC song?",
        //             "Let's sing the ABC song.",
        //             "ABCDEFG HIJKLMN OPQ RST UVW XYZ.",
        //             "Now, I know my ABC's.",
        //             "Next time won't you sing with me.",
        //             "Good job singing.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '04', page: '00', action: '09', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '05', page: '20', action: '01', scene: '01', sentences: [
        //             "Hidden Pictures Teaching Guide",
        //             "Hidden Pictures 제목을 말한다.",
        //             "Let's recycle!",
        //             "Some things are hidden.",
        //             "Bread, cereal, cookie, doughnut, muffin, rice, glass bottle, box, can, newspaper.",
        //             "Hidden Pictures 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '05', page: '20', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '05', page: '20', action: '01', scene: '03', sentences: [
        //             "These are the hidden things.",
        //             "Find the hidden things.",
        //             "Good job! (활동마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '05', page: '20', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '06', page: '00', action: '04', scene: '01', sentences: [
        //             "Clean up Song Teaching Guide",
        //             "Clean up Song 영상을 본다.",
        //             "Let's sing: Clean up Song.노래, 율동",
        //             "Good job! Singing",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '06', page: '00', action: '04', scene: '02', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '07', page: '22', action: '01', scene: '01', sentences: [
        //             "Let's Move Robby Teaching Guide",
        //             "Let's Move Robby제목을 말한다.",
        //             "Robby wants some fruit.",
        //             "Can you move Robby?",
        //             "Let's Move 영상을 본다.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '07', page: '22', action: '01', scene: '02', sentences: [
        //             "Who wants to come up here?",
        //             "Raise your hands.",
        //             "민호, Come to the front.",
        //             "Good job! 하이파이브!",
        //             "Go back to your seat",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '07', page: '22', action: '01', scene: '03', sentences: [
        //             "활동을 해볼까요?",
        //             "Can you move Robby?",
        //             "Start /End /Right /Left / Up / Down",
        //             "Good job! 활동하는 것으로 마무리",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '07', page: '22', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '08', page: '16', action: '01', scene: '01', sentences: [
        //             "Build the Cup Tower Teaching Guide",
        //             "Build the Cup Tower 제목을 말한다.",
        //             "Can you build the cup tower with your friends?",
        //             "Build cup, down, left, right, tower, up",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '08', page: '16', action: '01', scene: '02', sentences: [
        //             "Build the Cup Tower . 영상을 본다.",
        //             "How do you build the cup tower?",
        //             "Good job!",
        //             "Put the stickers on the circles when you are done.",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '08', page: '16', action: '01', scene: '03', sentences: [
        //             "Now try making your own cup tower with your friends",
        //             "Let's try making!",
        //             "Take a picture and paste it.",
        //             "Good job! (활동 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '08', page: '16', action: '01', scene: '04', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //             "Music Maker Teaching Guide",
        //             "Music Maker 제목을 말한다.",
        //             "Let's make some sounds.",
        //             "Can we make sounds with",
        //             "(glass bottles, plastic containers, cans, and boxes)?",
        //             "Use rubber bands.",
        //             "Can you hear the sounds?",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '09', page: '14', action: '01', scene: '02', sentences: [
        //             "Circle what you want to use.",
        //             "glass bottles, plastic containers, cans, and boxes",
        //             "What sounds!",
        //             "Put a rubber band on things we can recycle.",
        //             "Good job! / (확인 하는 것으로 마무리)",
        //         ]
        //     }, {
        //         level: '03', volume: '10', week: '04', slide: '09', page: '14', action: '01', scene: '03', sentences: [
        //             "오늘 교실에서 배운 영상을 부모님과 같이 해보세요.",
        //             "(복습, 반복, 부모님과의 상호작용)",
        //             "Goodbye~ 다음 시간에 만나요.",
        //         ]
        //     }
        // ]
    },
    {
        title: 'Fun & Hunt',
        guides: [
            { level: '01', volume: '01', week: '01', slide: '01', page: '03', action: '01', scene: '01', sentences: [
                'Feelings Song Teaching Guide', 
                'Feelings Song 영상을 보여준다.',
                'How does he feel today?',
                'I am happy!',
                'Happy / sad',
                'Circle the picture. (기분을 동그라미를 해볼까요?)'
            ]},
        ]
    },
]

export { classroomEnglish, teachingGuides }