<template>
  <v-dialog v-model="dialog" max-width="600" overlay-opacity="1">
    <!-- play button -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text color="deep-purple lighten-2">
        <v-icon left>mdi-television-guide</v-icon>
        <span>Classroom English</span>
      </v-btn>
    </template>

    <!-- dialog contents -->
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="blue-grey"
        grow
      >
        <v-tab v-for="(guide, index) in guides" :key="index">
          SCRIPT{{ index + 1 }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(guide, index) in guides" :key="index">
          <v-list :color="colors[index]">
            <v-list-item v-for="(sentence, i) in guide.sentences" :key="i">
              <v-list-item-avatar>
                <v-img :src="src"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="white--text text-left">
                <v-list-item-title>{{ sentence }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn text fab @click="speak(sentence)">
                  <v-icon color="white">
                    mdi-volume-high
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon class="close" @click="dialog = false" color="blue-grey">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- if there are't guides -->
    <!-- <v-card v-else>
      <v-img
        :src="`/0${Math.floor(Math.random() * 10) + 1}.jpg`"
        aspect-ratio="1.7"
      ></v-img>
    </v-card> -->
  </v-dialog>
</template>

<script>
export default {
  props: ["guides"],
  data() {
    return {
      dialog: false,
      tab: null,
      colors: [
        "blue-grey",
        "lime darken-4",
        "green darken-1",
        "teal darken-3",
        "deep-purple lighten-2",
      ],
      src: "/teacher.jpg",
      voices: [],
    };
  },
  methods: {
    speak(message) {
      let sentence = new SpeechSynthesisUtterance(message);
      sentence.lang = "en-US";
      sentence.rate = 0.8;
      sentence.pitch = 1;

      this.populateVoiceList();
      const voices = this.voices.filter((voice) => voice.lang.includes("en"));
      // console.log(voices);
      const randomNumber = Math.floor(Math.random() * voices.length);
      sentence.voice = voices[randomNumber];
      // if (voices.length > 1) {
      //   sentence.voice = voices[1];
      // } else {
      //   sentence.voice = voices[0];
      // }

      speechSynthesis.cancel();
      speechSynthesis.speak(sentence);
    },
    populateVoiceList() {
      this.voices = speechSynthesis.getVoices();
    },
  },
  created() {
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = this.populateVoiceList;
    }
  },
};
</script>
