<template>
  <v-dialog v-model="dialog" max-width="600" overlay-opacity="0.8" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card v-bind="attrs" v-on="on">
          <v-img class="link-image" :src="link.imageURL" aspect-ratio="1">
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal text-h2 white--text"
                style="height: 100%;"
              >
                <span class="text-h4">{{ link.title }}</span>
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-hover>
    </template>

    <!-- Let's make -->
    <video
      v-if="link.videoURL"
      width="100%"
      :poster="link.imageURL"
      controls
      :src="link.videoURL"
      controlsList="nodownload"
    ></video>

    <!-- <Jigsaw v-else-if="link.title == 'Story Puzzle'" :link="link" /> -->

    <!-- <MemoryGame v-else-if="link.title == 'Memory Game'" :link="link" /> -->

    <!-- Assessment -->
    <v-img
      v-else
      max-width="100%"
      class="align-center mx-auto"
      :src="link.imageURL"
      aspect-ratio="1"
    >
      <v-btn fab color="purple" dark large :href="link.htmlURL" target="_blank">
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </v-img>
  </v-dialog>
</template>

<script>
import Jigsaw from "../components/Jigsaw.vue";
import MemoryGame from "../components/MemoryGame.vue";

export default {
  components: {
    Jigsaw,
    MemoryGame,
  },
  props: ["link"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.week-image {
  filter: grayscale(0%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
