<template>
  <div class="hangeul-details">
    <!-- 한글기초이면 -->
    <v-container v-if="level_volume.slice(1, 2) == '1'">
      <v-row class="text-h6 my-3 grey--text">
        <v-icon class="primary--text text--darken-2" large
          >mdi-play-box-multiple-outline</v-icon
        >
        <span class="ml-3">동영상</span>
      </v-row>
      <v-row class="my-1">
        <v-col
          cols="12"
          sm="3"
          v-for="(content, i) in levelOneVideoContents"
          :key="i"
        >
          <HangeulModal :content="content" />
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <v-row class="text-h6 my-3 grey--text">
        <v-icon class="pink--text text--darken-2" large>mdi-music</v-icon>
        <span class="ml-3">음원</span>
      </v-row>
      <v-row class="my-1">
        <v-col
          cols="12"
          sm="3"
          v-for="(content, i) in levelOneMP3Contents"
          :key="i"
        >
          <HangeulModal :content="content" />
        </v-col>
      </v-row>
    </v-container>

    <!-- 한글기초가 아니면 -->
    <v-container v-else>
      <v-row class="my-1">
        <v-col cols="12" sm="3" v-for="(content, i) in book.contents" :key="i">
          <HangeulModal :content="content" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { projectStorage } from "../firebase/config";
import HangeulModal from "../components/HangeulModal.vue";

export default {
  components: { HangeulModal },
  data() {
    return {
      levels: this.$store.state.hangeul,
      level_volume: this.$route.params.level_volume,
      // book: this.$route.params.book,
      // level: this.$route.params.level,
    };
  },
  computed: {
    levelNumber() {
      return parseInt(this.level_volume.slice(1, 2)) - 1;
    },
    bookNumber() {
      if (this.level_volume.length == 5) {
        return parseInt(this.level_volume.slice(3, 5)) - 1;
      } else {
        return parseInt(this.level_volume.slice(4, 6)) - 1;
      }
    },
    book() {
      return this.levels[this.levelNumber].books[this.bookNumber];
    },
    levelOneVideoContents() {
      return this.book.contents.filter((content) => {
        return content.videoTitle ? true : false;
      });
    },
    levelOneMP3Contents() {
      return this.book.contents.filter((content) => {
        return content.songTitle ? true : false;
      });
    },
  },
  methods: {
    fillBook() {
      this.book.contents.forEach((item) => {
        // fill vidio thumbnails
        let imagePath;
        if (item.videoTitle) {
          imagePath = "/hangeul/" + item.videoTitle + ".jpg";
        } else {
          imagePath = "/hangeul/" + item.songTitle + ".jpg";
        }
        projectStorage
          .ref(imagePath)
          .getDownloadURL()
          .then((imageURL) => {
            if (imageURL) {
              item.imageURL = imageURL;
            }
          })
          .catch((err) => {
            console.log(err.message);
            item.imageURL =
              "https://images.unsplash.com/flagged/photo-1551600466-464bbbbd15f9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";
          });
        // fill videos
        if (!item.htmlURL && !item.videoURL) {
          let path = "/videos/" + item.videoTitle + ".mp4";
          projectStorage
            .ref(path)
            .getDownloadURL()
            .then((videoURL) => {
              if (videoURL) {
                item.videoURL = videoURL;
              }
            })
            .catch((err) => {
              console.log(err.message);
              item.videoURL =
                "https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/rZJIMvhmliwmde8a6/videoblocks-little-boys-and-girls-playing-with-block-toys-in-playroom-kids-playing-with-developing-toys-indoor-creative-kids-learning-center_rvzekwq0e__634b285e66ab9f705dd1b613bb400463__P360.mp4";
            });
        }
        // fill audios
        if (!item.mp3URL) {
          let path = "/hangeulMP3/" + item.songTitle + ".mp3";
          projectStorage
            .ref(path)
            .getDownloadURL()
            .then((mp3URL) => {
              if (mp3URL) {
                item.mp3URL = mp3URL;
              }
            })
            .catch((err) => {
              console.log(err.message);
              item.mp3URL = "https://ccrma.stanford.edu/~jos/mp3/harpsi-cs.mp3";
            });
        }
      });
    },
  },
  mounted() {
    this.fillBook();
  },
};
</script>

<style></style>
