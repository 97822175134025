<template>
  <div class="hangeul">
    <v-container>
      <!-- <v-row class="d-flex flex-nowrap" v-for="(level, i) in levels" :key="i"> -->

      <!-- level 1 -->
      <v-row class="mb-3" v-if="levelOfKorean.includes('한글1')">
        <!-- <v-col
          v-for="(book, index) in levels[0].books"
          :key="index"
          cols="12"
          sm="4"
          md="3"
        > -->
        <v-col
          v-for="(book, index) in levels[0].books"
          :key="index"
          cols="4"
          md="3"
          class="pa-1"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <router-link
                :to="{
                  name: 'HangeulDetails',
                  params: {
                    level: levels[0].level,
                    level_volume: `k1-0${index + 1}`,
                    book: book,
                  },
                }"
              >
                <v-img :src="book.src"> </v-img>
              </router-link>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <!-- level 2 한글1-->
      <v-row class="mb-3" v-if="levelOfKorean.includes('한글2')">
        <!-- <v-col
          v-for="(book, index) in levels[1].books"
          :key="index"
          cols="12"
          sm="4"
          md="3"
        > -->
        <v-col
          v-for="(book, index) in levels[1].books"
          :key="index"
          cols="4"
          md="3"
          class="pa-1"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <router-link
                :to="{
                  name: 'HangeulDetails',
                  params: {
                    level: levels[1],
                    level_volume: `k2-0${index + 1}`,
                    book: book,
                  },
                }"
              >
                <v-img :src="book.src"> </v-img>
              </router-link>
            </v-card>
          </v-hover>
        </v-col>
        <!-- <v-col
          cols="12"
          sm="4"
          md="3"
          v-for="part in levels[1].mp3"
          :key="part.part"
        > -->
        <v-col
          cols="4"
          md="3"
          class="pa-1"
          v-for="part in levels[1].mp3"
          :key="part.part"
        >
          <Mp3Modal :part="part" />
        </v-col>
      </v-row>

      <!-- level 3 -->
      <v-row v-if="levelOfKorean.includes('한글3')">
        <!-- <v-col
          v-for="(book, index) in levels[2].books"
          :key="index"
          cols="12"
          sm="4"
          md="3"
        > -->
        <v-col
          v-for="(book, index) in levels[2].books"
          :key="index"
          cols="4"
          md="3"
          class="pa-1"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <router-link
                :to="{
                  name: 'HangeulDetails',
                  params: {
                    level: levels[2],
                    level_volume: `k3-0${index + 1}`,
                    book: book,
                  },
                }"
              >
                <v-img :src="book.src"> </v-img>
              </router-link>
            </v-card>
          </v-hover>
        </v-col>
        <!-- <v-col
          cols="12"
          sm="4"
          md="3"
          v-for="part in levels[2].mp3"
          :key="part.part"
        > -->
        <v-col
          cols="4"
          md="3"
          class="pa-1"
          v-for="part in levels[2].mp3"
          :key="part.part"
        >
          <Mp3Modal :part="part" />
        </v-col>
      </v-row>
      <v-row v-if="member == '부모님'">
        <v-col>선생님만 보실 수 있습니다</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { projectAuth } from "../firebase/config";
import { projectFirestore } from "../firebase/config";
import { projectStorage } from "../firebase/config";
import Mp3Modal from "../components/Mp3Modal.vue";

export default {
  components: { Mp3Modal },
  data() {
    return {
      member: "",
      levelOfKorean: [],
      levels: this.$store.state.hangeul,
      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  methods: {
    getUser() {
      const user = projectAuth.currentUser;
      projectFirestore
        .collection("users")
        .where("user_id", "==", user.uid)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            projectFirestore
              .collection("users")
              .doc(doc.id)
              .get()
              .then((user) => {
                // this.user = user.data();
                // let levelOfKorean;
                // const temp = user.data().levelOfKorean;
                // if (temp == "한글1") {
                //   levelOfKorean = 1;
                // } else if (temp == "한글2") {
                //   levelOfKorean = 2;
                // } else if (temp == "한글3") {
                //   levelOfKorean = 3;
                // } else if (temp == "admin") {
                //   levelOfKorean = 4;
                // } else {
                //   levelOfKorean = 0;
                // }
                this.levelOfKorean = user.data().levelOfKorean;
                this.member = user.data().member;
                // console.log(this.levelOfKorean);
              });
          });
        });
    },
    fillLevelTwoMP3() {
      this.levels[1].mp3.forEach((part) => {
        part.content.forEach((item) => {
          // fill imageURL
          let imagePath;
          if (item.songTitle) {
            imagePath = "/hangeul/" + item.songTitle + ".jpg";
            projectStorage
              .ref(imagePath)
              .getDownloadURL()
              .then((imageURL) => {
                if (imageURL) {
                  item.imageURL = imageURL;
                }
              })
              .catch((err) => {
                console.log(err.message);
                item.imageURL =
                  "https://images.unsplash.com/flagged/photo-1551600466-464bbbbd15f9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";
              });
          }

          // fill mp3URL
          if (!item.mp3URL) {
            let path = "/hangeulMP3/" + item.songTitle + ".mp3";
            projectStorage
              .ref(path)
              .getDownloadURL()
              .then((mp3URL) => {
                if (mp3URL) {
                  item.mp3URL = mp3URL;
                }
              })
              .catch((err) => {
                console.log(err.message);
                item.mp3URL =
                  "https://ccrma.stanford.edu/~jos/mp3/harpsi-cs.mp3";
              });
          }
        });
      });
    },
    fillLevelThreeMP3() {
      this.levels[2].mp3.forEach((part) => {
        part.content.forEach((item) => {
          // fill imageURL
          let imagePath;
          if (item.songTitle) {
            imagePath = "/hangeul/" + item.songTitle + ".jpg";
            projectStorage
              .ref(imagePath)
              .getDownloadURL()
              .then((imageURL) => {
                if (imageURL) {
                  item.imageURL = imageURL;
                }
              })
              .catch((err) => {
                console.log(err.message);
                item.imageURL =
                  "https://images.unsplash.com/flagged/photo-1551600466-464bbbbd15f9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";
              });
          }

          // fill mp3URL
          if (!item.mp3URL) {
            let path = "/hangeulMP3/" + item.songTitle + ".mp3";
            projectStorage
              .ref(path)
              .getDownloadURL()
              .then((mp3URL) => {
                if (mp3URL) {
                  item.mp3URL = mp3URL;
                }
              })
              .catch((err) => {
                console.log(err.message);
                item.mp3URL =
                  "https://ccrma.stanford.edu/~jos/mp3/harpsi-cs.mp3";
              });
          }
        });
      });
    },
  },
  mounted() {
    this.getUser();
    this.fillLevelTwoMP3();
    this.fillLevelThreeMP3();
  },
};
</script>

<style scoped>
.flex-nowrap {
  overflow-x: scroll;
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}

.v-card__title {
  opacity: 0.4;
}

.show-btns {
  color: rgb(10, 0, 0) !important;
}
.v-responsive__content {
  text-align: center;
}
</style>
