import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyBO0PR-lFACP2Z4gEOIq3dKtkdLTwYiKZw",
    authDomain: "iwonder-b5a76.firebaseapp.com",
    projectId: "iwonder-b5a76",
    storageBucket: "iwonder-b5a76.appspot.com",
    messagingSenderId: "558615987639",
    appId: "1:558615987639:web:dda5d5fdce6f8c1f88d432"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const projectStorage = firebase.storage()

export { projectAuth, projectFirestore, projectStorage }