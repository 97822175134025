<template>
  <div class="parents">
    <v-container>
      <v-row>
        <!-- games -->
        <v-col cols="12">
          <v-row>
            <!-- story puzzle -->
            <!-- <v-col cols="4" md="3" class="pa-1">
              <v-img
                src="https://img.freepik.com/free-vector/v19-pla6_53876-43686.jpg?w=996&t=st=1672066640~exp=1672067240~hmac=c5c56a248530e92b29ed33a58ac40fe21834d2097c43d9c0324dd9ff57edfe8d"
              ></v-img>
            </v-col> -->

            <!-- memory game -->
            <!-- <v-col cols="4" md="3" class="pa-1">
              <router-link
                :to="{
                  name: 'Games',
                }"
              >
                <v-img
                  src="https://img.freepik.com/free-vector/old-people-icons-collection_1284-994.jpg?t=st=1672067096~exp=1672067696~hmac=3a7a62821c540effd86a70634f809eec67c16634702513c937fc2aa5d7f215f2"
                ></v-img>
              </router-link>
            </v-col> -->
          </v-row>
        </v-col>

        <!-- videos -->
        <v-col cols="12" class="mt-5">
          <v-row v-for="(level, i) in levels" :key="i">
            <!-- level 1, 2, 3 -> book 1, 2, 3.... 9, 10 (3 * 10) -->
            <template v-for="(book, j) in level.books">
              <!-- <v-col
            v-if="levelOfEnglish.includes(`K-${i + 1}`)"
            :key="j"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          > -->
              <v-col
                v-if="levelOfEnglish.includes(`K-${i + 1}`)"
                :key="j"
                cols="4"
                md="3"
                class="pa-1"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <router-link
                      :to="{
                        name: 'VideosForParents',
                        params: {
                          level_volume: `${level.level}-${book.volume}`,
                          weeks: book.weeks,
                          steam: book.steam,
                          supplement: book.supplement,
                          mp3: book.mp3,
                          parents: book.parents,
                        },
                      }"
                    >
                      <v-img
                        class="align-end"
                        :src="`/img/stories/${level.level}-${book.volume}.jpg`"
                      >
                        <v-card-title :class="`white--text ${level.color}`">
                          {{ level.level }}-
                          {{
                            book.volume != "10" ? book.volume[1] : book.volume
                          }}
                        </v-card-title>
                      </v-img>
                    </router-link>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <!-- level 1, 2, 3 -->
      <!-- <v-row class="d-flex flex-nowrap" v-for="(level, i) in levels" :key="i"> -->
    </v-container>
  </div>
</template>

<script>
import { projectAuth } from "../firebase/config";
import { projectFirestore } from "../firebase/config";

export default {
  name: "Parents",
  data() {
    return {
      levelOfEnglish: [],
      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    levels() {
      return this.$store.state.items;
    },
  },
  methods: {
    getUser() {
      const user = projectAuth.currentUser;
      projectFirestore
        .collection("users")
        .where("user_id", "==", user.uid)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            projectFirestore
              .collection("users")
              .doc(doc.id)
              .get()
              .then((user) => {
                this.levelOfEnglish = user.data().levelOfEnglish;
              });
          });
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
/* .flex-nowrap {
  overflow-x: scroll;
} */
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
}

.v-card__title {
  opacity: 0.4;
}

.show-btns {
  color: rgb(10, 0, 0) !important;
}
.v-responsive__content {
  text-align: center;
}
</style>
