<template>
  <div class="login">
    <h1 class="indigo--text text-center mt-5">로그인</h1>
    <v-container>
      <v-row class="fill-height" justify="center" align="center">
        <v-col cols="12" sm="8" md="6" lg="5" xl="4" class="text-center">
          <v-form class="pa-3" v-model="valid" ref="form">
            <v-card outlined class="pa-5">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="이메일"
                    required
                    prepend-icon="mdi-account-box-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="password"
                    v-model="password"
                    :counter="8"
                    :rules="passwordRules"
                    label="비밀번호"
                    required
                    prepend-icon="mdi-lock-outline"
                  ></v-text-field>
                  <p class="red--text">
                    {{ error }}
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    dark
                    color="indigo"
                    @click.prevent="login"
                    :loading="loading"
                    >들어가기</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <ResetPasswordModal :email="email" v-if="error" />
                  <!-- <v-btn @click="resetPassword" text color="primary">
                    비밀번호를 잊었어요
                    <v-icon>mdi-cursor-default-click-outline</v-icon>
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ResetPasswordModal from "../components/ResetPasswordModal.vue";
import { projectAuth } from "../firebase/config";

export default {
  name: "Login",
  components: { ResetPasswordModal },
  data: () => ({
    loading: false,
    error: "",
    valid: true,
    email: "",
    emailRules: [
      (v) => !!v || "이메일은 꼭 필요해요",
      (v) => /.+@.+\..+/.test(v) || "유효한 이메일이 필요해요",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "비밀번호는 꼭 필요해요",
      (v) => (v && v.length >= 8) || "비밀번호는 8자 이상으로 하세요",
    ],
  }),
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        projectAuth
          .signInWithEmailAndPassword(this.email, this.password)
          .then((cred) => {
            this.loading = false;
            this.$router.push({ name: "Home" });
          })
          .catch((err) => {
            this.loading = false;
            if (
              err.message ==
              "The password is invalid or the user does not have a password."
            ) {
              this.error = "비밀번호가 틀리거나 없어요!";
            } else if (
              err.message ==
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
            ) {
              this.error =
                "너무 많이 틀렸어요 ㅜㅜ 비밀번호를 다시 확인해 보세요.";
            } else if (
              (err.message =
                "There is no user record corresponding to this identifier. The user may have been deleted.")
            ) {
              this.error = "일치하는 사용자 id가 없습니다.";
            } else {
              this.error = err.message;
            }
            console.log(err.message);
          });
      }
    },
    // reset() {
    //   this.$refs.form.reset();
    // },
  },
};
</script>

<style>
.login {
  height: 600px;
  padding: 150px 0;
}
</style>
