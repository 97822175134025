<template>
  <div class="navbar">
    <v-app-bar app flat>
      <!-- draw icon -->
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->

      <!-- title -->
      <v-toolbar-title>
        <router-link to="/">
          <v-img src="/logo.png" max-width="100"></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- action icons -->
      <v-btn text v-if="!user" color="grey" to="/signup">
        <v-icon left>mdi-account-plus-outline</v-icon>
        <span>회원가입</span>
      </v-btn>

      <v-btn text v-if="!user" color="grey" to="/login">
        <v-icon left>mdi-login-variant</v-icon>
        <span>로그인</span>
      </v-btn>

      <v-btn
        text
        v-if="email == 'cchonhee@daum.net' || email == 'iwonder3@daum.net'"
        color="teal"
        to="/admin"
      >
        <v-icon left>mdi-account-outline</v-icon>
        <span class="d-none d-sm-flex">관리자</span>
      </v-btn>

      <v-btn text v-if="user" color="teal" to="/classroom-english">
        <v-icon left>mdi-google-classroom</v-icon>
        <span class="d-none d-sm-flex">Classroom English</span>
      </v-btn>

      <v-btn text v-if="user" color="indigo" @click="logout">
        <v-icon left>mdi-logout</v-icon>
        <span class="d-none d-sm-flex">로그아웃</span>
      </v-btn>
    </v-app-bar>

    <!-- side menu -->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            IWonder
          </v-list-item-title>
          <v-list-item-subtitle>
            with children
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item router to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item router :to="{ name: 'PlayAndThink' }">
          <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Play & Think</v-list-item-title>
        </v-list-item>
        <v-list-item router :to="{ name: 'Hangeul' }">
          <v-list-item-icon>
            <v-icon>mdi-crown-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>한글밥상</v-list-item-title>
        </v-list-item>

        <!-- Play && Think -->
        <!-- <v-list-group prepend-icon="mdi-music">
          <template v-slot:activator>
            <v-list-item-title>Play &amp; Think</v-list-item-title>
          </template>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K1</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in PlayAndThinkThree"
              :key="i"
              link
              router
              :to="{
                name: 'PlayAndThinkDetails',
                params: { volume: `PT-K10${i + 1}` },
              }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K2</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in PlayAndThinkFour"
              :key="i"
              link
              router
              :to="{
                name: 'PlayAndThinkDetails',
                params: { volume: `PT-K10${i + 1}` },
              }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K3</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in PlayAndThinkFive"
              :key="i"
              link
              router
              :to="{
                name: 'PlayAndThinkDetails',
                params: { volume: `PT-K10${i + 1}` },
              }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group> -->

        <!-- Fun & Hunt -->
        <!-- <v-list-group prepend-icon="mdi-book-open-page-variant-outline">
          <template v-slot:activator>
            <v-list-item-title>Fun & Hunt</v-list-item-title>
          </template>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K1</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in FunAndHuntThree"
              :key="i"
              link
              router
              :to="{
                name: 'FunAndHuntDetails',
                params: { volume: `FA-K10${i + 1}` },
              }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K2</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in FunAndHuntFour"
              :key="i"
              link
              router
              :to="{
                name: 'FunAndHuntDetails',
                params: { volume: `FA-K20${i + 1}` },
              }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K3</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in FunAndHuntFive"
              :key="i"
              link
              router
              :to="{
                name: 'FunAndHuntDetails',
                params: { volume: `FA-K30${i + 1}` },
              }"
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group> -->

        <!-- Cooking -->
        <!-- <v-list-group prepend-icon="mdi-stove">
          <template v-slot:activator>
            <v-list-item-title>Cooking Science</v-list-item-title>
          </template>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K1</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in CookingThree"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K2</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in CookingFour"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K3</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in CookingFive"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group> -->

        <!-- Hangeul -->
        <!-- <v-list-group prepend-icon="mdi-crown-outline">
          <template v-slot:activator>
            <v-list-item-title>한글밥상</v-list-item-title>
          </template>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K1</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in HangeulThree"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K2</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in HangeulFour"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>K3</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="([title, icon], i) in HangeulFive"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group> -->
      </v-list>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 lime--text text--darken-2">
            For Teachers
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list>
        <v-list-item link router :to="{ name: 'ClassroomEnglish' }">
          <v-list-item-icon>
            <v-icon>mdi-google-classroom</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Classroom English</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { projectAuth } from "../firebase/config";

export default {
  name: "Navbar",
  data() {
    return {
      user: "",
      email: "",
      drawer: null,
    };
  },
  methods: {
    logout() {
      projectAuth.signOut().then(() => {
        console.log("logout");
        this.$router.push({ name: "Login" });
      });
    },
  },
  mounted() {
    projectAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.email = user.email;
        console.log(this.user);
      } else {
        this.user = null;
      }
    });
  },
};
</script>

<style></style>
