<template>
  <v-dialog v-model="dialog" max-width="800" overlay-opacity="0.8" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card v-if="content" v-bind="attrs" v-on="on">
          <v-img :src="content.imageURL" height="300px" class="week-image">
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal text-h5 white--text"
                style="height: 100%;"
              >
                {{ content.songTitle || content.sceneTitle }}
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-hover>
    </template>

    <v-img
      v-if="content.htmlURL"
      class="align-center mx-auto"
      :src="content.imageURL"
    >
      <v-btn
        fab
        color="purple"
        dark
        large
        :href="content.htmlURL"
        target="_blank"
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </v-img>
    <v-img
      v-else-if="content.songTitle"
      class="align-end"
      :src="content.imageURL"
    >
      <audio
        style="width: 90%;"
        controls
        :src="content.mp3URL"
        controlsList="nodownload"
      ></audio>
    </v-img>
    <!-- <audio
      v-else-if="content.songTitle"
      controls
      :src="content.mp3URL"
      controlsList="nodownload"
    ></audio> -->
    <video
      v-else-if="content.videoTitle"
      width="100%"
      :poster="content.imageURL"
      controls
      :src="content.videoURL"
      controlsList="nodownload"
    ></video>
  </v-dialog>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.week-image {
  filter: grayscale(0%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
