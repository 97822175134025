<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="blue" dark v-bind="attrs" v-on="on">
          비밀번호를 다른 것으로 바꿀래요.<v-icon
            >mdi-cursor-default-click-outline</v-icon
          >
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <small
            >*이메일 확인하시고 <span class="primary--text">보내기</span> 버튼
            누르세요.</small
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="Email"
                  required
                ></v-text-field>
              </v-col>
              <v-col v-if="error" cols="12">
                <p class="error--text">{{ error }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetPassword">
            보내기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { projectAuth } from "../firebase/config";

export default {
  props: ["email"],
  data: () => ({
    dialog: false,
    error: "",
  }),
  methods: {
    resetPassword() {
      projectAuth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.dialog = false;
          alert(
            "비밀번호 재설정 이메일이 전송되었습니다. 이메일을 확인해주세요. 메일이 도착하는데 시간이 좀 걸릴 수 있어요. 보낸 사람은 'noreply'로 나타날 거에요. 스펨 아니니 지우지 마세요!"
          );
        })
        .catch((error) => {
          this.error = error.message;
          console.log(this.error);
          alert("비밀번호 재설정 중 오류가 발생했습니다. 다시 시도해주세요.");
        });
    },
  },
};
</script>

<style></style>
