<template>
  <div class="play-and-think-details">
    <v-container>
      <!-- weeks -->
      <v-row class="my-1">
        <v-col
          cols="12"
          sm="6"
          lg="3"
          v-for="week in book.weeks"
          :key="week.name"
        >
          <SceneModal :week="week" :level_volume="level_volume" />
        </v-col>
      </v-row>

      <!-- steam / supplement -->
      <v-row class="my-1">
        <!-- steam -->
        <v-col cols="12" md="6">
          <v-card class="mx-auto" flat>
            <v-img
              class="white--text"
              src="/steams.jpg"
              height="300px"
              @click="book.steam.show = !book.steam.show"
            >
              <v-card-title>STEAM</v-card-title>
            </v-img>

            <v-expand-transition>
              <div v-show="book.steam.show">
                <v-row>
                  <v-col
                    cols="6"
                    v-for="(link, i) in book.steam.links"
                    :key="i"
                    class="mt-3"
                  >
                    <SteamModal :link="link" />
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <!-- supplement -->
        <v-col cols="12" md="6">
          <v-card class="mx-auto" flat>
            <v-img
              class="white--text"
              src="/supplement.jpeg"
              height="300px"
              @click="book.supplement.show = !book.supplement.show"
            >
              <v-card-title>SUPPLEMENT</v-card-title>
            </v-img>

            <v-expand-transition>
              <div v-show="book.supplement.show">
                <v-row>
                  <v-col
                    cols="6"
                    v-for="(item, i) in book.supplement.links"
                    :key="i"
                    class="mt-3"
                  >
                    <v-card>
                      <v-img
                        class="align-end"
                        :src="item.imageURL"
                        :lazy-src="
                          `https://picsum.photos/10/6?image=${i * 5 + 10}`
                        "
                        aspect-ratio="1"
                      >
                        <v-btn
                          small
                          dark
                          :color="item.color"
                          :href="item.URL"
                          target="_blank"
                        >
                          <span>DOWNLOAD</span>
                          <v-icon right>mdi-download</v-icon>
                        </v-btn>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SceneModal from "../components/SceneModal.vue";
import SteamModal from "../components/SteamModal.vue";
import { projectStorage } from "../firebase/config";

export default {
  components: { SceneModal, SteamModal },
  data() {
    return {
      levels: this.$store.state.items,
      level_volume: this.$route.params.level_volume,
    };
  },
  computed: {
    levelNumber() {
      return parseInt(this.level_volume.slice(1, 2)) - 1;
    },
    bookNumber() {
      return parseInt(this.level_volume.slice(3, 5)) - 1;
    },
    book() {
      return this.levels[this.levelNumber].books[this.bookNumber];
    },
  },
  methods: {
    fillSupplement() {
      this.book.supplement.links.forEach((link) => {
        const title = "/supplements/" + this.level_volume + " " + link.title;
        projectStorage
          .ref(title + ".jpg")
          .getDownloadURL()
          .then((imageURL) => {
            link.imageURL = imageURL;
          })
          .catch((err) => {
            console.log(err);
          });
        projectStorage
          .ref(title + ".pdf")
          .getDownloadURL()
          .then((URL) => {
            link.URL = URL;
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    fillWeeks() {
      this.book.weeks.forEach((week) => {
        week.rounds.forEach((round) => {
          round.steps.forEach((step) => {
            // fill thumbnails
            let imagePath = "/thumbnails/" + step.videoTitle + ".jpg";
            projectStorage
              .ref(imagePath)
              .getDownloadURL()
              .then((imageURL) => {
                if (imageURL) {
                  step.imageURL = imageURL;
                }
              })
              .catch((err) => {
                console.log(err.message);
                step.imageURL =
                  "https://images.unsplash.com/photo-1571210862729-78a52d3779a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";
              });

            // fill videos
            if (!step.htmlURL && !step.videoURL) {
              let path = "/videos/" + step.videoTitle + ".mp4";
              projectStorage
                .ref(path)
                .getDownloadURL()
                .then((videoURL) => {
                  if (videoURL) {
                    step.videoURL = videoURL;
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                  step.videoURL =
                    "https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/rZJIMvhmliwmde8a6/videoblocks-little-boys-and-girls-playing-with-block-toys-in-playroom-kids-playing-with-developing-toys-indoor-creative-kids-learning-center_rvzekwq0e__634b285e66ab9f705dd1b613bb400463__P360.mp4";
                });
            }
          });
        });
      });
    },
  },
  mounted() {
    this.fillSupplement();
    this.fillWeeks();
    console.log(this.levelNumber);
  },
};
</script>

<style>
.link-image {
  filter: grayscale(0%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
