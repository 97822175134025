<template>
  <div class="chapter">
    <h3 class="my-3 py-3 blue-grey--text text-center">{{ chapter }}</h3>

    <!-- search -->
    <v-container>
      <form>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Cards"
          single-line
        ></v-text-field>
      </form>
    </v-container>

    <!-- cards -->
    <template v-for="situation in situations">
      <v-container :key="situation" class="mb-5">
        <v-subheader class="pink--text">{{ situation }}</v-subheader>
        <v-row>
          <v-col
            v-for="card in cardsSearched.filter(
              (card) => card.situation == situation
            )"
            :key="card.english"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-img
                :src="`https://picsum.photos/500/300?image=${getImage()}`"
                height="300px"
              >
              </v-img>
              <v-card-title class="blue-grey--text">{{
                card.english
              }}</v-card-title>
              <v-card-subtitle>{{ card.korean }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="grey--text">
                  <span>Speak</span>
                  <v-icon right>mdi-volume-high</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
// import { projectFirestore } from '../firebase/config'
import { classroomEnglish } from "../data/db";

export default {
  data() {
    return {
      search: "",
      cards: [],
      chapter: this.$route.params.chapter,
    };
  },
  mounted() {
    this.cards = classroomEnglish.filter((item) => {
      return item.chapter == this.chapter;
    });
  },
  computed: {
    cardsSearched() {
      return this.cards.filter((card) => {
        return (
          card.english.match(this.search) ||
          card.korean.match(this.search) ||
          card.title.match(this.search) ||
          card.situation.match(this.search)
        );
      });
    },
    situations() {
      const situations = new Set();
      this.cards.forEach((card) => {
        situations.add(card.situation);
      });
      // return Array.from(situations).sort()
      return [...situations];
    },
  },
  methods: {
    getImage() {
      const min = 530;
      const max = 560;

      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style></style>
