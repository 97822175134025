<template>
  <v-dialog v-model="dialog" max-width="800" overlay-opacity="0.8" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card v-bind="attrs" v-on="on">
          <v-img :src="part.imageURL" class="week-image">
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal text-h5 white--text"
                style="height: 100%;"
              >
                PLAY
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-hover>
    </template>

    <v-card class="pa-5">
      <v-row>
        <v-col
          v-for="(audio, index) in part.content"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card>
            <v-img :src="audio.imageURL" class="align-end"></v-img>
            <audio
              style="width: 100%;"
              controls
              :src="audio.mp3URL"
              controlsList="nodownload"
            ></audio>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["part"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.week-image {
  filter: grayscale(0%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
audio::-webkit-media-controls-panel {
  background-color: #fff;
}
</style>
