<template>
  <div class="memory-card">
    <div :class="{ flipped: flipped }">
      <v-img class="front" :src="card.src" alt="card front" />
      <v-img
        class="back"
        src="/img/memory/card-back01.png"
        alt="card back"
        @click="handleClick(card)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "handleChoice", "flipped", "disabled"],
  data() {
    return {
      voices: [],
    };
  },
  methods: {
    handleClick(card) {
      if (!this.disabled) {
        this.handleChoice(card);

        // message
        const startIndex = card.src.lastIndexOf("%2F") + 3;
        const endIndex = card.src.indexOf(".png");
        let message = card.src.slice(startIndex, endIndex);
        let sentence = new SpeechSynthesisUtterance(message);
        // sentence.lang = "en-US";
        // sentence.rate = 0.8;
        // sentence.pitch = 1;

        this.populateVoiceList();

        const voices = this.voices.filter((voice) => voice.lang.includes("en"));

        // for testing
        // let textForTesting = [];
        // voices.forEach((voice) => {
        //   textForTesting.push(voice.name);
        // });
        // alert(textForTesting);

        const randomNumber = Math.floor(Math.random() * voices.length);
        sentence.voice = voices[randomNumber];

        // sentence.voice = voices[0];
        speechSynthesis.cancel();
        speechSynthesis.speak(sentence);
      }
    },
    populateVoiceList() {
      this.voices = speechSynthesis.getVoices();
    },
  },
  created() {
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = this.populateVoiceList();
    }
  },
};
</script>

<style>
.memory-card {
  position: relative;
}
.memory-card .v-image__image.v-image__image--cover {
  width: 100%;
  display: block;
  border: 1px solid #fff;
  border-radius: 6px;
}

/* front of card - the picture */
.memory-card .front {
  transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  position: absolute;
}
.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0s;
}

/* back of card - cover */
.memory-card .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
}
.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}
</style>
