var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"play-and-think"},[(_vm.member == '부모님')?_c('v-container',[_vm._v(" 선생님만 보실 수 있습니다. ")]):_c('v-container',[_vm._l((_vm.levels),function(level,i){return _c('v-row',{key:i},[_vm._l((level.books),function(book,j){return [(_vm.levelOfEnglish.includes(("K-" + (i + 1))))?_c('v-col',{key:j,staticClass:"pa-1",attrs:{"cols":"4","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('router-link',{attrs:{"to":{
                  name: 'PlayAndThinkDetails',
                  params: {
                    level_volume: ((level.level) + "-" + (book.volume)),
                  },
                }}},[_c('v-img',{attrs:{"src":("/img/stories/" + (level.level) + "-" + (book.volume) + ".jpg")}},[_c('v-card-title',{class:("white--text " + (level.color))},[_vm._v(" "+_vm._s(level.level)+"- "+_vm._s(book.volume != "10" ? book.volume[1] : book.volume)+" ")])],1)],1)],1)]}}],null,true)})],1):_vm._e()]})],2)}),_c('v-row')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }