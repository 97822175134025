<template>
  <div class="signup py-0">
    <v-container>
      <v-stepper class="mx-auto" max-width="600" outlined v-model="e6" vertical>
        <!-- 회원선택 -->
        <v-stepper-step color="indigo" :complete="e6 > 1" step="1">
          회원선택
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card outlined color="blue-grey lighten-5" class="mb-6 pa-3">
            <v-radio-group v-model="user.member" row>
              <v-radio color="purple" label="기관" value="기관"></v-radio>
              <v-radio color="purple" label="부모님" value="부모님"></v-radio>
            </v-radio-group>
            <v-card-text v-if="feedback" class="pink--text">{{
              feedback
            }}</v-card-text>
          </v-card>
          <v-btn dark color="indigo" @click="validateOne">
            다음
          </v-btn>
        </v-stepper-content>

        <!-- 기본정보 -->
        <v-stepper-step color="indigo" :complete="e6 > 2" step="2">
          기본정보
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card outlined color="blue-grey lighten-5" class="mb-6 pa-5">
            <v-select
              v-model="user.kindergarten"
              :items="kindergartenNames"
              menu-props="auto"
              label="기관 이름"
              prepend-icon="mdi-bus-school"
              :rules="[(v) => !!v || '기관 이름은 꼭 필요해요!']"
              required
            ></v-select>
            <v-select
              v-model="user.classroom"
              :items="classroomNames"
              menu-props="auto"
              label="반 이름"
              prepend-icon="mdi-google-classroom"
              :rules="[(v) => !!v || '반 이름은 꼭 필요해요!']"
              required
            ></v-select>
            <v-text-field
              v-model="user.name"
              :rules="nameRules"
              label="이름"
              placeholder="조민아"
              prepend-icon="mdi-teddy-bear"
              required
            ></v-text-field>
            <v-text-field
              type="email"
              v-model="user.email"
              :rules="emailRules"
              label="이메일(ID)"
              placeholder="abckids@iwonder.com"
              prepend-icon="mdi-at"
              required
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="user.password"
              :counter="8"
              :rules="passwordRules"
              label="비밀번호"
              placeholder="12345678"
              required
              prepend-icon="mdi-lock-outline"
            ></v-text-field>
            <v-card-text v-if="feedbackTwo" class="pink--text">{{
              feedbackTwo
            }}</v-card-text>
          </v-card>
          <v-btn text @click="e6 = 1">
            이전
          </v-btn>
          <v-btn dark color="indigo" @click="validateTwo">
            다음
          </v-btn>
        </v-stepper-content>

        <!-- 구독정보 -->
        <v-stepper-step color="indigo" :complete="e6 > 3" step="3">
          구독정보
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card outlined color="blue-grey lighten-5" class="mb-6 pa-5">
            <v-row>
              <!-- English -->
              <v-col cols="6">
                <v-card-title class="orange--text text-darken-3"
                  >English</v-card-title
                >
                <v-card-text>
                  <v-checkbox
                    v-model="user.levelOfEnglish"
                    label="K-1"
                    color="purple"
                    value="K-1"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="user.levelOfEnglish"
                    label="K-2"
                    color="purple"
                    value="K-2"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="user.levelOfEnglish"
                    label="K-3"
                    color="purple"
                    value="K-3"
                    hide-details
                  ></v-checkbox>
                </v-card-text>
              </v-col>
              <!-- 한글 -->
              <v-col cols="6">
                <v-card-title class="green--text text-darken-3"
                  >한글</v-card-title
                >
                <v-card-text>
                  <v-checkbox
                    v-model="user.levelOfKorean"
                    label="한글1"
                    color="purple"
                    value="한글1"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="user.levelOfKorean"
                    label="한글2"
                    color="purple"
                    value="한글2"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="user.levelOfKorean"
                    label="한글3"
                    color="purple"
                    value="한글3"
                    hide-details
                  ></v-checkbox>
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-text v-if="feedbackThree" class="pink--text">{{
              feedbackThree
            }}</v-card-text>
          </v-card>
          <v-btn text @click="e6 = 2">
            이전
          </v-btn>
          <v-btn dark color="indigo" @click="validateThree">
            다음
          </v-btn>
        </v-stepper-content>

        <v-stepper-step color="indigo" step="4">
          가입하기
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card outlined color="blue-grey lighten-5" class="mb-6">
            <v-card-text>
              <p class="text-subtitle-2 text--primary">
                회원종류: <span class="purple--text">{{ user.member }}</span>
              </p>
              <p class="text-subtitle-2 text--primary">
                호칭: <span class="purple--text">{{ alias }}</span>
              </p>
              <p class="text-subtitle-2 text--primary">
                이메일(ID): <span class="purple--text">{{ user.email }}</span>
              </p>
              <p class="text-subtitle-2 text--primary">
                비밀번호: <span class="purple--text">{{ user.password }}</span>
              </p>
              <p class="text-subtitle-2 text--primary">
                English:
                <v-chip
                  outlined
                  v-for="level in user.levelOfEnglish"
                  :key="level"
                  class="purple--text mr-2"
                  >{{ level }}</v-chip
                >
              </p>
              <p class="text-subtitle-2 text--primary">
                한글:
                <v-chip
                  outlined
                  v-for="level in user.levelOfKorean"
                  :key="level"
                  class="purple--text mr-2"
                  >{{ level }}</v-chip
                >
              </p>
              <v-divider></v-divider>
              <p class="mt-3">
                * <span class="purple--text">'이메일'</span>과
                <span class="purple--text">'비밀번호'</span> 확인하시고,
                계속하시려면 <span class="purple--text">'가입'</span>을 눌러
                주세요!
              </p>
              <p class="mt-3 pink--text">{{ feedback }}</p>
            </v-card-text>
          </v-card>
          <v-btn text @click="e6 = 3">
            이전
          </v-btn>
          <v-btn dark color="purple" @click.prevent="signup" :loading="loading">
            가입
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import { projectAuth } from "../firebase/config";
import { projectFirestore } from "../firebase/config";
import slugify from "slugify";

export default {
  name: "Signup",
  data: () => ({
    e6: 1,
    user: {
      member: "",
      kindergarten: "",
      classroom: "",
      name: "",
      email: "",
      password: "",
      levelOfEnglish: [],
      levelOfKorean: [],
    },
    loading: false,
    error: "",
    feedback: "",
    feedbackTwo: "",
    feedbackThree: "",
    kindergartenNames: [],
    kindergartenRules: [
      (v) => !!v || "기관 이름은 꼭 필요해요",
      (v) => (v && v.length >= 3) || "3자 이상으로 하세요",
    ],
    classroomNames: [],
    classroomRules: [
      (v) => !!v || "반 이름은 꼭 필요해요",
      (v) => (v && v.length >= 2) || "2자 이상으로 하세요",
    ],
    nameRules: [
      (v) => !!v || "이름은 꼭 필요해요",
      (v) => (v && v.length >= 2) || "2자 이상으로 하세요",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "이메일은 꼭 필요해요",
      (v) => /.+@.+\..+/.test(v) || "유효한 이메일이 필요해요",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "비밀번호는 꼭 필요해요",
      (v) => (v && v.length >= 8) || "비밀번호는 8자 이상으로 하세요",
    ],
  }),
  methods: {
    signup() {
      if (this.user.email && this.user.name) {
        this.loading = true;

        // slugify
        const slug = slugify(this.alias, {
          replacement: "-",
          remove: /[$*_=~.()'"!\-:@]/g,
          lower: true,
        });

        // firestore
        let ref = projectFirestore.collection("users").doc(slug);
        ref.get().then((doc) => {
          if (doc.exists) {
            this.feedback =
              "* 기관/반/성명이 모두 같은 다른 친구가 있어요. '기본정보' 단계로 돌아가 '멋진홍길동' 처럼 구별될 수 있는 이름으로 바꿔 주세요";
            this.loading = false;
          } else {
            projectAuth
              .createUserWithEmailAndPassword(
                this.user.email,
                this.user.password
              )
              .then((cred) => {
                ref
                  .set({
                    alias: this.alias,
                    member: this.user.member,
                    user_id: cred.user.uid,
                    email: cred.user.email,
                    levelOfEnglish: this.user.levelOfEnglish,
                    levelOfKorean: this.user.levelOfKorean,
                  })
                  .then(() => {
                    this.loading = false;
                    this.$router.push({ name: "Home" });
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.feedback = err.message;
                    console.log(err.message);
                  });
              })
              .catch((err) => {
                this.loading = false;
                if (
                  err.message ==
                  "The email address is already in use by another account."
                ) {
                  this.feedback = '"이미 사용되고 있는 이메일 주소입니다."';
                }
                console.log(err.message);
              });
            this.feedback = "";
          }
        });
      }
    },
    validateOne() {
      if (this.user.member) {
        this.feedback = "";
        this.e6 = 2;
      } else {
        this.feedback = "회원을 선택하고 '다음'을 누르세요!";
      }
    },
    validateTwo() {
      if (
        this.user.kindergarten &&
        this.user.classroom &&
        this.user.name &&
        this.user.email &&
        this.user.password
      ) {
        this.feedbackTwo = "";
        this.e6 = 3;
      } else {
        this.feedbackTwo = "내용을 채우고 '다음'을 누르세요!";
      }
    },
    validateThree() {
      if (
        this.user.levelOfEnglish.length > 0 ||
        this.user.levelOfKorean.length > 0
      ) {
        this.feedbackThree = "";
        this.e6 = 4;
      } else {
        this.feedbackThree =
          "'English' 혹은 '한글' 선택하고 '다음'을 누르세요!";
      }
    },
    // reset
  },
  computed: {
    alias() {
      return `${this.user.kindergarten} ${this.user.classroom} ${this.user.name}`;
    },
  },
  mounted() {
    projectFirestore
      .collection("data")
      .doc("kindergartens")
      .get()
      .then((doc) => {
        this.kindergartenNames = doc.data().names.sort();
      });
    projectFirestore
      .collection("data")
      .doc("classrooms")
      .get()
      .then((doc) => {
        this.classroomNames = doc.data().names.sort();
      });
  },
};
</script>

<style>
.signup {
  height: 800px;
  padding: 50px 0;
}
</style>
