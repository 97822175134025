<template>
  <div class="member">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-icon :color="color" v-bind="attrs" v-on="on">
          {{ icon }}
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h6 pink darken-3 white--text">
          {{ member.alias }}
        </v-card-title>

        <v-card-text class="py-5">
          <v-row>
            <!-- English -->
            <v-col cols="6">
              <v-checkbox
                v-model="member.levelOfEnglish"
                label="K-1"
                color="indigo"
                value="K-1"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="member.levelOfEnglish"
                label="K-2"
                color="indigo"
                value="K-2"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="member.levelOfEnglish"
                label="K-3"
                color="indigo"
                value="K-3"
                hide-details
              ></v-checkbox>
            </v-col>
            <!-- 한글 -->
            <v-col cols="6">
              <v-checkbox
                v-model="member.levelOfKorean"
                label="한글1"
                color="indigo"
                value="한글1"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="member.levelOfKorean"
                label="한글2"
                color="indigo"
                value="한글2"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="member.levelOfKorean"
                label="한글3"
                color="indigo"
                value="한글3"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <!-- <v-select
            v-model="member.levelOfEnglish"
            :items="['없음', 'K-1', 'K-2', 'K-3']"
            menu-props="auto"
            label="English"
            prepend-icon="mdi-ab-testing"
          ></v-select>
          <v-select
            v-model="member.levelOfKorean"
            :items="['없음', '한글1', '한글2', '한글3']"
            menu-props="auto"
            label="한글"
            prepend-icon="mdi-map"
          ></v-select> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updateMember">
            수정하기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { projectFirestore } from "../firebase/config";

export default {
  name: "Member",
  props: ["color", "icon", "member"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    updateMember() {
      const userId = this.member.id;
      projectFirestore
        .collection("users")
        .doc(userId)
        .update({
          levelOfEnglish: this.member.levelOfEnglish,
          levelOfKorean: this.member.levelOfKorean,
        })
        .then(() => {
          console.log("The member is updated");
          this.dialog = false;
        });
    },
  },
};
</script>

<style></style>
