<template>
  <div class="memory">
    <v-card outlined>
      <v-card-actions>
        <v-btn
          @click="selectCardLevel('Capital-A')"
          color="green"
          fab
          x-small
          dark
          ><v-icon>mdi-sort-alphabetical-ascending-variant</v-icon></v-btn
        >
        <v-btn
          @click="selectCardLevel('small-a')"
          color="purple"
          fab
          x-small
          dark
          ><v-icon>mdi-alphabet-latin</v-icon></v-btn
        >
        <v-btn @click="selectCardLevel('numbers')" color="pink" fab x-small dark
          ><v-icon>mdi-numeric</v-icon></v-btn
        >
        <v-btn @click="selectCardLevel('shapes')" color="teal" fab x-small dark
          ><v-icon>mdi-shape</v-icon></v-btn
        >
        <v-btn @click="selectCardLevel('family')" color="lime" fab x-small dark
          ><v-icon>mdi-account-group-outline</v-icon></v-btn
        >
        <!--for testing -->
        <!-- <v-btn @click="test" color="blue" fab x-small dark
          ><v-icon>mdi-plus</v-icon></v-btn
        > -->

        <v-spacer></v-spacer>
        <v-btn
          @click="selectCardLevel('words-one')"
          color="orange"
          fab
          x-small
          dark
          ><v-icon>mdi-image-outline</v-icon></v-btn
        >
        <v-btn
          @click="selectCardLevel('words-two')"
          color="lime"
          fab
          x-small
          dark
          ><v-icon>mdi-image-outline</v-icon></v-btn
        >
        <v-btn
          @click="selectCardLevel('words-three')"
          color="primary"
          fab
          x-small
          dark
          ><v-icon>mdi-image-outline</v-icon></v-btn
        >
      </v-card-actions>
      <v-row class="ma-0">
        <v-col class="ma-0 pa-0" cols="3" v-for="card in cards" :key="card.id">
          <MemoryCard
            :card="card"
            :handleChoice="handleChoice"
            :flipped="card === choiceOne || card === choiceTwo || card.matched"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text
          >Turns:
          <v-chip outlined color="pink" class="ml-2">{{ turns }}</v-chip></v-btn
        >

        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="shuffleCards">
          Start
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { projectStorage } from "../firebase/config";
import MemoryCard from "../components/MemoryCard.vue";

export default {
  props: ["link"],
  components: {
    MemoryCard,
  },
  data() {
    return {
      cardLevel: "Capital-A",
      largeLetters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      smallLetters: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "x",
      ],
      numbers: [
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
      ],
      shapes: ["circle", "heart", "hexagon", "oval", "pentagon", "triangle"],
      family: [
        "baby",
        "brother",
        "daddy",
        "grandma",
        "grandpa",
        "mommy",
        "sister",
      ],
      wordsOne: [
        "apple",
        "banana",
        "cookie",
        "doughnut",
        "egg",
        "fish",
        "fox",
        "grapes",
        "ham",
        "igloo",
        "jam",
        "kiwi",
        "lemon",
        "milk",
        "mouth",
        "nose",
        "nuts",
        "orange",
        "pizza",
        "queen",
        "rice",
        "tomato",
        "up",
        "van",
        "waffle",
        "yogurt",
        "zucchini",
      ],
      wordsTwo: [
        "acorn",
        "apron",
        "bun",
        "butter",
        "cake",
        "carrot",
        "dishes",
        "dumpling",
        "eel",
        "eggplant",
        "fork",
        "fruit",
        "garlic",
        "gum",
        "honey",
        "hotdog",
        "ice cream",
        "iguana",
        "jellybean",
        "juice",
        "ketchup",
        "lemon",
        "lettuce",
        "lollipop",
        "melon",
        "muffin",
        "napkin",
        "noodles",
        "octopus",
        "onion",
        "ox",
        "pineapple",
        "pot",
        "quail",
        "quince",
        "rabbit",
        "raisins",
        "salad",
        "soup",
        "spoon",
        "tent",
        "toaster",
        "tomato",
        "umbrella",
        "umpire",
        "vest",
        "vine",
        "water",
        "window",
        "yacht",
        "yarn",
        "zebra",
        "zipper",
      ],
      wordsThree: [
        "bib",
        "bus",
        "can",
        "cane",
        "cape",
        "cube",
        "cup",
        "fine",
        "hen",
        "hop",
        "hope",
        "hot",
        "huge",
        "kite",
        "lips",
        "mate",
        "mop",
        "mope",
        "net",
        "nut",
        "pan",
        "pig",
        "pine",
        "robe",
        "six",
        "sun",
        "ten",
        "tube",
        "web",
      ],
      cardImages: [
        { id: "", src: "", matched: false },
        { id: "", src: "", matched: false },
        { id: "", src: "", matched: false },
        { id: "", src: "", matched: false },
        { id: "", src: "", matched: false },
        { id: "", src: "", matched: false },
        // { src: "/img/memory/helmet-1.png", matched: false },
        // { src: "/img/memory/potion-1.png", matched: false },
        // { src: "/img/memory/ring-1.png", matched: false },
        // { src: "/img/memory/scroll-1.png", matched: false },
        // { src: "/img/memory/shield-1.png", matched: false },
        // { src: "/img/memory/sword-1.png", matched: false },
      ],
      cards: [],
      turns: 0,
      choiceOne: null,
      choiceTwo: null,
      disabled: false,
      voices: [],
    };
  },
  methods: {
    test() {
      const agent = navigator.userAgent;
      alert(agent);
    },
    shuffleCards() {
      let levelArray = [];
      if (this.cardLevel == "Capital-A") {
        levelArray = [...this.largeLetters];
      } else if (this.cardLevel == "small-a") {
        levelArray = [...this.smallLetters];
      } else if (this.cardLevel == "numbers") {
        levelArray = [...this.numbers];
      } else if (this.cardLevel == "shapes") {
        levelArray = [...this.shapes];
      } else if (this.cardLevel == "family") {
        levelArray = [...this.family];
      } else if (this.cardLevel == "words-one") {
        levelArray = [...this.wordsOne];
      } else if (this.cardLevel == "words-two") {
        levelArray = [...this.wordsTwo];
      } else if (this.cardLevel == "words-three") {
        levelArray = [...this.wordsThree];
      }

      const selectedArray = levelArray
        .sort(() => Math.random() - 0.5)
        .splice(0, 6);

      this.cardImages.forEach((cardImage, i) => {
        const fileName =
          "/memoryGames/" + this.cardLevel + "/" + selectedArray[i];
        projectStorage
          .ref(fileName + ".png")
          .getDownloadURL()
          .then((imageURL) => {
            cardImage.src = imageURL;
          })
          .then(() => {
            const shuffledCards = [...this.cardImages, ...this.cardImages]
              .sort(() => Math.random() - 0.5)
              .map((card) => ({ ...card, id: Math.random() }));
            this.cards = shuffledCards;
            this.turns = 0;
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleChoice(card) {
      this.choiceOne ? (this.choiceTwo = card) : (this.choiceOne = card);
    },
    resetTurn() {
      this.choiceOne = null;
      this.choiceTwo = null;
      this.turns += 1;
      this.disabled = false;
    },

    selectCardLevel(level) {
      this.cardLevel = level;
      this.shuffleCards();
    },
    populateVoiceList() {
      this.voices = speechSynthesis.getVoices();
    },
  },
  mounted() {
    this.shuffleCards();
  },
  watch: {
    choiceTwo() {
      if (this.choiceOne && this.choiceTwo) {
        this.disabled = true;
        if (this.choiceOne.src == this.choiceTwo.src) {
          // select message
          const messages = [
            "Good job!",
            "Excellent!",
            "Super nice!",
            "Way to go!",
            "Well done!",
          ];
          const randomMessage = Math.floor(Math.random() * messages.length);
          const message = messages[randomMessage];
          let sentence = new SpeechSynthesisUtterance(message);

          // make sound
          this.populateVoiceList();
          const voices = this.voices.filter((voice) =>
            voice.lang.includes("en")
          );
          const randomNumber = Math.floor(Math.random() * voices.length);
          sentence.voice = voices[randomNumber];
          // speechSynthesis.cancel();
          speechSynthesis.speak(sentence);

          //   console.log("those cards match");
          this.cards.forEach((card) => {
            if (card.src === this.choiceOne.src) {
              card.matched = true;
            }
          });
          this.resetTurn();
        } else {
          //   console.log("those cards do not match");
          setTimeout(() => {
            this.resetTurn();
          }, 1000);
        }
        // console.log(this.cards);
      }
    },
  },
  created() {
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = this.populateVoiceList();
    }
  },
};
</script>

<style></style>
